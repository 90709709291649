import React, { createContext } from 'react'
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { SetOnlineUsers, AddTypingUser, RemoveTypingUser, getStaffChatRooms, ReceiveNewMessage } from './redux/staffChat/actionCreator';
import { setNotification } from './redux/authentication/actionCreator'
import { baseUrl, socketURL } from './constants';
const WebSocketContext = createContext(null)


export { WebSocketContext }

export default ({ children }) => {
    const dispatch = useDispatch()
    let socket;
    let ws;

    const sendMessage = (payload) => {
        socket.emit("send-message", JSON.stringify(payload));
    }

    const startTyping = (sender, receiver, roomId) => {
        const payload = { roomId, sender, receiver };
        socket.emit("start-typing", JSON.stringify(payload));
    }

    const stopTyping = (sender, receiver, roomId) => {
        const payload = { roomId, sender, receiver };
        socket.emit("stop-typing", JSON.stringify(payload));
    }
    const getSocket = (userId) => {
        if (!socket) {
            socket = io(`${socketURL}/admin?userId=${userId}`, {
                transports: ["websocket"],
            });
            socket.on("init", (onlineUsers) => {
                dispatch(SetOnlineUsers(onlineUsers));
            });
            // Event if user disconnected
            socket.on("user_disconnected", (onlineUsers) => {
                dispatch(SetOnlineUsers(onlineUsers));
            });

            // Event for start typing
            socket.on("user-start-typing", (data) => {
                dispatch(AddTypingUser(data));
            });

            // Event for stop typing
            socket.on("user-stop-typing", (data) => {
                dispatch(RemoveTypingUser(data));
            });

            // Event for send message success
            socket.on("message-sent-success", (data) => {

            });

            // Event for send message failed
            socket.on("message-sent-failed", (data) => {

            });

            // Event for receive message
            socket.on("receive-message", (data) => {
                dispatch(ReceiveNewMessage(data));
            });

            socket.on("notification", (data) => {
                // console.log({data})
                dispatch(setNotification(data.noOfUnseen))
            })

            dispatch(getStaffChatRooms(userId));
        }
    }

    const disconnectSocket = () => {
        socket?.close()
    }
    ws = {
        socket: socket,
        startTyping,
        stopTyping,
        sendMessage,
        getSocket,
        disconnectSocket
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}