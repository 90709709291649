import React from "react";
import {
	Route,
	Redirect,
} from "react-router-dom";

const PrivateRoute = ({ component: Component,isAuth, ...rest }) => {
    return (
        <Route
        {...rest}
        render={(props) => {
            if (isAuth) {
                    return <Component {...props} />;
				}
                return (
                    <Redirect
                        to={{ pathname: "/", state: { from: props.location } }}
                    />
                );
			}}
		/>
	);
};

export default PrivateRoute;