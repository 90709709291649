
const actions = {

  GET_RFQ_P_LIST_BEGIN: 'GET_RFQ_P_LIST_BEGIN',
  GET_RFQ_P_LIST_SUCCESS: 'GET_RFQ_P_LIST_SUCCESS',
  GET_RFQ_P_LIST_ERROR: 'GET_RFQ_P_LIST_ERROR',

  GET_RFQ_LIST_BEGIN: 'GET_RFQ_LIST_BEGIN',
  GET_RFQ_LIST_SUCCESS: 'GET_RFQ_LIST_SUCCESS',
  GET_RFQ_LIST_ERROR: 'GET_RFQ_LIST_ERROR',

  GET_RFQ_EXCEL_BEGIN: 'GET_RFQ_EXCEL_BEGIN',
  GET_RFQ_EXCEL_SUCCESS: 'GET_RFQ_EXCEL_SUCCESS',
  GET_RFQ_EXCEL_ERROR: 'GET_RFQ_EXCEL_ERROR',

  GET_RFQ_P_SUCCESS: 'GET_RFQ_P_SUCCESS',
  
  getRfqPListBegin: () => {
    return {
      type: actions.GET_RFQ_P_LIST_BEGIN,
    };
  },
  
  getRfqPListSuccess: data => {
    return {
      type: actions.GET_RFQ_P_LIST_SUCCESS,
      data
    };
  },
    
  getRfqPListError: err => {
    return {
      type: actions.GET_RFQ_P_LIST_ERROR,
      err
    };
  },

  getRfqListBegin: () => {
    return {
      type: actions.GET_RFQ_LIST_BEGIN,
    };
  },
  
  getRfqListSuccess: data => {
    return {
      type: actions.GET_RFQ_LIST_SUCCESS,
      data
    };
  },
    
  getRfqListError: err => {
    return {
      type: actions.GET_RFQ_LIST_ERROR,
      err
    };
  },

  getRfqExcelBegin: () => {
    return {
      type: actions.GET_RFQ_EXCEL_BEGIN,
    }
  },

  getRfqExcelSuccess: data => {
    return {
      type: actions.GET_RFQ_EXCEL_SUCCESS,
      data
    }
  },
  getRfqPSuccess: data => {
    return {
      type: actions.GET_RFQ_P_SUCCESS,
      data
    }
  },

  getRfqExcelError: err => {
    return {
      type: actions.GET_RFQ_EXCEL_ERROR,
      err
    }
  }
};

export default actions;
