const actions = {
  CLOCKINOUT_BEGIN: 'CLOCKINOUT_BEGIN',
  GET_CLOCKINOUT_SUCCESS: 'GET_CLOCKINOUT_SUCCESS',
  CLOCKINOUT_ERR: 'CLOCKINOUT_ERR',
  clockInOutBegin: () => {
    return {
      type: actions.CLOCKINOUT_BEGIN
    };
  },
  getClockInOutSuccess: data => {
    return {
      type: actions.GET_CLOCKINOUT_SUCCESS,
      data
    };
  },
  clockInOutErr: err => {
    return {
      type: actions.CLOCKINOUT_ERR,
      err
    };
  }
};

export default actions;
