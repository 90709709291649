import actions from './actions';

const initialState = {
  summaryData: null,
  suLoading: false,
  userChartData: null,
  ucLoading: false,
  error: null,
};

const {
  SUMMARY_OVERVIEW_BEGIN,
  SUMMARY_OVERVIEW_SUCCESS,
  SUMMARY_OVERVIEW_ERR,
  USER_CHART_BEGIN,
  USER_CHART_SUCCESS,
  USER_CHART_ERR,
} = actions;

const dashboardContentReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SUMMARY_OVERVIEW_BEGIN:
      return {
        ...state,
        suLoading: true,
      };
    case SUMMARY_OVERVIEW_SUCCESS:
      return {
        ...state,
        summaryData: data,
        suLoading: false,
      };
    case SUMMARY_OVERVIEW_ERR:
      return {
        ...state,
        error: err,
        suLoading: false,
      };

    case USER_CHART_BEGIN:
      return {
        ...state,
        ucLoading: true,
      };
    case USER_CHART_SUCCESS:
      return {
        ...state,
        userChartData: data,
        ucLoading: false,
      };
    case USER_CHART_ERR:
      return {
        ...state,
        error: err,
        ucLoading: false,
      };
    default:
      return state;
  }
};

export default dashboardContentReducer;
