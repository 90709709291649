import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const MarketingManagerList = lazy(() => import('../../container/pages/MarketingManagerListTable'));
// const EditMarketing = lazy(() => import('../../container/pages/EditMarketingForm'));
const EditDelivery = lazy(() => import('../../container/pages/EditDeliveryForm'));
const AddMarketing = lazy(() => import('../../container/pages/wizards/overview/AddMarketing'));

const MarketingManager = () => {
  const { path } = useRouteMatch();
  return (
    // <h1>Comming Soon..</h1>
    <Switch>
      {/* <Route path={`${path}/delivery/:id`} component={BuyerDetails} /> */}
      <Route exact path={`${path}`} component={MarketingManagerList} />
      {/* <Route path={`${path}/edit-marketing/:id`} component={EditMarketing} /> */}
      {/* <Route path={`${path}/Marketing-manager/add`} component={AddMarketing} /> */}
      <Route path={`${path}/add`} component={AddMarketing} />
    </Switch>
  );
};

export default MarketingManager;
