import React, { lazy } from 'react';
import RfqPDetails from '../container/rfq/RfqPDetails';

const RolesAndPermissions = lazy(() => import('../container/RolesAndPermissions'));
const CategoryListing = lazy(() => import('../container/CategoryListing'));
const ListingBrand = lazy(() => import('../container/listingbrand/ListingBrand'));
const SubCatListing = lazy(() => import('../container/CategoryListing/SubCatListing'));
const Reviews = lazy(() => import('../container/pages/reviews'));
const SearchKeywords = lazy(() => import('../container/pages/searchkeywords'));
const BulkImage = lazy(() => import('../container/pages/BulkImage'));
// import Deliveries from '../../container/deliveries'; not in used
// import Payments from '../../container/pages/payment'; not in used
const NewsList = lazy(() => import('../container/pages/NewsListDataTable'));
const EditNews = lazy(() => import('../container/pages/wizards/overview/EditNewsForm'));
const AddNews = lazy(() => import('../container/pages/wizards/overview/AddNews'));
const GallegaList = lazy(() => import('../container/pages/GallegaUserListTable/index.jsx'));
const GallegaEmails = lazy(() => import('../container/pages/GallegaEmails'));
const AddGallega = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'));
const FinanceList = lazy(() => import('../container/pages/FinanceManagerListTable'));
const StaffList = lazy(() => import('../container/pages/StaffListTable/index'));
const AddStaff = lazy(() => import('../container/pages/wizards/overview/AddStaff'));
const AddFinance = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'));
const AddMerchandiser = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'));
const MerchandiserList = lazy(() => import('../container/pages/MerchandiserListTable'));
const MarketingManagerList = lazy(() => import('../container/pages/MarketingManagerListTable'));
// const EditMarketing = lazy(() => import('../container/pages/EditMarketingForm'));
const AddMarketing = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'));
const SalesManagerList = lazy(() => import('../container/pages/SalesManagerListTable'));
const AddSalesManager = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'))
const DeliveryList = lazy(() => import('../container/pages/DeliveryScoutListTable'));
// const EditDelivery = lazy(() => import('../container/pages/EditDeliveryForm'));not in used
const AddDelivery = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'));
const SalesList = lazy(() => import('../container/pages/SalesScoutListTable'));
const AddSales = lazy(() => import('../container/pages/wizards/overview/AddAdminUser'));
// const EditSales = lazy(() => import('../container/pages/EditSalesForm'));not in used
const ClockList = React.lazy(() => import('../container/pages/ClockListDataTable'));
const Rfq = lazy(() => import('../container/rfq'));
const RfqExcelDetails = lazy(() => import('../container/rfq/RfqDetails'));
const RfqEditDetails = lazy(() => import('../container/rfq/RfqEdit'));
const Orders = lazy(() => import('../container/orders'));
const OrderDetails = lazy(() => import('../container/orders/OrderDetails'));

//BUILD A CART
const AllCarts = lazy(() => import('../container/pages/orders/AllCarts'));
const CreateOrder = lazy(() => import('../container/pages/orders/CreateOrder'));
const Checkout = lazy(() => import('../container/pages/orders/Checkout'));

const Messages = lazy(() => import('../container/messages'));
const Enquiries = lazy(() => import('../container/EnquiryPage'));
const EnquiryDetail = lazy(() => import('../container/EnquiryPage/EnquiryDetail'));
const Listing = lazy(() => import('../container/listing/Listings'));
const Grid = lazy(() => import('../container/listing/overview/Grid'));
const List = lazy(() => import('../container/listing/overview/List'));
const ListingEdit = lazy(() => import('../container/listing/EditListingForm'));
const ListingAdd = lazy(() => import('../container/listing/AddListingForm'));
const BulkListing = lazy(() => import('../container/bulkuploadedlisting'));
const BulkListDetail = lazy(() => import('../container/bulkuploadedlisting/Details'))
const ProductDetail = lazy(() => import('../container/pages/ProductDetail'))
const EditFormBulk = lazy(() => import('../container/pages/wizards/overview/EditFormBulk'));
const EditFormBulkSeller = lazy(() => import('../container/pages/wizards/overview/EditFormBulkSeller'));
// const AddUser = lazy(() => import('../container/pages/wizards/Wizards'));//not in used

//BUYER
const BuyerList = lazy(() => import('../container/pages/BuyerListDataTable'));
const NewBuyerList = lazy(() => import('../container/pages/NewBuyersTable'));
const BuyerAddresses = lazy(() => import('../container/pages/buyer-addresses'));
const AddBuyer = lazy(() => import('../container/pages/wizards/overview/AddBuyer'));
const EditBuyer = lazy(() => import('../container/pages/EditBuyerForm'));
const ViewBuyer = lazy(() => import('../container/pages/ViewBuyer'));
const BuyerDetails = lazy(() => import('../container/pages/BuyerDetails'));

//BULK BUYER
const BulkBuyer = lazy(() => import('../container/pages/BulkBuyer'));
const ViewBulkBuyer = lazy(() => import('../container/pages/ViewBulkBuyer'));

//SELLER
const SellerList = lazy(() => import('../container/pages/SellerListDataTable'));
const ViewSeller = lazy(() => import('../container/pages/ViewSeller'));
const AddSeller = lazy(() => import('../container/pages/wizards/overview/AddSeller'));
const EditSeller = lazy(() => import('../container/pages/EditSellerForm'));

//BULK SELLER
const BulkSeller = lazy(() => import('../container/pages/BulkSeller'));
const ViewBulkSeller = lazy(() => import('../container/pages/ViewBulkSeller'));

const OptList = lazy(() => import('../container/pages/Opt'));
const RequestList = lazy(() => import('../container/pages/RequestListDataTable'));
const RequestDetail = lazy(() => import('../container/pages/RequestDetail'));
const WareHouseList = lazy(() => import('../container/pages/warehouse/'));
const AddWareHouse = lazy(() => import('../container/pages/warehouse/AddWareHouse'));
const EditWareHouse = lazy(() => import('../container/pages/warehouse/EditWareHouse'));
const WalletList = lazy(() => import('../container/pages/wallet'));
const AddWallet = lazy(() => import('../container/pages/wallet/AddWallet'));
const EditWallet = lazy(() => import('../container/pages/wallet/EditWallet'));
const CouponList = lazy(() => import('../container/pages/coupon'));
const AddCoupon = lazy(() => import('../container/pages/coupon/AddCoupon'));
const EditCoupon = lazy(() => import('../container/pages/coupon/EditCoupon'));
const CouponListUser = lazy(() => import('../container/pages/coupon/User'));
const AddCouponUser = lazy(() => import('../container/pages/coupon/User/AddCoupon'));
const BannerList = lazy(() => import('../container/pages/banners'));
const AddBanner = lazy(() => import('../container/pages/banners/AddBanner'));
const EditBanner = lazy(() => import('../container/pages/banners/EditBanner'));
const ArchiveBanner = lazy(() => import('../container/pages/banners/ArchiveBanner'));
const Price = lazy(() => import('../container/pages/price'));
const AddPrice = lazy(() => import('../container/pages/price/AddPrice'));
const EditPrice = lazy(() => import('../container/pages/price/EditPrice'));
const Chat = React.lazy(() => import('../container/pages/Chat'));
const ChatBox = lazy(() => import('../container/pages/Chat/ChatBox/ChatBox'));
const ChatBoxAB = lazy(() => import('../container/pages/Chat/ChatBox/ABChatBox'));
const BuyerSellerChatBox = React.lazy(() => import('../container/pages/Chat/ChatBox/BuyerSellerChatBox'));
const Dashboard = lazy(() => import("../container/dashboard"))
const NewsletterSubsList = lazy(() => import('../container/pages/newsletter'));

//collection
const Collection = lazy(() => import('../container/pages/collection'));
const AddCollection = lazy(() => import('../container/pages/collection/AddCollection'));
const EditCollection = lazy(() => import('../container/pages/collection/EditCollection'));

//brands
const BrandList = lazy(() => import("../container/pages/brands"));
const AddBrand = lazy(() => import("../container/pages/brands/AddBrand"));
const EditBrand = lazy(() => import("../container/pages/brands/EditBrand"));
const BrandCategories = lazy(() => import("../container/pages/brands/BrandCategories"));
const BrandSubCategories = lazy(() => import("../container/pages/brands/BrandSubCategories"));
const HiddenCustomerList = lazy(() => import("../container/pages/users/HiddenCustomer/HiddenCustomer"));

//ERP Logs
const ERPLogs = lazy(() => import("../container/pages/erpLogs"));

//makes
const ListingMakes = lazy(() => import('../container/makes'));
const ListingModels = lazy(() => import('../container/models'));

//homepage settings
const HomepageSections = lazy(() => import('../container/pages/HomepageSections'));
const AddHomepageSection = lazy(() => import('../container/pages/HomepageSections/AddHomepageSection'));
const EditHomepageSection = lazy(() => import('../container/pages/HomepageSections/EditHomepageSection'));

// customer vat settting
const CustomerVATSetting = lazy(() => import('../container/customerVatSetting'))

// delivery time settings
const DeliveryTime = lazy(() => import('../container/pages/deliveryTime'))

// car inspection
const AddCarInspection = lazy(() => import('../container/pages/CarInspection/AddInspectionModule/index.jsx'))
const CarInspectionList = lazy(() => import('../container/pages/CarInspection/InspectionModuleList/index.jsx'))

const routes = [
    {
        path: '/',
        component: Dashboard,
        exact: true,
        name: 'Dashboard',
    },
    {
        path: '/newsletter',
        component: NewsletterSubsList,
        exact: true,
        name: 'NewsletterList',
    },
    {
        path: '/collections',
        component: Collection,
        exact: true,
        name: 'CollectionList',
        subRoutes: [
            {
                path: '/collections/add',
                component: AddCollection,
                exact: true,
                name: 'AddCollection',
            },
            {
                path: '/collections/edit/:id',
                component: EditCollection,
                exact: true,
                name: 'EditCollection',
            }
        ]
    },
    {
        path: '/banner',
        component: BannerList,
        exact: true,
        name: 'BannerList',
        subRoutes: [
            {
                path: '/banner/add',
                component: AddBanner,
                exact: true,
                name: 'AddBanner',
            },
            {
                path: '/banner/edit/:id',
                component: EditBanner,
                exact: true,
                name: 'EditBanner',
            },
            {
                path: '/banner/archive',
                component: ArchiveBanner,
                exact: true,
                name: 'ArchiveBanner',
            },
        ]
    },
    {
        path: '/price',
        component: Price,
        exact: true,
        name: 'Price',
        subRoutes: [
            {
                path: '/price/add',
                component: AddPrice,
                exact: true,
                name: 'AddPrice',
            },
            {
                path: '/price/edit/:id',
                component: EditPrice,
                exact: true,
                name: 'EditPrice',
            },
        ]
    },
    {
        path: '/coupon',
        component: CouponList,
        exact: true,
        name: 'CounponList',
        subRoutes: [
            {
                path: '/coupon/view/:id',
                component: CouponListUser,
                exact: true,
                name: 'CouponListUser',
            },
            {
                path: '/coupon/user/add/:id',
                component: AddCouponUser,
                exact: true,
                name: 'AddCouponUser',
            },
            {
                path: '/coupon/add',
                component: AddCoupon,
                exact: true,
                name: 'AddCoupon',
            },
            {
                path: '/coupon/edit/:id',
                component: EditCoupon,
                exact: true,
                name: 'EditCoupon',
            },
        ]
    },
    {
        path: '/wallets',
        component: WalletList,
        exact: true,
        name: 'WalletList',
        subRoutes: [
            {
                path: '/wallets/add',
                component: AddWallet,
                exact: true,
                name: 'AddWallet',
            },
            {
                path: '/wallets/edit/:id',
                component: EditWallet,
                exact: true,
                name: 'EditWallet',
            },
        ]
    },
    {
        path: '/warehouse',
        component: WareHouseList,
        exact: true,
        name: 'WareHouseList',
        subRoutes: [
            {
                path: '/warehouse/add',
                component: AddWareHouse,
                exact: true,
                name: 'AddWareHouse',
            },
            {
                path: '/warehouse/edit/:id',
                component: EditWareHouse,
                exact: true,
                name: 'EditWareHouse',
            },
        ]
    },
    {
        path: '/users',
        component: null,
        exact: true,
        name: 'Users',
        subRoutes: [
            {
                path: '/users/sellers',
                component: SellerList,
                exact: true,
                name: 'SellerList',
            },
            {
                path: '/users/buyers/:id',
                component: BuyerDetails,
                exact: true,
                name: "BuyerDetails"
            },
            {
                path: '/users/buyers',
                component: BuyerList,
                exact: true,
                name: "BuyerList"
            },
            {
                path: '/users/new-buyers',
                component: NewBuyerList,
                exact: true,
                name: "NewBuyerList"
            },
            {
                path: '/users/address',
                component: BuyerAddresses,
                exact: true,
                name: "BuyerAddress"
            },
            {
                path: '/users/opt',
                component: OptList,
                exact: true,
                name: "OptList"
            },
            {
                path: '/users/view-seller/:id',
                component: ViewSeller,
                exact: true,
                name: "ViewSeller"
            },
            {
                path: '/users/view-buyer/:id',
                component: ViewBuyer,
                exact: true,
                name: "ViewBuyer"
            },
            {
                path: '/users/edit-seller/:id',
                component: EditSeller,
                exact: true,
                name: "EditSeller"
            },
            {
                path: '/users/edit-buyer/:id',
                component: EditBuyer,
                exact: true,
                name: "EditBuyer"
            },
            {
                path: '/users/add',
                component: null,
                exact: true,
                name: "AddUser",
                subRoutes: [
                    {
                        path: '/users/add/buyer',
                        component: AddBuyer,
                        exact: true,
                        name: 'AddBuyer',
                    },
                    {
                        path: '/users/add/seller',
                        component: AddSeller,
                        exact: true,
                        name: 'AddSeller',
                    },
                    {
                        path: '/users/add/buyer-edit-bulk',
                        component: EditFormBulk,
                        exact: true,
                        name: 'EditBulkBuyer',
                    },
                    {
                        path: '/users/add/seller-edit-bulk',
                        component: EditFormBulkSeller,
                        exact: true,
                        name: 'EditBulkSeller',
                    },
                ]
            },
            {
                path: '/users/request/:id',
                component: RequestDetail,
                exact: true,
                name: "RequestDetail"
            },
            {
                path: '/users/request',
                component: RequestList,
                exact: true,
                name: "RequestList"
            },
            {
                path: '/users/bulkbuyer',
                component: BulkBuyer,
                exact: true,
                name: "BulkBuyer"
            },
            {
                path: '/users/view-bulk-buyer/:id',
                component: ViewBulkBuyer,
                exact: true,
                name: "ViewBulkBuyer"
            },
            {
                path: '/users/bulkseller',
                component: BulkSeller,
                exact: true,
                name: "BulkSeller"
            },
            {
                path: '/users/view-bulk-seller/:id',
                component: ViewBulkSeller,
                exact: true,
                name: "ViewBulkSeller"
            },
            {
                path: '/users/hidden-customer',
                component: HiddenCustomerList,
                exact: true,
                name: "HiddenCustomerList"
            }
        ]
    },
    {
        path: '/listings',
        component: Listing,
        exact: true,
        name: 'Listings',
        subRoutes: [
            {
                path: '/listings/grid',
                component: Grid,
                exact: true,
                name: 'LisitngGrid'
            },
            {
                path: '/listings/list',
                component: List,
                exact: true,
                name: 'LisitngList'
            },
            {
                path: '/listings/add',
                component: ListingAdd,
                exact: true,
                name: 'AddListing'
            },
            {
                path: '/listings/edit/:id',
                component: ListingEdit,
                exact: true,
                name: 'EditListing'
            },
            {
                path: '/listings/view-product-details/:id',
                component: ProductDetail,
                exact: true,
                name: 'ListingDetail'
            },
            {
                path: '/listings/bulklistings',
                component: BulkListing,
                exact: true,
                name: 'BulkListing'
            },
            {
                path: '/listings/view-bulk-detail/:id',
                component: BulkListDetail,
                exact: true,
                name: "BulkListDetail"
            }
        ]
    },
    {
        path: '/messages',
        component: Messages,
        exact: true,
        name: 'Messages'
    },
    {
        path: '/enquiries',
        component: Enquiries,
        exact: true,
        name: 'Enquiries',
        subRoutes: [
            {
                path: '/enquiries/:id',
                component: EnquiryDetail,
                exact: true,
                name: 'EnquiryDetail'
            }
        ]
    },
    {
        path: '/orders',
        component: Orders,
        exact: true,
        name: 'Orders',
        subRoutes: [
            {
                path: '/orders/view/:id',
                component: OrderDetails,
                exact: true,
                name: 'OrderDetail'
            },
            {
                path: '/orders/carts',
                component: AllCarts,
                exact: true,
                name: 'AllCarts'
            },
            {
                path: '/orders/createorder',
                component: CreateOrder,
                exact: true,
                name: 'CreateOrder'
            },
            {
                path: '/orders/createorder/:id',
                component: CreateOrder,
                exact: true,
                name: 'EditOrder'
            },
            {
                path: '/orders/checkout',
                component: Checkout,
                exact: true,
                name: 'Checkout'
            }
        ]

    },
    {
        path: '/rfq',
        component: Rfq,
        exact: true,
        name: 'RFQ',
        subRoutes: [
            {
                path: '/rfq/view-excel/:id',
                component: RfqExcelDetails,
                exact: true,
                name: 'RfqDetails'
            },
            {
                path: '/rfq/edit-excel/:id',
                component: RfqEditDetails,
                exact: true,
                name: 'RfqEdit'
            },
            {
                path: '/rfq/view-rfq-detail/:id',
                component: RfqPDetails,
                exact: true,
                name: 'RfqPDetails'
            },
        ]
    },
    {
        path: '/staff',
        component: StaffList,
        exact: true,
        name: 'StaffList',
        subRoutes: [
            {
                path: '/staff/add',
                component: AddStaff,
                exact: true,
                name: 'AddStaff'
            }
        ]
    },
    {
        path: '/sales',
        component: SalesList,
        exact: true,
        name: 'SalesScout',
        subRoutes: [
            {
                path: '/sales/add',
                component: AddSales,
                exact: true,
                name: 'AddSalesScout'
            },
            {
                path: '/sales/clock-in-out/:id',
                component: ClockList,
                exact: true,
                name: 'SalesScoutClockInOut'
            }
        ]
    },
    {
        path: '/delivery',
        component: DeliveryList,
        exact: true,
        name: 'DeliveryScout',
        subRoutes: [
            {
                path: '/delivery/add',
                component: AddDelivery,
                exact: true,
                name: 'AddDeliveryScout'
            },
            {
                path: '/delivery/clock-in-out/:id',
                component: ClockList,
                exact: true,
                name: 'DeliveryScoutClockInOut'
            }
        ]
    },
    {
        path: '/sales-manager',
        component: SalesManagerList,
        exact: true,
        name: 'SalesManagerList',
        subRoutes: [
            {
                path: '/sales-manager/add',
                component: AddSalesManager,
                exact: true,
                name: 'AddSalesManager'
            }
        ]
    },
    {
        path: '/marketing',
        component: MarketingManagerList,
        exact: true,
        name: 'MarketingManagerList',
        subRoutes: [
            {
                path: '/marketing/add',
                component: AddMarketing,
                exact: true,
                name: 'AddMarketing'
            }
        ]
    },
    {
        path: '/merchandiser',
        component: MerchandiserList,
        exact: true,
        name: 'MerchandiserList',
        subRoutes: [
            {
                path: '/merchandiser/add',
                component: AddMerchandiser,
                exact: true,
                name: 'AddMerchandiser'
            }
        ]
    },
    {
        path: '/finance',
        component: FinanceList,
        exact: true,
        name: 'FinanceList',
        subRoutes: [
            {
                path: '/finance/add',
                component: AddFinance,
                exact: true,
                name: 'AddFinance'
            }
        ]
    },
    {
        path: '/gallega',
        component: GallegaList,
        exact: true,
        name: 'GallegaList',
        subRoutes: [
            {
                path: '/gallega/add',
                component: AddGallega,
                exact: true,
                name: 'AddGallega'
            },
            {
                path: '/gallega/emails',
                component: GallegaEmails,
                exact: true,
                name: 'GallegaEmails'
            },
        ]
    },
    {
        path: '/news',
        component: NewsList,
        exact: true,
        name: 'NewsList',
        subRoutes: [
            {
                path: '/news/add',
                component: AddNews,
                exact: true,
                name: 'AddNews'
            },
            {
                path: '/news/edit-news/:id',
                component: EditNews,
                exact: true,
                name: 'EditNews'
            },
        ]
    },
    {
        path: '/review',
        component: Reviews,
        exact: true,
        name: 'Reviews'
    },
    {
        path: '/search-keywords',
        component: SearchKeywords,
        exact: true,
        name: 'SearchKeywords'
    },
    {
        path: '/bulk-image',
        component: BulkImage,
        exact: true,
        name: 'BulkImageSKU'
    },
    {
        path: '/category-mapping',
        component: CategoryListing,
        exact: true,
        name: 'CategoryMappingListing'
    },
    {
        path: '/brand-mapping',
        component: ListingBrand,
        exact: true,
        name: 'BrandMappingListing'
    },
    // {
    //     path: '/makes',
    //     component: ListingMakes,
    //     exact: true,
    //     name: 'ListingMakes'
    // },
    // {
    //     path: '/view-models/:id',
    //     component: ListingModels,
    //     exact: true,
    //     name: 'ListingModels'
    // },
    {
        path: '/view-subcategories/:id',
        component: SubCatListing,
        exact: true,
        name: 'SubCatListing'//falls under the categery mapping
    },
    // {
    //     path:'/chats',
    //     component: Chat,
    //     exact: true,
    //     name: 'Chat',
    //     subRoutes: [
    //         // {
    //         //     path: '/chats/buyer-seller-chat/:id',//not in used
    //         //     component: BuyerSellerChatBox,
    //         //     exact: true,
    //         //     name: 'BuyerSellerChatBox',
    //         // },
    //         {
    //             path:'/chats/message/:id',
    //             component: ChatBox,
    //             exact: true,
    //             name:"StaffChatBox"
    //         },
    //         {
    //             path:'/chats/admin-buyer/message/:id',
    //             component: ChatBoxAB,
    //             exact: true,
    //             name:"AdminBuyerChatBox"
    //         }
    //     ]
    // },
    {
        path: '/roles-permissions',
        component: RolesAndPermissions,
        exact: true,
        name: 'RolesAndPermissions'
    },
    {
        path: '/brand',
        component: BrandList,
        exact: true,
        name: 'Brand',
        subRoutes: [
            {
                path: '/brand/add',
                component: AddBrand,
                exact: true,
                name: 'AddBrand',
            },
            {
                path: '/brand/edit/:id',
                component: EditBrand,
                exact: true,
                name: 'EditBrand',
            },
            {
                path: '/brand/categories',
                component: BrandCategories,
                exact: true,
                name: 'BrandCategories',
            },
            {
                path: '/brand/subcategories/:id',
                component: BrandSubCategories,
                exact: true,
                name: 'BrandSubCategories',
            },
        ]
    },
    {
        path: '/erp-logs',
        component: ERPLogs,
        exact: true,
        name: 'ERPLogs',
    },
    {
        path: '/homepage-sections',
        component: HomepageSections,
        exact: true,
        name: 'HomePageSections',
        subRoutes: [
            {
                path: '/homepage-sections/add',
                component: AddHomepageSection,
                exact: true,
                name: 'AddHomePageSections',
            },
            {
                path: '/homepage-sections/edit/:id',
                component: EditHomepageSection,
                exact: true,
                name: 'EditHomePageSections',
            },
        ]
    },
    {
        path: '/customer-vat-setting',
        component: CustomerVATSetting,
        exact: true,
        name: 'Customer VAT Setting',
    },
    {
        path: '/delivery-time',
        component: DeliveryTime,
        exact: true,
        name: 'DeliveryTimes',
    },
    {
        path: '/car-inspection',
        component: HomepageSections,
        exact: true,
        name: 'CarInspection',
        subRoutes: [
            // {
            //     path: '/car-inspection/add',
            //     component: AddCarInspection,
            //     exact: true,
            //     name: 'AddCarInspection',
            // },
            {
                path: '/car-inspection/edit/:id',
                component: EditHomepageSection,
                exact: true,
                name: 'EditCarInspection',
            },
            {
                path: '/car-inspection/list',
                component: CarInspectionList,
                exact: true,
                name: 'CarInspectionList',
            },
        ]
    },
]


export default routes;