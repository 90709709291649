import React from 'react'
import FeatherIcon from 'feather-icons-react';
import { Table } from 'antd'
import { Button } from '../../components/buttons/buttons'
import { UserTableStyleWrapper } from '../pages/style'
import { TableWrapper } from '../styled'
const RolesList = ({ roles, onEdit, openConfirm }) => {

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '20%'
    },
    {
      title: 'No of Routes',
      dataIndex: 'routePermissions',
      key: 'routePermissions',
      render: (routePermissions) => `${routePermissions.length}`
    },
    {
      title: 'Actions',
      key: 'action',
      render: (record) => (
        <div className="table-actions">
          <>
            <Button onClick={() => onEdit(record?.role)} className="btn-icon" type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
            <Button
              onClick={() => openConfirm(record?._id, record?.role)}//from buyer tabel
              className="btn-icon"
              type="danger"
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </>
        </div>
      ),
    }
  ]

  return (
    <UserTableStyleWrapper>
      <TableWrapper className="table-responsive">
        <Table
          expandable={{
            expandedRowRender: ({ routePermissions }) => <>
              <h4 style={{ margin: 0 }}>Routes:</h4>
              <ul>
                {routePermissions?.map(permission => {
                  return (
                    <li key={permission}>{permission}</li>
                  )
                })}
              </ul>
            </>,
          }}
          dataSource={roles}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            total: roles.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />
      </TableWrapper>
    </UserTableStyleWrapper>
  )
}

export default RolesList