import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main, BasicFormWrapper } from '../styled';
import axios from 'axios';
import SearchBuyer from '../pages/price/SearchBuyer';
import PriceDiffBuyersTable from '../pages/overview/PriceDiffBuyersTable';
const { TextArea } = Input;

export default () => {
  const [buyers, setBuyers] = useState([]);
  const [buyerCount, setBuyerCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false)
  const [resetBuyers, setResetBuyers] = useState(false)

  const [form] = Form.useForm();

  const handleSubmit = values => {
    let data = {
      ...values,
      users: values.users.map(user => user.key)
    }
    axios.post('/api/users/send-message', data).then(res => {
      form.resetFields()
      setResetBuyers(true)
      message.success('Message sent successfully')
    }).catch(err => {
      message.error('Error sending message')
    }
    )
  };


  const setSelectedBuyers = (selectedBuyers) => {
    form.setFieldsValue({ users: selectedBuyers });
  }



  return (
    <>
      <PageHeader ghost title="Custom Message" />
      <Main>
        <div className="customized-msg-add-form" style={{ position: 'relative' }}>
          <BasicFormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              name="messages"
              onFinish={handleSubmit}
            >
              <Cards
                title={
                  <>
                    <FeatherIcon icon="plus" size={14} /> Add Customized Message
                  </>
                }
              >
                <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="message"
                      label="Message"
                      rules={[
                        {
                          message: 'Please input message',
                          required: true
                        }
                      ]}
                    >
                      <TextArea rows={5} placeholder="Message..." />
                    </Form.Item>
                  </Col>
                </Row>
              </Cards>
              <Cards title={
                <>
                  <FeatherIcon icon="plus" size={14} /> Add Buyers
                </>
              }>

                {<Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                  <Col xs={24} md={12}>
                    <SearchBuyer reset={resetBuyers} setBuyers={setBuyers} setBuyerCount={setBuyerCount} setLoading={setLoading} page={page} setPage={setPage} limit={limit}/>
                  </Col>
                </Row>}

                <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="users"
                      label="Assigned To"
                      rules={[
                        {
                          message: 'Please select buyers to be assigned',
                          required: true
                        }
                      ]}
                    >
                      <PriceDiffBuyersTable loading={loading} form={form} buyers={buyers} setSelectedBuyers={setSelectedBuyers}  buyerCount={buyerCount} page={page} setPage={setPage} limit={limit} setLimit={setLimit} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    className="btn-signin"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  // loading={priceLoading}
                  >
                    Send Message
                  </Button>
                </Form.Item>
              </Cards>
            </Form>
          </BasicFormWrapper>

        </div>
      </Main>

    </>
  );
};
