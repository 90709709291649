const actions = {
  PERFORMANCE_BEGIN: 'PERFORMANCE_BEGIN',
  PERFORMANCE_SUCCESS: 'PERFORMANCE_SUCCESS',
  PERFORMANCE_ERR: 'PERFORMANCE_ERR',

  performanceBegin: () => {
    return {
      type: actions.PERFORMANCE_BEGIN,
    };
  },

  performanceSuccess: data => {
    return {
      type: actions.PERFORMANCE_SUCCESS,
      data,
    };
  },

  performanceErr: err => {
    return {
      type: actions.PERFORMANCE_ERR,
      err,
    };
  },
};

export default actions;
