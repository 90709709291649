const actions = {
  GET_LISTING_BRAND_BEGIN: 'GET_LISTING_BRAND_BEGIN',
  GET_LISTING_BRAND_SUCCESS: 'GET_LISTING_BRAND_SUCCESS',
  GET_LISTING_BRAND_FAILED: 'GET_LISTING_BRAND_FAILED',

  ADD_BRAND_BEGIN: 'ADD_BRAND_BEGIN',
  ADD_BRAND_SUCCESS: 'ADD_BRAND_SUCCESS',
  ADD_BRAND_FAILED: 'ADD_BRAND_FAILED',

  UPDATE_BRAND_BEGIN: 'UPDATE_BRAND_BEGIN',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_FAILED: 'UPDATE_BRAND_FAILED',

  DELETE_BRAND_BEGIN: 'DELETE_BRAND_BEGIN',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_FAILED: 'DELETE_BRAND_FAILED',

  getListingBrandBegin: () => {
    return {
      type: actions.GET_LISTING_BRAND_BEGIN,
    };
  },

  getListingBrandSuccess: data => {
    return {
      type: actions.GET_LISTING_BRAND_SUCCESS,
      data,
    };
  },

  getListingBrandFailed: err => {
    return {
      type: actions.GET_LISTING_BRAND_FAILED,
      err,
    };
  },

  addListingBrandBegin: () => {
    return {
      type: actions.ADD_BRAND_BEGIN,
    };
  },

  addListingBrandSuccess: data => {
    return {
      type: actions.ADD_BRAND_SUCCESS,
      data,
    };
  },

  addListingBrandFailed: err => {
    return {
      type: actions.ADD_BRAND_FAILED,
      err,
    };
  },
  updateListingBrandBegin: () => {
    return {
      type: actions.UPDATE_BRAND_BEGIN,
    };
  },

  updateListingBrandSuccess: data => {
    return {
      type: actions.UPDATE_BRAND_SUCCESS,
      data,
    };
  },

  updateListingBrandFailed: err => {
    return {
      type: actions.UPDATE_BRAND_FAILED,
      err,
    };
  },
  deleteListingBrandBegin: () => {
    return {
      type: actions.DELETE_BRAND_BEGIN,
    };
  },

  deleteListingBrandSuccess: data => {
    return {
      type: actions.DELETE_BRAND_SUCCESS,
      data,
    };
  },

  deleteListingBrandFailed: err => {
    return {
      type: actions.DELETE_BRAND_FAILED,
      err,
    };
  },
};

export default actions;
