import React, { useState, useEffect } from 'react';
import { Badge } from 'antd';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { getNotification } from '../../../redux/authentication/actionCreator';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addBuyerAddressSelected, addBuyerSelected, getAddress, upsertBuyerCart } from '../../../redux/orders/actionCreator';
import actions from '../../../redux/orders/actions';

const NotificationBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState({});
  const { rtl, noOfUnseen, notifications, _isLoggedIn } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      noOfUnseen: state.auth.notification.noOfUnseen,
      notifications: state.auth.notification.notifications || [],
      _isLoggedIn: state.auth.login ? state.auth.login : null
    };
  });

  useEffect(() => {
    if (typeof _isLoggedIn === 'string') {
      setIsLoggedIn(JSON.parse(_isLoggedIn));
    } else {
      setIsLoggedIn(_isLoggedIn);
    }
  }, [_isLoggedIn]);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6'
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px'
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px'
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object)
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object)
  };

  const handleClick = () => {
    if (isLoggedIn._id) {
      dispatch(getNotification(isLoggedIn._id));
    }
  };

  const handleOrderUploadSuccess = (info) => {
    dispatch(actions.setOrderState({
      state: "showListing",
      data: true
    }));
    dispatch(actions.upsertBuyerCartSuccess({ _id: info?.cartId, user: info?.buyerId, delivery_address: info?.addressId }));
    dispatch(getAddress("6478579e57831a0bf9d65057", "64785c694fa2e55dbabe71f0"));
    dispatch(upsertBuyerCart())
    history.push('/admin/orders/checkout')
  }

  const msg = (sub_type, info = '', rest) => {
    switch (sub_type) {
      case 'order_created':
        return (
          <>
            <span>New Order</span> has arrived.({info?.toUpperCase()})
          </>
        );
      case 'order_assigned':
        return (
          <>
            <span>An order</span> has been assigned to you.({info?.toUpperCase()})
          </>
        );
      case 'status_changed':
        return (
          <>
            <span>{rest?.product} </span> of order({info?.toUpperCase()}) has been changed to status{' '}
            <span>{rest?.order_status?.toUpperCase()}</span>.
          </>
        );
      case 'order_returned':
        return (
          <>
            <span>{rest?.product} </span> of order({info?.toUpperCase()}) has been changed to status{' '}
            <span>{rest?.status.toUpperCase()}</span>.
          </>
        );
      case 'msg_receiver':
        return (
          <>
            <span>{info}</span> has sent a message to you.
          </>
        );
      case 'dailyinventorycheck':
        return (
          <>
            Daily inventory check.{' '}
            <a href={info} download>
              <span>Click here to download inventory daily check report.</span>
            </a>{' '}
          </>
        );
      case 'price_diff_created':
        return (
          <>
            <span>{info}</span> price differential{' '}
            {rest.type === 'success' ? 'has been created successfully.' : 'could not be created.'}{' '}
          </>
        );
      case 'create_coupon':
        return (
          <>
            <span>{info}</span>
            {' '} {rest.message}{' '}
          </>
        )
      case 'usercouponcsv':
        return (
          <>
            User Coupon CSV.{" "}
            {info ? <a href={info} download>
              <span>Click here to download user coupon csv report.</span>
            </a> : <span>User coupon CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'ordercsv':
        return (
          <>
            Order CSV.{" "}
            {rest.url ? <a href={rest.url} download>
              <span>Click here to download user order csv report.</span>
            </a> : rest?.isEmpty ? <span>No Orders found on the filtered list</span>
              : <span>Order CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'orderinvoice':
        return (
          <>
            Order Invoice.{" "}
            <br />
            {rest.url ? <a
              href={rest.url}
              style={{ cursor: "pointer" }}
              download >
              <span>Click here to download user order Invoice report.</span>
            </a> : <span>No Invoice found on the filtered list</span>}
            {' '}
          </>
        );
      case 'buyercsv':
        return (
          <>
            {rest?.forKYC ? 'New' : null} Buyer CSV.{" "}
            {rest.url ? <a href={rest.url} download>
              <span>Click here to download {rest?.forKYC ? 'new ' : null} buyer csv report.</span>
            </a> : <span>{rest?.forKYC ? 'New' : null}Buyer CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'sellercsv':
        return (
          <>
            Seller CSV.{" "}
            {rest.url ? <a href={rest.url} download>
              <span>Click here to download seller csv report.</span>
            </a> : <span>Seller CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'listingcsv':
        return (
          <>
            Listing CSV.{" "}
            {rest.url ? <a href={rest.url} download>
              <span>Click here to download user listing csv report.</span>
            </a> : <span>Listing CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'couponcsv':
        return (
          <>
            Coupon CSV.{" "}
            {info ? <a href={info} download>
              <span>Click here to download coupon csv report.</span>
            </a> : <span>Coupon CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'trade_license_expiry':
        return (<>Trade License of <span>{rest.name}</span> is going to expires {' '}
          {moment(rest.date).tz("Asia/Dubai").fromNow()}.</>
        );
      case 'price_diff_report':
        return (
          <>
            <span>{info}</span> price differential has some common users with other differential.
            <a href={rest.report} download>
              <span>Click here to download the report.</span>
            </a>
          </>
        );
      case 'price_diff_updated':
        return (
          <>
            <span>{info}</span> price differential{' '}
            {rest.type === 'success' ? 'has been updated successfully.' : 'could not be updated.'}{' '}
          </>
        );
      case 'bulk_status_change':
        return (
          <>
            Bulk orders status update.
            {info ? (
              <a href={info} download>
                <span>Click here to download the report.</span>
              </a>
            ) : (
              <span>Successfully updated.</span>
            )}
          </>
        );
      case 'bulk_status_change':
        return (
          <>
            Bulk orders status update.
            {info ? (
              <a href={info} download>
                <span>Click here to download the report.</span>
              </a>
            ) : (
              <span>Successfully updated.</span>
            )}
          </>
        );
      case 'wallet':
        if (rest.order)
          return (<>Wallet <span>debited</span> by <span>AED {rest.amount}</span> on <span>{moment(rest.date).tz('Asia/Dubai').format('LLL')}</span> of <span>{rest?.buyer}</span> for the order <span>{rest.order}</span></>);
        else
          return (<>Wallet <span>{rest.type}ed</span> by <span>AED {rest.amount}</span> on <span>{moment(rest.date).tz('Asia/Dubai').format('LLL')}</span> of <span>{rest?.buyer}</span> </>);
      case 'kyc_pending_buyer_tried_ordering_second_time':
        return (<>Buyer with ID:<span>{rest.buyerId}</span> attempted to place an order for the second time while their kyc is still in pending state.</>);
      case 'bulk_upload':
        return (<>Buyer Upload File uploaded successfully.</>);
      case 'bulk_seller_upload':
        return (<>Seller Upload File uploaded successfully.</>);
      case 'download':
        return (
          <>
            Price Diff file is ready to download.
            {
              info ? (
                <a href={info} download>
                  <span>Click here to download the report.</span>
                </a>
              ) : <span>Data not found</span>
            }
          </>
        );
      case 'walletcsv':
        return (
          <>
            Wallet CSV.{" "}
            {rest.url ? <a href={rest.url} download>
              <span>Click here to download Wallet csv report.</span>
            </a> : <span>Wallet CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'walletlogcsv':
        return (
          <>
            Wallet Log CSV.{" "}
            {rest.url ? <a href={rest.url} download>
              <span>Click here to download Wallet Log CSV report.</span>
            </a> : <span>Wallet Log CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'bulk_product_upload':
        return (<span>{rest?.name}</span>);
      case 'collectioncsv':
        return (
          <>
            Collection CSV.{" "}
            {info ? <a href={info} download>
              <span>Click here to download collection csv report.</span>
            </a> : <span>Collection CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'checkPD':
        return (
          <>
            Common PD CSV.{" "}
            {info ? <a href={rest.url} download>
              <span>Click here to download common listings report.</span>
            </a> : <span>Common listings CSV could not be created.</span>}
            {' '}
          </>
        );
      case 'admin_order_success':
        return (
          <div onClick={() => handleOrderUploadSuccess(rest)}>
            <span className="color-primary cursor-pointer">Items has been uploaded successfully through bulk upload for {rest?.cartCode}</span>
          </div>
        );
      case 'admin_order_fail':
        return (
          <>
            <>Bulk Upload failed for {rest?.cartCode}</>
            <a href={rest?.filePath} download>
              <span>Click here to download report.</span>
            </a>
          </>
        );
      default:
        return null;
    }
  };

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        {notifications.map((n, i) => {
          const { sub_type, order_id, _id, sender, ...rest } = n.notificationDetail;
          let data;
          let link = '#';
          let icon;
          function filterInfo(type) {
            switch (type) {
              case 'order':
                link = _id ? `/admin/orders/view/${_id}` : '/admin/orders';
                icon = 'shopping-cart';
                data = order_id;
                break;
              case 'price_diff':
                link = `/admin/price`;
                icon = 'plus-circle';
                data = rest?.name || rest?.url;
                break;
              case 'coupon-bg':
                link = `/admin/coupon`;
                icon = 'plus-circle';
                data = rest?.name;
                break;
              case 'price_diff_report':
                icon = 'plus-circle';
                data = rest?.name;
                break;
              case 'coupon-bg':
                link = `/admin/coupon`;
                icon = 'plus-circle';
                data = rest?.name;
                break;
              case 'expiry':
                icon = 'clock';
                break;
              case 'wallet':
                icon = 'dollar-sign';
                link = rest?.walletId ? `/admin/wallets/edit/${rest.walletId}` : '#';
                break;
              case 'kyc_pending_buyer_order':
                icon = 'shopping-cart';
                link = rest?.buyerId ? `/admin/users/view-buyer/${rest?.buyerId}` : '#';
                break;
              case 'expiry':
                icon = 'clock';
                break;
              case 'bulk_status_order':
                icon = 'shopping-cart';
                data = rest?.report;
                break;
              case 'chat':
                link = '/admin/chats/buyer-seller-chat';
                icon = 'message-circle';
                data = sender;
                break;
              case 'listing':
                icon = 'truck';
                data = rest?.reportFile;
                break;
              case 'coupon':
                icon = 'plus-circle';
                data = rest?.url;
                break;
              case 'buyer':
                icon = 'file-plus';
                data = rest?.url;
                break;
              case 'seller':
                icon = 'file-plus';
                data = rest?.url;
                break;
              case 'pd_download':
                icon = 'file-plus';
                data = rest?.url;
                break;
              case 'wallet_download':
                icon = 'file-plus';
                data = rest?.url;
                break;
              case 'wallet_log_download':
                icon = 'file-plus';
                data = rest?.url;
                break;
              case 'listing_bulk_upload':
                icon = 'file-plus';
                break;
              case 'checkPD':
                icon = 'file-plus';
                data = rest?.url;
                break;
            }
          }
          filterInfo(n.notificationType);
          return n?.notificationType === 'listing' ||
            n?.notificationType === 'bulk_status_order' ||
            n?.notificationType === 'price_diff_report' ||
            n?.notificationType === 'price_diff' ||
            n?.notificationType === 'coupon' ||
            n?.notificationType === 'ordercsvdownload' ||
            n?.notificationType === 'orderinvoicedownload' ||
            n?.notificationType === 'listingcsvdownload' ||
            n?.notificationType === 'collectioncsvdownload' ||
            n?.notificationType === 'buyercsvdownload' ||
            n?.notificationType === 'sellercsvdownload' ||
            n?.notificationType === 'wallet_download' ||
            n?.notificationType === 'admin_order' ||
            n?.notificationType === 'checkPD' ||
            n.notificationType === 'expiry' ? (
            <div key={n._id} style={{ display: "flex", alignItems: "center" }} className="atbd-top-dropdwon__content notifications notification-list">
              <div className="notification-icon bg-primary" style={{ position: 'relative' }}>
                <FeatherIcon icon={icon} />
              </div>
              <div className="notification-content d-flex">
                <div className="notification-text">
                  <Heading as="h5">{msg(sub_type, data, rest)}</Heading>
                  <p>
                    {moment(n.date)
                      .tz('Asia/Dubai')
                      .fromNow()}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <Link key={n._id} to={link} className={'notification-list'}>
              <div style={{ display: "flex", alignItems: "center" }} className="atbd-top-dropdwon__content notifications">
                <div className="notification-icon bg-primary" style={{ position: 'relative' }}>
                  <FeatherIcon icon={icon} />
                </div>
                <div className="notification-content d-flex">
                  <div className="notification-text">
                    <Heading as="h5">{msg(sub_type, data, rest)}</Heading>
                    <p>
                      {moment(n.date)
                        .tz('Asia/Dubai')
                        .fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </Scrollbars>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification" style={{ position: 'relative', top: '10px', right: '17px' }}>
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge count={noOfUnseen} style={{ textAlign: 'center', paddingTop: '2px' }}>
          <div onClick={handleClick} className="head-example cursor-pointer">
            <FeatherIcon icon="bell" size={20} />
          </div>
        </Badge>
      </Popover>
    </div>
  );
};

export default NotificationBox;
