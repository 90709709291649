import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentList } from '../../../redux/payment/actionCreator';
import { Table, Row, Col } from 'antd';
import { TableWrapper } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';

const paymentTable = ({ paymentDetails, loader }) => {
  const getTotalAmount = amount => {
    const countryCurrency = amount.toString().slice(0, 3);
    const totalAmount = amount.toString().slice(3);
    return (
      <div style={{ display: 'flex' }}>
        <span style={{ fontSize: '10px', marginRight: '6px', color: 'grey', lineHeight: '2.5' }}>
          {countryCurrency}
        </span>
        <span>{totalAmount}</span>
      </div>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: 'id',
      align: 'center',
      render: paymentDetails => paymentDetails?._id.slice(0, 8).toUpperCase()
    },
    {
      title: 'User',
      dataIndex: '',
      align: 'center',
      render: paymentDetails => (
        <div className="user-info">
          <figure>
            <img src={paymentDetails?.user?.avatar} width="60px" />
          </figure>
          <figcaption>
            <div className="user-name">{paymentDetails?.user?.name}</div>
            <div className="user-designation">{paymentDetails?.user?.email}</div>
            <div className="user-designation">{paymentDetails?.user?.phone}</div>
          </figcaption>
        </div>
      )
    },
    {
      title: 'Card Name',
      dataIndex: 'card_name',
      align: 'center',
      key: 'card_name'
    },
    {
      title: 'card Number',
      dataIndex: 'card_number',
      key: 'card_number',
      align: 'center'
    },
    {
      title: 'Price',
      dataIndex: '',
      key: 'total',
      align: 'center',
      render: paymentDetails => getTotalAmount(paymentDetails?.total)
    }
  ];

  return (
    <TableWrapper>
      <Table
        columns={columns}
        loading={loader}
        dataSource={paymentDetails}
        pagination={{
          defaultPageSize: 10,
          total: paymentDetails.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </TableWrapper>
  );
};

export default paymentTable;
