const actions = {
  USER_CHART_BEGIN: 'USER_CHART_BEGIN',
  USER_CHART_SUCCESS: 'USER_CHART_SUCCESS',
  USER_CHART_ERR: 'USER_CHART_ERR',

  userChartBegin: () => {
    return {
      type: actions.USER_CHART_BEGIN,
    };
  },

  userChartSuccess: data => {
    return {
      type: actions.USER_CHART_SUCCESS,
      data,
    };
  },

  userChartErr: err => {
    return {
      type: actions.USER_CHART_ERR,
      err,
    };
  },
};

export default actions;
