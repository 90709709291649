import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Emails = lazy(() => import('../../container/emails'));

const EmailsRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={Emails} />
    </Switch>
  );
};

export default EmailsRoute;
