import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const SalesList = lazy(() => import('../../container/pages/SalesScoutListTable'));
const DeliveryList = lazy(() => import('../../container/pages/DeliveryScoutListTable'));
const EditSales = lazy(() => import('../../container/pages/EditSalesForm'));
const EditDelivery = lazy(() => import('../../container/pages/EditDeliveryForm'));
const BuyerDetails = lazy(() => import('../../container/pages/BuyerDetails'));

const Delivery = () => {
  const { path } = useRouteMatch();
  return (
    // <h1>Comming Soon..</h1>
    <Switch>
      {/* <Route path={`${path}/delivery/:id`} component={BuyerDetails} /> */}
      <Route path={`${path}`} component={DeliveryList} />
      <Route exact path={`${path}/view-delivery/:id`} component={EditDelivery} />
      <Route path={`${path}/edit-delivery/:id`} component={EditDelivery} />
      <Route path={`${path}/add`} component={AddUser} />
    </Switch>
  );
};



export default Delivery;
