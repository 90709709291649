import { de } from 'date-fns/locale';
import actions from './actions';

const initialState = {
  newsletterSubsData: [],
  isNewsletterSubsLoading: false,
  error: null
};

const { NEWSLETTERSUB_BEGIN, GET_NEWSLETTERSUB_SUCCESS, DELETE_NEWSLETTERSUB_SUCCESS, NEWSLETTERSUB_ERR } = actions;

const NewsletterSubReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NEWSLETTERSUB_BEGIN:
      return {
        ...state,
        isNewsletterSubsLoading: true
      };
    case GET_NEWSLETTERSUB_SUCCESS:
      return {
        ...state,
        newsletterSubsData: data,
        isNewsletterSubsLoading: false
      };
    case DELETE_NEWSLETTERSUB_SUCCESS:
      return {
        ...state,
        isNewsletterSubsLoading: false,
        newsletterSubsData: [...state.newsletterSubsData].filter(newsletter => newsletter._id !== data)
      };
    case NEWSLETTERSUB_ERR:
      return {
        ...state,
        error: err,
        isNewsletterSubsLoading: false
      };
    default:
      return state;
  }
};

export default NewsletterSubReducer;
