const actions = {
  GET_ADMIN_USER_LIST_BEGIN: 'GET_ADMIN_USER_LIST_BEGIN',
  GET_ADMIN_USER_LIST_SUCCESS: 'GET_ADMIN_USER_LIST_SUCCESS',
  GET_ADMIN_USER_LIST_ERR: 'GET_ADMIN_USER_LIST_ERR',

  ADMIN_SEARCH_BEGIN: 'ADMIN_SEARCH_BEGIN',
  ADMIN_SEARCH_SUCCESS: 'ADMIN_SEARCH_SUCCESS',
  ADMIN_SEARCH_ERROR: 'ADMIN_SEARCH_ERROR',

  GET_SALES_SCOUT_LIST_BEGIN: 'GET_SALES_SCOUT_LIST_BEGIN',
  GET_SALES_SCOUT_LIST_SUCCESS: 'GET_SALES_SCOUT_LIST_SUCCESS',
  GET_SALES_SCOUT_LIST_ERR: 'GET_SALES_SCOUT_LIST_ERR',

  GET_STAFF_LIST_BEGIN: 'GET_STAFF_LIST_BEGIN',
  GET_STAFF_LIST_SUCCESS: 'GET_STAFF_LIST_SUCCESS',
  GET_STAFF_LIST_ERR: 'GET_STAFF_LIST_ERR',

  GET_MERCHANDISER_LIST_BEGIN: 'GET_MERCHANDISER_LIST_BEGIN',
  GET_MERCHANDISER_LIST_SUCCESS: 'GET_MERCHANDISER_LIST_SUCCESS',
  GET_MERCHANDISER_LIST_ERR: 'GET_MERCHANDISER_LIST_ERR',

  GET_FINANCE_LIST_BEGIN: 'GET_FINANCE_LIST_BEGIN',
  GET_FINANCE_LIST_SUCCESS: 'GET_FINANCE_LIST_SUCCESS',
  GET_FINANCE_LIST_ERR: 'GET_FINANCE_LIST_ERR',

  GET_GALLEGA_LIST_BEGIN: 'GET_GALLEGA_LIST_BEGIN',
  GET_GALLEGA_LIST_SUCCESS: 'GET_GALLEGA_LIST_SUCCESS',
  GET_GALLEGA_LIST_ERR: 'GET_GALLEGA_LIST_ERR',

  GET_GALLEGA_EMAILS_LIST_BEGIN: 'GET_GALLEGA_EMAILS_LIST_BEGIN',
  GET_GALLEGA_EMAILS_LIST_SUCCESS: 'GET_GALLEGA_EMAILS_LIST_SUCCESS',
  GET_GALLEGA_EMAILS_LIST_ERR: 'GET_GALLEGA_EMAILS_LIST_ERR',

  GET_DELIVERY_SCOUT_LIST_BEGIN: 'GET_DELIVERY_SCOUT_LIST_BEGIN',
  GET_DELIVERY_SCOUT_LIST_SUCCESS: 'GET_DELIVERY_SCOUT_LIST_SUCCESS',
  GET_DELIVERY_SCOUT_LIST_ERR: 'GET_DELIVERY_SCOUT_LIST_ERR',

  GET_SALES_MANAGER_LIST_BEGIN: 'GET_SALES_MANAGER_LIST_BEGIN',
  GET_SALES_MANAGER_LIST_SUCCESS: 'GET_SALES_MANAGER_LIST_SUCCESS',
  GET_SALES_MANAGER_LIST_ERR: 'GET_SALES_MANAGER_LIST_ERR',

  GET_MARKETING_MANAGER_LIST_BEGIN: 'GET_MARKETING_MANAGER_LIST_BEGIN',
  GET_MARKETING_MANAGER_LIST_SUCCESS: 'GET_MARKETING_MANAGER_LIST_SUCCESS',
  GET_MARKETING_MANAGER_LIST_ERR: 'GET_MARKETING_MANAGER_LIST_ERR',

  UPDATE_USER_STATUS_BEGIN: 'UPDATE_USER_STATUS_BEGIN',
  UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
  UPDATE_USER_STATUS_ERR: 'UPDATE_USER_STATUS_ERR',

  RESET_FLAGS: 'RESET_FLAGS',

  getAdminUserListBegin: () => {
    return {
      type: actions.GET_ADMIN_USER_LIST_BEGIN
    };
  },

  getAdminUserListSuccess: data => {
    return {
      type: actions.GET_ADMIN_USER_LIST_SUCCESS,
      data
    };
  },

  getAdminUserListError: err => {
    return {
      type: actions.GET_ADMIN_USER_LIST_ERR,
      err
    };
  },

  adminUserSearchBegin: () => {
    return {
      type: actions.ADMIN_SEARCH_BEGIN
    };
  },

  adminUserSearchSuccess: data => {
    return {
      type: actions.ADMIN_SEARCH_SUCCESS,
      data
    };
  },

  adminUserSearchError: err => {
    return {
      type: actions.ADMIN_SEARCH_ERROR,
      err
    };
  },

  getStaffListBegin: () => {
    return {
      type: actions.GET_STAFF_LIST_BEGIN
    };
  },

  getStaffListSuccess: data => {
    return {
      type: actions.GET_STAFF_LIST_SUCCESS,
      data
    };
  },

  getStaffListErr: err => {
    return {
      type: actions.GET_STAFF_LIST_ERR,
      err
    };
  },

  getSalesScoutListBegin: () => {
    return {
      type: actions.GET_SALES_SCOUT_LIST_BEGIN
    };
  },

  getSalesScoutListSuccess: data => {
    return {
      type: actions.GET_SALES_SCOUT_LIST_SUCCESS,
      data
    };
  },

  getSalesScoutListErr: err => {
    return {
      type: actions.GET_SALES_SCOUT_LIST_ERR,
      err
    };
  },

  getMerchandiserListBegin: () => {
    return {
      type: actions.GET_MERCHANDISER_LIST_BEGIN
    };
  },

  getMerchandiserListSuccess: data => {
    return {
      type: actions.GET_MERCHANDISER_LIST_SUCCESS,
      data
    };
  },

  getMerchandiserListErr: err => {
    return {
      type: actions.GET_MERCHANDISER_LIST_ERR,
      err
    };
  },


  getFinanceListBegin: () => {
    return {
      type: actions.GET_FINANCE_LIST_BEGIN
    };
  },

  getFinanceListSuccess: data => {
    return {
      type: actions.GET_FINANCE_LIST_SUCCESS,
      data
    };
  },

  getFinanceListErr: err => {
    return {
      type: actions.GET_FINANCE_LIST_ERR,
      err
    };
  },


  getGallegaListBegin: () => {
    return {
      type: actions.GET_GALLEGA_LIST_BEGIN
    };
  },

  getGallegaListSuccess: data => {
    return {
      type: actions.GET_GALLEGA_LIST_SUCCESS,
      data
    };
  },

  getGallegaListErr: err => {
    return {
      type: actions.GET_GALLEGA_LIST_ERR,
      err
    };
  },

  //
  getGallegaEmailsListBegin: () => {
    return {
      type: actions.GET_GALLEGA_EMAILS_LIST_BEGIN
    };
  },

  getGallegaEmailsListSuccess: data => {
    return {
      type: actions.GET_GALLEGA_EMAILS_LIST_SUCCESS,
      data
    };
  },

  getGallegaEmailsListErr: err => {
    return {
      type: actions.GET_GALLEGA_EMAILS_LIST_ERR,
      err
    };
  },
  //

  getDeliveryScoutListBegin: () => {
    return {
      type: actions.GET_DELIVERY_SCOUT_LIST_BEGIN
    };
  },

  getDeliveryScoutListSuccess: data => {
    return {
      type: actions.GET_DELIVERY_SCOUT_LIST_SUCCESS,
      data
    };
  },

  getDeliveryScoutListErr: err => {
    return {
      type: actions.GET_DELIVERY_SCOUT_LIST_ERR,
      err
    };
  },

  getSalesManagerListBegin: () => {
    return {
      type: actions.GET_SALES_MANAGER_LIST_BEGIN
    };
  },

  getSalesManagerListSuccess: data => {
    return {
      type: actions.GET_SALES_MANAGER_LIST_SUCCESS,
      data
    };
  },

  getSalesManagerListErr: err => {
    return {
      type: actions.GET_SALES_MANAGER_LIST_ERR,
      err
    };
  },


  getMarketingManagerListBegin: () => {
    return {
      type: actions.GET_MARKETING_MANAGER_LIST_BEGIN
    };
  },

  getMarketingManagerListSuccess: data => {
    return {
      type: actions.GET_MARKETING_MANAGER_LIST_SUCCESS,
      data
    };
  },

  getMarketingManagerListErr: err => {
    return {
      type: actions.GET_MARKETING_MANAGER_LIST_ERR,
      err
    };
  },

  updateUserStatusBegin: () => {
    return {
      type: actions.UPDATE_USER_STATUS_BEGIN
    };
  },

  updateUserStatusSuccess: response => {
    return {
      type: actions.UPDATE_USER_STATUS_SUCCESS,
      response
    };
  },

  updateUserStatusErr: err => {
    return {
      type: actions.UPDATE_USER_STATUS_ERR,
      err
    };
  },

  resetFlags: () => {
    return {
      type: actions.RESET_FLAGS
    };
  }
};

export default actions;
