import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Settings = lazy(() => import('../../container/settings'));

const SettingsRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}`} component={Settings} /> */}
    </Switch>
  );
};

export default SettingsRoute;
