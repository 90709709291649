const actions = {
  GET_MAKE_BEGIN: 'GET_MAKE_BEGIN',
  GET_MAKE_SUCCESS: 'GET_MAKE_SUCCESS',
  GET_MAKE_FAILED: 'GET_MAKE_FAILED',

  ADD_MAKE_BEGIN: 'ADD_MAKE_BEGIN',
  ADD_MAKE_SUCCESS: 'ADD_MAKE_SUCCESS',
  ADD_MAKE_FAILED: 'ADD_MAKE_FAILED',

  UPDATE_MAKE_BEGIN: 'UPDATE_MAKE_BEGIN',
  UPDATE_MAKE_SUCCESS: 'UPDATE_MAKE_SUCCESS',
  UPDATE_MAKE_FAILED: 'UPDATE_MAKE_FAILED',

  DELETE_MAKE_BEGIN: 'DELETE_MAKE_BEGIN',
  DELETE_MAKE_SUCCESS: 'DELETE_MAKE_SUCCESS',
  DELETE_MAKE_FAILED: 'DELETE_MAKE_FAILED',

  getMakeBegin: () => {
    return {
      type: actions.GET_MAKE_BEGIN,
    };
  },

  getMakeSuccess: data => {
    return {
      type: actions.GET_MAKE_SUCCESS,
      data,
    };
  },

  getMakeFailed: err => {
    return {
      type: actions.GET_MAKE_FAILED,
      err,
    };
  },

  addMakeBegin: () => {
    return {
      type: actions.ADD_MAKE_BEGIN,
    };
  },

  addMakeSuccess: data => {
    return {
      type: actions.ADD_MAKE_SUCCESS,
      data,
    };
  },

  addMakeFailed: err => {
    return {
      type: actions.ADD_MAKE_FAILED,
      err,
    };
  },
  updateMakeBegin: () => {
    return {
      type: actions.UPDATE_MAKE_BEGIN,
    };
  },

  updateMakeSuccess: data => {
    return {
      type: actions.UPDATE_MAKE_SUCCESS,
      data,
    };
  },

  updateMakeFailed: err => {
    return {
      type: actions.UPDATE_MAKE_FAILED,
      err,
    };
  },
  deleteMakeBegin: () => {
    return {
      type: actions.DELETE_MAKE_BEGIN,
    };
  },

  deleteMakeSuccess: data => {
    return {
      type: actions.DELETE_MAKE_SUCCESS,
      data,
    };
  },

  deleteMakeFailed: err => {
    return {
      type: actions.DELETE_MAKE_FAILED,
      err,
    };
  },
};

export default actions;
