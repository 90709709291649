import React, { Suspense } from 'react';
import Users from './users';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Wallet from './wallet';
import Orders from './orders';
import withAdminLayout from '../../layout/withAdminLayout';

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/orders`} component={Orders} />
        <Route path={`${path}/wallets`} component={Wallet} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
