import actions from './actions';

const initialState = {
  userChartData: null,
  ucLoading: false,
  error: null,
};

const { USER_CHART_BEGIN, USER_CHART_SUCCESS, USER_CHART_ERR } = actions;

const userChartContentReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_CHART_BEGIN:
      return {
        ...state,
        ucLoading: true,
      };
    case USER_CHART_SUCCESS:
      return {
        ...state,
        userChartData: data,
        ucLoading: false,
      };
    case USER_CHART_ERR:
      return {
        ...state,
        error: err,
        ucLoading: false,
      };
    default:
      return state;
  }
};

export default userChartContentReducer;
