import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { UserTableStyleWrapper } from './style';
import { TableWrapper } from '../styled';
import FeatherIcon from 'feather-icons-react';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { NavLink, useHistory } from 'react-router-dom';

const OrderListTable = ({ orders }) => {
  const ordersTableData = [];
  orders &&
    orders.length > 0 &&
    orders.map((order, index) => {
      const { _id, createdAt, name, mobile_number, perferred_mode_of_contact, resonOfEnquiry, email, message } = order;
      return ordersTableData.push({
        key: _id,
        name: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {name}
            </Heading>
          </div>
        ),
        date: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {moment(createdAt)
                .tz('Asia/Dubai')
                .format('DD/MM/YYYY')}
            </Heading>
          </div>
        ),
        email: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {email}
            </Heading>
          </div>
        ),
        mobile_number: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {mobile_number}
            </Heading>
          </div>
        ),
        resonOfEnquiry: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {resonOfEnquiry}
            </Heading>
          </div>
        ),
        perferred_mode_of_contact: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {perferred_mode_of_contact && perferred_mode_of_contact[0] && perferred_mode_of_contact[0]}
              {perferred_mode_of_contact && perferred_mode_of_contact[1] && ',' + perferred_mode_of_contact[1]}
            </Heading>
          </div>
        ),
        message: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {message}
            </Heading>
          </div>
        ),
        action: (
          <div className="table-actions">
            <>
              <Button className="btn-icon" type="primary" to="#" shape="circle">
                <NavLink to={`/admin/enquiries/${_id}`}>
                  <FeatherIcon icon="eye" size={16} />
                </NavLink>
              </Button>
              {/* <a href={`mailto:${email}`}>
                <FeatherIcon icon="mail" size={16} />
              </a> */}
            </>
          </div>
        )
      });
    });

  const ordersTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number'
    },
    {
      title: 'Reason Of Enquiry',
      dataIndex: 'resonOfEnquiry',
      key: 'resonOfEnquiry'
    },
    {
      title: 'Perferred Mode Of Contact',
      dataIndex: 'perferred_mode_of_contact',
      key: 'perferred_mode_of_contact'
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action'
    }
  ];

  const rowSelection = {
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={ordersTableData}
            columns={ordersTableColumns}
            pagination={{
              defaultPageSize: 20,
              total: ordersTableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  );
};

export default OrderListTable;
