import React from 'react';
import ReviewTable from './reviewTable';
import { Main, CardToolbox } from '../../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { AutoCompleteStyled } from '../../../components/autoComplete/style';
import { getReviewsList } from '../../../redux/reviews/actionCreator';
import moment from 'moment';
import axios from 'axios';

const review = () => {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [filteredSearch, setFilteredSearch] = React.useState([]);
  const reviewDetails = useSelector(state => state.reviews.reviews);
  const loader = useSelector(state => state.reviews.loading);
  const [starts, setStarts] = React.useState(0);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getReviewsList());
  }, [dispatch]);

  React.useEffect(() => {
    setFilteredSearch(reviewDetails);
  }, [reviewDetails]);

  const saveChanges = (id, review) => {
    const data = {
      comment: review
    };
    axios
      .put(`/api/review/update-review/${id}`, data)
      .then(res => {
        dispatch(getReviewsList());
      })
      .catch(err => {});
  };

  const handleSearch = e => {
    setSearchKeyword(e.target.value);
    const filter = reviewDetails.filter(obj => {
      if (searchKeyword === '') {
        return obj;
      } else {
        return (
          obj?.star == parseInt(searchKeyword) ||
          moment(obj?.createdAt)
            .tz('Asia/Dubai')
            .format('DD/MM/YYYY')
            .toLowerCase()
            .includes(searchKeyword?.toLowerCase()) ||
          moment(obj?.createdAt)
            .tz('Asia/Dubai')
            .format('HH:mm')
            .toLowerCase()
            .includes(searchKeyword?.toLowerCase())
        );
      }

      //  return obj;
    });
    setFilteredSearch(filter);
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Reveiw Records"
          subTitle={
            <>
              <span className="title-counter"> {filteredSearch && filteredSearch.length} Found</span>
              <AutoCompleteStyled>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  onChange={handleSearch}
                  value={searchKeyword}
                />
              </AutoCompleteStyled>
            </>
          }
        />
      </CardToolbox>

      <Main>
        <Row>
          <Col md={24}>
            <ReviewTable reviewDetails={filteredSearch} loader={loader} saveChanges={saveChanges} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default review;
