import actions from './action';

const initialState = {
  bannerData: [],
  bannerLoading: false,
  error: null,
  bannerCollections: [],
  isBannerCollectionLoading: false,
};

const {
  BANNER_BEGIN,
  GET_BANNER_SUCCESS,
  ADD_BANNER_SUCCESS,
  EDIT_BANNER_SUCCESS,
  DELETE_BANNER_SUCCESS,
  BANNER_ERR,
  BANNER_COLLECTION_BEGIN,
  BANNER_COLLECTION_SUCCESS,
  BANNER_COLLECTION_ERROR
} = actions;

const bannerReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case BANNER_BEGIN:
      return {
        ...state,
        bannerLoading: true
      };
    case GET_BANNER_SUCCESS:
      return {
        ...state,
        bannerData: data,
        bannerLoading: false
      };

    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        bannerData: [...state.bannerData, data],
        bannerLoading: false
      };

    case EDIT_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
        bannerData: state.bannerData.map(banner => (banner._id === data._id ? data : banner))
      };

    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
        bannerData: [...state.bannerData].filter(banner => banner._id !== data)
      };

    case BANNER_ERR:
      return {
        ...state,
        error: err,
        bannerLoading: false
      };

    case BANNER_COLLECTION_BEGIN:
      return {
        ...state,
        isBannerCollectionLoading: true
      };
    case BANNER_COLLECTION_SUCCESS:
      return {
        ...state,
        bannerCollections: data,
        isBannerCollectionLoading: false
      };
    case BANNER_COLLECTION_ERROR:
      return {
        ...state,
        isBannerCollectionLoading: false
      };
    default:
      return state;
  }
};

export default bannerReducer;
