const actions = {
  SUMMARY_OVERVIEW_BEGIN: 'SUMMARY_OVERVIEW_BEGIN',
  SUMMARY_OVERVIEW_SUCCESS: 'SUMMARY_OVERVIEW_SUCCESS',
  SUMMARY_OVERVIEW_ERR: 'SUMMARY_OVERVIEW_ERR',
  USER_CHART_BEGIN: 'USER_CHART_BEGIN',
  USER_CHART_SUCCESS: 'USER_CHART_SUCCESS',
  USER_CHART_ERR: 'USER_CHART_ERR',
  
  summaryOverviewBegin: () => {
    return {
      type: actions.SUMMARY_OVERVIEW_BEGIN,
    };
  },

  summaryOverviewSuccess: data => {
    return {
      type: actions.SUMMARY_OVERVIEW_SUCCESS,
      data,
    };
  },

  summaryOverviewErr: err => {
    return {
      type: actions.SUMMARY_OVERVIEW_ERR,
      err,
    };
  },
  userChartBegin: () => {
    return {
      type: actions.USER_CHART_BEGIN,
    };
  },

  userChartSuccess: data => {
    return {
      type: actions.USER_CHART_SUCCESS,
      data,
    };
  },

  userChartErr: err => {
    return {
      type: actions.USER_CHART_ERR,
      err,
    };
  },
};

export default actions;
