import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const gallegaList = lazy(() => import('../../container/pages/GallegaUserListTable'));
const gallegaEmails = lazy(() => import('../../container/pages/GallegaEmails'));
import AddGallega from '../../container/pages/wizards/overview/AddGallega';

const Gallega = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route eaxct path={`${path}/emails`} component={gallegaEmails} />
      <Route exact path={`${path}/add`} component={AddGallega} />
      <Route eaxct path={`${path}/`} component={gallegaList} />
    </Switch>
  );
};

export default Gallega;
