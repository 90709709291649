const actions = {
  GET_SELLER_LIST_BEGIN: 'GET_SELLER_LIST_BEGIN',
  GET_SELLER_LIST_SUCCESS: 'GET_SELLER_LIST_SUCCESS',
  GET_SELLER_LIST_ERR: 'GET_SELLER_LIST_ERR',

  GET_SELLER_TABLE_LIST_BEGIN: 'GET_SELLER_TABLE_LIST_BEGIN',
  GET_SELLER_TABLE_LIST_SUCCESS: 'GET_SELLER_TABLE_LIST_SUCCESS',
  GET_SELLER_TABLE_COUNT_SUCCESS: 'GET_SELLER_TABLE_COUNT_SUCCESS',
  GET_SELLER_TABLE_LIST_ERR: 'GET_SELLER_TABLE_LIST_ERR',

  GET_OPT_LIST_BEGIN: 'GET_OPT_LIST_BEGIN',
  GET_OPT_LIST_SUCCESS: 'GET_OPT_LIST_SUCCESS',
  GET_OPT_LIST_ERR: 'GET_OPT_LIST_ERR',

  GET_BUYER_LIST_BEGIN: 'GET_BUYER_LIST_BEGIN',
  GET_BUYER_LIST_SUCCESS: 'GET_BUYER_LIST_SUCCESS',
  GET_BUYER_LIST_ERR: 'GET_BUYER_LIST_ERR',

  GET_BUYER_TABLE_LIST_BEGIN: 'GET_BUYER_TABLE_LIST_BEGIN',
  GET_BUYER_TABLE_LIST_SUCCESS: 'GET_BUYER_TABLE_LIST_SUCCESS',
  GET_BUYER_TABLE_COUNT_SUCCESS: 'GET_BUYER_TABLE_COUNT_SUCCESS',
  GET_BUYER_TABLE_LIST_ERR: 'GET_BUYER_TABLE_LIST_ERR',

  GET_ONEBUYER_BEGIN: 'GET_ONEBUYER_BEGIN',
  GET_ONEBUYER_SUCCESS: 'GET_ONEBUYER_SUCCESS',
  GET_ONEBUYER_ERR: 'GET_ONEBUYER_ERR',

  UPDATE_USER_STATUS_BEGIN: 'UPDATE_USER_STATUS_BEGIN',
  UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
  UPDATE_USER_STATUS_ERR: 'UPDATE_USER_STATUS_ERR',
  GET_SOCIAL_SUCCESS: 'GET_SOCIAL_SUCCESS',
  GET_BULK_BUYER_LIST_SUCCESS: 'GET_BULK_BUYER_LIST_SUCCESS',
  GET_BULK_BUYER_LIST_COUNT_SUCCESS: 'GET_BULK_BUYER_LIST_COUNT_SUCCESS',
  GET_BULK_BUYER_LIST_INNER_SUCCESS: 'GET_BULK_BUYER_LIST_INNER_SUCCESS',

  GET_BULK_SELLER_LIST_SUCCESS: 'GET_BULK_SELLER_LIST_SUCCESS',
  GET_BULK_SELLER_LIST_COUNT_SUCCESS: 'GET_BULK_SELLER_LIST_COUNT_SUCCESS',
  GET_BULK_SELLER_LIST_INNER_SUCCESS: 'GET_BULK_SELLER_LIST_INNER_SUCCESS',

  GET_TLS_SETING: 'GET_TLS_SETING',
  GET_TLS_SETTING_SUCCESS: 'GET_BULK_SELLER_LIST_COUNT_SUCCESS',
  GET_TLS_SETTING_ERR: 'GET_TLS_SETTING_ERR',

  RESET_FLAGS: 'RESET_FLAGS',

  getSellerListBegin: () => {
    return {
      type: actions.GET_SELLER_LIST_BEGIN,
    };
  },

  getSellerListSuccess: data => {
    return {
      type: actions.GET_SELLER_LIST_SUCCESS,
      data,
    };
  },

  getSellerListErr: err => {
    return {
      type: actions.GET_SELLER_LIST_ERR,
      err,
    };
  },

  //admin-seller-table
  getSellerTableListBegin: () => {
    return {
      type: actions.GET_SELLER_TABLE_LIST_BEGIN,
    };
  },

  getSellerTableListSuccess: data => {
    return {
      type: actions.GET_SELLER_TABLE_LIST_SUCCESS,
      data,
    };
  },

  getSellerTableCountSuccess: data => {
    return {
      type: actions.GET_SELLER_TABLE_COUNT_SUCCESS,
      data,
    };
  },

  getSellerTableListErr: err => {
    return {
      type: actions.GET_SELLER_TABLE_LIST_ERR,
      err,
    };
  },

  //opt
  getOptListBegin: () => {
    return {
      type: actions.GET_OPT_LIST_BEGIN,
    };
  },

  getOptListSuccess: data => {
    return {
      type: actions.GET_OPT_LIST_SUCCESS,
      data,
    };
  },

  getOptListErr: err => {
    return {
      type: actions.GET_OPT_LIST_ERR,
      err,
    };
  },
  //opt end


  getBuyerListBegin: () => {
    return {
      type: actions.GET_BUYER_LIST_BEGIN,
    };
  },

  getBuyerListSuccess: data => {
    return {
      type: actions.GET_BUYER_LIST_SUCCESS,
      data,
    };
  },

  //admin-buyers-table
  getBuyerTableListBegin: () => {
    return {
      type: actions.GET_BUYER_TABLE_LIST_BEGIN,
    };
  },

  getBuyerTableListSuccess: data => {
    return {
      type: actions.GET_BUYER_TABLE_LIST_SUCCESS,
      data,
    };
  },

  getBuyerTableCountSuccess: data => {
    return {
      type: actions.GET_BUYER_TABLE_COUNT_SUCCESS,
      data,
    };
  },

  getBuyerTableListErr: err => {
    return {
      type: actions.GET_BUYER_TABLE_LIST_ERR,
      err,
    };
  },

  getBulkBuyerListSuccess: data => {
    return {
      type: actions.GET_BULK_BUYER_LIST_SUCCESS,
      data,
    };
  },
  getBulkBuyerListCountSuccess: data => {
    return {
      type: actions.GET_BULK_BUYER_LIST_COUNT_SUCCESS,
      data,
    };
  },
  getBulkBuyerListInnerSuccess: data => {
    return {
      type: actions.GET_BULK_BUYER_LIST_INNER_SUCCESS,
      data,
    };
  },
  getBulkSellerListSuccess: data => {
    return {
      type: actions.GET_BULK_SELLER_LIST_SUCCESS,
      data,
    };
  },
  getBulkSellerListCountSuccess: data => {
    return {
      type: actions.GET_BULK_SELLER_LIST_COUNT_SUCCESS,
      data,
    };
  },
  getBulkSellerListInnerSuccess: data => {
    return {
      type: actions.GET_BULK_SELLER_LIST_INNER_SUCCESS,
      data,
    };
  },

  getBuyerListErr: err => {
    return {
      type: actions.GET_BUYER_LIST_ERR,
      err,
    };
  },

  getOneBuyerBegin: () => {
    return {
      type: actions.GET_ONEBUYER_BEGIN,
    };
  },

  getOneBuyerSuccess: data => {
    return {
      type: actions.GET_ONEBUYER_SUCCESS,
      data,
    };
  },

  getSocialSuccess: data => {
    return {
      type: actions.GET_SOCIAL_SUCCESS,
      data,
    };
  },

  getOneBuyerErr: err => {
    return {
      type: actions.GET_ONEBUYER_ERR,
      err,
    };
  },

  updateUserStatusBegin: () => {
    return {
      type: actions.UPDATE_USER_STATUS_BEGIN,
    };
  },

  updateUserStatusSuccess: response => {
    return {
      type: actions.UPDATE_USER_STATUS_SUCCESS,
      response,
    };
  },

  updateUserStatusErr: err => {
    return {
      type: actions.UPDATE_USER_STATUS_ERR,
      err,
    };
  },

  resetFlags: () => {
    return {
      type: actions.RESET_FLAGS,
    };
  },
};

export default actions;
