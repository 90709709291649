import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Dashboard from './dashboard';
import Users from './users';
import WareHouse from './warehouse';
import Banner from './banner';
import Newsletter from './newsletter';
import Listings from './listings';
import Enquiries from '../../container/EnquiryPage';
import EnquiryDetail from '../../container/EnquiryPage/EnquiryDetail';
import withAdminLayout from '../../layout/withAdminLayout';
import News from './news';
import Delivery from './delivery';
import Deliveries from '../../container/deliveries';
import Reviews from '../../container/pages/reviews';
import SearchKeywords from '../../container/pages/searchkeywords';
import Coupon from './coupon';

const Chat = React.lazy(() => import('../../container/pages/Chat'));

const Admin = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/listings`} component={Listings} />
        <Route exact path={`${path}/enquiries`} component={Enquiries} />
        <Route path={`${path}/enquiries/:id`} component={EnquiryDetail} />
        <Route path={`${path}/delivery`} component={Delivery} />
        <Route path={`${path}/news`} component={News} />
        <Route exact path={`${path}/deliveries`} component={Deliveries} />
        <Route exact path={`${path}/reviews`} component={Reviews} />
        <Route exact path={`${path}/search-keywords`} component={SearchKeywords} />

        {/* <Route path={`${path}/chats`} component={Chat} /> */}
        <Route path={`${path}/coupon`} component={Coupon} />

        <Route path={`${path}/banner`} component={Banner} />
        <Route path={`${path}/newsletter`} component={Newsletter} />
        <Route exact path={path} render={() => history.push('/admin/users/buyers')} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
