import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect, useHistory,useRouteMatch } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));


const FrontendRoutes = () => {
  const { path } = useRouteMatch();
  const history= useHistory();
  const { isLoggedIn } = useSelector(state => {
    return {
      isLoggedIn: state.auth.login ? state.auth.login : null,
    };
  });

  useEffect(() => {
  if(!isLoggedIn) {
    history.push('/admin')
  }
  }, [isLoggedIn])

   return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        } 
      >

        <Route exact path={`/admin`}  component={Login} />   

      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
