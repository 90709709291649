import React, { useState, useContext } from 'react';
import { Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { WebSocketContext } from '../../../WebSocket';
const AuthInfo = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: 'english'
  });
  const history = useHistory();
  const context = useContext(WebSocketContext)
  const { flag } = state;

  const SignOut = e => {
    e.preventDefault();
    context.disconnectSocket()
    dispatch(logOut());
    history.push('/');
  };

  // const userContent = (
  //   <UserDropDwon>
  //     <div className="user-dropdwon">
  //       <figure className="user-dropdwon__info">
  //         <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
  //         {/* <figcaption>
  //           <Heading as="h5">Abdullah Bin Talha</Heading>
  //           <p>UI Expert</p>
  //         </figcaption> */}
  //       </figure>
  //       <ul className="user-dropdwon__links">
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="user" /> Profile
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="settings" /> Settings
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="dollar-sign" /> Billing
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="users" /> Activity
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="bell" /> Help
  //           </Link>
  //         </li>
  //       </ul>
  //       <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
  //         <FeatherIcon icon="log-out" /> Sign Out
  //       </Link>
  //     </div>
  //   </UserDropDwon>
  // );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      <Notification />
      {/* <Support />
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon style={{ width: 18, position: 'relative', top: 8 }} icon="log-out" /> Sign Out
        </Link>
        {/* <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover> */}
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
