import React, {useEffect, useState} from 'react';
import { Table } from 'antd';
import { UserTableStyleWrapper } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { TableWrapper } from '../../styled';
import { BulkAssignUser } from '../coupon/User/AddCoupon';

const PriceDiffBuyersTable = ({ buyers, buyerCount, setLimit, setPage, page, setSelectedBuyers, form, loading }) => {
  let selectedBuyers = form?.getFieldValue('users') || [];
  const [bulkSelectBuyer,setBulkSelectBuyer] = useState(selectedBuyers)
  const [current, setCurrent] = React.useState(1);
  const buyerTableData = [];
    buyers?.forEach((buyer, i) => {
      buyerTableData.push({
        key: buyer._id,
        buyer: buyer?.details?.garage_name
      });
    });
 useEffect(()=>{
   setBulkSelectBuyer(selectedBuyers)
 },[selectedBuyers.length])

useEffect(()=>{
  setSelectedBuyers(bulkSelectBuyer)
},[bulkSelectBuyer.length])
  const buyerTableColumns = [
    {
      title: 'Buyer Name',
      dataIndex: 'buyer',
      key: 'buyer',
    }
  ];

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedBuyers(selectedRows);
  //   },
  //   selectedRowKeys: form?.getFieldValue('users') || [],
  //   selections: [
  //     Table.SELECTION_ALL,
  //     Table.SELECTION_NONE,
  //   ]
  // };


  const rowSelection = {

    onSelectAll: (selected, selectedRows, changeRows) => {
      let uniqueBuyers = []
    
      if (selected) {
        let newBuyers = [...selectedBuyers, ...selectedRows]?.filter(i => i !== undefined)
        uniqueBuyers = newBuyers.reduce((acc, curr) => {
          if (acc &&!acc.some(a => a?.key === curr?.key)) {
            acc.push(curr);
          }
          return acc;
        }, []);

      } else {
        let newBuyers = selectedBuyers?.filter(s => !changeRows?.some(a => a?.key === s?.key))
        uniqueBuyers = newBuyers
      }
      setSelectedBuyers(uniqueBuyers);
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      let uniqueBuyers = []
      if (selected) {
        let newBuyers = [...selectedBuyers, ...selectedRows]
        uniqueBuyers = newBuyers?.reduce((acc, curr) => {
          if (curr && !acc.some(a => a?.key === curr?.key)) {
            acc.push(curr);
          }
          return acc;
        }, []);

      } else {
        let newBuyers = selectedBuyers?.filter(s => record?.key !== s?.key)
        uniqueBuyers = newBuyers
      }
      setSelectedBuyers(uniqueBuyers);

    },
    onSelectNone: () => {
      let changeRows = buyerTableData
      let newBuyers = selectedBuyers?.filter(s => !changeRows?.some(a => a?.key === s?.key))
      // let changeRowsMaxUseSum = changeRows?.reduce((acc, curr) => acc + curr?.max_use, 0)
      // setAvailableCouponCount(availableCouponCount + changeRowsMaxUseSum)

      setSelectedBuyers(newBuyers);
    },
    selectedRowKeys: selectedBuyers?.map(k => k?.key) || [],
    selections: [
      // Table.SELECTION_ALL,
      {
        key: 'all',
        text: 'Select All data',
        onSelect: changableRowKeys => {
          let uniqueBuyers = []

          let newBuyers = [...selectedBuyers, ...buyerTableData]
          uniqueBuyers = newBuyers.reduce((acc, curr) => {
            if (curr &&  !acc.some(a => a?.key === curr?.key)) {
              acc.push(curr);
            }
            return acc;
          }, []);
          setSelectedBuyers(uniqueBuyers);
        },
      },
      Table.SELECTION_NONE,

    ]
  };


  useEffect(()=>{
    if (buyers.length) {
      setCurrent(1)
    }
  },[buyers])

  const handlePagination = (page, perPage) => {
    setPage(page);
    setLimit(perPage)
  }
  return (
    <>
      <Cards headless bodypadding={'0px'}>
      <BulkAssignUser 
       title="Bulk Assign Buyer" 
       setSelectedBuyers={setBulkSelectBuyer}
        />

        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive"> 
            <Table
              rowSelection={rowSelection}
              dataSource={buyerTableData}
              columns={buyerTableColumns}
              loading={loading}
              pagination={{
                defaultPageSize: 10,
                total: buyerCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                current:page,
                onChange: handlePagination,
                showSizeChanger:true
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </Cards>
    </>
  );
};

export default React.memo(PriceDiffBuyersTable);
