import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import OrderTable from './OrderTable';
import { AutoCompleteStyled } from '../../components/autoComplete/style';
import BulkModal from '../../components/modals/BulkModal';
import AddCategories from '../../components/modals/AddCategories';
import AddSubCategories from '../../components/modals/AddSubCategories';
import { getParentCategories, getCategories } from '../../redux/categories/actionCreator'
import { useSelector, useDispatch } from 'react-redux';


const Orders = () => {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [filteredData, setFilteredData] = React.useState([]);
  const [masterfilteredData, setMasterFilteredData] = React.useState([]);

  const [fitlerByOrderStatus, setFitlerByOrderStatus] = useState('all');
  const [ordersList, setOrdersList] = useState([]);
  const [catmodal, setCatmodal] = React.useState(false);
  const [subcatmodal, setSubCatmodal] = React.useState(false);
  const [isFiltered, setIsFiltered] = useState(false);


  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getParentCategories())
    dispatch(getCategories())
  }, [dispatch])

  const handleSearch = e => {
    setSearchKeyword(e.target.value);
    setIsFiltered(e.target.value === "" && true)
  };

  const handleClosecat = () => {
    setCatmodal(false)
  }
  const handleCloseSubcat = () => {
    setSubCatmodal(false)
  }

  const modalClose = () => {
    setCatmodal(false)
  }

  const modalCloseSub = () => {
    setSubCatmodal(false)
  }


  const { parentCategories, categories } = useSelector(state => {
    return {
      parentCategories: state.categories.categories?.parentCategories,
      categories: state.categories.categories?.categories

    };
  });

  useEffect(() => {
    if (categories && categories.length > 0) {
      setFilteredData(categories);
      setMasterFilteredData(categories)
    }
  }, [categories, isFiltered]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      const filter = filteredData?.filter(obj => obj?.name.toLowerCase().includes(searchKeyword.toLowerCase()));
      setMasterFilteredData(filter)
    }
  };

  const handleSearchOnClick = (event) => {
    event.preventDefault(); // Prevent form submission
    const filter = filteredData?.filter(obj => obj?.name.toLowerCase().includes(searchKeyword.toLowerCase()));
    setMasterFilteredData(filter)
  };

  return (
    <div className="admin_category_table">
      <CardToolbox>
        <PageHeader
          ghost
          title="Category"
          subTitle={
            <>
              <span className="title-counter">{masterfilteredData && masterfilteredData.length} Categories </span>
              <AutoCompleteStyled>
                <Input
                  suffix={<Button type="link" onClick={(e) => handleSearchOnClick(e)}><SearchOutlined /></Button>}
                  placeholder="Search by Category name"
                  onChange={handleSearch}
                  value={searchKeyword}
                  onKeyDown={handleKeyDown}
                />
              </AutoCompleteStyled>

            </>
          }
          buttons={[
            <>

              <Button className="btn-add_new" size="default" onClick={() => setCatmodal(true)} type="primary" key="1">
                + Add New Category
              </Button>

              <Button className="btn-add_new" size="default" onClick={() => setSubCatmodal(true)} type="primary" key="1">
                + Add New SubCategories
              </Button>

            </>
          ]}
        />
      </CardToolbox>
      <Main>
        <AddCategories
          parentCategories={parentCategories}
          showmodal={catmodal}
          onClose={handleClosecat}
          modalClose={modalClose}
        />
        <AddSubCategories
          categories={categories}
          showmodal={subcatmodal}
          onClose={handleCloseSubcat}
          modalClose={modalCloseSub}

        />
        <Row gutter={15}>
          <Col md={24}>
            <OrderTable
              parent={parentCategories}
              categories={masterfilteredData}
            />
          </Col>
        </Row>
      </Main>
    </div>
  );
};

export default Orders;
