import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, message, TreeSelect } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main, BasicFormWrapper } from '../styled';
import axios from 'axios';
import routes from '../../routes/router';
import RolesList from './RolesList';
import EditModal from './EditModal';
import ConfirmModal from '../../components/modals/confirmation';
const { SHOW_PARENT } = TreeSelect;
const chatRoutes = [{
  title: "Chats",
  value: '/chats',
  key: '/chats',
  children: []

}, {
  title: "StaffChatBox",
  value: '/chats/message/:id',
  key: '/chats/message/:id',
  children: []
}, {
  title: 'AdminBuyerChatBox',
  value: '/chats/admin-buyer/message/:id',
  key: '/chats/admin-buyer/message/:id',
  children: []
}]
const RolesAndPermissions = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [transformedRoutes, setTransformedRoutes] = useState([]);
  const [form] = Form.useForm();
  const generateRoutes = () => {
    let newArr = []
    const transformRoutes = (ruts) => {
      for (let i = 0; i < ruts.length; i++) {
        const r = ruts[i];
        if (r?.subRoutes?.length > 0) {
          newArr.push({
            title: r.name,
            value: r.path,
            key: `${r.path}`,
            children: []
          })
          transformRoutes(r.subRoutes);
        }
        if (!r?.subRoutes?.length > 0) {
          newArr.push({
            title: r.name,
            value: r.path,
            key: `${r.path}`,
            children: []
          })
        }
      }
    }
    transformRoutes(routes);
    setTransformedRoutes([...newArr, ...chatRoutes]);
  }

  let getRoleAndPermissions = () => {
    axios.get(`/api/roles-permission`)
      .then(res => {
        setRoles(res.data.map((r, i) => {
          return {
            ...r,
            key: i,
            routePermissions: r.routePermissions.map(rp => {
              return transformedRoutes.find(route => route.value === rp)?.title
            })
          }
        }))
      })
      .catch(err => {
        message.error('Error fetching roles and permissions');
      })
  }



  useEffect(() => {
    generateRoutes()
  }, [routes])

  useEffect(() => {
    getRoleAndPermissions()
  }, [transformedRoutes])


  const handleSubmit = values => {
    setLoading(true);
    axios.post(`/api/roles-permission`, values).then(res => {
      form.resetFields();
      message.success('Role created successfully');
      generateRoutes();
      getRoleAndPermissions();
    }).catch(err => {
      message.error(err?.response?.data?.message || 'Error creating role')
    }).finally(() => {
      setLoading(false);
    })
  };

  const onEdit = (role) => {
    setIsModalOpen(true);
    axios.get(`/api/roles-permission/${role}`).then(res => {
      setRole({
        role,
        routePermissions: res.data
      });
    })
  }

  const [confirm, setConfirm] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState('');
  const openConfirm = (id, role) => {
    setConfirm(true);
    setId(id);
    setName(role)
  };

  const deleteRolesAndPermission = async id => {
    await axios.delete(`/api/roles-permission/${id}`)
      .then(res => {
        message.success('Successfully deleted Role & Route Permissions!');
        generateRoutes();
        getRoleAndPermissions();
      })
      .catch(err => {
        message.err('Failed to delete Role & Route Permissions!');
      });
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setRole(null);
  }

  return (
    <>
      <EditModal getRoleAndPermissions={getRoleAndPermissions} visible={isModalOpen} closeModal={onCloseModal} transformedRoutes={transformedRoutes} role={role} />
      <ConfirmModal
        showmodal={confirm}
        onClose={() => setConfirm(false)}
        eventFunc={deleteRolesAndPermission}
        id={id}
        name={name}
      />
      <PageHeader ghost title="Roles & Permissions" />
      <Main>
        <div className="customized-msg-add-form" style={{ position: 'relative' }}>
          <BasicFormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              name="rolesandpermissions"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <Cards
                title={
                  <>
                    <FeatherIcon icon="plus" size={14} /> Create Role & Route Permissions
                  </>
                }
              >
                <Row align="center" justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="role"
                      label="Role"
                      rules={[
                        {
                          message: 'Please input role',
                          required: true
                        }
                      ]}
                    >
                      <Input rows={5} placeholder="Enter Role" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={14}>
                    <Form.Item
                      name="routePermissions"
                      label="Route Permission"
                      rules={[
                        {
                          message: 'Please select route permissions',
                          required: true
                        }
                      ]}
                    >
                      <TreeSelect
                        style={{height: "100%"}}
                        treeData={transformedRoutes}
                        treeCheckable
                        showCheckedStrategy={SHOW_PARENT}
                        placeholder="Select Route Permissions"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={4}>
                    <Form.Item>
                      <Button
                        style={{ marginTop: '36px' }}
                        htmlType="submit"
                        type="primary"
                        size="large"
                        loading={loading}
                      >
                        Create
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Cards>
              <Cards
                title={
                  <>
                    List of Role & Route Permissions
                  </>
                }
              >
                <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                  <Col xs={24} md={24}>
                    <RolesList roles={roles} onEdit={onEdit} openConfirm={openConfirm} />
                  </Col>
                </Row>
              </Cards>
            </Form>
          </BasicFormWrapper>

        </div>
      </Main>

    </>
  );
};

export default RolesAndPermissions;