import actions from './actions';

const initialState = {
  requests: [],
  loading: false,
  error: null,
  isRequestUpdated: false
};

const {
  GET_REQUEST_LIST_BEGIN,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_LIST_ERR,
  UPDATE_REQUEST_STATUS_BEGIN,
  UPDATE_REQUEST_STATUS_SUCCESS,
  UPDATE_REQUEST_STATUS_ERR,

  RESET_FLAGS
} = actions;

const requestReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_REQUEST_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: data
      };
    case GET_REQUEST_LIST_ERR:
      return {
        ...state,
        loading: false,
        requests: [],
        err: err
      };
    case UPDATE_REQUEST_STATUS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case UPDATE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isRequestUpdated: true
      };
    case UPDATE_REQUEST_STATUS_ERR:
      return {
        ...state,
        loading: false,
        err: err
      };
    case RESET_FLAGS:
      return {
        isRequestUpdated: false
      };
    default:
      return state;
  }
};

export default requestReducer;
