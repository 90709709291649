import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Users from './users';
import Listings from './listings';
import Settings from './settings';
import Emails from './emails';
import Enquiries from '../../container/EnquiryPage';
import Orders from './orders';
import RfqRoute from './rfq';
import withAdminLayout from '../../layout/withAdminLayout';
import Payments from '../../container/pages/payment';
import Sales from './sales';
import Delivery from './delivery';
import Deliveries from '../../container/deliveries';
const Chat = React.lazy(() => import('../../container/pages/Chat'));

const SaleScout = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/users`} component={Users} />
        {/* <Route exact path={`${path}/payments`} component={Payments} /> */}
        {/* <Route path={`${path}/listings`} component={Listings} /> */}
        {/* <Route path={`${path}/settings`} component={Settings} /> */}
        {/* <Route path={`${path}/enquiries`} component={Enquiries} /> */}
        {/* <Route path={`${path}/orders`} component={Orders} /> */}
        {/* <Route exact path={`${path}/deliveries`} component={Deliveries} /> */}
        {/* <Route path={`${path}/chats`} component={Chat} /> */}
        {/* <Route path={`${path}/rfq`} component={RfqRoute} /> */}
        {/* <Route exact path={`${path}/sales`} component={Sales} />
        <Route exact path={`${path}/delivery`} component={Delivery} /> */}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(SaleScout);
