import axios from 'axios';
import actions from './actions';

const { getReviewsListBegin, getReviewsListSuccess, getReviewsListErr } = actions;

export const getReviewsList = () => async dispatch => {
  try {
    dispatch(getReviewsListBegin());
    const resp = await axios.get('/api/review/all-reviews');

    dispatch(getReviewsListSuccess(resp.data));
  } catch (err) {
    dispatch(getReviewsListErr(err));
  }
};
