import React, { useEffect, useState, useContext, Suspense } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Spin } from 'antd';
import withAdminLayout from './layout/withAdminLayout';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Finance from './routes/finance';
import Sales from './routes/sale_scout';
import Merchandiser from './routes/merchandiser';
import Gallega from './routes/gallega';
import Marketing from './routes/marketing';
import Delivery from './routes/delivery_scout';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import axios from 'axios';
import { baseUrl } from './constants';
import WebSocketProvider, { WebSocketContext } from './WebSocket';
import WebSocketAdminBuyerProvider, { WebSocketAdminBuyerContext } from './AdminBuyerSocket';
import Warehouse from './routes/warehouse';
import { getNotificationInital, getPermissions } from './redux/authentication/actionCreator';
const Chat = React.lazy(() => import('./container/pages/Chat'));
import routes from './routes/router';
import PrivateRoute from './routes/PrivateRoute';
import Cookies from 'js-cookie';
import { ignoredUrls } from './utility/utility';
const { theme } = config;



const Routes = withAdminLayout(({ routePermissions, path }) => {
  const history = useHistory()

  const [pathname, setPathname] = useState(window.location.pathname)
  useEffect(() => {
    if (pathname === "/") {
      history.push("/admin")
    }
  }, [pathname])

  const _newRoutes = []
  const newRoutes = (ruts) => {
    if (ruts?.subRoutes?.length > 0) {
      if (ruts?.component) {
        _newRoutes.push({
          path: ruts.path,
          component: ruts.component,
          exact: ruts.exact,
          name: ruts.name,
        })
      }
      ruts.subRoutes.forEach(r => newRoutes(r))
    } else {
      _newRoutes.push(ruts)
    }
  }
  routes.forEach(r => newRoutes(r))
  let isAuthChat = routePermissions?.includes(`/chats`)
  return (
    <Switch>

      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {
          routePermissions.length > 0 && _newRoutes.map((route, index) => {
            let isAuth = routePermissions?.includes(route?.path) || false
            if (route.component) {
              return (
                <PrivateRoute
                  key={index}
                  path={`${path}${route.path}`}
                  exact={route.exact}
                  isAuth={isAuth}
                  component={route.component}
                />
              );
            }
          }
          )
        }
        {/*
          isAuthChat  && <Route path={`${path}/chats`} component={Chat} />
      */}
      </Suspense>
    </Switch>
  )
})
const ProviderConfig = () => {
  const [_isLoggedIn, setIsLoggedIn] = useState(null);
  const dispatch = useDispatch();
  const context = useContext(WebSocketContext);
  const adminBuyerWebsocketContext = useContext(WebSocketAdminBuyerContext);
  const { rtl, isLoggedIn, topMenu, darkMode, routePermissions } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login ? state.auth.login : null,
      routePermissions: state.auth.routePermissions
    };
  });
  const [path, setPath] = useState(window.location.pathname);


  useEffect(() => {
    if (typeof isLoggedIn === 'string') {
      setIsLoggedIn(JSON.parse(isLoggedIn));
    } else {
      setIsLoggedIn(isLoggedIn);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (_isLoggedIn?._id) {
      dispatch(getNotificationInital(_isLoggedIn._id));
      dispatch(getPermissions(_isLoggedIn.role));
      context.getSocket(_isLoggedIn._id);
      adminBuyerWebsocketContext.getSocketAB(_isLoggedIn._id);
    }
  }, [_isLoggedIn]);

  useEffect(() => {

    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  axios.defaults.baseURL = baseUrl;

  // Added a request interceptors
  axios.interceptors.request.use(function (config) {
    const token = Cookies.get('access_token');

    if (token && !config?.url.includes(ignoredUrls)) {
      config.headers.Authorization = token;
    }

    return config;
  });

  //Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the token has expired and we need to refresh it
      if (error.response?.status === 403 || error.response?.status === 401) {
        // originalRequest._retry = true;

        // try {
        //   const refreshToken = Cookies.get('refreshToken');
        //   const response = await axios.post('/api/refresh-token', { refreshToken });
        //   const { token } = response.data;

        //   Cookies.set('access_token', token);

        //   // Retry the original request with the new token
        //   originalRequest.headers.Authorization = `Bearer ${token}`;
        //   return axios(originalRequest);
        // } catch (error) {
        //   Cookies.remove('logedIn');
        //   Cookies.remove('access_token');

        //   window.location.href = "/admin";
        // }
        Cookies.remove('logedIn');
        Cookies.remove('access_token');

        window.location.href = "/admin";
      }

      return Promise.reject(error);
    }
  );


  let cmp = Admin

  switch (_isLoggedIn?.role) {
    case 'admin':
      cmp = Admin
      break;
    case 'sales':
      cmp = Sales
      break;
    case 'merchandiser':
      cmp = Merchandiser
      break;
    case 'logistics_admin':
      cmp = Gallega
      break;
    case 'delivery':
      cmp = Delivery
      break;
    case 'finance':
      cmp = Finance
      break;
    case 'marketing_manager':
      cmp = Marketing
      break;
    case 'warehouse':
      cmp = Warehouse
      break;
    default:
      cmp = Admin
      break;
  }

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router>
          {!_isLoggedIn && <Route path="/" component={Auth} />}
          {/* <ProtectedRoute path="/admin" component={cmp} /> */}
          {_isLoggedIn && <Routes path="/admin" routePermissions={routePermissions} />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};
function App() {
  return (
    <Provider store={store}>
      <WebSocketAdminBuyerProvider>
        <WebSocketProvider>
          <ProviderConfig />
        </WebSocketProvider>
      </WebSocketAdminBuyerProvider>
    </Provider>
  );
}

export default hot(App);
