import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
          <div className="auth-side-content">
            <Content>
              <img style={{ width: '300px' }} src={require('../../../static/img/autobox-logo.svg')} alt="" />
              {/* <Heading as="h1">
                Spare Parts Marketplace <br />
                Admin Application
              </Heading> */}
            </Content>
          </div>
        </Col>

        <Col xxl={24} md={24} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
