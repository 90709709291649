import React from 'react';
import { Table } from 'antd';
import { UserTableStyleWrapper } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { TableWrapper } from '../../styled';
import './my-style.css'

const CouponUserListTable = ({ selectedRows, couponList, setSelectedBuyers, setAvailableCouponCount, availableCouponCount, buyerCount, setLimit, setPage, page, couponData, userCoupons, loading }) => {

  let selectedBuyers = [...selectedRows]

  const couponTableData = [];

  couponList &&
    couponList.length > 0 &&
    couponList.map((couponuser, i) => {
      const { max_use, ...buyer } = couponuser;

      let max = selectedRows?.find(s => s?.key === buyer?._id)?.max_use
      let m = max || max_use
      let total_used = userCoupons?.find(u => u?.user?._id === buyer?._id)?.total_used || 0

      return couponTableData.push({
        key: buyer._id,
        buyer: buyer?.details?.garage_name,
        max_use: m > 0 ? m : total_used,
        total_used,
      });
    });

  const couponTableColumns = [
    {
      title: 'Buyer',
      dataIndex: 'buyer',
      key: 'buyer',
    },
    {
      title: 'Max Use',
      dataIndex: 'max_use',
      key: 'max_use',
    },
    {
      title: 'Total Used',
      dataIndex: 'total_used',
      key: 'total_used',
    }
  ];

  const rowSelection = {
    onSelectAll: (selected, selectedRows, changeRows) => {
      let uniqueBuyers = []
      if (selected) {
        let newBuyers = [...selectedBuyers, ...selectedRows, ...changeRows]?.filter(i => i !== undefined)
        uniqueBuyers = newBuyers.reduce((acc, curr) => {
          if (!acc.some(a => a?.key === curr?.key)) {
            acc.push(curr);
          }
          return acc;
        }, []);
      } else {
        function checkAvailability(arr, val) {
          return arr.some(function (arrVal) {
            return val === arrVal;
          });
        }
        let newBuyers = selectedBuyers?.filter(s => !changeRows?.some(a => a?.key === s?.key))
        let _uniqueBuyers = newBuyers.filter(s => s?.max_use !== 0)

        let total_used_all = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
        let _availableCouponCount = (couponData?.max_use || 0) - total_used_all
        let selectedBuyerCount = _uniqueBuyers?.reduce((acc, curr) => acc + curr?.max_use, 0)

        let changeRowsId = _uniqueBuyers?.map(s => s?.key)
        let selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))

        let selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);

        uniqueBuyers = newBuyers
        setAvailableCouponCount(_availableCouponCount - selectedBuyerCount + selectedBuyerUsed)
      }
      setSelectedBuyers(uniqueBuyers);
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      let uniqueBuyers = []
      if (selected) {
        let newBuyers = [...selectedBuyers, ...selectedRows]?.filter(i => i !== undefined)
        uniqueBuyers = newBuyers?.reduce((acc, curr) => {
          if (!acc.some(a => a?.key === curr?.key)) {
            acc.push(curr);
          }
          return acc;
        }, []);

      } else {
        function checkAvailability(arr, val) {
          return arr.some(function (arrVal) {
            return val === arrVal;
          });
        }
        let newBuyers = selectedBuyers?.filter(s => record?.key !== s?.key)
        let _uniqueBuyers = selectedBuyers?.filter(s => (record?.key !== s?.key && s?.max_use !== 0))
        uniqueBuyers = newBuyers

        let total_used_all = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
        let _availableCouponCount = (couponData?.max_use || 0) - total_used_all

        let selectedBuyerCount = _uniqueBuyers?.reduce((acc, curr) => acc + curr?.max_use, 0)

        let changeRowsId = _uniqueBuyers?.map(s => s?.key)

        let selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))

        let selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
        setAvailableCouponCount(_availableCouponCount - selectedBuyerCount + selectedBuyerUsed)
      }
      setSelectedBuyers(uniqueBuyers);

    },
    onSelectNone: () => {
      function checkAvailability(arr, val) {
        return arr.some(function (arrVal) {
          return val === arrVal;
        });
      }
      let changeRows = couponTableData
      let newBuyers = selectedBuyers?.filter(s => !changeRows?.some(a => a?.key === s?.key))
      // let newBuyers = []

      let total_used_all = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
      let _availableCouponCount = (couponData?.max_use || 0) - total_used_all

      let selectedBuyerCount = newBuyers?.reduce((acc, curr) => acc + curr?.max_use, 0)

      let changeRowsId = newBuyers?.map(s => s?.key)
      let selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))

      let selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);

      setAvailableCouponCount(_availableCouponCount - selectedBuyerCount + selectedBuyerUsed)

      setSelectedBuyers(newBuyers);
    },
    selectedRowKeys: selectedRows?.map(k => k?.key) || [],
    selections: [
      // Table.SELECTION_ALL,
      {
        key: 'all',
        text: 'Select All data',
        onSelect: changableRowKeys => {
          let uniqueBuyers = []

          let newBuyers = [...selectedBuyers, ...couponTableData]
          uniqueBuyers = newBuyers.reduce((acc, curr) => {
            if (!acc.some(a => a?.key === curr?.key)) {
              acc.push(curr);
            }
            return acc;
          }, []);
          setSelectedBuyers(uniqueBuyers);
        },
      },
      Table.SELECTION_NONE,
    ]
  };

  const handlePagination = (page, perPage) => {
    setPage(page);
    setLimit(perPage)
  }

  return (
    <>
      <Cards headless>
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive th-left1">
            <Table
              loading={loading}
              rowSelection={rowSelection}
              dataSource={couponTableData}
              columns={couponTableColumns}
              rowKey={(record) => record.key}
              pagination={{
                defaultPageSize: 10,
                total: buyerCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                current: page,
                onChange: handlePagination,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </Cards>
    </>
  );
};

export default CouponUserListTable;
