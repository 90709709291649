import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const SalesList = lazy(() => import('../../container/pages/SalesScoutListTable'));
const SalesManagerList = lazy(() => import('../../container/pages/SalesManagerListTable'));
const EditSales = lazy(() => import('../../container/pages/EditSalesForm'));
const EditDelivery = lazy(() => import('../../container/pages/EditDeliveryForm'));
const AddSalesManager = lazy(() => import('../../container/pages/wizards/overview/AddSalesManager'));

const BuyerDetails = lazy(() => import('../../container/pages/BuyerDetails'));

const SalesManager = () => {
  const { path } = useRouteMatch();
  return (
    // <h1>Comming Soon..</h1>
    <Switch>
      {/* <Route path={`${path}/delivery/:id`} component={BuyerDetails} /> */}
      <Route exact path={`${path}`} component={SalesManagerList} />
      <Route exact path={`${path}/view-sales-manager/:id`} component={EditDelivery} />
      <Route path={`${path}/edit-sales-manager/:id`} component={EditDelivery} />
      {/* <Route path={`${path}/sales-manager/add`} component={AddSalesManager} /> */}
      <Route path={`${path}/add`} component={AddSalesManager} />
    </Switch>
  );
};

export default SalesManager;
