import React, { useState } from 'react';
import { Rate, Row, Col, Drawer } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { ListingCard } from './Style';
import { updateWishList } from '../../../redux/listing/actionCreator';
import Product from './Product';
import { Checkbox } from 'antd';
import { ProductImage } from '../../../utility/utility';
const ListingCardList = ({ part, fileId, checkedList, setCheckedList }) => {
  const { _id, partName, priceExclVat, pic, description, imageArray,partSKU } = part;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const numberSeperator = num => {
    var n = parseFloat(num).toFixed(2);
    n = Math.floor(n);
    var withCommas = Number(n).toLocaleString('en');
    return withCommas;
  };
  const rate = 3.5;
  let url = pic || imageArray?.[0]

  const onChange = (e, id) => {
    if (e.target.checked) {
      setCheckedList(prev => [...prev, id]);
    } else {
      setCheckedList(prev => {
        return prev.filter(item => item !== id);
      });
    }
  };


  const searchURL = `https://www.google.com/search?q=${partSKU}&sxsrf=ALeKk024yvF_PK1goOAj5BEzi2WDA_torg:1625654125967&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiZr9LF4dDxAhVZILcAHX18CLIQ_AUoAXoECAEQAw&biw=1366&bih=617`
  return (
    <ListingCard className="list-view" style={{ marginBottom: 20 }}>
      <Drawer
        width={840}
        title="List Details"
        placement="right"
        closable={false}
        style={{ zIndex: 20 }}
        onClose={onClose}
        visible={visible}
      >
        <Product fileId={fileId} list={part} onClose={onClose} />
      </Drawer>
      <div className="product-list">
        <Row gutter={15}>
          <Col md={1} xs={24}>
            <Checkbox
              onChange={e => onChange(e, _id)}
              checked={checkedList?.length > 0 && checkedList?.includes(_id)}
            />
            {/* <Checkbox value={_id} /> */}
          </Col>

          <Col md={5} xs={24}>
            <figure>
              {/* <img src={url} alt={`img${_id}`} height={200} width="100%" style={{ objectFit: 'cover' }} /> */}
              <ProductImage
                avatar={url}
                alt={`img${_id}`}
                height={200}
                width="100%"
                style={{ objectFit: 'cover' }}
              />
            </figure>
          </Col>
          <Col md={12} xs={24}>
            <div className="product-single-description">
              <Heading className="product-single-title" as="h5">
                {partName}
              </Heading>
              <div dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </Col>
          <Col md={6} xs={24}>
            <div className="product-single-info">
              <p className="product-single-price">
                <span className="product-single-price__new">AED {numberSeperator(priceExclVat)} </span>
              </p>
              <span type="secondary">Exc. VAT</span>
              {/* <div className="product-single-rating">
                <Rate allowHalf defaultValue={rate} disabled /> 4.9
                <span className="total-reviews"> 778 Reviews</span>
              </div> */}
              <div className="product-single-action">
                {/* <Button className="btn-cart" size="small" type="white" outlined>
                  <FeatherIcon icon="shopping-bag" size={14} />
                  Add To Cart
                </Button> */}
                <Button onClick={showDrawer} size="small" type="primary">
                  View
                </Button>

                <Button size="small" style={{ marginTop: '10px' }} type="danger" outlined="variant">
                  <a href={searchURL} target="_blank">
                    View Images
                  </a>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </ListingCard>
  );
};

ListingCardList.propTypes = {
  part: PropTypes.object
};

export default ListingCardList;
