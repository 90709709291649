import React from 'react';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { useSelector, useDispatch } from 'react-redux';
import { Main, CardToolbox } from '../../styled';
import { getPaymentList } from '../../../redux/payment/actionCreator';
import { Row, Col, Input } from 'antd';
import PaymentTable from './paymentTable';
import { SearchOutlined } from '@ant-design/icons';
import { AutoCompleteStyled } from '../../../components/autoComplete/style';

const index = () => {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [filteredSearch, setFilteredSearch] = React.useState([]);
  const paymentDetails = useSelector(state => state.payment.payment);
  const loader = useSelector(state => state.payment.loading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPaymentList());
  }, [dispatch]);

  React.useEffect(() => {
    setFilteredSearch(paymentDetails);
  }, [paymentDetails]);

  const handleSearch = e => {
    if (e.target.value === '') {
      setFilteredSearch(paymentDetails);
    } else {
      let filter = paymentDetails.filter(({ _id, user, card_name }) => {
        let str = `${_id} ${user?.name} ${card_name}`;
        return str.toLowerCase().search(e.target.value.toLowerCase()) > -1;
      });
      setFilteredSearch(filter);
    }
    setSearchKeyword(e.target.value);
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Payment Record"
          subTitle={
            <>
              <span className="title-counter"> {filteredSearch && filteredSearch.length} Found</span>
              <AutoCompleteStyled>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  onChange={handleSearch}
                  value={searchKeyword}
                />
              </AutoCompleteStyled>
            </>
          }
        />
      </CardToolbox>

      <Main>
        <Row>
          <Col md={24}>
            <PaymentTable paymentDetails={filteredSearch} loader={loader} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default index;
