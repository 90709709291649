import { de } from 'date-fns/locale';
import actions from './action';

const initialState = {
  priceData: [],
  priceLoading: false,
  error: null
};

const {
  PRICE_BEGIN,
  GET_PRICE_SUCCESS,
  ADD_PRICE_SUCCESS,
  EDIT_PRICE_SUCCESS,
  DELETE_PRICE_SUCCESS,
  PRICE_ERR,
} = actions;

const couponReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PRICE_BEGIN:
      return {
        ...state,
        priceLoading: true
      };
    case GET_PRICE_SUCCESS:
      return {
        ...state,
        priceData: data,
        priceLoading: false
      };

    case ADD_PRICE_SUCCESS:
      return {
        ...state,
        priceData: [...state.priceData, data],
        priceLoading: false
      };

    case EDIT_PRICE_SUCCESS:
      return {
        ...state,
        priceLoading: false,
        priceData: [...state.priceData].map(price => (price._id === data._id ? data : price))
      };

    case DELETE_PRICE_SUCCESS:
      return {
        ...state,
        priceLoading: false,
        priceData: [...state.priceData].filter(price => price._id !== data)
      };

    case PRICE_ERR:
      return {
        ...state,
        error: err,
        price: false
      };
    default:
      return state;
  }
};

export default couponReducer;
