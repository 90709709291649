import moment from 'moment-timezone';
import TotalRevenue from '../routes/TotalRevenue/TotalRevenue';

const numberWithCommas = x => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return 0;
};

export const noOfOrdersPerDayOnWeek = orders => {
  let counts = [0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    let index = moment(order.order_date).day();
    counts[index] += 1;
  }
  return counts;
};

export const noOfOrdersPerRange = (orders, range) => {
  let a = moment(range[1]);
  let b = moment(range[0]);
  let noOfDays = a.diff(b, 'days') + 1
  let counts = new Array(noOfDays).fill(0)
  for (let i = 0; i < counts.length; i++) {
    let thisday = moment(range[0]).add(i, 'days')
    let thisDateOrders = orders.filter(o => {
      let isOfSameDay = moment(o.order_date).isSame(thisday,'days')
      if (isOfSameDay) {
        return o
      }
    })
    counts[i] = thisDateOrders.length;
  }
  // counts = counts.reverse()
 return counts
}

export const labelsForRange = ( range) => {
  let a = moment(range[1]);
  let b = moment(range[0]);
  let noOfDays = a.diff(b, 'days') + 1
  let labels = []
  for (let i = 0; i < noOfDays; i++) {
    labels.push(moment(range[0]).add(i, 'days').format("Do MMM"))
  }
  return labels
}

export const noOfOrdersPerMonth = orders => {
  let counts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    let index = moment(order.order_date).month();
    counts[index] += 1;
  }
  return counts;
};

export const noOfOrdersPerYears = orders => {
  let counts = [0, 0, 0, 0, 0, 0];
  counts[0] = orders.lastYearOrders_4?.length;
  counts[1] = orders.lastYearOrders_3?.length;
  counts[2] = orders.lastYearOrders_2?.length;
  counts[3] = orders.lastYearOrders_1?.length;
  // counts[4] = orders.lastYearOrders_0?.length
  counts[4] = orders.lastYearOrders?.length;
  counts[5] = orders.thisYearOrders?.length;
  return counts;
};

const caclRevenue = orders => Math.round(orders
?.map(item => (item.total_sales ? item.total_sales : 0))
.reduce((prev, next) => prev + next, 0));

export const calcPrevRevenue = (totalRevenue, period = 'week', withTax = false) => {
  let orders
  if (period == 'week') orders = totalRevenue?.lastWeekOrders || []
  if (period == 'month') orders = totalRevenue?.lastYearOrders || []
  let prevRevenue = caclRevenue(orders)
  // if (withTax) {
  //   prevRevenue = prevRevenue + Math.ceil(prevRevenue * 0.05)
  // }
  return numberWithCommas(prevRevenue);
};


export const calcCurrentRevenue = (totalRevenue, period = 'week', withTax = false) => {
  let orders
  if (period == 'week') orders = totalRevenue?.thisWeekOrders || []
  if (period == 'month') orders = totalRevenue?.thisYearOrders || []
  if (period == 'year') orders = [...totalRevenue?.thisYearOrders || [], ...totalRevenue?.lastYearOrders || [], ...totalRevenue?.lastYearOrders_1 || [], ...totalRevenue?.lastYearOrders_2 || [], ...totalRevenue?.lastYearOrders_3 || [], ...totalRevenue?.lastYearOrders_4 || [],]
  let currentRevenue = caclRevenue(orders)
  // if (withTax) {
  //   currentRevenue = currentRevenue + Math.ceil(currentRevenue * 0.05)
  // }

  return numberWithCommas(currentRevenue?.toFixed(2));
};


export const calcCustomRevenue = (totalRevenue) => {
  let dateRevenue = caclRevenue(totalRevenue?.dateRangeOrders)
  return numberWithCommas(dateRevenue);
}
