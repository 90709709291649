import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const AddMerchandiser = lazy(() => import('../../container/pages/wizards/overview/AddMerchandiser'));
const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const merchandiserList = lazy(() => import('../../container/pages/MerchandiserListTable'));
// const Editmerchandiser = lazy(() => import('../../container/pages/EditMerchandiserForm'));

const Merchandiser = () => {
  const { path } = useRouteMatch();

  return (
    // <h1>Comming Soon..</h1>
    <Switch>
      {/* <Route path={`${path}/:id`} component={BuyerDetails} /> */}
      {/* <Route exact path={`${path}/add`} component={AddMerchandiser} /> */}
      <Route eaxct path={`${path}/`} component={merchandiserList} />
      {/* <Route exact path={`${path}/view-merchandiser/:id`} component={Editmerchandiser} />
      <Route exact path={`${path}/edit-merchandiser/:id`} component={Editmerchandiser} /> */}
    </Switch>
  );
};

export default Merchandiser;
