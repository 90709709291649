const actions = {
  ALL_CHATROOM_BEGIN: 'ALL_CHATROOM_BEGIN',
  ALL_CHATROOM_SUCCESS: 'ALL_CHATROOM_SUCCESS',
  ALL_CHATROOM_ERR: 'ALL_CHATROOM_ERR',

  CHATROOM_MESSAGE_BEGIN: 'CHATROOM_MESSAGE_BEGIN',
  CHATROOM_MESSAGE_SUCCESS: 'CHATROOM_MESSAGE_SUCCESS',
  CHATROOM_MESSAGE_ERR: 'CHATROOM_MESSAGE_ERR',

  chatRoomBegin: () => {
    return {
      type: actions.ALL_CHATROOM_BEGIN
    };
  },

  chatRoomSuccess: data => {
    return {
      type: actions.ALL_CHATROOM_SUCCESS,
      data
    };
  },

  chatRoomErr: err => {
    return {
      type: actions.ALL_CHATROOM_ERR,
      err
    };
  },

  chatRoomMessageBegin: () => {
    return {
      type: actions.CHATROOM_MESSAGE_BEGIN
    };
  },
  chatRoomMessageSuccess: data => {
    return {
      type: actions.CHATROOM_MESSAGE_SUCCESS,
      data
    };
  },

  chatRoomMessageErr: err => {
    return {
      type: actions.CHATROOM_MESSAGE_ERR,
      err
    };
  }
};

export default actions;
