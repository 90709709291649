import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table, Drawer, Typography } from 'antd';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import { TableWrapper, StatusWrapper } from '../../styled';
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { updateUser } from '../../../redux/users/actionCreator';
import ConfirmModal from '../../../components/modals/confirmation';
import { formatPhoneNumber } from '../../../utility/utility';
import { WarningOutlined } from '@ant-design/icons';


const SellerListTable = ({ sellers, sellerCount, deleteSeller, loading, page,
  setPage, setLimit, setarrayAssigner, setIsAllUnSelect, isAllUnSelecet, arrayAssigner }) => {
  const dispatch = useDispatch();
  const { isUserUpdated } = useSelector(state => {
    return {
      isUserUpdated: state.users.isUserUpdated
    };
  });
  const [approvalModal, setApprovalModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    if (isUserUpdated) {
      closeModal();
    }
  }, [isUserUpdated]);
  const showModal = value => {
    setSelectedSeller(value);
    setApprovalModal(true);
  };
  const closeModal = () => {
    setSelectedSeller(null);
    setApprovalModal(false);
  };
  const handleUpdateStatus = status => {
    if (status) {
      dispatch(updateUser({ ...selectedSeller, status }));
    } else {
      dispatch(
        updateUser({ ...selectedSeller, status: selectedSeller.status === 'Approved' ? 'Blocked' : 'Approved' })
      );
    }
  };
  const [deleteMessage, setDeleteMessage] = useState('')
  const getSeller = async id => {
    setOpenDrawer(true);
  };
  const openConfirm = (id, name) => {
    setConfirm(true);
    setId(id);
    setName(name);
    setDeleteMessage(<Typography.Text type='danger'>Note:<span> All SKUs under the seller will also be deleted. </span></Typography.Text>)
  };
  const usersTableData = [];
  sellers &&
    sellers.length > 0 &&
    sellers.map(user => {
      const { _id, email, phone, details, avatar, date, status, sellerpic, totalSales, seller_code, bmDTs, aliasName, enableDeletion } = user;
      const numberSeperator = num => {
        var n = parseFloat(num).toFixed(2);
        n = Math.floor(n);
        var withCommas = Number(n).toLocaleString('en');
        return withCommas;
      };
      return usersTableData.push({
        key: _id,
        aliasName,
        user: (
          <>
            <div className="user-info" style={{ alignItems: "flex-start" }}>
              <figure>
                <img style={{ width: '00px' }} src={sellerpic} alt="" />
              </figure>
              {enableDeletion == false && <span><WarningOutlined style={{ color: '#f39200', fontSize: 22 }} /></span>}
              <figcaption style={{marginLeft: "8px"}}>
                <Heading className="user-name" as="h6">
                  {details && details.company_name}
                </Heading>
                <span className="user-designation">{email}</span>
                <span className="user-designation">{phone && formatPhoneNumber(phone)}</span>
                <span className="user-designation">
                  Joined{' '}
                  {moment(date)
                    .tz('Asia/Dubai')
                    .format('DD/MM/YYYY')}
                </span>
              </figcaption>
            </div>
          </>

        ),
        totalAmount: `AED ${numberSeperator(Math.round(totalSales || 0))}`,
        delivery_time: <>
          {
            bmDTs?.map(bmDT => (
              <div className='d-grid grid-column-2 gap-10 user-info'>
                <h6 className="user-name mr-2" style={{ marginBottom: "0px" }}>{bmDT?.business_model}</h6>
                <span className='user-designation'>({bmDT?.delivery_time?.name})</span>
              </div>
            ))
          }
        </>,
        seller_code,
        emirate: details?.emirate,
        status: (
          <StatusWrapper>
            <div className="user-status" onClick={() => showModal(user)}>
              {status}
            </div>
            {details && details.seller_type && (
              <div className="user-type" style={{ whiteSpace: 'break-spaces' }}>
                {details.seller_type}
              </div>
            )}
          </StatusWrapper>
        ),
        action: (
          <div className="table-actions">
            <>
              <Button className="btn-icon" type="primary" to="#" shape="circle">
                <NavLink to={`/admin/users/view-seller/${_id}`}>
                  <FeatherIcon icon="eye" size={16} />
                </NavLink>
              </Button>
              <Button className="btn-icon" type="info" to="" shape="circle">
                <NavLink to={`/admin/users/edit-seller/${_id}`}>
                  <FeatherIcon icon="edit" size={16} />
                </NavLink>
              </Button>
              <Button
                onClick={() => openConfirm(_id, details?.company_name)}
                className="btn-icon"
                type="danger"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </>
          </div>
        )
      });
    });
  const usersTableColumns = [
    {
      title: 'Seller Code',
      dataIndex: 'seller_code',
      key: 'seller_code',
    },
    {
      title: 'Alias Name',
      dataIndex: 'aliasName',
      key: 'aliasName',
    },
    {
      title: 'Seller Details',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Delivery Time',
      dataIndex: 'delivery_time',
      key: 'delivery_time',
    },
    {
      title: 'Emirate',
      dataIndex: 'emirate',
      key: 'emirate'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '40%'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
      fixed: "right",
    }
  ];

  const handlePagination = (page, perPage) => {
    setPage(page);
    setLimit(perPage)
  }


  const onSelectChange = (newSelectedRowKeys) => {
    setarrayAssigner(newSelectedRowKeys)
    setIsAllUnSelect(false)
  };
  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: isAllUnSelecet ? [] : arrayAssigner,
    onChange: onSelectChange,
  };



  return (
    <>
      <ConfirmModal
        showmodal={confirm}
        onClose={() => setConfirm(false)}
        eventFunc={deleteSeller}
        id={id}
        note={deleteMessage}
        name={name}
      />
      <Cards headless>
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              // rowSelection={rowSelection}
              dataSource={usersTableData}
              columns={usersTableColumns}
              loading={loading}
              pagination={{
                onChange: handlePagination,
                current: page,
                defaultPageSize: 10,
                total: sellerCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
        <Modal
          type="primary"
          title={'Update Status'}
          visible={approvalModal}
          footer={
            selectedSeller && selectedSeller.status === 'Pending'
              ? [
                <Button size="default" type="primary" onClick={() => closeModal()} key="cancel-status">
                  Cancel
                </Button>,
                <Button
                  htmlType="submit"
                  size="default"
                  type="primary"
                  onClick={() => handleUpdateStatus('Approved')}
                  key="update-status"
                >
                  Approve
                </Button>,
                <Button
                  htmlType="submit"
                  size="default"
                  type="primary"
                  onClick={() => {
                    handleUpdateStatus('Blocked')
                  }}
                  key="update-status"
                >
                  Block
                </Button>
              ]
              : [
                <Button size="default" type="primary" onClick={() => closeModal()} key="cancel-status">
                  Cancel
                </Button>,
                <Button
                  htmlType="submit"
                  size="default"
                  type="primary"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                  key="update-status"
                >
                  {selectedSeller && selectedSeller.status === 'Approved' ? 'Block' : 'Approve'}
                </Button>
              ]
          }
          onCancel={() => closeModal()}
        >
          <div>
            Are you sure to{' '}
            {selectedSeller && selectedSeller.status === 'Pending'
              ? 'Approve/Block'
              : selectedSeller && selectedSeller.status === 'Approved'
                ? 'Block'
                : 'Approve'}{' '}
            this seller?
          </div>
        </Modal>
        <Drawer
          width={800}
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawer(false)}
          visible={openDrawer}
          getContainer={false}
        >
          <div className="drawaer-container">
            <Row justify="center">
              <Col xl={10} md={16} xs={24}>
                <div className="user-info-form">{'helloworld'}</div>
              </Col>
            </Row>
          </div>
        </Drawer>
      </Cards>
    </>
  );
};
export default SellerListTable;
