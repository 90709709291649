const actions = {
    INSPECTION_MODULE_BEGIN: 'INSPECTION_MODULE_BEGIN',
    GET_INSPECTION_MODULE_SUCCESS: 'GET_INSPECTION_MODULE_SUCCESS',
    GET_INSPECTION_MODULE_BY_ID_SUCCESS: 'GET_INSPECTION_MODULE_BY_ID_SUCCESS',
    INSPECTION_MODULE_ERR: 'INSPECTION_MODULE_ERR',
    ADD_INSPECTION_MODULE_SUCCESS: 'ADD_INSPECTION_MODULE_SUCCESS',
    DELETE_INSPECTION_MODULE_SUCCESS: 'DELETE_INSPECTION_MODULE_SUCCESS',


    inspectionModuleBegin: () => {
        return {
            type: actions.INSPECTION_MODULE_BEGIN
        };
    },
    getInspectionModuleSuccess: data => {
        return {
            type: actions.GET_INSPECTION_MODULE_SUCCESS,
            data
        };
    },
    inspectionModuleErr: err => {
        return {
            type: actions.INSPECTION_MODULE_ERR,
            err
        };
    },
    addInspectionModuleSuccess: _ => {
        return {
            type: actions.ADD_INSPECTION_MODULE_SUCCESS
        };
    },
    getInspectionModuleByIdSuccess: data => {
        return {
            type: actions.GET_INSPECTION_MODULE_BY_ID_SUCCESS,
            data
        };
    },
    deleteInspectionModuleSuccess: _ => {
        return {
            type: actions.DELETE_INSPECTION_MODULE_SUCCESS
        };
    },
};

export default actions;
