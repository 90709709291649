const actions = {
  CATEGORIES_BEGIN: 'CATEGORIES_BEGIN',
  GET_PARENT_CATEGORIES_SUCCESS: 'GET_PARENT_CATEGORIES_SUCCESS',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_SUB_CATEGORIES_SUCCESS: 'GET_SUB_CATEGORIES_SUCCESS',
  ADD_CATEGORIES_SUCCESS: 'ADD_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  EDIT_CATEGORIES_SUCCESS: 'EDIT_CATEGORIES_SUCCESS',
  DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
  CATEGORIES_ERR: 'CATEGORIES_ERR',
  GET_CATEGORY_SUBCATEGORY_SUCCESS: 'GET_CATEGORY_SUBCATEGORY_SUCCESS',

  categoriesBegin: () => {
    return {
      type: actions.CATEGORIES_BEGIN
    };
  },
  getParentCategoriesSuccess: data => {
    return {
      type: actions.GET_PARENT_CATEGORIES_SUCCESS,
      data
    };
  },
  getCategoriesSuccess: data => {
    return {
      type: actions.GET_CATEGORIES_SUCCESS,
      data
    };
  },
  getSubCategoriesSuccess: data => {
    return {
      type: actions.GET_SUB_CATEGORIES_SUCCESS,
      data
    };
  },
  AddCategoriesSuccess: data => {
    return {
      type: actions.ADD_CATEGORIES_SUCCESS,
      data
    };
  },
  updateCategorySuccess:data=>{
    return {
      type: actions.UPDATE_CATEGORIES_SUCCESS,
      data
    };
  },
  categoriesErr: err => {
    return {
      type: actions.CATEGORIES_ERR,
      err
    };
  },
  getCategoriesWithSubcategoriesSuccess: data => {
    return {
      type: actions.GET_CATEGORY_SUBCATEGORY_SUCCESS,
      data
    };
  },
};

export default actions;
