const actions = {
  GET_BULKUPLOADFILES: 'GET_BULKUPLOADFILES',
  GET_BULKUPLOADED_LISTING_BEGIN: "GET_BULKUPLOADED_LISTING_BEGIN",
  GET_BULKUPLOADED_LISTING: 'GET_BULKUPLOADED_LISTING',

  GET_LISTINGS_BEGIN: 'GET_LISTINGS_BEGIN',
  GET_LISTINGS_COUNT_BEGIN: 'GET_LISTINGS_COUNT_BEGIN',
  GET_LISTINGS_SUCCESS: 'GET_LISTINGS_SUCCESS',
  GET_LISTINGS_COUNT_SUCCESS: 'GET_LISTINGS_COUNT_SUCCESS',

  GET_LISTINGS_PAGINATION_SUCCESS: 'GET_LISTINGS_PAGINATION_SUCCESS',
  EMPTY_LISTINGS_PAGINATION_SUCCESS: 'EMPTY_LISTINGS_PAGINATION_SUCCESS',
  GET_LISTINGS_ERR: 'GET_LISTINGS_ERR',

  GET_LISTING_BEGIN: 'GET_LISTING_BEGIN',
  GET_LISTING_SUCCESS: 'GET_LISTING_SUCCESS',
  GET_LISTING_ERR: 'GET_LISTING_ERR',

  SORTING_LISTING_BEGIN: 'SORTING_LISTING_BEGIN',
  SORTING_LISTING_SUCCESS: 'SORTING_LISTING_SUCCESS',
  SORTING_LISTING_ERR: 'SORTING_LISTING_ERR',

  FILTER_LISTING_BEGIN: 'FILTER_LISTING_BEGIN',
  FILTER_LISTING_SUCCESS: 'FILTER_LISTING_SUCCESS',
  FILTER_LISTING_ERR: 'FILTER_LISTING_ERR',

  GET_ALL_BRANDS_BEGIN: 'GET_ALL_BRANDS_BEGIN',
  GET_ALL_BRANDS_SUCCESS: 'GET_ALL_BRANDS_SUCCESS',
  GET_ALL_BRANDS_ERR: 'GET_ALL_BRANDS_ERR',

  SINGLE_PRODUCT_BEGIN: 'SINGLE_PRODUCT_BEGIN',
  SINGLE_PRODUCT_SUCCESS: 'SINGLE_PRODUCT_SUCCESS',
  SINGLE_PRODUCT_ERR: 'SINGLE_PRODUCT_ERR',

  GET_ALL_CATEGORIES_BEGIN: 'GET_ALL_CATEGORIES_BEGIN',
  GET_ALL_CATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_SUCCESS',
  GET_ALL_CATEGORIES_ERR: 'GET_ALL_CATEGORIES_ERR',

  GET_SUB_CATEGORIES_BEGIN: 'GET_SUB_CATEGORIES_BEGIN',
  GET_SUB_CATEGORIES_SUCCESS: 'GET_SUB_CATEGORIES_SUCCESS',
  GET_SUB_CATEGORIES_ERR: 'GET_SUB_CATEGORIES_ERR',

  GET_ALL_CAR_MAKES_BEGIN: 'GET_ALL_CAR_MAKES_BEGIN',
  GET_ALL_CAR_MAKES_SUCCESS: 'GET_ALL_CAR_MAKES_SUCCESS',
  GET_ALL_CAR_MAKES_ERR: 'GET_ALL_CAR_MAKES_ERR',

  GET_CAR_MODELS_BEGIN: 'GET_CAR_MODELS_BEGIN',
  GET_CAR_MODELS_SUCCESS: 'GET_CAR_MODELS_SUCCESS',
  GET_CAR_MODELS_ERR: 'GET_CAR_MODELS_ERR',

  CREATE_LISTING_BEGIN: 'CREATE_LISTING_BEGIN',
  CREATE_LISTING_SUCCESS: 'CREATE_LISTING_SUCCESS',
  CREATE_LISTING_ERR: 'CREATE_LISTING_ERR',

  UPDATE_LISTING_BEGIN: 'UPDATE_LISTING_BEGIN',
  UPDATE_LISTING_SUCCESS: 'UPDATE_LISTING_SUCCESS',
  UPDATE_LISTING_ERR: 'UPDATE_LISTING_ERR',

  RESET_LISTING_FLAGS: 'RESET_LISTING_FLAGS',
  GET_MY_LISTINGS_SUCCESS: 'GET_MY_LISTINGS_SUCCESS',
  GET_SINGLE_LISTINGS_SUCCESS: 'GET_SINGLE_LISTINGS_SUCCESS',
  GET_REVIEW_SUCCESS: 'GET_REVIEW_SUCCESS',

  getListingsBegin: () => {
    return {
      type: actions.GET_LISTINGS_BEGIN
    };
  },

  getListingsSuccess: data => {
    return {
      type: actions.GET_LISTINGS_SUCCESS,
      data
    };
  },

  getReviewSuccess: data => {
    return {
      type: actions.GET_REVIEW_SUCCESS,
      data
    };
  },

  getSingleListingsSuccess: data => {
    return {
      type: actions.GET_SINGLE_LISTINGS_SUCCESS,
      data
    };
  },

  getListingsPaginationSuccess: (value, page) => {
    return {
      type: actions.GET_LISTINGS_PAGINATION_SUCCESS,
      data: {
        page: page,
        value: value
      }
    };
  },
  emptyListingsPaginationSuccess: () => {
    return {
      type: actions.EMPTY_LISTINGS_PAGINATION_SUCCESS
    };
  },
  getMyListingsSuccess: data => {
    return {
      type: actions.GET_MY_LISTINGS_SUCCESS,
      data
    };
  },
  getListingsErr: err => {
    return {
      type: actions.GET_LISTINGS_ERR,
      err
    };
  },

  getListingBegin: () => {
    return {
      type: actions.GET_LISTING_BEGIN
    };
  },
  getListingSuccess: data => {
    return {
      type: actions.GET_LISTING_SUCCESS,
      data
    };
  },
  getListingErr: err => {
    return {
      type: actions.GET_LISTING_ERR,
      err
    };
  },

  sortingListingBegin: () => {
    return {
      type: actions.SORTING_LISTING_BEGIN
    };
  },
  sortingListingSuccess: data => {
    return {
      type: actions.SORTING_LISTING_SUCCESS,
      data
    };
  },
  sortingListingErr: err => {
    return {
      type: actions.SORTING_LISTING_ERR,
      err
    };
  },

  filterListingBegin: () => {
    return {
      type: actions.FILTER_LISTING_BEGIN
    };
  },
  filterListingSuccess: data => {
    return {
      type: actions.FILTER_LISTING_SUCCESS,
      data
    };
  },
  filterListingErr: err => {
    return {
      type: actions.FILTER_LISTING_ERR,
      err
    };
  },

  getAllBrandsBegin: () => {
    return {
      type: actions.GET_ALL_BRANDS_BEGIN
    };
  },
  getAllBrandsSuccess: data => {
    return {
      type: actions.GET_ALL_BRANDS_SUCCESS,
      data
    };
  },
  getAllBrandsErr: err => {
    return {
      type: actions.GET_ALL_BRANDS_ERR,
      err
    };
  },

  singleProductBegin: () => {
    return {
      type: actions.SINGLE_PRODUCT_BEGIN
    };
  },
  singleProductSuccess: data => {
    return {
      type: actions.SINGLE_PRODUCT_SUCCESS,
      data
    };
  },
  singleProductErr: err => {
    return {
      type: actions.SINGLE_PRODUCT_ERR,
      err
    };
  },

  getAllCategoriesBegin: () => {
    return {
      type: actions.GET_ALL_CATEGORIES_BEGIN
    };
  },
  getAllCategoriesSuccess: data => {
    return {
      type: actions.GET_ALL_CATEGORIES_SUCCESS,
      data
    };
  },
  getAllCategoriesErr: err => {
    return {
      type: actions.GET_ALL_CATEGORIES_ERR,
      err
    };
  },

  getSubCategoriesBegin: () => {
    return {
      type: actions.GET_SUB_CATEGORIES_BEGIN
    };
  },
  getSubCategoriesSuccess: data => {
    return {
      type: actions.GET_SUB_CATEGORIES_SUCCESS,
      data
    };
  },
  getSubCategoriesErr: err => {
    return {
      type: actions.GET_SUB_CATEGORIES_ERR,
      err
    };
  },

  getAllCarMakesBegin: () => {
    return {
      type: actions.GET_ALL_CAR_MAKES_BEGIN
    };
  },
  getAllCarMakesSuccess: data => {
    return {
      type: actions.GET_ALL_CAR_MAKES_SUCCESS,
      data
    };
  },
  getAllCarMakesErr: err => {
    return {
      type: actions.GET_ALL_CAR_MAKES_ERR,
      err
    };
  },

  getCarModelsBegin: () => {
    return {
      type: actions.GET_CAR_MODELS_BEGIN
    };
  },
  getCarModelsSuccess: data => {
    return {
      type: actions.GET_CAR_MODELS_SUCCESS,
      data
    };
  },
  getCarModelsErr: err => {
    return {
      type: actions.GET_CAR_MODELS_ERR,
      err
    };
  },

  createListingBegin: () => {
    return {
      type: actions.CREATE_LISTING_BEGIN
    };
  },
  updateListingBegin: () => {
    return {
      type: actions.UPDATE_LISTING_BEGIN
    };
  },
  createListingSuccess: data => {
    return {
      type: actions.CREATE_LISTING_SUCCESS,
      data
    };
  },
  updateListingSuccess: data => {
    return {
      type: actions.UPDATE_LISTING_SUCCESS,
      data
    };
  },
  createListingErr: err => {
    return {
      type: actions.CREATE_LISTING_ERR,
      err
    };
  },
  updateListingErr: err => {
    return {
      type: actions.UPDATE_LISTING_ERR,
      err
    };
  },
  resetListingFlags: () => {
    return {
      type: actions.RESET_LISTING_FLAGS
    };
  }
};

export default actions;
