import React, { useState, useEffect } from 'react';
import { Row, Col, Pagination, Spin, Button, message, Popconfirm, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ListingCard from './ListingCardList';
import Heading from '../../../components/heading/heading';
import { PaginationWrapper } from './Style';
import axios from 'axios';
import { getbulkListing } from '../../../redux/listing/actionCreator';

const List = ({ bulkListing, isLoader, fileId }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    listings: [],
    current: 1,
    pageSize: 10,
    minIndex: 0,
    maxIndex: 0
  });

  const [loading, setLoading] = useState(false);

  const [checkedList, setCheckedList] = useState([]);

  const { listings } = state;

  useEffect(() => {
    setState({
      ...state,
      listings: bulkListing,
      minIndex: (state.current - 1) * state.pageSize,
      maxIndex: state.current * state.pageSize
    });
  }, [bulkListing]);


  const onShowSizeChange = (current, pageSize) => {
    setState({
      ...state,
      current,
      pageSize,
      minIndex: (current - 1) * pageSize,
      maxIndex: current * pageSize
    });
  };

  const onHandleChange = (current, pageSize) => {
    setState({
      ...state,
      current,
      pageSize,
      minIndex: (current - 1) * pageSize,
      maxIndex: current * pageSize
    });
  };

  // const onCheckSelect = checkedValues => {
  //   setCheckedList(checkedValues);
  // };
  const handleApproveUpdate = async () => {
    setLoading(true);
    const resp = await axios.post(process.env.REACT_APP_ASSETS_URL+'/api/products/approve-selected-bulk-list', checkedList);
    if (resp?.data?.data?.length > 0) {
      dispatch(getbulkListing(fileId));
      setCheckedList([]);
      message.success(resp?.data?.message);
    }
    setLoading(false);
  };

  const handleBulkDelete = async () => {
    setLoading(true);
    const resp = await axios.patch('/api/products/delete-bulk-listing', checkedList);
    if (resp?.data) {
      dispatch(getbulkListing(fileId));
      setCheckedList([]);
      message.success('DeletedSuccessfully');
    }
    setLoading(false);
  };


  return (
    <Row gutter={15}>
      <Col xs={24}>
        <Row gutter={15}>
          <Col xs={12} style={{ textAlign: 'start', paddingTop:'21px', paddingLeft:'27px' }}>
            {bulkListing.length ? <Checkbox
          onChange={e => {
            if(e.target.checked){
            let listings = bulkListing.map(b => b._id);
            setCheckedList(listings)

            }else{
              setCheckedList([])
            }
            
          }}
          checked={checkedList.length === bulkListing?.length ? true :false}
        >
              <span style={{fontSize:'16px',fontWeight:520}}>Select All</span>
        </Checkbox> : null}
          </Col>

          <Col xs={12} style={{ textAlign: 'end' }}>



        <Button
          loading={loading}          
          htmlType="submit"
          type="primary"
          size="large"
          style={{ margin: '10px 5px' }}
          disabled={checkedList && checkedList?.length > 0 ? false : true}
          onClick={handleApproveUpdate}
        >
              {checkedList.length === bulkListing?.length ? "Approve All" : "Approve Selected"}
        </Button>
        <Button
          loading={loading}
          htmlType="submit"
          danger
          type="primary"
          size="large"
          style={{ margin: '10px 5px' }}
          disabled={checkedList && checkedList?.length > 0 ? false : true}
          onClick={handleBulkDelete}
        >
              {checkedList.length === bulkListing?.length ? "Delete All" : "Delete Selected"}
          
        </Button>
          </Col>
        </Row>
      </Col>

      {isLoader ? (
        <div className="d-flex justify-content-center w-100">
          <Spin />
        </div>
      ) : listings.length ? (
        <>
          {listings.slice(state.minIndex, state.maxIndex).map(part => {
            return (
              <Col xs={24} key={part._id}>
                <ListingCard fileId={fileId} part={part} checkedList={checkedList} setCheckedList={setCheckedList} />
              </Col>
            );
          })}
        </>
      ) : (
        <Col xs={24}>
          <Heading as="h1">All Listing has been approved.</Heading>
        </Col>
      )}
      <Col xs={24} className="pb-30">
        <PaginationWrapper style={{ marginTop: 20 }}>
          {listings.length ? (
            <Pagination
              onChange={onHandleChange}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSize={state.pageSize}
              defaultCurrent={state.current}
              total={listings?.length || 0}
            />
          ) : null}
        </PaginationWrapper>
      </Col>
    </Row>
  );
};

export default List;
