import axios from 'axios';
import actions from './actions';
import { message } from 'antd';

const {
  getSellerListBegin,
  getSellerListSuccess,
  getSellerListErr,

  getSellerTableListBegin,
  getSellerTableListSuccess,
  getSellerTableCountSuccess,
  getSellerTableListErr,

  getOptListBegin,
  getOptListSuccess,
  getOptListErr,

  getBuyerListBegin,
  getBuyerListSuccess,
  getBuyerListErr,

  getBuyerTableListBegin,
  getBuyerTableListSuccess,
  getBuyerTableCountSuccess,
  getBuyerTableListErr,

  updateUserStatusBegin,
  updateUserStatusSuccess,
  updateUserStatusErr,

  getOneBuyerBegin,
  getOneBuyerSuccess,
  getOneBuyerErr,
  getSocialSuccess,
  resetFlags,
  getBulkBuyerListSuccess,
  getBulkBuyerListCountSuccess,
  getBulkBuyerListInnerSuccess,

  getBulkSellerListSuccess,
  getBulkSellerListCountSuccess,
  getBulkSellerListInnerSuccess,
} = actions;

const getSellerList = (user_id = '') => {
  return async dispatch => {
    try {
      dispatch(getSellerListBegin());
      const resp = await axios.get(`/api/users?all=true&role=SELLER`);
      dispatch(getSellerListSuccess(resp.data.users));
    } catch (err) {
      dispatch(getSellerListErr(err));
    }
  };
};

//get seller list table for admin portal
const getSellerTableList = (search = "", status = "", sellerType = "", emirate = "", operatingCategories = "", operatingPartTypes = "", searchBusinessModel = null, searchDeliveryTime, limit = 10, page = 1, searchEnableDisableListing) => {
  const encodedSellerType = sellerType ? encodeURIComponent(sellerType) : ""
  return async dispatch => {
    try {
      dispatch(getSellerTableListBegin());
      let resp;
      resp = await axios.get(`/api/users?role=SELLER&search=${search}&status=${status}&seller_type=${encodedSellerType}&emirate=${emirate}&operating_categories=${operatingCategories}&operating_part_types=${operatingPartTypes}&business_model=${searchBusinessModel?.value ?? ""}&delivery_time=${searchDeliveryTime?.value ?? ""}&limit=${limit}&page=${page}&enableDisableListing=${searchEnableDisableListing?.value ?? ""}`);
      dispatch(getSellerTableListSuccess(resp.data?.users));
      dispatch(getSellerTableCountSuccess(resp.data?.total));
    } catch (err) {
      dispatch(getSellerTableListErr(err));
    }
  };
};

const getOptList = (user_id = '') => {
  return async dispatch => {
    try {
      dispatch(getOptListBegin());
      const resp = await axios.get(`/api/opt/all`);
      dispatch(getOptListSuccess(resp.data.opts));
    } catch (err) {
      dispatch(getOptListErr(err));
    }
  };
};

const getBuyerList = (role, user_id = '') => {
  return async dispatch => {
    try {
      dispatch(getBuyerListBegin());
      let resp;

      if (user_id) {
        resp = await axios.get(`/api/users/?role=BUYER&role2=${role}&user_id=${user_id}&all=true`);
      } else {
        resp = await axios.get(`/api/users?role=BUYER&status=Approved&all=true`);
      }

      dispatch(getBuyerListSuccess(resp.data.users));
    } catch (err) {
      dispatch(getBuyerListErr(err));
    }
  };
};

//get buyer list table for admin portal
const getBuyerTableList = (KYCStatus = '', search = "", status = "", garageType = "", emirate = "", limit = 10, page = 1, isHidden) => {
  const encodedGarageType = garageType !== "" ? encodeURIComponent(garageType) : "";
  const encodedSearch = search !== "" ? encodeURIComponent(search) : "";
  return async dispatch => {
    try {
      dispatch(getBuyerTableListBegin());
      let resp;
      let URL = `/api/users?role=BUYER&search=${encodedSearch}&KYCStatus=${KYCStatus}&status=${status}&garage_type=${encodedGarageType}&emirate=${emirate}&limit=${limit}&page=${page}`;
      if (isHidden) URL += `&isHidden=${isHidden}`;
      resp = await axios.get(URL);
      dispatch(getBuyerTableListSuccess(resp.data?.users));
      dispatch(getBuyerTableCountSuccess(resp.data?.total));
    } catch (err) {
      dispatch(getBuyerTableListErr(err));
    }
  };
};

const getBulkBuyerList = (sales_id = '', search = '', page = 1, limit = 10) => {
  return async dispatch => {
    try {
      dispatch(getBuyerListBegin());
      const resp = await axios.get(`/api/users/buyer-list-bulk?user_id=${sales_id}&search=${search}&page=${page}&limit=${limit}`);
      dispatch(getBulkBuyerListSuccess(resp.data?.buyerbulkList));
      dispatch(getBulkBuyerListCountSuccess(resp.data?.total));
    } catch (err) {
      console.log('errr', err);
      dispatch(getBuyerListErr(err));
    }
  };
};

const getBulkBuyerListInner = (sales_id = '', search = '', page = 1, limit = 10) => {
  return async dispatch => {
    try {
      dispatch(getBuyerListBegin());

      const resp = await axios.get(`/api/users/buyer-list-bulk-inner?user_id=${sales_id}&search=${search}&page=${page}&limit=${limit}`);
      dispatch(getBulkBuyerListInnerSuccess(resp.data));
    } catch (err) {
      console.log('errr', err);
      dispatch(getBuyerListErr(err));
    }
  };
};

const getBulkSellerList = (search = "", page = 1, limit = 10) => {
  return async dispatch => {
    try {
      dispatch(getBuyerListBegin());
      const resp = await axios.get(`/api/users/seller-list-bulk?search=${search}&page=${page}&limit=${limit}`);
      dispatch(getBulkSellerListSuccess(resp.data?.sellerbulkList));
      dispatch(getBulkSellerListCountSuccess(resp.data?.total));
    } catch (err) {
      console.log('errr', err);
      dispatch(getBuyerListErr(err));
    }
  };
};
const getBulkSellerListInner = (user_id, search, page, limit) => {
  return async dispatch => {
    try {
      dispatch(getBuyerListBegin());

      const resp = await axios.get(`/api/users/seller-list-bulk-inner?user_id=${user_id}&search=${search}&page=${page}&limit=${limit}`);
      dispatch(getBulkSellerListInnerSuccess(resp.data));
    } catch (err) {
      console.log('errr', err);
      dispatch(getBuyerListErr(err));
    }
  };
};

const addBulkAssigner = data => {
  return async dispatch => {
    try {
      await axios.patch(`/api/products/add-assigner-bulk-buyer`, data);
      message.success('You have assigned to the sales scout successfully!');
    } catch (err) {
      dispatch(updateUserStatusErr(err));
    }
  };
};

const deleteBulkList = () => {
  return async dispatch => {
    try {
      dispatch(getBuyerListBegin());
      dispatch(getBulkBuyerListInnerSuccess(resp.data));
    } catch (err) {
      console.log('errr', err);
      dispatch(getBuyerListErr(err));
    }
  };
};

const getOneBuyer = id => async dispatch => {
  try {
    dispatch(getOneBuyerBegin());
    const resp = await axios.get(`/api/users/${id}`);
    dispatch(getOneBuyerSuccess(resp.data.user));
  } catch (err) {
    dispatch(getOneBuyerErr(err));
  }
};
const getOneSeller = id => async dispatch => {
  try {
    dispatch(getOneBuyerBegin());
    const resp = await axios.get(`/api/users/${id}`);
    dispatch(getOneBuyerSuccess(resp.data.user));
    dispatch(getOneLinks(resp.data?.user?.slug));
  } catch (err) {
    dispatch(getOneBuyerErr(err));
  }
};

const getOneLinks = slug => async dispatch => {
  try {
    dispatch(getOneBuyerBegin());
    const resp = await axios.get(`/api/users/${slug}`);
    dispatch(getSocialSuccess(resp.data?.user?.details?.social_links));
  } catch (err) {
    dispatch(getOneBuyerErr(err));
  }
};

const updateUser = payload => {
  return async dispatch => {
    try {
      dispatch(updateUserStatusBegin());
      const { _id, ...data } = payload;
      const formData = {
        ...data,
        id: _id
      }
      const resp = await axios.put(`/api/users/${_id}`, { status: formData.status, remarks: formData.remark });
      message.success('Status has been changed successfully!');
      dispatch(updateUserStatusSuccess(resp));
    } catch (err) {
      dispatch(updateUserStatusErr(err));
    }
  };
};

// const updateUser = payload => {
//   return async dispatch => {
//     try {
//       console.log(_id)
//       dispatch(updateUserStatusBegin());
//       const resp = await axios.put(`/api/users/${_id}`, { status: data.status });
//       message.success('Status has been changed successfully!');
//       dispatch(updateUserStatusSuccess(resp));
//     } catch (err) {
//       dispatch(updateUserStatusErr(err));
//     }
//   };
// };

const updateUserHiddenStatus = payload => {
  return async dispatch => {
    try {
      const { buyerId, isHidden, remarks } = payload;
      const resp = await axios.put(`/api/users/${buyerId}`, { isHidden, hiddenRemark: remarks });
      message.success('Status has been changed successfully!');
      dispatch(updateUserStatusSuccess(resp));
    } catch (err) {
      console.log('errr', err);
      dispatch(updateUserStatusErr(err));
    }
  };
};

const addAssigner = data => {
  return async dispatch => {
    try {
      await axios.patch(`/api/users/add-assigner`, data);
    } catch (err) {
      dispatch(updateUserStatusErr(err));
    }
  };
};

const deleteUser = user_id => {
  return async (dispatch, getState) => {
    let user = getState().auth.login;
    if (typeof user === 'string') {
      user = JSON.parse(user);
    }
    try {
      let resp = await axios.delete(`/api/users/${user_id}`, { data: { deletedBy: user._id } });
      if (resp?.data?.message) {
        message.error(resp?.data?.message ?? 'Seller Cannot be deleted')
      } else {
        message.success("User Deleted Successfully")
      }
      return true;
    } catch (error) {
      return false;
    }
  };
};

const resetUserFlags = () => {
  return async dispatch => {
    dispatch(resetFlags());
  };
};

const getUsersOrders = () => {
  return async dispatch => {
    try {
    } catch (err) {
      console.log('errr', err);
      // dispatch(getBuyerListErr(err));
    }
  };
};

const enableDisableListingDeletion = (data) => {
  return async dispatch => {
    const { setIsAssignIsLoading } = data;
    try {
      setIsAssignIsLoading(true);
      const res = await axios.patch(`/api/users/enable-disable-listing-deletion`, data);
      if (res.status === 200) {
        setIsAssignIsLoading(false);
      }
      message.success(`You have successfully ${data?.enableListingDeletion ? "enabled" : "disabled"} listing deletion!`);
    } catch (err) {
      setIsAssignIsLoading(false);
      message.error(err?.data?.message ?? `Seller Cannot be ${data?.enableListingDeletion ? "enabled" : "disabled"}`)
    }
  };
};

export {
  getSellerList,
  getSellerTableList,
  getBuyerList,
  getBuyerTableList,
  updateUser,
  resetUserFlags,
  deleteUser,
  getOneBuyer,
  getOneSeller,
  getOneLinks,
  addAssigner,
  getBulkBuyerList,
  getBulkBuyerListInner,
  deleteBulkList,
  addBulkAssigner,
  getUsersOrders,
  getBulkSellerList,
  getBulkSellerListInner,
  getOptList,
  updateUserHiddenStatus,
  enableDisableListingDeletion
};
