const actions = {
  GET_ORDERS_LIST_BEGIN: 'GET_ORDERS_LIST_BEGIN',
  GET_ORDERS_LIST_COUNT_BEGIN: 'GET_ORDERS_LIST_COUNT_BEGIN',
  GET_ORDERS_LIST_SUCCESS: 'GET_ORDERS_LIST_SUCCESS',
  GET_ORDERS_COUNT_SUCCESS: 'GET_ORDERS_COUNT_SUCCESS',
  GET_ORDERS_PAGINATION_SUCCESS: 'GET_ORDERS_PAGINATION_SUCCESS',
  EMPTY_ORDERS_PAGINATION_SUCCESS: 'EMPTY_ORDERS_PAGINATION_SUCCESS',
  GET_ORDERS_LIST_ERR: 'GET_ORDERS_LIST_ERR',
  GET_ORDER_LOG_BEGIN: 'GET_ORDER_LOG_BEGIN',
  GET_ORDER_LOG_SUCCESS: 'GET_ORDER_LOG_SUCCESS',
  GET_ORDER_LOG_ERR: 'GET_ORDER_LOG_ERR',
  GET_ORDER_BEGIN: 'GET_ORDER_BEGIN',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_COUNT_SUCCESS: 'GET_ORDER_COUNT_SUCCESS',
  GET_ORDER_ERR: 'GET_ORDER_ERR',
  RESET_FLAGS: 'RESET_FLAGS',
  INITIALIZE_BEGIN: 'INITIALIZE_BEGIN',
  INITIALIZE_SUCCESS: 'INITIALIZE_SUCCESS',
  INITIALIZE_ERROR: 'INITIALIZE_ERROR',
  ADD_ITEM: 'ADD_ITEM',
  GET_MY_CART_LISTINGS: 'GET_MY_CART_LISTINGS',
  GET_TOTAL_PRICE: 'GET_TOTAL_PRICE',

  GET_USER_ADDRESS_BEGIN: 'GET_USER_ADDRESS_BEGIN',
  GET_USER_ADDRESS: 'GET_USER_ADDRESS',
  GET_USER_ADDRESS_ERROR: 'GET_USER_ADDRESS_ERROR',

  GET_DELIVERY_OPTION: 'GET_DELIVERY_OPTION',
  UPDATE_ITEM: 'UPDATE_ITEM',
  GET_COLLECTION_AMOUNT: 'GET_COLLECTION_AMOUNT',
  COLLECTION_AMOUNT_ERR: 'COLLECTION_AMOUNT_ERR',

  SET_UPSERT_BUYER_CART_BEGIN: 'SET_UPSERT_BUYER_CART_BEGIN',
  SET_UPSERT_BUYER_CART_SUCCESS: 'SET_UPSERT_BUYER_CART_SUCCESS',
  SET_UPSERT_BUYER_CART_ERROR: 'SET_UPSERT_BUYER_CART_ERROR',

  SET_SELECTED_ADDRESS: 'SET_SELECTED_ADDRESS',
  SET_SELECTED_BUYER: 'SET_SELECTED_BUYER',
  
  GET_ORDERS_BY_ORDER_ID_BEGIN: 'GET_ORDERS_BY_ORDER_ID_BEGIN',
  GET_ORDERS_BY_ORDER_ID_SUCCESS: 'GET_ORDERS_BY_ORDER_ID_SUCCESS',
  GET_ORDERS_BY_ORDER_ID_ERROR: 'GET_ORDERS_BY_ORDER_ID_ERROR',
  
  GET_CART_ITEM_BEGIN: 'GET_CART_ITEM_BEGIN',
  GET_CART_ITEM_SUCCESS: 'GET_CART_ITEM_SUCCESS',
  GET_CART_ITEM_ERROR: 'GET_CART_ITEM_ERROR',

  SET_PAYMENT_METHOD : 'SET_PAYMENT_METHOD',
  
  SET_WALLET_AMOUNT: 'SET_WALLET_AMOUNT',
  SET_WALLET_ERROR: 'SET_WALLET_ERROR',
  
  SET_ELIGIBLE_COUPON_SUCCESS: 'SET_ELIGIBLE_COUPON_SUCCESS',
  APPLY_COUPON_BEGIN: 'APPLY_COUPON_BEGIN',
  APPLY_COUPON_SUCCESS: 'APPLY_COUPON_SUCCESS',
  APPLY_COUPON_ERROR: 'APPLY_COUPON_ERROR',

  SET_ORDER_SUMMARY: 'SET_ORDER_SUMMARY',
  RESET_ORDER_STATE: 'RESET_ORDER_STATE',
  SET_ORDER_STATE: 'SET_ORDER_STATE',
  RESET_CREATE_ORDER: 'RESET_CREATE_ORDER',

  ADD_TO_CART_BEGIN: 'ADD_TO_CART_BEGIN',
  ADD_TO_CART_ERROR: 'ADD_TO_CART_ERROR',

  setSelectedBuyer: data => {
    return {
      type: actions.SET_SELECTED_BUYER,
      data
    };
  },
  setSelectedAddress: data => {
    return {
      type: actions.SET_SELECTED_ADDRESS,
      data
    };
  },

  resetCreateOrder: _ => {
    return {
      type: actions.RESET_CREATE_ORDER
    }
  },

  getCollectedAmount: data => {
    return {
      type: actions.GET_COLLECTION_AMOUNT,
      data
    };
  },
  collectedAmountErr: () => {
    return {
      type: actions.COLLECTION_AMOUNT_ERR
    };
  },

  getOrderLogBegin: () => {
    return {
      type: actions.GET_ORDER_LOG_BEGIN
    };
  },

  addItemInCart: data => {
    return {
      type: actions.ADD_ITEM,
      data
    };
  },
  updateIteminCart: data => {
    return {
      type: actions.UPDATE_ITEM,
      data
    };
  },
  getdeliveryOption: data => {
    return {
      type: actions.GET_DELIVERY_OPTION,
      data
    };
  },

  getTotalPrice: data => {
    return {
      type: actions.GET_TOTAL_PRICE,
      data
    };
  },

  getMycartListing: data => {
    return {
      type: actions.GET_MY_CART_LISTINGS,
      data
    };
  },
  getAddressBegin: _ => {
    return {
      type: actions.GET_USER_ADDRESS_BEGIN
    };
  },
  getuserAddress: data => {
    return {
      type: actions.GET_USER_ADDRESS,
      data
    };
  },
  getAddressError: _ => {
    return {
      type: actions.GET_USER_ADDRESS_ERROR
    };
  },

  InitializeBegin: _ => {
    return {
      type: actions.INITIALIZE_BEGIN
    };
  },

  InitializeSuccess: data => {
    return {
      type: actions.INITIALIZE_SUCCESS,
      data
    };
  },

  InitializeError: _ => {
    return {
      type: actions.INITIALIZE_ERROR
    };
  },

  getOrderLogSuccess: data => {
    return {
      type: actions.GET_ORDER_LOG_SUCCESS,
      data
    };
  },

  getOrderLogErr: err => {
    return {
      type: actions.GET_ORDER_LOG_ERR,
      err
    };
  },

  getOrdersListBegin: () => {
    return {
      type: actions.GET_ORDERS_LIST_BEGIN
    };
  },
  getOrdersListCountBegin: () => {
    return {
      type: actions.GET_ORDERS_LIST_COUNT_BEGIN
    };
  },

  getOrdersListSuccess: data => {
    return {
      type: actions.GET_ORDERS_LIST_SUCCESS,
      data
    };
  },
  getOrdersCountSuccess: data => {
    return {
      type: actions.GET_ORDERS_COUNT_SUCCESS,
      data
    };
  },

  getOrdersPaginationSuccess: (value, page) => {
    return {
      type: actions.GET_ORDERS_PAGINATION_SUCCESS,
      data: {
        page: page,
        value: value
      }
    };
  },
  emptyOrdersPaginationSuccess: () => {
    return {
      type: actions.EMPTY_ORDERS_PAGINATION_SUCCESS
    };
  },

  getOrdersListErr: err => {
    return {
      type: actions.GET_ORDERS_LIST_ERR,
      err
    };
  },
  getOrderBegin: () => {
    return {
      type: actions.GET_ORDER_BEGIN
    };
  },

  getOrderSuccess: data => {
    return {
      type: actions.GET_ORDER_SUCCESS,
      data
    };
  },

  getOrderCountSuccess: data => {
    return {
      type: actions.GET_ORDER_COUNT_SUCCESS,
      data
    };
  },

  getOrderErr: err => {
    return {
      type: actions.GET_ORDER_ERR,
      err
    };
  },
  resetFlags: () => {
    return {
      type: actions.RESET_FLAGS
    };
  },
  getOrdersListFromOrderIdBegin: () => {
    return {
      type: actions.GET_ORDERS_BY_ORDER_ID_BEGIN
    };
  },
  getOrdersListFromOrderIdSucess: (data) => {
    return {
      type: actions.GET_ORDERS_BY_ORDER_ID_SUCCESS,
      data
    };
  },
  getOrdersListFromOrderIdError: err => {
    return {
      type: actions.GET_ORDERS_BY_ORDER_ID_ERROR,
      err
    };
  },
  setPaymentMethod: data => {
    return {
      type: actions.SET_PAYMENT_METHOD,
      data
    }
  },
  setWalletAmount: data => {
    return {
      type: actions.SET_WALLET_AMOUNT,
      data
    }
  },
  setWalletError: error => {
    return {
      type: actions.SET_WALLET_ERROR,
      err: error
    }
  },
  applyCouponBegin: _ => {
    return {
      type: actions.APPLY_COUPON_BEGIN
    }
  },
  applyCouponSuccess: data => {
    return {
      type: actions.APPLY_COUPON_SUCCESS,
      data
    }
  },
  applyCouponError: _ => {
    return {
      type: actions.APPLY_COUPON_ERROR
    }
  },
  setEligibleCouponSuccessOrError: _ => {
    return {
      type: actions.SET_ELIGIBLE_COUPON_SUCCESS
    }
  },
  setOrderSummary: data => {
    return {
      type: actions.SET_ORDER_SUMMARY,
      data
    }
  },
  resetOrderState: data => {
    return {
      type: actions.RESET_ORDER_STATE,
      data
    }
  },
  setOrderState: data => {
    return {
      type: actions.SET_ORDER_STATE,
      data
    }
  },
  upsertBuyerCartBegin: _ => {
    return {
      type: actions.SET_UPSERT_BUYER_CART_BEGIN
    }
  },
  upsertBuyerCartSuccess: data => {
    return {
      type: actions.SET_UPSERT_BUYER_CART_SUCCESS,
      data
    }
  },
  upsertBuyerCartError: _ => {
    return {
      type: actions.SET_UPSERT_BUYER_CART_ERROR,
    }
  },
  addToCartBegin: _ => {
    return {
      type: actions.ADD_TO_CART_BEGIN,
    }
  },
  addToCartError: _ => {
    return {
      type: actions.ADD_TO_CART_ERROR,
    }
  }
};

export default actions;
