import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Input, Button } from 'antd';
import { TableWrapper } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Avatar, Image } from 'antd';
import { Rate } from 'antd';
import { Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { EditOutlined } from '@ant-design/icons';

const reviewTable = ({ reviewDetails, loader, saveChanges }) => {
  const [edit, setEdit] = useState(false);
  const [currentEditId, setcurrentEditId] = useState('');
  const [review, setReview] = useState('');
  const [data, setData] = useState({});

  const fetchData = () => {
    setData(reviewDetails);
  };

  React.useEffect(() => {
    fetchData();
  }, [review]);

  const handleEdit = (id, cmt) => {
    setcurrentEditId(id);
    setReview(cmt);
    setEdit(true);
  };

  const columns = [
    {
      title: 'USER',
      dataIndex: '',
      key: 'id',
      align: 'start',
      render: reviewDetails => (
        <div className="user-info">
          <figure>
            <Avatar src={reviewDetails?.user?.avatar} size={64} />
          </figure>
          <figcaption>
            <div className="user-name" style={{ textAlign: 'left' }}>
              <NavLink to={`/admin/users/view-seller/${reviewDetails?.user?._id}`} style={{ cursor: 'pointer' }}>
                {reviewDetails?.user?.email}
              </NavLink>
            </div>
          </figcaption>
        </div>
      )
    },
    {
      title: 'PRODUCT',
      dataIndex: '',
      align: 'start',
      key: 'part_name',
      render: reviewDetails => {
        let products = reviewDetails?.list?.partName;
        let seller = reviewDetails?.seller?.name;
        return (
          <>
            <NavLink
              to={`/admin/users/view-seller/${reviewDetails?.seller?._id}`}
              style={{ color: 'black', textDecoration: 'underline' }}
            >
              <>
                <p>
                  <strong>{products}</strong>
                </p>
                <span>{seller}</span>
              </>
            </NavLink>
          </>
        );
      }
    },
    {
      title: 'REVIEW',
      dataIndex: '',
      align: 'start',
      key: 'review',
      render: reviewDetails => (
        <>
          <Rate style={{ marginTop: 30 }} readOnly allowHalf defaultValue={reviewDetails?.star} />
          <div>
            {edit == true && currentEditId == reviewDetails?._id ? (
              <>
                <Input
                  type="text"
                  defaultValue={reviewDetails?.comment}
                  value={review}
                  onChange={e => setReview(e.target.value)}
                  style={{ height: 35, width: 200 }}
                />
                <p style={{ fontSize: 12, marginTop: 5 }}>
                  {moment(reviewDetails?.updatedAt)
                    .tz('Asia/Dubai')
                    .format('DD/MM/YYYY')}{' '}
                  &nbsp;
                  {moment(reviewDetails?.updatedAt)
                    .tz('Asia/Dubai')
                    .format('HH:mm')}
                </p>
              </>
            ) : (
              <>
                <p>{reviewDetails?.comment}</p>
                <p style={{ fontSize: 12, marginTop: 5 }}>
                  {moment(reviewDetails?.updatedAt)
                    .tz('Asia/Dubai')
                    .format('DD/MM/YYYY')}{' '}
                  &nbsp;
                  {moment(reviewDetails?.updatedAt)
                    .tz('Asia/Dubai')
                    .format('HH:mm')}
                </p>
              </>
            )}
          </div>
        </>
      )
    },
    {
      title: 'Edit Reviews',
      dataIndex: '',
      align: 'start',
      key: 'review',
      render: reviewDetails => (
        <>
          {edit == true && currentEditId == reviewDetails?._id ? (
            <div style={{ justifyContent: 'space-between', display: 'flex' }}>
              <Button
                type="primary"
                className="mr-3"
                onClick={() => {
                  saveChanges(reviewDetails?._id, review), setEdit(false);
                }}
              >
                Save Changes
              </Button>
              <Button
                className="btn btn-primary ml-1"
                onClick={() => {
                  setEdit(false);
                }}
              >
                Cancle
              </Button>
            </div>
          ) : (
            <div>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(reviewDetails?._id, reviewDetails?.comment)}>
                <EditOutlined style={{ fontSize: 22 }} />
              </div>
            </div>
          )}
        </>
      )
    }
  ];
  return (
    <TableWrapper>
      <Table
        columns={columns}
        loading={loader}
        dataSource={reviewDetails}
        pagination={{
          defaultPageSize: 50,
          total: reviewDetails.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </TableWrapper>
  );
};

export default reviewTable;
