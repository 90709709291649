import actions from './action';
import axios from 'axios';
import { message } from 'antd';

const {
  categoriesBegin,
  getParentCategoriesSuccess,
  getCategoriesSuccess,
  getSubCategoriesSuccess,
  categoriesErr,
  getCategoriesWithSubcategoriesSuccess
} = actions;

export const getParentCategories = () => {
  return async dispatch => {
    try {
      dispatch(categoriesBegin());
      const resp = await axios.get('/api/categories/all-parent-categories');
      dispatch(getParentCategoriesSuccess(resp?.data));
    } catch (err) {
      dispatch(categoriesErr(err));
    }
  };
};

export const getCategories = () => {
  return async dispatch => {
    try {
      dispatch(categoriesBegin());
      const resp = await axios.get('/api/categories/all');
      dispatch(getCategoriesSuccess(resp?.data));
    } catch (err) {
      dispatch(categoriesErr(err));
    }
  };
};

export const getSubCategories = id => {
  return async dispatch => {
    try {
      dispatch(categoriesBegin());
      const resp = await axios.get(`/api/subcategories/${id}`);
      dispatch(getSubCategoriesSuccess(resp?.data));
    } catch (err) {
      dispatch(categoriesErr(err));
    }
  };
};

export const addCategory = (data, closeModal, closeLoader, resetState) => {
  return async dispatch => {
    dispatch(categoriesBegin());
    await axios
      .post(`/api/categories`, data)
      .then(res => {
        closeLoader();
        closeModal();
        message.success('Successfully Added new Category!');
        resetState();
        dispatch(getParentCategories());
        dispatch(getCategories());
      })
      .catch(err => {
        closeLoader();
        dispatch(categoriesErr(err));
        message.error(err?.response?.data?.message ||'Failed to add new Category!');
      });
  };
};
export const addSubCategory = (data, closeModal, closeLoader, resetFields) => {
  return async dispatch => {
    dispatch(categoriesBegin());
    await axios
      .post(`/api/subcategories`, data)
      .then(res => {
        closeLoader();
        resetFields();
        closeModal();
        message.success('Successfully Added new SubCategories!');
        dispatch(getParentCategories());
        dispatch(getCategories());
      })
      .catch(err => {
        closeLoader();
        dispatch(categoriesErr(err));
        message.error(err?.response?.data?.message || 'Failed to update Category!');
      });
  };
};

export const updateCategory = (data, cat_id, closeModal, closeLoader) => {
  return async dispatch => {
    dispatch(categoriesBegin());
    await axios
      .patch(`/api/categories/update-category?cat_id=${cat_id}`, data)
      .then(res => {
        closeLoader();
        closeModal();
        message.success('Successfully Updated Category!');
        dispatch(getParentCategories());
        dispatch(getCategories());
      })
      .catch(err => {
        closeLoader();
        dispatch(categoriesErr(err));
        message.error(err?.response?.data?.message ||'Failed to update Category!');
      });
  };
};

export const updateSubCategory = (data, cat_id, closeModal, closeLoader, paramsId) => {
  return async dispatch => {
      dispatch(categoriesBegin());
      await axios
        .patch(`/api/subcategories/update-subcategory?cat_id=${cat_id}`, data)
        .then(res => {
          closeLoader();
          closeModal();
          message.success('Successfully Updated Category!');
          dispatch(getSubCategories(paramsId));
        })
        .catch(err => {
          closeLoader();
          dispatch(categoriesErr(err));
          message.error('Failed to update Category!');
        });
  };
};

export const getCategoriesWithSubcategories = _ => {
  return async dispatch => {
    dispatch(categoriesBegin());
    axios
      .get(`/api/categories/category-subcategory`)
      .then(res => {
        const allCategories = res?.data;

        dispatch(getCategoriesWithSubcategoriesSuccess(allCategories))
      })
      .catch(err => {
        dispatch(categoriesErr(err))
      });
  };
};