import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Row, Col, Popconfirm, Select } from 'antd';
import UserCards from '../pages/overview/UserCards';
import UserBio from './overview/UserBio';
import OverviewCardSellerMonth from '../../components/OverviewCardSellerMonth';
import OverviewCardSellerWeek from '../../components/OverviewCardSellerWeek';

import LineChart from '../../components/LineChart';
import ProductTable from '../../components/ProductTable';
import { getOneSeller, addAssigner, getSellerList } from '../../redux/users/actionCreator';
import { getOrderSeller } from '../../redux/orders/actionCreator';
import { useParams } from 'react-router';
import SellerListTable from './overview/SellerTable';
import MySales from './overview/MySales';
import { GetMyListings } from '../../redux/listing/actionCreator';
import axios from 'axios'
import TotalRevenue from '../../routes/TotalRevenue/TotalRevenue';
import UserRevenue from '../../routes/UserRevenue/UserRevenueSeller';
import UserProfileTabs from './overview/UserProfileTabs';
import { getSalesScoutList } from '../../redux/admin_user/actionCreator';
const ViewSeller = () => {
  const { id } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isAssignToConfirmOpen, setIsAssignToConfirmOpen] = useState(false)
  const [assignTo, setAssignTo] = useState('')
  const seller = useSelector(state => state.users.buyer);
  const loading = useSelector(state => state.users.loading);
  const orderDetails = useSelector(state => state.order.order);
  const listingCount = useSelector(state => state.listings.listingCount);
  const links = useSelector(state => state.users.socialLinks);

  const [filteredList, setFilteredList] = React.useState([]);
  const [userRevenue, setUserRevenue] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);

  const dispatch = useDispatch();

  const { sellers, _isLoggedIn, sales } = useSelector(state => {
    return {
      sellers: state.users.sellers,
      sales: state.admin_user.sales,
      _isLoggedIn: state.auth.login ? state.auth.login : null
    };
  });
  const [socialLinks, setSocialLinks] = React.useState([])
  const salesOptions = useMemo(() => {
    return sales?.map(a => ({
      label: a.email,
      value: a._id
    }))
  }, [sales])

  useEffect(() => {
    if (typeof _isLoggedIn === 'string') {
      setIsLoggedIn(JSON.parse(_isLoggedIn));
    } else {
      setIsLoggedIn(_isLoggedIn);
    }
  }, [_isLoggedIn]);

  const onAssignToChange = _assignTo => {
    setIsAssignToConfirmOpen(true)
    setAssignTo(_assignTo)
  }

  const onAssignToConfirm = e => {
    dispatch(addAssigner({
      id,
      assignedTo: assignTo
    }))
    setIsAssignToConfirmOpen(false)
  }

  const onAssignToConfirmCancel = e => {
    setIsAssignToConfirmOpen(false)
    setAssignTo(seller?.assignedTo)
  }
  useEffect(() => {
    setAssignTo(seller?.assignedTo)
  }, [seller]);
  useEffect(() => {
    const { role } = isLoggedIn || {}
    role === 'admin' && dispatch(getSalesScoutList());
  }, [isLoggedIn])


  React.useEffect(() => {
    dispatch(getOneSeller(id));
    dispatch(getSellerList());

  }, [dispatch]);

  React.useEffect(() => {
    dispatch(GetMyListings(id))
    getRevenueAdmin()

  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getOrderSeller(id, page, perPage));
  }, [page, perPage])

  React.useEffect(() => {
    const findseller = sellers?.find(item => item._id === id)
    setFilteredList(findseller);
  }, [sellers]);


  const getRevenueAdmin = async () => {
    const result = await axios.get(`/api/order/get-revenue?seller_id=${id}`);
    setUserRevenue(result.data);
    if (result.data) {
      setLoader(false)
    }
  }

  return (
    <>
      <PageHeader ghost title={`Seller ID:${seller?.ecvId}`} buttons={[<div key="1" className="page-header-actions">{isLoggedIn?.role === 'admin' &&
        <Popconfirm
          title={"Are you sure to assign ?"}
          onConfirm={onAssignToConfirm}
          onCancel={onAssignToConfirmCancel}
          visible={isAssignToConfirmOpen}
          okText="Assign"
          cancelText="Cancel"
        >
          <Select
            value={assignTo}
            showSearch
            placeholder="Search to Select"
            size='small'
            style={{ width: 225, marginTop: '20px' }}
            onChange={onAssignToChange}
            options={salesOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
            }
          />
        </Popconfirm>}</div>]} />
      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <UserCards data={seller} orderDetails={orderDetails} listingCount={listingCount} userdata={filteredList} />

            <UserBio data={seller} links={links} />
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <Row style={{ justifyContent: 'space-between', display: 'flex' }}>
              <Col xxl={11} lg={11} md={11} xs={24}>
                <OverviewCardSellerWeek userRevenue={userRevenue} loader={loader} />
              </Col>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <OverviewCardSellerMonth userRevenue={userRevenue} loader={loader} />
              </Col>
            </Row>
            <UserRevenue role={seller?.role} id={id} loader={loader} />
          </Col>
        </Row>
        <Row>
          {/* <div><h2 style={{fontWeight:600}}>Seller Sales</h2></div> */}
          <Col xs={24}>
            <UserProfileTabs orderDetais={orderDetails} userData={seller} setPage={setPage} setPerPage={setPerPage} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ViewSeller;
