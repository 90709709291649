import { theme, darkTheme } from './theme/themeVariables';

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  frontEndBaseUrl: process.env.REACT_APP_URL,
  backendBaseURL: process.env.REACT_APP_BASE_URL

};

export default config;
