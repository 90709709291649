import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const NewsList = lazy(() => import('../../container/pages/NewsListDataTable'));
const EditNews = lazy(() => import('../../container/pages/wizards/overview/EditNewsForm'));
const AddNews = lazy(() => import('../../container/pages/wizards/overview/AddNews'));

const News = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={NewsList} />
      <Route exact path={`${path}/add`} component={AddNews} />
      <Route exact path={`${path}/edit-news/:id`} component={EditNews} />
    </Switch>
  );
};

export default News;
