import Styled from 'styled-components';

const UserTableStyleWrapper = Styled.nav`
  table{
    tbody{
      
      td{
        .user-info{
          .user-name{
            font-size: 14px;
            color: ${({ theme }) => theme['gray-color']};
          }
        }
        span.status-text{
          font-size: 12px;
          padding: 0 12.41px;
          line-height: 1.9;
          font-weight: 500;
          border-radius: 12px;
          text-transform: capitalize;
          display: inline-block !important;
          background: #ddd;
          &.active{
            background-color: ${({ theme }) => theme['success-color']}15;
            color: ${({ theme }) => theme['success-color']};
          }
          &.deactivate{
            background-color: ${({ theme }) => theme['warning-color']}15;
            color: ${({ theme }) => theme['warning-color']};
          }
          &.blocked{
            background-color: ${({ theme }) => theme['danger-color']}15;
            color: ${({ theme }) => theme['danger-color']};
          }
        }
      }
    }
  }
  .ant-table-pagination.ant-pagination{
    width: 100%;
    text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
    border-top: 1px solid ${({ theme }) => theme['border-color-light']};
    margin-top: 0 !important;
    padding-top: 30px;
    @media only screen and (max-width: 767px){
      text-align: center;
    }
  }
  .contact-table{
    table{
      tr{
        th{
          &:first-child{
            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 20px;
          }
          &:last-child{
            ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
          }
        }
      }
      .table-actions{
        button{
          width: auto;
          height: auto;
          padding: 0;
          background-color: transparent;
          &:hover{
            background-color: transparent;
          }
          &.ant-btn-primary{
            &:hover{
              color: #ADB4D2;
            }
          }
        }
      }
      tbody >tr.ant-table-row-selected >td{
        background-color: ${({ theme }) => theme['primary-color']}10;
      }
    }
  }
`;

const OrderCard = Styled.div`
    span{
      color: ${({ theme }) => theme['gray-color']};
      font-size: 13px;
    }
    .sub-heading{
      font-size:15px;
      font-weight:600;
      color: ${({ theme }) => theme['graySolid']};
    }
    .values{
      font-size:13px;
      font-weight:600;
      color: ${({ theme }) => theme['graySolid']};
    }
    .total{
      font-size:16px;
      font-weight:600;
    }
    .product-name{
      color: ${({ theme }) => theme['primary-color']};
      margin-left:10px;
      font-weight:600;

    }
`;
const EnquiryDetailsWrapper = Styled.div`
  .ant-card-body{
    padding: 30px 0 !important;
  }
  .ant-card-head-wrapper{
    @media only screen and (max-width: 767px){
      flex-flow: column;
      align-items: center;
    }
  }

  .ant-card-head {
    .ant-card-extra{
      @media only screen and (max-width: 767px){
        width: 100%;
        justify-content: center;
      }
    }
    .ant-card-head-title{
      @media only screen and (max-width: 767px){
        padding: 18px 0 0;
      }
    }
  }
`;
const MailRightAction = Styled.div`
  span{
    color: ${({ theme }) => theme['light-color']};
  }
`;
const MessageAction = Styled.div`
  display: flex;
  align-items: center;
  margin: 0 -5px;
  @media only screen and (max-width: 575px){
    flex-flow: row !important;
    margin-bottom: 5px;
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin: 0 4px;
    transition: 0.3s ease;
    @media only screen and (max-width: 575px){
      width: 25px;
      height: 25px;
    }
    svg{
      width: 16px;
      color: ${({ theme }) => theme['gray-solid']};
    }
    &:hover{
      background: rgba(95,99,242,0.05);
      svg{
        color: ${({ theme }) => theme['primary-color']};
      }
    }
  }
`;
const EnquiryDetails = Styled.div`
  padding: 0 30px;
  .message-box {
    @media only screen and (max-width: 767px){
      flex-flow: column;
      align-items: flex-start;
    }
    @media only screen and (max-width: 575px){
      padding: 0;
    }
  }
  >div{
    &.align-items-center{
      @media only screen and (max-width: 375px){
        align-items: flex-start;
      }
    }
  }
  .message-subject{
    h1{
      display: flex;
      align-items: center;
      font-weight: 500;
      @media only screen and (max-width: 767){
        font-size: 20px;
      }
      @media only screen and (max-width: 375px){
        flex-flow: column;
        align-items: flex-start;
      }
      .mail-badge{
        display: inline-block;
        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
        font-size: 12px;
        font-weight: 500;
        height: 20px;
        line-height: 1.6;
        padding: 0 6.4px;
        border-radius: 3px;
        text-transform: capitalize;
        @media only screen and (max-width: 375px){
          margin: 15px 0 0 0;
        }
        background: ${({ theme }) => theme['bg-color-deep']};
        &.primary{
          background: ${({ theme }) => theme['primary-color']}10;
          color: ${({ theme }) => theme['primary-color']};
        }
      }
    }
  }
  .message-action{
    display: flex;
    align-items: center;
    a{
      display: flex;
      align-items:center;
      justify-content: center;
      flex-direction: column;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      transition: 0.3s ease;
      color: ${({ theme }) => theme['gray-solid']};
      svg{
        margin: -3px 0;
      }
      &:hover{
        background: rgba(95,99,242,0.05);
      }
    }
  }
  .message-author{
    display: flex;
    align-items: center;
    margin-top: 20px;
    @media only screen and (max-width: 767px){
      margin-top: 14px;
    }
    div{
      ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
      h1{
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 4px;
      }
      a{
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme['gray-solid']};
        svg,
        i{
          ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 5px;
        }
      }
    }

  }
  .message-excerpt{
    display: flex;
    align-items: center;
    margin: 0 -15px;
    @media only screen and (max-width: 767px){
      margin: 18px 0 0;
    }
    span + span{
      font-size: 13px;
      line-height: 1.5;
    }
    span, a{
      display: block;
      padding: 0 15px;
      line-height: 1;
      color: ${({ theme }) => theme['gray-solid']};
      @media only screen and (max-width: 1199px){
        padding: 0 6px;
      }
    }
    & > span{
      ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
      svg{
        margin-top: 6px;
      }
    }
    svg{
      width: 16px;
    }
    a{
      i,
      span.fa{
        font-size: 16px;
      }
      &.starDeactivate{
        i:before{
          content: "\f31b";
        }
      }
      &.starActive{
        color: ${({ theme }) => theme['warning-color']};
        i:before,
        span.fa:before{
          content: "\f005";
        }
      }
    }
  }

  .message-body{
    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 82px;
    margin-top: 22px;
    @media only screen and (max-width: 767px){
      ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0px;
      margin-top: 18px;
    }
    .welcome-text{
      font-size: 15px;
      margin-bottom: 40px;
      display: inline-block;
      color: ${({ theme }) => theme['gray-color']};
    }
    p{
      color: ${({ theme }) => theme['gray-color']};
      font-size: 15px;
      line-height: 1.667;
      margin-bottom: 55px;
    }
    h1{
      font-size: 15px;
      color: ${({ theme }) => theme['gray-color']};
      font-weight: normal;
      line-height: 30px;
    }
  }
  .message-attachments{
    margin: 44px -5px 0 -5px;
    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 82px;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 767px){
      margin: 30px 0 0;
      ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0px;
      justify-content: center;
    }
    figure{
      position: relative;
      border: 1px solid ${({ theme }) => theme['border-color-light']};
      border-radius: 10px;
      padding: 10px;
      margin: 0 5px 30px;
      &:hover{
        box-shadow: 0 10px 20px ${({ theme }) => theme['gray-solid']}10;
        .attachment-image{
          &:after{
            height: 100%;
            opacity: 1;
            visibility: visible;
          }
        }
        .attachment-hover{
          opacity: 1;
          visibility: visible
        }
      }
      .attachment-image{
        position: relative;
        &:after{
          position: absolute;
          ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
          top: 0;
          width: 100%;
          height: 0%;
          border-radius: 10px;
          content: '';
          opacity: 0;
          visibility: hidden;
          transition: .3s ease-in;
          background: ${({ theme }) => theme['dark-color']}50;
        }
      }
      .attachment-hover{
        position: absolute;
        top: 80px;
        ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: .35s;
        .btn-link{
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          &:after{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #fff;
            opacity: .20;
            content: '';
          }
          svg,
          img{
            width: 14.5px;
            color: #fff;
          }
        }
        .btn-link + .btn-link{
          ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
        }
      }
      figcaption{
        margin-top: 10px;
        h1{
          font-size: 13px;
          margin: 0;
        }
        p{
          font-size: 12px;
          color: ${({ theme }) => theme['gray-solid']};
          margin:0;
        }
      }
    }
  }
  hr{
    margin-bottom: 30px;
    border: 0 none;
    height: 1px;
    background: ${({ theme }) => theme['border-color-light']};
  }
`;

const EnquiryReply = Styled.div`
  padding: 0 30px;
  @media only screen and (max-width: 575px){
    padding: 0 15px;
  }
  nav{
    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 70px;
    margin-bottom: 30px;
    @media only screen and (max-width: 575px){
      ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
    }
    ul{
      display: flex;
      align-items: center;
      list-style:none;
      margin: 0 -5px;
      padding: 0;
      li{
        padding: 0 5px;
        a{
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          line-height: 44px;
          display: inline-block;
          padding: 0 20px;
          font-size: 14px;
          font-weight: 600;
          color: ${({ theme }) => theme['gray-color']};
          border-radius: 4px;
          &:hover{
            color: ${({ theme }) => theme['primary-color']};
          }
        }
      }
    }
  }
  .reply-form{
    @media only screen and (max-width: 575px){
      flex-flow: column;
      align-items: center;
    }
    img{
      margin: ${({ theme }) => (theme.rtl ? '10px 0 0 20px' : '10px 20px 0 0')};
      border-radius: 50%;
      @media only screen and (max-width: 575px){
        margin: 0 0 20px;
      }
    }
  }
  .RichTextEditor__root___2QXK-{
    border: 0 none;
    padding: 15px 0px;
    @media only screen and (max-width: 575px){
      padding: 15px 0;
    }
    .public-DraftEditor-content{
      min-height: 120px;
    }
  }
  .reply-box{
    display: flex;
    > div{
      width: 100%;
      z-index: 10;
      border: 1px solid ${({ theme }) => theme['border-color-light']};
    }
    .reply-inner{
      width: 100%
      border-bottom: 1px solid #F1F2F6 !important;
      @media only screen and (max-width: 575px){
        flex-flow: column;
        align-items: flex-start !important;
        padding-top: 15px;
      }
      .react-tagsinput{
        border-bottom: 0 none !important;
      }
    }
    .reply-title{
      ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
      color: ${({ theme }) => theme['light-color']};
    }
    .mail-cc{
      color: ${({ theme }) => theme['light-color']};
    }
    .body{
      .DraftEditor-root{
        >div{
          font-size: 14px;
          font-family: 'Inter', sans-serif;
        }
      }
      .public-DraftEditorPlaceholder-root{
        padding-top: 20px;
      }
      .public-DraftEditor-content {
        height: 155px;
        padding-top: 20px;
        @media only screen and (max-width: 575px){
          height: 140px
        }
      }
    }
    .footer{
      margin: 0 30px 0;
      @media only screen and (max-width: 575px){
        margin: 0 15px 0
      }
    }
  }
`;

const EnquiryReplyList = Styled.div`
  box-shadow: 0 15px 40px ${({ theme }) => theme['light-color']}10;
  margin: 0px 0 50px;
  padding-top: 30px;
  .reply-view__single{
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    @media only screen and (max-width: 375px){
      flex-flow: column;
    }
    &:not(:last-child){
      margin-bottom: 30px;
    }
    .reply-view__content{
      @media only screen and (max-width: 575px){
          flex-flow: column;
      }
      img{
        ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
        border-radius: 50%;
        @media only screen and (max-width: 575px){
          margin: 0 0 15px 0;
        }
      }
      figcaption{
        h1{
          font-weight: 500;
          margin-bottom: 12px;
        }
        p{
          font-size: 15px;
          color: ${({ theme }) => theme['gray-color']};
        }
      }
    }
    .reply-view__meta{
      @media only screen and (max-width: 375px){
        order: -1;
      }
      span{
        font-size: 13px;
      }
      svg,
      i,
      span{
        color: ${({ theme }) => theme['light-color']};
      }
      i,
      span.fa{
        font-size: 16px;
      }
      svg,
      img{
        width: 16px;
      }
      .meta-list{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 575px){
            flex-flow: column;
        }
        @media only screen and (max-width: 375px){
            flex-flow: row;
            justify-content: flex-start;
            margin-bottom: 20px;
        }
        a{
          line-height: 1;
        }
        .date-meta{
          min-width: 135px;
          ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 30px
          @media only screen and (max-width: 767px){
            ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
          }
          @media only screen and (max-width: 575px){
            margin: 0 0 15px 0;
          }
          @media only screen and (max-width: 375px){
            margin: ${({ theme }) => (theme.rtl ? '0 0 0 15px' : '0 15px 0 0')};
          }
        }
        a{
          &:not(:last-child){
            ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 30px;
            @media only screen and (max-width: 767px){
              ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
            }
            @media only screen and (max-width: 575px){
                margin: 0 0 15px 0;
            }
            @media only screen and (max-width: 375px){
              margin: 0 15px 0 0;
            }
          }
        }
      }
    }
  }
`;
const EnquiryWrapper = Styled.div`
  position: relative;
  .trigger-close.ant-btn-link{
    margin: 0 !important;
    position: absolute;
    ${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 20px;
    top: 20px;
    z-index: 99;
    padding: 0;
    background: transparent !important;
  }
  .trigger-col {
    @media only screen and (max-width: 991px){
      text-align: center;
    }
  }
  .ant-btn-link{
    background: #fff !important;
    margin-bottom: 25px;
    border-radius: 6px;
    color: ${({ theme }) => theme['primary-color']} !important;
    &:focus{
      color: ${({ theme }) => theme['primary-color']} !important;
    }
  }
  .mail-sideabr{
    &.hide{
      transform: translateX(${({ theme }) => (theme.rtl ? '100%' : '-100%')});
      transition: .35s ease-in;
    }
    &.show{
      transform: translateX(0%);
      transition: .35s ease-in;
    }
    @media only screen and (max-width: 991px){
      display: block;
      background: #fff;
      position: fixed;
      ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
      top: 60px;
      width: 280px;
      height: 100%;
      z-index: 99;
    }
    .ant-card{
      min-height: 900px;
      .ant-card-body{
        padding: 0px !important;
      }
    }
  }

  .mail-sidebar-top{
    padding: 30px 30px 0;
    @media only screen and (max-width: 991px){
      padding: 60px 30px 0;
    }
  }

  .mail-sidebar-bottom{
    padding: 0 15px 25px 15px;
  }

  table{
    .ant-table-tbody{
      .ant-table-cell{
        vertical-align: top;
      }
    }
  }
`;

export {
  UserTableStyleWrapper,
  OrderCard,
  EnquiryDetailsWrapper,
  MailRightAction,
  MessageAction,
  EnquiryDetails,
  EnquiryReply,
  EnquiryReplyList,
  EnquiryWrapper
};
