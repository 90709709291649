import actions from './action';
import axios from 'axios';
import { message } from 'antd'

const {
  couponBegin,
  addCouponSuccess,
  getCouponSuccess,
  editCouponSuccess,
  getUserCouponSuccess,
  couponErr
} = actions;

export const addCoupon = (payload, history) => {
  return async dispatch => {
    try {
      dispatch(couponBegin());
      const resp = await axios.post('/api/coupon', payload);
      if (resp) {
        message.info(resp?.data?.msg)
        // if (resp.data?.exclskumsg) {
        //   setTimeout(() => message.info(resp.data?.exclskumsg), 100)
        // }
        dispatch(addCouponSuccess(resp.data));
      }
      history.push('/admin/coupon');
    } catch (err) {
      if (err.response?.data?.error?.code === 11000) {
        message.error('Coupon code already exist !')
      } else {
        message.error(err?.response?.data?.message ?? 'Something went wrong !');
      }
      dispatch(couponErr(err));
    }
  };
};

export const editCoupon = (id, payload, history) => {
  return async dispatch => {
    try {
      dispatch(couponBegin());
      const resp = await axios.put(`/api/coupon/${id}`, payload);
      message.info(resp?.data?.msg)
      history.push('/admin/coupon');
      // if (resp.data?.exclskumsg) {
      //   setTimeout(() => message.info(resp.data?.exclskumsg), 100)
      // }
      dispatch(editCouponSuccess(resp.data.coupon));
      dispatch(getCoupon())
    } catch (err) {
      if (err.response?.data?.message) {
        message.error(err.response.data.message)
      }
      dispatch(couponErr(err));
    }
  };
};

export const getCoupon = (data) => {
  return async dispatch => {
    try {
      dispatch(couponBegin());
      let url = '/api/coupon?';
      Object.keys(data || {}).forEach((k) => {
        url += `${k}=${data[k]}&`
      })
      url = url.slice(0, -1);
      const resp = await axios.get(url);
      dispatch(getCouponSuccess(resp?.data));
    } catch (err) {
      console.log({ err })
      dispatch(couponErr(err));
    }
  };
};

export const getUserCoupons = (id = '', allowDel = true) => {
  return async dispatch => {
    if (!id) {
      return;
    }
    try {
      const resp = await axios.get(`/api/coupon/user?coupon=${id}&allowDel=${allowDel}`);
      dispatch(getUserCouponSuccess(resp?.data?.reverse()));
    } catch (err) {
      dispatch(getUserCouponSuccess([]));
    }
  };
};


export const deleteCoupon = (id) => {
  return async dispatch => {
    try {
      dispatch(couponBegin());
      await axios.delete(`/api/coupon/${id}`);
      dispatch(getCoupon())
      message.success('Coupon deleted successfully !')
    } catch (err) {
      dispatch(couponErr(err));
    }
  };
};
