import actions from './actions';

const initialState = {
  performanceData: null,
  perLoading: false,

  error: null,
};

const { PERFORMANCE_BEGIN, PERFORMANCE_SUCCESS, PERFORMANCE_ERR } = actions;

const chartTotalRevenueReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PERFORMANCE_BEGIN:
      return {
        ...state,
        perLoading: true,
      };
    case PERFORMANCE_SUCCESS:
      return {
        ...state,
        performanceData: data,
        perLoading: false,
      };
    case PERFORMANCE_ERR:
      return {
        ...state,
        error: err,
        perLoading: false,
      };

    default:
      return state;
  }
};

export default chartTotalRevenueReducer;
