import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';

const ConfirmModal = ({ showmodal, eventFunc, id, onClose, name, news, message, title, okText, cancelText, note }) => {
  const [modalText, setModalText] = React.useState('Are you sure want to delete');
  const handledelete = () => {
    eventFunc(id);
    onClose();
  };

  return (
    <>
      <Modal title={title ? title : "Confirm Delete"} visible={showmodal} okText={okText || "Ok"} cancelText={cancelText || "Cancel"} onCancel={onClose} onOk={handledelete}>
        {message ? <p>
          {message && message}
        </p>
          : <div>
            {modalText}&nbsp;{news && 'news'}&nbsp;
            <span style={{ color: 'rgb(243, 146, 0)', fontSize: 14, fontWeight: 600 }}>{name}?</span>
            <div> {note}</div>
          </div>}
      </Modal>
    </>
  );
};
export default ConfirmModal;
