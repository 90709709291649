import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// import BlankPage from '../../container/pages/BlankPage';

// const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const BuyerList = lazy(() => import('../../container/pages/BuyerListDataTable'));
// const OptList = lazy(() => import('../../container/pages/Opt'));
// const SellerList = lazy(() => import('../../container/pages/SellerListDataTable'));
// const RequestList = lazy(() => import('../../container/pages/RequestListDataTable'));
// const RequestDetail = lazy(() => import('../../container/pages/RequestDetail'));
// const EditSeller = lazy(() => import('../../container/pages/EditSellerForm'));
// const EditBuyer = lazy(() => import('../../container/pages/EditBuyerForm'));
// const ViewBuyer = lazy(() => import('../../container/pages/ViewBuyer'));
// const ViewSeller = lazy(() => import('../../container/pages/ViewSeller'));
// const BuyerDetails = lazy(() => import('../../container/pages/BuyerDetails'));
// const BlankPage = lazy(() => import('../../container/pages/BlankPage'));
// const BulkBuyer = lazy(() => import('../../container/pages/BulkBuyer'));
// const ViewBulkBuyer = lazy(() => import('../../container/pages/ViewBulkBuyer'));
// const BulkSeller = lazy(() => import('../../container/pages/BulkSeller'));
// const ViewBulkSeller = lazy(() => import('../../container/pages/ViewBulkSeller'));




const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/sellers`} component={SellerList} />
      <Route path={`${path}/buyers/:id`} component={BuyerDetails} /> */}
      <Route path={`${path}/buyers`} component={BuyerList} />
      {/* <Route path={`${path}/opt`} component={OptList} />
      <Route exact path={`${path}/view-seller/:id`} component={ViewSeller} />
      <Route exact path={`${path}/view-buyer/:id`} component={ViewBuyer} />
      <Route path={`${path}/edit-seller/:id`} component={EditSeller} />
      <Route path={`${path}/edit-buyer/:id`} component={EditBuyer} />
      <Route path={`${path}/add`} component={AddUser} />
      <Route path={`${path}/request/:id`} component={RequestDetail} />
      <Route path={`${path}/request`} component={RequestList} />
      <Route path={`${path}/view-part`} component={BlankPage} />
      <Route exact path={`${path}/bulkbuyer`} component={BulkBuyer} />
      <Route exact path={`${path}/view-bulk-buyer/:id`} component={ViewBulkBuyer} />
      <Route exact path={`${path}/bulkseller`} component={BulkSeller} />
      <Route exact path={`${path}/view-bulk-seller/:id`} component={ViewBulkSeller} />
      <Route exact path={`${path}/view-bulk-seller/:id`} component={ViewBulkSeller} /> */}


   


    </Switch>
  );
};

export default PagesRoute;
