import React, { lazy, Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { WizardBlock } from './Style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import { ShareButtonPageHeader } from '../../../components/buttons/share-button/share-button';
import { ExportButtonPageHeader } from '../../../components/buttons/export-button/export-button';
import { CalendarButtonPageHeader } from '../../../components/buttons/calendar-button/calendar-button';
// import AddSales from './overview/AddSales'

const AddSeller = lazy(() => import('./overview/AddSeller'));
const AddBuyer = lazy(() => import('./overview/AddBuyer'));
const AddSales = lazy(() => import('./overview/AddSales'));
const AddDelivery = lazy(() => import('./overview/AddDelivery'));
const EditFormBulk = lazy(() => import('./overview/EditFormBulk'));
const EditFormBulkSeller = lazy(() => import('./overview/EditFormBulkSeller'));

const Wizards = () => {
  const { path } = useRouteMatch();

  return (
    <>
      {/* <PageHeader
        title="New Users"
        buttons={[
          <div key="1" className="page-header-actions">
            <CalendarButtonPageHeader />
            <ExportButtonPageHeader />
            <ShareButtonPageHeader />
            <Button size="small" type="primary">
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      /> */}
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            {/* <Switch> */}
            <WizardBlock>
              <Cards headless>
                <Row justify="center">
                  <Col xxl={20} xs={24}>
                    <Switch>
                      {/* <Suspense
                          fallback={
                            <Cards headless>
                              <Skeleton paragraph={{ rows: 20 }} active />
                            </Cards>
                          }
                        > */}
                      <Route exact path={`${path}/seller`} component={AddSeller} />
                      <Route exact path={`${path}/buyer`} component={AddBuyer} />
                      <Route exact path={`${path}/`} component={AddSales} />
                      <Route exact path={`${path}/add`} component={AddSales} />
                      <Route exact path={`${path}/delivery`} component={AddDelivery} />
                      <Route exact path={`${path}/news`} component={AddDelivery} />
                      <Route exact path={`${path}/buyer-edit-bulk`} component={EditFormBulk} />
                      <Route exact path={`${path}/seller-edit-bulk`} component={EditFormBulkSeller} />

                      {/* </Suspense> */}
                    </Switch>
                  </Col>
                </Row>
              </Cards>
            </WizardBlock>
            {/* </Switch> */}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Wizards;
