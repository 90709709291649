import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin } from 'antd';
import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios'
import { getBulkBuyerList } from '../../redux/users/actionCreator';
import { useSelector, useDispatch } from 'react-redux';
import { getbulkListing } from '../../redux/listing/actionCreator';
import { uploadFileToAssets } from '../../utility/apiUtils';

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const FileUploadModal = ({ showmodal, onClose, id, userId, filename, type}) => {
  const [file, setOrgFile] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  

  const dispatch=useDispatch()
    const props = {
        name: 'file',
          headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
         
            setOrgFile(info.fileList[0])
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        }
    };

  const handleSubmit = async () => {
    setLoader(true)
    if (isEmpty(file)) {
      message.error('Please upload file!');
      setLoader(false)
    } else {
      try {
        const fileData = await uploadFileToAssets({
          file: file?.originFileObj,
          type: "listing"
        });

        const data = {
          file_id: fileData?._id
        }

        let url = ''
        if (type === 'general') url = 'reupload-product'
        if (type === 'tire') url = 'reupload-tires'
        if (type === 'battery') url = 'reupload-batteries'
        axios
          .post(`/api/products/${url}?userId=${userId}&file=${fileData?._id}`)
          .then((res) => {
            onClose();
            setLoader(false)
            setOrgFile({})
            dispatch(getbulkListing(id))
            message.success('You have added bulk listing successfully!');
          })
          .catch((err) => {
            setLoader(false)
            setOrgFile({})
            message.error(err?.response?.data?.message);
          })
      } catch (error) {
        setLoader(false)
        message.error(error?.message || "Error while reuploading bulk listing")
      }
    }
  };
  return (
    <>
      <Modal title={
           <div>
           Please select excel file{" "}
         </div>
      } visible={showmodal} onCancel={onClose}
        footer={[
            <Button key="back" onClick={onClose}>
              Cancel
            </Button>,
            <Button key="submit" disabled={loader} loading={loader} onClick={handleSubmit} type="primary">
              Upload 
            </Button>
          ]}
         >
           <div>
               <div>Please backup your listing file before reuploading the file.</div>
               <br/>
            <Upload {...props} beforeUpload={()=>{  
                return false
            }} >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
           </div>  
      </Modal>
    </>
  );
};
export default FileUploadModal;



