import React, { Suspense , useEffect, lazy} from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect, BrowserRouter, useHistory } from 'react-router-dom';
import Dashboard from './dashboard';
import Payments from '../../container/pages/payment';
import Deliveries from '../../container/deliveries';
import WareHouse from './warehouse';
import Delivery from './delivery';
// import Users from './users';
// import Listings from './listings';
// import Settings from './settings';
// import Emails from './emails';
// import Enquiries from '../../container/EnquiryPage';
import Orders from './orders';
// import OrderDeliveries from './OrderDeliveries';
// import RfqRoute from './rfq';
import withAdminLayout from '../../layout/withAdminLayout';
const ClockList = React.lazy(() => import('../../container/pages/ClockListDataTable'));
const OrderDetails = lazy(() => import('../../container/orders/OrderDetails'));
// import Sales from './sales';
const Chat = React.lazy(() => import('../../container/pages/Chat'));
// import Delivery from './delivery';
 
const RedirectToOrder = () => {
  const history = useHistory()
  useEffect(() => {
    history.push("/admin/orders")
    
  }, [])
return null
}

const LogisticsAdmin = () => {
  const { path } = useRouteMatch();
  return (

    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
      
        {/* <Route exact path={path} render={() => <RedirectToOrder/>} /> */}
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/orders/view/:id`} component={OrderDetails} />
        <Route exact path={`${path}/orders`} component={Orders} />
        <Route exact path={`${path}/payments`} component={Payments} />
        <Route exact path={`${path}/deliveries`} component={Deliveries} />
        <Route path={`${path}/warehouse`} component={WareHouse} />
        <Route path={`${path}/delivery`} component={Delivery} />
        <Route exact path={`${path}/delivery/clock-in-out/:id`} component={ClockList} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(LogisticsAdmin);
