
const actions = {
    GET_NEWS_LIST_BEGIN: 'GET_NEWS_LIST_BEGIN',
    GET_NEWS_LIST_SUCCESS: 'GET_NEWS_LIST_SUCCESS',
    GET_NEWS_LIST_ERR: 'GET_NEWS_LIST_ERR',

    UPDATE_NEWS_BEGIN: 'UPDATE_NEWS_BEGIN',
    UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
    UPDATE_NEWS_ERR: 'UPDATE_NEWS_ERR',

    RESET_FLAGS: 'RESET_FLAGS',

    getNewsListBegin: () => {
      return {
        type: actions.GET_NEWS_LIST_BEGIN,
      };
    },
    
    getNewsListSuccess: data => {
        return {
          type: actions.GET_NEWS_LIST_SUCCESS,
          data
        };
      },
      
    getNewsListErr: err => {
        return {
          type: actions.GET_NEWS_LIST_ERR,
          err
        };
      },

      updateNewsBegin: () => {
        return {
          type: actions.UPDATE_NEWS_BEGIN,
        };
      },
      
      updateNewsSuccess: response => {
          return {
            type: actions.UPDATE_NEWS_SUCCESS,
            response
          };
        },
        
      updateNewsErr: err => {
          return {
            type: actions.UPDATE_NEWS_ERR,
            err
          };
        },

        resetFlags: () => {
          return {
            type: actions.RESET_FLAGS,   
          };
        },
  };
  
  export default actions;
  