import React, { useState, useEffect } from 'react';
import { Table, Tag,Button, message } from 'antd';

import { UserTableStyleWrapper } from './style';
import { TableWrapper, StatusWrapper } from '../styled';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button as AntdButton, Radio } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';
import EditSubCatModal from '../../components/modals/EditSubCatModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getSubCategories } from '../../redux/listing/actionCreator';
import ConfirmModal from '../../components/modals/confirmation';
import axios from 'axios';
// handleSearchByOrderStatus, fitlerByOrderStatus 
const SubcategoriesTable = ({subCategories,categories,paramsId}) => {
  const [orderFilter, setOrderFilter] = useState('all');
  const [id,setId]=useState('')
  const [pid,setPId]=useState('')
  const [modalOpen,setModalopen]=useState(false)
  const [name,setName]=useState('')
  const [pname,setPname]=useState('')
  const [code,setErpCode]=useState('')
  const [confirm,setConfirm]=useState(false)
  const dispatch=useDispatch()
  
  
  const handleEdit=(id,pid,pname,name,erpCode)=>{
    setModalopen(true)
    setId(id)
    setPId(pid)
    setName(name)
    setPname(pname)
    setErpCode(erpCode)
  }

  
  const modalClose=()=>{
    setModalopen(false)
  }
  const openConfirm = (id, name) => {
    setConfirm(true);
    setId(id);
    setName(name);
  };

  const deletesubcategory=async (id)=>{
    await axios.patch(`/api/subcategories/delete-subcategory?cat_id=${id}`)
    .then((res)=>{
      message.success('Successfully deleted subcategory!')
      dispatch(getSubCategories(paramsId))
    }).catch((err)=>{
      message.err('Failed to delete subcategory!')
    })
    
  }

  const subCategoriesTableData = [];
  subCategories &&
    subCategories.length > 0 &&
    subCategories.map((category, index) => {
      const { _id, name,category_id, erpCode } = category;
      return subCategoriesTableData.push({
        key:_id,
        sNo: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {category_id?.name}
            </Heading>
          </div>
        ),
        Name: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {name?.toUpperCase()}
            </Heading>
          </div>
        ),
        erpCode,
        action: (
          <div className="table-actions">
            <>
              <Button onClick={()=>handleEdit(_id,category_id._id,category_id.name,name,erpCode)} className="btn-icon" type="info" to="#" shape="circle">
                  <FeatherIcon icon="edit" size={16} />
               </Button>
              <Button
                onClick={() => openConfirm(_id,name)}
                className="btn-icon"
                type="danger"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </>
          </div>
        )
      });
    });

  const ordersTableColumns = [
    {
      title: 'Category',
      dataIndex: 'sNo',
      key: 'sNo',
    },
    {
      title: 'Sub Categories',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'ERP Code',
      dataIndex: 'erpCode',
      key: 'erpCode',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      fixed:'right'
      
    },
  ];

  const rowSelection = {
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
      <>
           <ConfirmModal showmodal={confirm} onClose={() => setConfirm(false)} eventFunc={deletesubcategory} id={id} name={name} />

    <Cards headless>
        <EditSubCatModal
        paramsId={paramsId}
        parentCategories={categories}
        showmodal={modalOpen}
        onClose={()=>setModalopen(false)}
        modalClose={modalClose}
        name={name}
        id={id}
        pid={pid}
        pname={pname}
        code={code}
      />
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={subCategoriesTableData}
            columns={ordersTableColumns}
            pagination={{
              defaultPageSize: 20,
              total: subCategoriesTableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
    </>
  );
};

export default SubcategoriesTable;
