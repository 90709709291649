import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Listings from './listings';
import withAdminLayout from '../../layout/withAdminLayout';

const SaleScout = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />

        <Route path={`${path}/listings`} component={Listings} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(SaleScout);
