import actions from './actions';

const initialState = {
  orders: [],
  orderCount: 0,
  order: null,
  amountCollected: [],
  loading: false,
  error: null,
  orderLog: [],
  ordersFromPagination: {},
  countLoading: false,
  order_excel_file: '',
  count: 0,
  showListing: false,
  isListingLoading: false,
  listings: [],
  listingCount: 0,
  cart: [],
  cartListings: [],
  totalPrice: 0,

  isAddressLoading: false,
  usseraddress: [],

  delivery_option: [],

  isAddingToCart: false,

  cartDetail: null,
  isCartDetailLoading: false,
  
  selectedBuyer: '',
  selectedAddress: '',

  _orders: {},
  _current: 1,
  _pageSize: 10,
  orderSearchList: [],

  paymentMethod: "cash",
  walletAmount: 0,
  walletError: "",

  eligibleCoupons: [],
  coupon: {},
  isApplyingCoupon: false,

  orderSummary: {
    tax: 0,
    discount: 0,
    discountPerUnit: 0,
    totalExclVat: 0,
    shippingTax: 0,
    shippingAmount: 0,
    totalAmount: 0
  },
};

const {
  GET_ORDERS_LIST_BEGIN,
  GET_ORDERS_LIST_COUNT_BEGIN,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_COUNT_SUCCESS,
  GET_ORDERS_PAGINATION_SUCCESS,
  EMPTY_ORDERS_PAGINATION_SUCCESS,
  GET_ORDERS_LIST_ERR,
  GET_ORDER_LOG_BEGIN,
  GET_ORDER_LOG_SUCCESS,
  GET_ORDER_LOG_ERR,
  GET_ORDER_BEGIN,
  GET_ORDER_SUCCESS,
  GET_ORDER_COUNT_SUCCESS,
  GET_ORDER_ERR,
  RESET_FLAGS,
  INITIALIZE_BEGIN,
  INITIALIZE_SUCCESS,
  INITIALIZE_ERROR,
  ADD_ITEM,
  GET_MY_CART_LISTINGS,
  GET_TOTAL_PRICE,
  GET_USER_ADDRESS_BEGIN,
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_ERROR,
  GET_DELIVERY_OPTION,
  UPDATE_ITEM,
  COLLECTION_AMOUNT_ERR,
  GET_COLLECTION_AMOUNT,
  SET_SELECTED_ADDRESS,
  SET_SELECTED_BUYER,
  RESET_CREATE_ORDER,
  GET_ORDERS_BY_ORDER_ID_BEGIN,
  GET_ORDERS_BY_ORDER_ID_SUCCESS,
  GET_ORDERS_BY_ORDER_ID_ERROR,
  GET_CART_ITEM_BEGIN,
  GET_CART_ITEM_SUCCESS,
  GET_CART_ITEM_ERROR,
  SET_PAYMENT_METHOD,
  SET_WALLET_AMOUNT,
  SET_WALLET_ERROR,
  APPLY_COUPON_BEGIN,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_ERROR,
  SET_ELIGIBLE_COUPON_SUCCESS,
  SET_ORDER_SUMMARY,
  RESET_ORDER_STATE,
  SET_ORDER_STATE,
  SET_UPSERT_BUYER_CART_BEGIN,
  SET_UPSERT_BUYER_CART_SUCCESS,
  SET_UPSERT_BUYER_CART_ERROR,
  ADD_TO_CART_BEGIN,
  ADD_TO_CART_ERROR
} = actions;

const orderReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case "set_order_state":
      return {
        ...state,
        _orders: data.orders,
        _current: data.current,
        _pageSize: data.pageSize
      }
    case INITIALIZE_BEGIN:
      return {
        ...state,
        isListingLoading: true,
        showListing: true,
        listings: [],
        listingCount: 0
      };
    case INITIALIZE_SUCCESS:
      return {
        ...state,
        isListingLoading: false,
        listings: data?.listings,
        listingCount: data?.totalCount
      };
    case INITIALIZE_ERROR:
      return {
        ...state,
        isListingLoading: false,
        listings: [],
        listingCount: 0
      };
    case GET_COLLECTION_AMOUNT:
      return {
        ...state,
        amountCollected: data
      };
    case COLLECTION_AMOUNT_ERR:
      return {
        ...state,
        amountCollected: []
      };

    case GET_MY_CART_LISTINGS:
      return {
        ...state,
        cartListings: data
      };

    case GET_TOTAL_PRICE:
      return {
        ...state,
        totalPrice: data
      };

    case GET_USER_ADDRESS_BEGIN:
      return {
        ...state,
        isAddressLoading: true
      };
    case GET_USER_ADDRESS:
      return {
        ...state,
        usseraddress: data,
        isAddressLoading: false
      };
    case GET_USER_ADDRESS_ERROR:
      return {
        ...state,
        usseraddress: data,
        isAddressLoading: false
      };
    case GET_DELIVERY_OPTION:
      return {
        ...state,
        delivery_option: data
      };

    case ADD_ITEM:
      return {
        ...state,
        cart: [...state.cart, ...data]
      };
    case UPDATE_ITEM:
      return {
        ...state,
        cart: data
      };

    case GET_ORDERS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_LIST_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true
      };
    case GET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: data
      };
    case GET_ORDERS_LIST_ERR:
      return {
        ...state,
        loading: false,
        orders: [],
        err: err
      };

    case GET_ORDER_LOG_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ORDER_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        orderLog: data
      };
    case GET_ORDER_LOG_ERR:
      return {
        ...state,
        loading: false,
        orderLog: null,
        err: err
      };
    case GET_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        order: null
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: data
      };
    case GET_ORDER_COUNT_SUCCESS:
      return {
        ...state,
        orderCount: data
      };
    case GET_ORDERS_COUNT_SUCCESS:
      return {
        ...state,
        countLoading: false,
        count: data
      };
    case 'orders_excel_file':
      return {
        ...state,
        order_excel_file: data
      }
    case GET_ORDERS_PAGINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ordersFromPagination: {
          ...state.ordersFromPagination,
          [data?.page]: data?.value?.orders
        },
        count: data?.value?.total || 0
      };
    case EMPTY_ORDERS_PAGINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ordersFromPagination: {}
      };
    case GET_ORDER_ERR:
      return {
        ...state,
        loading: false,
        order: null,
        err: err
      };
    case SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: data
      };
    case SET_SELECTED_BUYER:
      return {
        ...state,
        selectedBuyer: data
      };
    case RESET_CREATE_ORDER:
      return {
        ...state,
        selectedAddress: '',
        selectedBuyer: '',
        cart: [],
        showListing: false,
        listings: [],
        listingCount: 0,
        usseraddress: []
      };
    case RESET_FLAGS:
      return {
        isUserUpdated: false
      };
    case GET_ORDERS_BY_ORDER_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        orderSearchList: data
      };
    case GET_ORDERS_BY_ORDER_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_CART_ITEM_BEGIN:
      return {
        ...state,
        isListingLoading: true,
      };
    case GET_CART_ITEM_SUCCESS:
      return {
        ...state,
        isListingLoading: false,
      };
    case GET_CART_ITEM_ERROR:
      return {
        ...state,
        isListingLoading: false,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: data,
      };
    case SET_WALLET_AMOUNT:
      return {
        ...state,
        walletAmount: data,
      };
    case SET_WALLET_ERROR:
      return {
        ...state,
        walletError: err,
      };
    case APPLY_COUPON_BEGIN:
      return {
        ...state,
        isApplyingCoupon: true,
      };
    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        coupon: data,
        isApplyingCoupon: false
      };
    case APPLY_COUPON_ERROR:
      return {
        ...state,
        coupon: {},
        isApplyingCoupon: false,
      };
    case SET_ELIGIBLE_COUPON_SUCCESS:
      return {
        ...state,
        isApplyingCoupon: false,
      };
    case SET_ORDER_SUMMARY:
      return {
        ...state,
        orderSummary: {
          ...state.orderSummary,
          ...data
        }
      };
    case RESET_ORDER_STATE:
      return {
        ...state,
        ...data,
        ...data?.orderSummary
      };
    case SET_ORDER_STATE:
      return {
        ...state,
        [data?.state]: data?.data
      };
    case SET_UPSERT_BUYER_CART_BEGIN:
      return {
        ...state,
        isCartDetailLoading: true
      };
    case SET_UPSERT_BUYER_CART_SUCCESS:
      return {
        ...state,
        isCartDetailLoading: false,
        isAddingToCart: false,
        cartDetail: data,
        selectedBuyer: data?.user,
        selectedAddress: data?.delivery_address,
      };
    case SET_UPSERT_BUYER_CART_ERROR:
      return {
        ...state,
        isCartDetailLoading: false,
        isAddingToCart: false
      };
    case ADD_TO_CART_BEGIN:
      return {
        ...state,
        isAddingToCart: true
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        isAddingToCart: false
      };
    default:
      return state;
  }
};

export default orderReducer;
