import Styled from 'styled-components';
const Style = Styled.div`
    .ant-card-body{
        padding: 0 !important;
        .ant-table{
            border-radius: ${({ theme }) => (theme.rtl ? '10px 10px 0 0' : '0 0 10px 10px')};
            margin-top: 1px;
        }
    }
    table{
        margin-bottom: 25px;
        .ant-table-thead > tr > th{
            background-color: #fff;
            color: ${({ theme }) => theme['dark-color']};
            &:first-child{
                padding-${({ theme }) => (theme.rtl ? 'right' : 'left')}: 25px;
            }
            &:last-child{
                text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
                padding-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 25px;
            }
            &.p_name{
                min-width: 420px;
            }
            &.p_price{
                min-width: 100px;
            }
        }
        .ant-table-tbody{
            tr{
                &:hover{
                    td{
                        background-color: ${({ theme }) => theme['bg-color-light']};
                    }
                }
                td{
                    padding: 14px 16px;
                    font-size: 14px;
                    color: ${({ theme }) => theme['gray-color']};
                    border-color: ${({ theme }) => theme['border-color-light']};
                    &:first-child{
                        padding-${({ theme }) => (theme.rtl ? 'right' : 'left')}: 25px;
                    }
                    &:last-child{
                        padding-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 25px;
                        text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
                    }
                }
            }
        }
    }
`;
export { Style };
