import { de } from 'date-fns/locale';
import actions from './action';

const initialState = {
  warehouseData: [],
  warehouseLoading: false,
  error: null
};

const {
  WAREHOUSE_BEGIN,
  GET_WAREHOUSE_SUCCESS,
  ADD_WAREHOUSE_SUCCESS,
  EDIT_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_SUCCESS,
  WAREHOUSE_ERR
} = actions;

const warehouseReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case WAREHOUSE_BEGIN:
      return {
        ...state,
        warehouseLoading: true
      };
    case GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseData: data,
        warehouseLoading: false
      };

    case ADD_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseData: [...state.warehouseData, data],
        warehouseLoading: false
      };

    case EDIT_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseLoading: false,
        warehouseData: state.warehouseData.map(warehouse => (warehouse._id === data._id ? data : warehouse))
      };

    case DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseLoading: false,
        warehouseData: [...state.warehouseData].filter(warehouse => warehouse._id !== data)
      };

    case WAREHOUSE_ERR:
      return {
        ...state,
        error: err,
        warehouseLoading: false
      };
    default:
      return state;
  }
};

export default warehouseReducer;
