import React, { useState } from 'react';
import KeywordTable from './KeywordTable';
import { Main, CardToolbox } from '../../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import moment from 'moment';
import axios from 'axios';

const review = () => {
  const [res, setres] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [filteredSearch, setFilteredSearch] = React.useState([]);
  const reviewDetails = useSelector(state => state.reviews.reviews);
  const loader = useSelector(state => state.reviews.loading);

  React.useEffect(() => {
    axios.get(`/api/listings/get-searched-keywords?page=${currentPage}&perPage=${perPage}`)
      .then(res => { setres(res.data) })
      .catch(err => { console.log(err) });
  }, [axios, currentPage, perPage]);

  React.useEffect(() => {
    setFilteredSearch(reviewDetails);
  }, [reviewDetails]);

  const handleSearch = e => {
    setSearchKeyword(e.target.value);
    const filter = reviewDetails.filter(obj => {
      if (searchKeyword === '') {
        return obj;
      } else {
        return (
          obj?.star == parseInt(searchKeyword) ||
          moment(obj?.createdAt)
            .tz('Asia/Dubai')
            .format('DD/MM/YYYY')
            .toLowerCase()
            .includes(searchKeyword?.toLowerCase()) ||
          moment(obj?.createdAt)
            .tz('Asia/Dubai')
            .format('HH:mm')
            .toLowerCase()
            .includes(searchKeyword?.toLowerCase())
        );
      }

      //  return obj;
    });
    setFilteredSearch(filter);
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Keyword Logs"
          subTitle={
            <>
              <span className="title-counter"> {res.total} Found</span>
              {/* <AutoCompleteStyled>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  onChange={handleSearch}
                  value={searchKeyword}
                />
              </AutoCompleteStyled> */}
            </>
          }
        />
      </CardToolbox>

      <Main>
        <Row>
          <Col md={24}>
            {/* {console.log(res)} */}
            <KeywordTable reviewDetails={res.keywords} total={res.total} setCurrentPage={setCurrentPage} setPerPage={setPerPage} loader={loader} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default review;
