import actions from './action';

const initialState = {
  walletData: [],
  walletCount: 0,
  walletLogData: [],
  walletLogCount: 0,
  wallet: {},
  walletLoading: false,
  walletLogLoading: false,
  error: null
};

const {
  WALLET_BEGIN,
  GET_WALLET_SUCCESS,
  WALLETLOG_BEGIN,
  GET_WALLETLOG_SUCCESS,
  ADD_WALLET_SUCCESS,
  EDIT_WALLET_SUCCESS,
  DELETE_WALLET_SUCCESS,
  GET_SINGLE_WALLET_SUCCESS,
  WALLET_ERR
} = actions;

const walletReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case WALLET_BEGIN:
      return {
        ...state,
        walletLoading: true
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        walletData: data.wallets,
        walletCount: data?.total || 0,
        walletLoading: false
      };

    case WALLETLOG_BEGIN:
      return {
        ...state,
        walletLogLoading: true
      };
    case GET_WALLETLOG_SUCCESS:
      return {
        ...state,
        walletLogData: data.walletLog,
        walletLogCount: data?.total || 0,
        walletLogLoading: false
      };


    case GET_SINGLE_WALLET_SUCCESS:
      return {
        ...state,
        wallet: data,
        walletLoading: false
      };

    case ADD_WALLET_SUCCESS:
      return {
        ...state,
        walletData: [...state.walletData, data],
        walletLoading: false
      };

    case EDIT_WALLET_SUCCESS:
      return {
        ...state,
        walletLoading: false,
        walletData: state.walletData.map(wallet => (wallet._id === data._id ? data : wallet))
      };

    case DELETE_WALLET_SUCCESS:
      return {
        ...state,
        walletLoading: false,
        walletData: [...state.walletData].filter(wallet => wallet._id !== data)
      };

    case WALLET_ERR:
      return {
        ...state,
        error: err,
        walletLoading: false
      };
    default:
      return state;
  }
};

export default walletReducer;
