import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Users from './users';
import WareHouse from './warehouse';
import Wallet from './wallet';
import Banner from './banner';
import Newsletter from './newsletter';
import Listings from './listings';
// import  EditListing from '../../container/listing/EditListingForm';
import Settings from './settings';
import Messages from '../../container/messages';
import RolesAndPermissions from '../../container/RolesAndPermissions';
import Emails from './emails';
import Enquiries from '../../container/EnquiryPage';
import EnquiryDetail from '../../container/EnquiryPage/EnquiryDetail';
import Orders from './orders';
import RfqRoute from './rfq';
import withAdminLayout from '../../layout/withAdminLayout';
import Sales from './sales';
import SalesManager from './sales_manager';
import MarketingManager from './marketing';
import Merchandiser from './merchandiser';
import Finance from './finance';
import Gallega from './gallega';
import News from './news';
import Delivery from './delivery';
import AddSales from '../../container/pages/wizards/overview/AddSales';
// import AddDelivery from '../../container/pages/wizards/overview/AddDelivery';
import Payments from '../../container/pages/payment';
import Wizards from '../../container/pages/wizards/Wizards';
import AddMerchandiser from '../../container/pages/wizards/overview/AddMerchandiser';
import AddFinance from '../../container/pages/wizards/overview/AddFinance';

import Deliveries from '../../container/deliveries';
import Reviews from '../../container/pages/reviews';
import SearchKeywords from '../../container/pages/searchkeywords';
import BulkImage from '../../container/pages/BulkImage';
import CategoryListing from '../../container/CategoryListing';
import SubCatListing from '../../container/CategoryListing/SubCatListing';
import Coupon from './coupon';
import Price from './price';
import AddSalesManager from '../../container/pages/wizards/overview/AddSalesManager';
import AddMarketingManager from '../../container/pages/wizards/overview/AddMarketing';

const Chat = React.lazy(() => import('../../container/pages/Chat'));
const ClockList = React.lazy(() => import('../../container/pages/ClockListDataTable'));

const BuyerSellerChatBox = React.lazy(() => import('../../container/pages/Chat/ChatBox/BuyerSellerChatBox'));
const ListingBrand = React.lazy(() => import('../../container/listingbrand/ListingBrand'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/listings`} component={Listings} />
        <Route path={`${path}/brand-mapping`} component={ListingBrand} />
        <Route path={`${path}/messages`} component={Messages} />
        <Route exact path={`${path}/enquiries`} component={Enquiries} />
        <Route exact path={`${path}/roles-permissions`} component={RolesAndPermissions} />
        <Route path={`${path}/enquiries/:id`} component={EnquiryDetail} />
        <Route path={`${path}/orders`} component={Orders} />
        <Route path={`${path}/rfq`} component={RfqRoute} />
        <Route exact path={`${path}/sales`} component={Sales} />
        <Route exact path={`${path}/sales-manager`} component={SalesManager} />
        <Route exact path={`${path}/sales-manager/add`} component={AddSalesManager} />

        {/* <Route exact path={`${path}/marketing`} component={MarketingManager} /> */}
        <Route exact path={`${path}/marketing/add`} component={AddMarketingManager} />

        <Route exact path={`${path}/sales/clock-in-out/:id`} component={ClockList} />
        <Route exact path={`${path}/merchandiser`} component={Merchandiser} />
        <Route exact path={`${path}/merchandiser/add`} component={AddMerchandiser} />
        <Route exact path={`${path}/finance`} component={Finance} />
        <Route exact path={`${path}/finance/add`} component={AddFinance} />
        <Route path={`${path}/gallega`} component={Gallega} />
        <Route exact path={`${path}/sales/add`} component={Wizards} />
        <Route exact path={`${path}/delivery/clock-in-out/:id`} component={ClockList} />
        <Route path={`${path}/delivery`} component={Delivery} />
        <Route path={`${path}/news`} component={News} />
        <Route exact path={`${path}/payments`} component={Payments} />
        <Route exact path={`${path}/deliveries`} component={Deliveries} />
        <Route exact path={`${path}/reviews`} component={Reviews} />
        <Route exact path={`${path}/search-keywords`} component={SearchKeywords} />
        <Route exact path={`${path}/bulk-image`} component={BulkImage} />
        <Route exact path={`${path}/category-mapping`} component={CategoryListing} />
        <Route exact path={`${path}/view-subcategories/:id`} component={SubCatListing} />

        {/* <Route path={`${path}/chats`} component={Chat} /> */}
        <Route path={`${path}/warehouse`} component={WareHouse} />
        <Route path={`${path}/wallets`} component={Wallet} />
        <Route path={`${path}/coupon`} component={Coupon} />
        <Route path={`${path}/price`} component={Price} />

        <Route path={`${path}/banner`} component={Banner} />
        <Route path={`${path}/newsletter`} component={Newsletter} />
        <Route exact path={path} component={Dashboard} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
