import React from 'react';
import { Cards } from '../cards/frame/cards-frame';
import { Table } from 'antd';
import { Style } from './style';

const index = () => {
  const dataSource = [
    {
      key: '1',
      name: 'Samsung Galaxy S8 256GB',
      price: '$280',
      sold: 126,
      revenue: '$38,536',
    },
    {
      key: '2',
      name: 'Half Sleeve Shirt',
      price: '$25',
      sold: 80,
      revenue: '$38,536',
    },
    {
      key: '3',
      name: 'Marco Shoes',
      price: '$32',
      sold: 58,
      revenue: '$38,536',
    },
    {
      key: '4',
      name: '15" Mackbook Pro        ',
      price: '$950',
      sold: 36,
      revenue: '$38,536',
    },
    {
      key: '5',
      name: 'Apple iPhone X',
      price: '$985',
      sold: 24,
      revenue: '$38,536',
    },
  ];
  const columns = [
    {
      title: 'Products Name',
      dataIndex: 'name',
      key: 'name',
      className: 'p_name',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      className: 'p_price',
    },
    {
      title: 'Sold',
      dataIndex: 'sold',
      key: 'sold',
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
    },
  ];
  return (
    <>
      <Style>
        <Cards title="My Products" size="default">
          <Table className="table-responsive" pagination={false} dataSource={dataSource} columns={columns} />
        </Cards>
      </Style>
    </>
  );
};

export default index;
