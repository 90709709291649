import actions from './actions';

const initialState = {
  sellers: [],
  allSellerList: [],
  allSellerCount: 0,
  buyers: [],
  allBuyerList: [],
  allBuyerCount: 0,
  opts: [],
  bulkbuyers: [],
  bulkbuyersCount: 0,
  bulkbuyersList: [],
  bulkbuyersListCount: 0,
  bulksellers: [],
  bulksellerCount: 0,
  bulksellersList: [],
  bulksellersListCount: 0,
  buyer: {},
  loading: false,
  error: null,
  isUserUpdated: false,
  socialLinks: [],
};

const {
  GET_SELLER_LIST_BEGIN,
  GET_SELLER_LIST_SUCCESS,
  GET_SELLER_LIST_ERR,

  GET_TLS_SETING,
  GET_TLS_SETTING_SUCCESS,
  GET_TLS_SETTING_ERR,

  GET_SELLER_TABLE_LIST_BEGIN,
  GET_SELLER_TABLE_LIST_SUCCESS,
  GET_SELLER_TABLE_COUNT_SUCCESS,
  GET_SELLER_TABLE_LIST_ERR,

  GET_OPT_LIST_BEGIN,
  GET_OPT_LIST_SUCCESS,
  GET_OPT_LIST_ERR,

  GET_BUYER_LIST_BEGIN,
  GET_BUYER_LIST_SUCCESS,
  GET_BUYER_LIST_ERR,

  GET_BUYER_TABLE_LIST_BEGIN,
  GET_BUYER_TABLE_LIST_SUCCESS,
  GET_BUYER_TABLE_COUNT_SUCCESS,
  GET_BUYER_TABLE_LIST_ERR,

  GET_ONEBUYER_BEGIN,
  GET_ONEBUYER_SUCCESS,
  GET_ONEBUYER_ERR,

  UPDATE_USER_STATUS_BEGIN,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERR,
  GET_SOCIAL_SUCCESS,
  GET_BULK_BUYER_LIST_SUCCESS,
  GET_BULK_BUYER_LIST_COUNT_SUCCESS,
  GET_BULK_BUYER_LIST_INNER_SUCCESS,
  GET_BULK_SELLER_LIST_SUCCESS,
  GET_BULK_SELLER_LIST_COUNT_SUCCESS,
  GET_BULK_SELLER_LIST_INNER_SUCCESS,
  RESET_FLAGS
} = actions;

const userReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_TLS_SETING:
      return {
        ...state,
        loading: true
      };
    case GET_TLS_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        tlsRequired: data
      };
    case GET_TLS_SETTING_ERR:
      return {
        ...state,
        loading: false,
        err: err
      };

    case GET_OPT_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_OPT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        opts: data
      };
    case GET_OPT_LIST_ERR:
      return {
        ...state,
        loading: false,
        opts: [],
        err: err
      };

    case GET_SELLER_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SELLER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sellers: data
      };
    case GET_SELLER_LIST_ERR:
      return {
        ...state,
        loading: false,
        sellers: [],
        err: err
      };
    case GET_BUYER_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_BUYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        buyers: data
      };
    case GET_BUYER_LIST_ERR:
      return {
        ...state,
        loading: false,
        buyers: [],
        err: err
      };
    case GET_BUYER_TABLE_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_BUYER_TABLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allBuyerList: data
      };
    case GET_BUYER_TABLE_COUNT_SUCCESS:
      return {
        ...state,
        allBuyerCount: data
      };
    case GET_BUYER_TABLE_LIST_ERR:
      return {
        ...state,
        loading: false,
        allBuyerList: [],
        allBuyerCount: 0,
        err: err
      };
    case GET_SELLER_TABLE_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SELLER_TABLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allSellerList: data
      };
    case GET_SELLER_TABLE_COUNT_SUCCESS:
      return {
        ...state,
        allSellerCount: data
      };
    case GET_SELLER_TABLE_LIST_ERR:
      return {
        ...state,
        loading: false,
        allSellerList: [],
        allSellerCount: 0,
        err: err
      };
    case GET_BULK_BUYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkbuyers: data,
        err: err
      };
    case GET_BULK_BUYER_LIST_COUNT_SUCCESS:
      return {
        ...state,
        bulkbuyersCount: data
      };
    case GET_BULK_BUYER_LIST_INNER_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkbuyersList: data?.buyerbulkList,
        bulkbuyersListCount: data?.total,
        err: err
      };
    case GET_BULK_SELLER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bulksellers: data,
        err: err
      };
    case GET_BULK_SELLER_LIST_COUNT_SUCCESS:
      return {
        ...state,
        bulksellerCount: data
      };
    case GET_BULK_SELLER_LIST_INNER_SUCCESS:
      return {
        ...state,
        loading: false,
        bulksellersList: data?.sellerbulkList,
        bulksellersListCount: data?.total,
        err: err
      };
    case GET_ONEBUYER_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ONEBUYER_SUCCESS:
      return {
        ...state,
        buyer: data,
        loading: false
      };
    case GET_SOCIAL_SUCCESS:
      return {
        ...state,
        socialLinks: data,
        loading: false
      };
    case GET_ONEBUYER_ERR:
      return {
        ...state,
        buyer: [],
        err: err,
        loading: false
      };
    case UPDATE_USER_STATUS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserUpdated: true
      };
    case UPDATE_USER_STATUS_ERR:
      return {
        ...state,
        loading: false,
        // buyers: [],
        err: err
      };
    case RESET_FLAGS:
      return {
        isUserUpdated: false
      };
    default:
      return state;
  }
};

export default userReducer;
