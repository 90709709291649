import actions from './actions';

const initialState = {
    addresses: [],
    isUpdating: false,

    states: [],
    isStateLoading: false,

    regions: [],
    isRegionLoading: false,

    locations: [],
    isLocationLoading: false,
}

const {
    GET_STATE_BEGIN,
    GET_STATE_SUCCESS,
    GET_STATE_ERROR,

    UPSERT_ADDRESS_BEGIN,
    UPSERT_ADDRESS_SUCCESS,
    UPSERT_ADDRESS_ERROR,

    GET_REGION_BEGIN,
    GET_REGION_SUCCESS,
    GET_REGION_ERROR,

    GET_LOCATION_BEGIN,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_ERROR
} = actions

const addressReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_STATE_BEGIN:
            return {
                ...state,
                isStateLoading: true
            };
        case GET_STATE_SUCCESS:
            return {
                ...state,
                states: data,
                isStateLoading: false
            };
        case GET_STATE_ERROR:
            return {
                ...state,
                isStateLoading: false
            };

        case UPSERT_ADDRESS_BEGIN:
            return {
                ...state,
                isUpdating: true
            };
        case UPSERT_ADDRESS_SUCCESS:
            return {
                ...state,
                isUpdating: false
            };
        case UPSERT_ADDRESS_ERROR:
            return {
                ...state,
                isUpdating: false
            };

        case GET_REGION_BEGIN:
            return {
                ...state,
                isRegionLoading: true
            };
        case GET_REGION_SUCCESS:
            return {
                ...state,
                regions: data,
                isRegionLoading: false
            };
        case GET_REGION_ERROR:
            return {
                ...state,
                isRegionLoading: false
            };

        case GET_LOCATION_BEGIN:
            return {
                ...state,
                isLocationLoading: true
            };
        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                locations: data,
                isLocationLoading: false
            };
        case GET_LOCATION_ERROR:
            return {
                ...state,
                isLocationLoading: false
            };

        default:
            return state;
    }
};

export default addressReducer;
