const actions = {
  HOMEPAGE_SECTION_BEGIN: 'HOMEPAGE_SECTION_BEGIN',
  GET_HOMEPAGE_SECTION_SUCCESS: 'GET_HOMEPAGE_SECTION_SUCCESS',
  ADD_HOMEPAGE_SECTION_SUCCESS: 'ADD_HOMEPAGE_SECTION_SUCCESS',
  HOMEPAGE_SECTION_ERR: 'HOMEPAGE_SECTION_ERR',
  EDIT_HOMEPAGE_SECTION_SUCCESS: 'EDIT_HOMEPAGE_SECTION_SUCCESS',
  DELETE_HOMEPAGE_SECTION_SUCCESS: 'DELETE_HOMEPAGE_SECTION_SUCCESS',
  GET_SINGLE_HOMEPAGE_SECTION_SUCCESS: 'GET_SINGLE_HOMEPAGE_SECTION_SUCCESS',
  GET_SETTING_BEGIN: 'GET_SETTING_BEGIN',
  GET_SETTING_SUCCESS: 'GET_SETTING_SUCCESS',
  GET_SETTING_FAILED: 'GET_SETTING_FAILED',
  DELIVERY_TIMES_BEGIN: 'DELIVERY_TIMES_BEGIN',
  GET_DELIVERY_TIME_SUCCESS: 'GET_DELIVERY_TIME_SUCCESS',
  DELIVERY_TIMES_ERROR: 'DELIVERY_TIMES_ERROR',

  getSettingBegin: () => {
    return {
      type: actions.GET_SETTING_BEGIN
    };
  },
  getSettingSuccess: data => {
    return {
      type: actions.GET_SETTING_SUCCESS,
      data
    };
  },
  getSettingFailed: data => {
    return {
      type: actions.GET_SETTING_FAILED,
      data
    };
  },

  homepageSectionBegin: () => {
    return {
      type: actions.HOMEPAGE_SECTION_BEGIN
    };
  },
  getHomepageSectionSuccess: data => {
    return {
      type: actions.GET_HOMEPAGE_SECTION_SUCCESS,
      data
    };
  },
  getSingleHomepageSectionSuccess: data => {
    return {
      type: actions.GET_SINGLE_HOMEPAGE_SECTION_SUCCESS,
      data
    };
  },
  addHomepageSectionSuccess: _ => {
    return {
      type: actions.ADD_HOMEPAGE_SECTION_SUCCESS
    };
  },
  editHomepageSectionSuccess: _ => {
    return {
      type: actions.EDIT_HOMEPAGE_SECTION_SUCCESS
    };
  },
  deleteHomepageSectionSuccess: _ => {
    return {
      type: actions.DELETE_HOMEPAGE_SECTION_SUCCESS
    };
  },
  homepageSectionErr: err => {
    return {
      type: actions.HOMEPAGE_SECTION_ERR,
      err
    };
  },

  deliveryTimeBegin: _ => {
    return {
      type: actions.DELIVERY_TIMES_BEGIN
    }
  },
  getDeliveryTimeSuccess: data => {
    return {
      type: actions.GET_DELIVERY_TIME_SUCCESS,
      data
    }
  },
  deliveryTimeError: _ => {
    return {
      type: actions.DELIVERY_TIMES_ERROR
    }
  },
};

export default actions;
