import React, { useEffect, lazy, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Link, NavLink, Switch, Route } from 'react-router-dom';
import { Tooltip, Row, Col, Spin, Tag } from 'antd';
import moment from 'moment';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import axios from 'axios';
import { Dropdown } from '../../components/dropdown/dropdown';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';

import { CalendarButtonPageHeader } from '../../components/buttons/calendar-button/calendar-button';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import {
  EnquiryDetailsWrapper,
  MessageAction,
  EnquiryDetails,
  EnquiryReplyList,
  EnquiryReply,
  MailRightAction,
  EnquiryWrapper
} from './style';

const EnquiryDetail = props => {
  const { match, history } = props;
  const [enquiry, setEnquiry] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await axios.get(`/api/enquiry/${match.params.id}`);
      setEnquiry(result?.data?.enquiry);
    })();
  }, [match.params.id]);

  return (
    <>
      <PageHeader
        ghost
        title={'Enquiry Details'}
        buttons={[
          <div key="1" className="page-header-actions">
            <CalendarButtonPageHeader />
          </div>
        ]}
      />
      <Main>
        <EnquiryWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col xxl={19} xl={17} lg={16}>
              <EnquiryDetailsWrapper>
                <Cards
                  title={
                    <>
                      <MessageAction>
                        <Link onClick={() => history.goBack()} to="#">
                          <FeatherIcon icon="arrow-left" size={14} />
                        </Link>
                        {/* <Tooltip placement="bottom" title="Refresh">
                          <NavLink to="/refresh">
                            <FeatherIcon icon="rotate-cw" size={18} />
                          </NavLink>
                        </Tooltip>

                        <Tooltip placement="bottom" title="Delete">
                          <NavLink to="/delete">
                            <FeatherIcon icon="trash" size={18} />
                          </NavLink>
                        </Tooltip> */}
                      </MessageAction>
                    </>
                  }
                >
                  <Row gutter={15} xs={24}>
                    <Col xs={24}>
                      <EnquiryDetails>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="message-subject">
                            <Heading as="h3">
                              Reason of Enquiry:
                              <span className="mail-badge primary">{enquiry?.resonOfEnquiry}</span>
                            </Heading>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="message-author">
                            <img
                              style={{ width: '60px', borderRadius: '50%' }}
                              src={'https://pickaface.net/gallery/avatar/unr_niceguy_180217_2320_7idsqs59.png'}
                              alt="user-image"
                            />
                            <div>
                              <Heading as="h4">{enquiry?.name}</Heading>

                              <Dropdown
                                placement="bottomCenter"
                                content={
                                  <ul className="mail-props">
                                    <li>
                                      <span>Email:</span> <span>{enquiry?.email}</span>{' '}
                                    </li>
                                    <li>
                                      <span>Phone:</span> <span>{enquiry?.mobile_number}</span>
                                    </li>
                                    <li>
                                      <span>Date:</span> <span>{moment(enquiry?.createdAt).format('LLL')}</span>
                                    </li>
                                    <li>
                                      <span>Preferred Contact:</span>
                                      {enquiry?.perferred_mode_of_contact.length > 0 &&
                                        enquiry?.perferred_mode_of_contact.map(item => {
                                          return item && <Tag color="magenta">{item}</Tag>;
                                        })}
                                    </li>
                                  </ul>
                                }
                              >
                                <Link to="#">
                                  Personal Info
                                  <FeatherIcon icon="chevron-down" size={14} />
                                </Link>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="message-excerpt">
                            <span>
                              <FeatherIcon icon="mail" />
                            </span>

                            <span> {moment(enquiry?.createdAt).format('LLL')} </span>
                              <a href={`mailto: ${enquiry?.email}`}>
                              <FeatherIcon icon="corner-up-left" />
                              </a>
                            <Link to="#">
                              <FeatherIcon icon="more-vertical" />
                            </Link>
                          </div>
                        </div>

                        <div className="message-body">
                          {/* <span className="welcome-text">Hello Concern,</span> */}
                          <p>{enquiry?.message}</p>
                        </div>

                        <hr />
                      </EnquiryDetails>
                    </Col>
                  </Row>

                  <Row gutter={15}>
                    <Col xs={24}></Col>
                  </Row>
                </Cards>
              </EnquiryDetailsWrapper>
            </Col>
          </Row>
        </EnquiryWrapper>
      </Main>
    </>
  );
};

EnquiryDetail.propTypes = {
  match: propTypes.object,
  history: propTypes.object
};

export default EnquiryDetail;
