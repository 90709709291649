import actions from './action';

const initialState = {
  parentCategories: [],
  categories: [],
  subCategories: [],
  categoriesLoading: false,
  error: null,
  allCategories: []
};

const {
  CATEGORIES_BEGIN,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_SUCCESS,
  ADD_CATEGORIES_SUCCESS,
  EDIT_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_SUCCESS,
  CATEGORIES_ERR,
  GET_CATEGORY_SUBCATEGORY_SUCCESS,
} = actions;

const categoriesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CATEGORIES_BEGIN:
      return {
        ...state,
        categoriesLoading: true
      };
    case GET_PARENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        parentCategories: data,
        categoriesLoading: false
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: data,
        categoriesLoading: false
      };
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: data,
        categoriesLoading: false
      };
    case GET_CATEGORY_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        allCategories: data,
        categoriesLoading: false
      };
    case CATEGORIES_ERR:
      return {
        ...state,
        error: err,
        categoriesLoading: false
      };
    default:
      return state;
  }
};

export default categoriesReducer;
