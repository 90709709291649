import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Popconfirm, Select } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { getOneBuyer, addAssigner } from '../../redux/users/actionCreator';
import { getOrderBuyer } from '../../redux/orders/actionCreator';
import UserCardsBuyer from './overview/UserCardsBuyer';
import UserBioBuyer from './overview/UserBioBuyer';
import UserRevenue from '../../routes/UserRevenue/UserRevenueBuyer';
import OverviewCardBuyerMonth from '../../components/OverviewCardBuyerMonth';
import OverviewCardBuyerWeek from '../../components/OverviewCardBuyerWeek';
import UserProfileTabs from './overview/UserProfileTabs';
import { getSalesScoutList } from '../../redux/admin_user/actionCreator';

const ViewBuyer = () => {
  const dispatch = useDispatch();
  const [userRevenue, setUserRevenue] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [loader, setLoader] = useState(true);
  const [isAssignToConfirmOpen, setIsAssignToConfirmOpen] = useState(false);
  const [assignTo, setAssignTo] = useState('');
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);

  const { id } = useParams();

  const { sales, orderDetails, buyer, _isLoggedIn } = useSelector(state => {
    return {
      sales: state.admin_user.sales,
      orderDetails: state.order.order,
      buyer: state.users.buyer,
      _isLoggedIn: state.auth.login ? state.auth.login : null
    };
  });

  const salesOptions = useMemo(() => {
    return sales?.map(a => ({
      label: a.email,
      value: a._id
    }));
  }, [sales]);

  useEffect(() => {
    if (typeof _isLoggedIn === 'string') {
      setIsLoggedIn(JSON.parse(_isLoggedIn));
    } else {
      setIsLoggedIn(_isLoggedIn);
    }
  }, [_isLoggedIn]);

  const onAssignToChange = _assignTo => {
    setIsAssignToConfirmOpen(true);
    setAssignTo(_assignTo);
  };

  const onAssignToConfirm = e => {
    dispatch(
      addAssigner({
        id,
        assignedTo: assignTo
      })
    );
    setIsAssignToConfirmOpen(false);
  };

  const onAssignToConfirmCancel = e => {
    setIsAssignToConfirmOpen(false);
    setAssignTo(buyer?.assignedTo);
  };

  useEffect(() => {
    setAssignTo(buyer?.assignedTo);
  }, [buyer]);

  useEffect(() => {
    const { role } = isLoggedIn || {};
    role === 'admin' && dispatch(getSalesScoutList());
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(getOneBuyer(id));
  }, [dispatch]);

  useEffect(() => {
    getRevenueAdmin();
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getOrderBuyer(id, page, perPage));
  }, [page, perPage])

  const getRevenueAdmin = async () => {
    const result = await axios.get(`/api/order/get-total-purchaes?buyer_id=${id}`);
    setUserRevenue(result.data);
    if (result.data) {
      setLoader(false);
    }
  };

  return (
    <>
      <PageHeader
        ghost
        title={<>
          {`Buyer ID: ${buyer?.ecvId}`}
          {buyer && buyer?.block_detail && buyer?.block_detail?.map((details) => {
            return <p style={{ fontSize: "14px", fontWeight: "normal", margin: 0, textTransform: "lowercase" }}>
              <span style={{ textTransform: "capitalize" }}>{buyer?.name}</span> was blocked by
              <span style={{ fontWeight: "bold" }}> {details?.blockedBy?.name && details?.blockedBy?.name}</span>
              {" "} at {" "}
              <span style={{ fontWeight: "bold" }}> {details?.createdAt && moment(details?.createdAt).format('YYYY-MM-DD hh:mm:ss')}</span>
              {" "} for reason: {" "}
              <span style={{ fontWeight: "bold" }}> {details?.remarks && details?.remarks}</span>

            </p>
          })}
        </>}
        buttons={[
          <div key="1" className="page-header-actions">
            {isLoggedIn?.role === 'admin' && (
              <Popconfirm
                title={'Are you sure to assign ?'}
                onConfirm={onAssignToConfirm}
                onCancel={onAssignToConfirmCancel}
                visible={isAssignToConfirmOpen}
                okText="Assign"
                cancelText="Cancel"
              >
                <Select
                  value={assignTo}
                  showSearch
                  size="small"
                  style={{ width: 225, marginTop: '20px' }}
                  placeholder="Please select"
                  onChange={onAssignToChange}
                  options={salesOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                  }
                />
              </Popconfirm>
            )}
          </div>
        ]}
      />
      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <UserCardsBuyer data={buyer} orderDetails={orderDetails} userdata={buyer} />

            <UserBioBuyer data={buyer} />

          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <Row style={{ justifyContent: 'space-between', display: 'flex' }}>
              <Col xxl={12} lg={12} md={12} xs={24}>
                <OverviewCardBuyerWeek userRevenue={userRevenue} loader={loader} />
              </Col>
              <Col xxl={11} lg={11} md={11} xs={24}>
                <OverviewCardBuyerMonth userRevenue={userRevenue} loader={loader} />
              </Col>
            </Row>
            {/* <UserChart/> */}
            {/* <LineChart /> */}
            <UserRevenue role={buyer?.role} id={id} loader={loader} />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <UserProfileTabs orderDetais={orderDetails} userData={buyer} setPage={setPage} setPerPage={setPerPage} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ViewBuyer;
