const actions = {
  COLLECTION_BEGIN: 'COLLECTION_BEGIN',
  GET_COLLECTION_SUCCESS: 'GET_COLLECTION_SUCCESS',
  ADD_COLLECTION_SUCCESS: 'ADD_COLLECTION_SUCCESS',
  COLLECTION_ERR: 'COLLECTION_ERR',
  EDIT_COLLECTION_SUCCESS: 'EDIT_COLLECTION_SUCCESS',
  DELETE_COLLECTION_SUCCESS: 'DELETE_COLLECTION_SUCCESS',
  CHANGE_COLLECTION_POSITION: 'CHANGE_COLLECTION_POSITION',

  collectionBegin: () => {
    return {
      type: actions.COLLECTION_BEGIN
    };
  },
  getCollectionSuccess: data => {
    return {
      type: actions.GET_COLLECTION_SUCCESS,
      data
    };
  },
  addCollectionSuccess: _ => {
    return {
      type: actions.ADD_COLLECTION_SUCCESS
    };
  },
  editCollectionSuccess: _ => {
    return {
      type: actions.EDIT_COLLECTION_SUCCESS
    };
  },
  deleteCollectionSuccess: _ => {
    return {
      type: actions.DELETE_COLLECTION_SUCCESS
    };
  },
  collectionErr: err => {
    return {
      type: actions.COLLECTION_ERR,
      err
    };
  },
  changeCollectionPosition: data => {
    return {
      type: actions.CHANGE_COLLECTION_POSITION,
      data
    }
  }
};

export default actions;
