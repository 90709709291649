import { de } from 'date-fns/locale';
import actions from './action';

const initialState = {
  couponData: [],
  total: 0,
  userCoupons: [],
  couponLoading: false,
  error: null
};

const {
  COUPON_BEGIN,
  GET_COUPON_SUCCESS,
  GET_USER_COUPON_SUCCESS,
  ADD_COUPON_SUCCESS,
  EDIT_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  COUPON_ERR
} = actions;

const couponReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COUPON_BEGIN:
      return {
        ...state,
        couponLoading: true
      };
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        couponData: data.coupons,
        total: data.total,
        couponLoading: false
      };
    case GET_USER_COUPON_SUCCESS:
      return {
        ...state,
        userCoupons: data
      };

    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        couponData: [...state.couponData, data],
        couponLoading: false
      };

    case EDIT_COUPON_SUCCESS:
      return {
        ...state,
        couponLoading: false,
        couponData: state.couponData.map(coupon => (coupon._id === data._id ? data : coupon))
      };

    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        couponLoading: false,
        couponData: [...state.couponData].filter(coupon => coupon._id !== data)
      };

    case COUPON_ERR:
      return {
        ...state,
        error: err,
        couponLoading: false
      };
    default:
      return state;
  }
};

export default couponReducer;
