import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import { Row, Col, Input } from 'antd';
import DeliveriesTable from './deliveriesTable';
import { getOrdersList } from '../../redux/orders/actionCreator';
import { SearchOutlined } from '@ant-design/icons';
import { AutoCompleteStyled } from '../../components/autoComplete/style';

const index = () => {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [deliveredList, setDeliveredList] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);

  const dispatch = useDispatch();

  const deliveriesDetails = useSelector(state => state.order.orders);
  const loader = useSelector(state => state.order.loading);

  React.useEffect(() => {
    dispatch(getOrdersList());
  }, [dispatch]);

  React.useEffect(() => {
    const getDeliveryList = deliveriesDetails.filter(deliveryList => deliveryList?.status === 'Out for Delivery');
    setFilteredData(getDeliveryList);
  }, [deliveriesDetails]);

  const handleSearch = e => {
    setSearchKeyword(e.target.value);
    const filter = deliveredList.filter(obj => obj.seller?.name.toLowerCase().indexOf(searchKeyword) > -1);
    setFilteredData(filter);
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Delivery Records"
          subTitle={
            <>
              <span className="title-counter">{filteredData && filteredData?.length} Found</span>
              <AutoCompleteStyled>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  onChange={handleSearch}
                  value={searchKeyword}
                />
              </AutoCompleteStyled>
            </>
          }
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <DeliveriesTable deliveryList={filteredData} loading={loader} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default index;
