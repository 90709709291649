import actions from './actions';

const initialState = {
    carts: [],
    totalCount: 0,
    isCartLoading: false
}

const {
    GET_ALL_CART_BEGIN,
    GET_ALL_CART_SUCCESS,
    GET_ALL_CART_ERROR,
    DELETE_CART_SUCCESS
} = actions;

const cartReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case GET_ALL_CART_BEGIN:
            return {
                ...state,
                isCartLoading: true
            }
        case GET_ALL_CART_SUCCESS:
            return {
                ...state,
                isCartLoading: false,
                carts: data?.carts,
                totalCount: data?.totalCount
            }
        case GET_ALL_CART_ERROR:
            return {
                ...state,
                isCartLoading: false
            }
        case DELETE_CART_SUCCESS:
            return {
                ...state,
                isCartLoading: false
            }
        default:
            return state;
    }
}

export default cartReducer;