import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Spin, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { uploadFileToAssets } from '../../utility/apiUtils';
import { Button } from '../buttons/buttons';

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const RfqUpdate = ({ showmodal, onClose, user_id, rfq_id, getDetails, rfqExcelFile }) => {
  const [file, setOrgFile] = useState({});
  const [loader, setLoader] = useState(false);

  const props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setOrgFile(info.fileList[0]);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const handleSubmit = async () => {
    try {
      setLoader(true);
      if (isEmpty(file)) {
        message.error('Please upload file!');
        setLoader(false);
      } else {
        const quotationFile = await uploadFileToAssets({
          file: file?.originFileObj,
          type: "uploads"
        });

        const formData = {
          file_id: quotationFile?._id,
          buyer: user_id
        }

        axios
          .patch(
            "/api/products/send-quotation?rfq_id=" + rfq_id,
            formData
          ).then((res) => {
            onClose();
            message.success('You have Updated response quotations successfully!');
            setLoader(false);
            getDetails()
          })
          .catch(err => {
            message.error(err?.response?.data?.message);
            // message.error('Failed to upload file!');
            setLoader(false);
          });
      }
    } catch (error) {
      setLoader(false);
      message.error(error?.message || "Error while uploading the file.")
    }
  };
  const closeModal = () => {
    onClose()
  }

  return (
    <>
      <Modal
        title={
          <div>
            Please select excel file {' '}
            {/* <a download="buyparts24_rfq_template" href={require(`../../static/files/buyparts24_rfq_template.xlsx`)}>
              Download our template
            </a> */}
          </div>
        }
        visible={showmodal}
        onCancel={closeModal}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              key="submit"
              type="primary"
              outlined={true}
              transparented={true} >
              <a href={`${rfqExcelFile}`} download>
                Download RFQ as Excel
              </a>
              {/* <a download="buyparts24_rfq_template" href={require(`../../static/files/buyparts24_rfq_template.xlsx`)}>
                Download RFQ as Excel
              </a> */}
            </Button>,
            <div>
              <Button key="back" onClick={closeModal}>
                Cancel
              </Button>,
              <Button key="submit" disabled={loader} onClick={handleSubmit} type="primary">
                {loader && <Spin style={{ color: '#fff', fontSize: 18 }} />}<UploadOutlined /> Upload
              </Button></div>
          </div>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Upload
            {...props}
            beforeUpload={() => {
              return false;
            }}
            accept=".xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            <Button>Select file</Button>
          </Upload>
        </div>
      </Modal>
    </>
  );
};
export default RfqUpdate;
