const actions = {
  NEWSLETTERSUB_BEGIN: 'NEWSLETTERSUB_BEGIN',
  GET_NEWSLETTERSUB_SUCCESS: 'GET_NEWSLETTERSUB_SUCCESS',
  DELETE_NEWSLETTERSUB_SUCCESS: 'DELETE_NEWSLETTERSUB_SUCCESS',
  NEWSLETTERSUB_ERR: 'NEWSLETTERSUB_ERR',
  newsletterSubBegin: () => {
    return {
      type: actions.NEWSLETTERSUB_BEGIN
    };
  },
  getNewsletterSubSuccess: data => {
    return {
      type: actions.GET_NEWSLETTERSUB_SUCCESS,
      data
    };
  },
  deleteNewsletterSubsSuccess: data => {
    return {
      type: actions.DELETE_NEWSLETTERSUB_SUCCESS,
      data
    };
  },
  newsletterSubErr: err => {
    return {
      type: actions.NEWSLETTERSUB_ERR,
      err
    };
  }
};

export default actions;
