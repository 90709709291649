import actions from './actions';
const {
  GET_BULKUPLOADFILES,
  GET_BULKUPLOADED_LISTING_BEGIN,
  GET_BULKUPLOADED_LISTING,
  GET_LISTINGS_BEGIN,
  GET_LISTINGS_COUNT_BEGIN,
  GET_LISTINGS_SUCCESS,
  GET_LISTINGS_COUNT_SUCCESS,
  GET_LISTINGS_PAGINATION_SUCCESS,
  EMPTY_LISTINGS_PAGINATION_SUCCESS,
  GET_LISTINGS_ERR,

  GET_LISTING_BEGIN,
  GET_LISTING_SUCCESS,
  GET_LISTING_ERR,

  GET_ALL_BRANDS_BEGIN,
  GET_ALL_BRANDS_SUCCESS,
  GET_ALL_BRANDS_ERR,

  SORTING_LISTING_BEGIN,
  SORTING_LISTING_SUCCESS,
  SORTING_LISTING_ERR,

  FILTER_LISTING_BEGIN,
  FILTER_LISTING_SUCCESS,
  FILTER_LISTING_ERR,

  SINGLE_PRODUCT_BEGIN,
  SINGLE_PRODUCT_SUCCESS,
  SINGLE_PRODUCT_ERR,

  GET_ALL_CATEGORIES_BEGIN,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERR,

  GET_SUB_CATEGORIES_BEGIN,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERR,

  GET_ALL_CAR_MAKES_BEGIN,
  GET_ALL_CAR_MAKES_SUCCESS,
  GET_ALL_CAR_MAKES_ERR,

  GET_CAR_MODELS_BEGIN,
  GET_CAR_MODELS_SUCCESS,
  GET_CAR_MODELS_ERR,

  CREATE_LISTING_BEGIN,
  CREATE_LISTING_SUCCESS,
  CREATE_LISTING_ERR,

  RESET_LISTING_FLAGS,
  GET_MY_LISTINGS_SUCCESS,
  GET_SINGLE_LISTINGS_SUCCESS,
  GET_REVIEW_SUCCESS
} = actions;

const initialStateFilter = {
  data: [],
  bulkListing: [],
  bulkListingFiles: [],
  filteredData: [],
  brandList: [],
  categories: [],
  subcategories: [],
  carMakes: [],
  carModels: [],
  listing: [],
  listingCount: 0,
  isListingCreated: false,
  loading: false,
  isListLoading: false,
  error: null,
  catName: {},
  listingsFromPagination: {},
  isLoadingCount: false,
  count: 0,
  singleListing: [],
  reviewdata: []
};

const initialState = {
  data: [],
  loading: false,
  error: null,
  bulkFile: null
};

const listingReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case 'SAVE_BULK_FILE':
      return {
        ...state,
        bulkFile: data
      };
    case GET_BULKUPLOADED_LISTING_BEGIN:
      return {
        ...state,
        bulkListing: [],
        isListLoading: true
      };
    case GET_BULKUPLOADED_LISTING:
      return {
        ...state,
        bulkListing: data,
        isListLoading: false
      };
    case GET_BULKUPLOADFILES:
      return {
        ...state,
        bulkListingFiles: data
      };
    case GET_LISTINGS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_LISTINGS_COUNT_BEGIN:
      return {
        ...state,
        isLoadingCount: true
      };
    case GET_LISTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
        filteredData: data
      };

    case GET_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewdata: data
      };
    case GET_SINGLE_LISTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        singleListing: data
      };
    case GET_LISTINGS_COUNT_SUCCESS:
      return {
        ...state,
        isLoadingCount: false,
        count: data
      };
    case GET_LISTINGS_PAGINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        listingsFromPagination: {
          ...state.listingsFromPagination,
          [data?.page]: data?.value?.listings
        },
        count: data?.value?.total
      };
    case EMPTY_LISTINGS_PAGINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        listingsFromPagination: {}
      };
    case GET_LISTINGS_ERR:
      return {
        ...state,
        loading: false,
        listingCount: 0,
        error: err
      };
    case GET_LISTING_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        listing: data
      };
    case GET_MY_LISTINGS_SUCCESS:
      return {
        ...state,
        listing: data?.listings,
        listingCount: data?.total
      };
    case GET_LISTING_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case GET_ALL_BRANDS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ALL_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        brandList: data
      };
    case GET_ALL_BRANDS_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case SORTING_LISTING_BEGIN:
      return {
        ...state,
        loading: true
      };
    case SORTING_LISTING_SUCCESS:
      return {
        ...state,
        filteredData: data,
        loading: false
      };
    case SORTING_LISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false
      };

    case FILTER_LISTING_BEGIN:
      return {
        ...state,
        loading: true
      };
    case FILTER_LISTING_SUCCESS:
      return {
        ...state,
        filteredData: data,
        loading: false
      };
    case FILTER_LISTING_ERR:
      return {
        ...state,
        error: err,
        filteredData: [],
        loading: false
      };
    case GET_ALL_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: data
      };
    case GET_ALL_CATEGORIES_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case GET_SUB_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        subcategories: data
      };
    case GET_SUB_CATEGORIES_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case GET_ALL_CAR_MAKES_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ALL_CAR_MAKES_SUCCESS:
      return {
        ...state,
        loading: false,
        carMakes: data
      };
    case GET_ALL_CAR_MAKES_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case GET_CAR_MODELS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_CAR_MODELS_SUCCESS:
      return {
        ...state,
        loading: false,
        carModels: data
      };
    case GET_CAR_MODELS_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case GET_ALL_CAR_MAKES_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case CREATE_LISTING_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isListingCreated: true
      };
    case CREATE_LISTING_ERR:
      return {
        ...state,
        loading: false,
        error: err
      };
    case RESET_LISTING_FLAGS:
      return {
        ...state,
        loading: false,
        error: '',
        isListingCreated: false
      };

    default:
      return state;
  }
};

const SingleListingReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true
      };
    case SINGLE_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false
      };
    case SINGLE_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false
      };

    default:
      return state;
  }
};

export { SingleListingReducer, listingReducer };
