const actions = {
  GET_REVIEWS_LIST_BEGIN: 'GET_REVIEWS_LIST_BEGIN',
  GET_REVIEWS_LIST_SUCCESS: 'GET_REVIEWS_LIST_SUCCESS',
  GET_REVIEWS_LIST_ERR: 'GET_REVIEWS_LIST_ERR',

  getReviewsListBegin: () => {
    return {
      type: actions.GET_REVIEWS_LIST_BEGIN,
    };
  },

  getReviewsListSuccess: data => {
    return {
      type: actions.GET_REVIEWS_LIST_SUCCESS,
      data,
    };
  },

  getReviewsListErr: err => {
    return {
      type: actions.GET_REVIEWS_LIST_ERR,
      err,
    };
  },
};

export default actions;
