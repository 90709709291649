import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Listing = lazy(() => import('../../container/listing/Listings'));
const ListingEdit = lazy(() => import('../../container/listing/EditListingForm'));
const ListingAdd = lazy(() => import('../../container/listing/AddListingForm'));
const BulkListing = lazy(() => import('../../container/bulkuploadedlisting'));
const BulkListDetail = lazy(()=> import('../../container/bulkuploadedlisting/Details'))
const ProductDetail = lazy(()=> import('../../container/pages/ProductDetail'))

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/view-bulk-detail/:id`} component={BulkListDetail} />
      <Route path={`${path}/bulklistings`} component={BulkListing} />
      <Route path={`${path}/edit/:id`} component={ListingEdit} />
      <Route path={`${path}/add`} component={ListingAdd} />
      <Route path={`${path}/view-product-details/:id`} component={ProductDetail} />
      <Route path={`${path}`} component={Listing} />

    </Switch>
  );
};

export default PagesRoute;
