import actions from './actions';

const initialState = {
  adminUsers: [],
  adminUserCount: 0,
  page: 1,
  limit: 10,
  sales: [],
  deliveries: [],
  merchandiser: [],
  finance: [],
  staff:[],
  gallega: [],
  salesManagers: [],
  marketingManagers: [],
  gallegaEmails: [],
  gallegaEmailCount: 0,
  isUserDeleted: false,
  loading: false,
  error: null,
  isUserUpdated: false,
  searchResults: []
};

const {
  GET_ADMIN_USER_LIST_BEGIN,
  GET_ADMIN_USER_LIST_SUCCESS,
  GET_ADMIN_USER_LIST_ERR,

  ADMIN_SEARCH_BEGIN,
  ADMIN_SEARCH_SUCCESS,
  ADMIN_SEARCH_ERROR,
  
  GET_SALES_SCOUT_LIST_BEGIN,
  GET_SALES_SCOUT_LIST_SUCCESS,
  GET_SALES_SCOUT_LIST_ERR,

  GET_STAFF_LIST_BEGIN,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_LIST_ERR,

  GET_MERCHANDISER_LIST_BEGIN,
  GET_MERCHANDISER_LIST_SUCCESS,
  GET_MERCHANDISER_LIST_ERR,

  GET_FINANCE_LIST_BEGIN,
  GET_FINANCE_LIST_SUCCESS,
  GET_FINANCE_LIST_ERR,

  GET_GALLEGA_LIST_BEGIN,
  GET_GALLEGA_LIST_SUCCESS,
  GET_GALLEGA_LIST_ERR,

  GET_GALLEGA_EMAILS_LIST_BEGIN,
  GET_GALLEGA_EMAILS_LIST_SUCCESS,
  GET_GALLEGA_EMAILS_LIST_ERR,

  GET_DELIVERY_SCOUT_LIST_BEGIN,
  GET_DELIVERY_SCOUT_LIST_SUCCESS,
  GET_DELIVERY_SCOUT_LIST_ERR,

  GET_SALES_MANAGER_LIST_BEGIN,
  GET_SALES_MANAGER_LIST_SUCCESS,
  GET_SALES_MANAGER_LIST_ERR,

  GET_MARKETING_MANAGER_LIST_BEGIN,
  GET_MARKETING_MANAGER_LIST_SUCCESS,
  GET_MARKETING_MANAGER_LIST_ERR,

  UPDATE_USER_STATUS_BEGIN,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERR,

  RESET_FLAGS
} = actions;

const adminReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case 'deleted_user':
      return {
        ...state,
        isUserDeleted: true
      };
    case GET_ADMIN_USER_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ADMIN_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adminUsers: data?.users,
        adminUserCount: data?.totalCount,
        limit: data?.per_page,
        page: data?.page
      };
    case GET_ADMIN_USER_LIST_ERR:
      return {
        ...state,
        loading: false,
        adminUsers: [],
        adminUserCount: 0,
        limit: 10,
        page: 1,
        err: err
      };
    case ADMIN_SEARCH_BEGIN:
      return {
        ...state,
        loading: true
      };
    case ADMIN_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResults: data
      };
    case GET_ADMIN_USER_LIST_ERR:
      return {
        ...state,
        loading: false,
        searchResults: []
      };
    case GET_STAFF_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_STAFF_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        staff: data
      };
    case GET_STAFF_LIST_ERR:
      return {
        ...state,
        loading: false,
        staff: [],
        err: err
      };
    case GET_FINANCE_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_FINANCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        finance: data
      };
    case GET_FINANCE_LIST_ERR:
      return {
        ...state,
        loading: false,
        finance: [],
        err: err
      };
    case GET_MERCHANDISER_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_MERCHANDISER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        merchandiser: data
      };
    case GET_MERCHANDISER_LIST_ERR:
      return {
        ...state,
        loading: false,
        merchandiser: [],
        err: err
      };
    case GET_GALLEGA_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_GALLEGA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        gallega: data
      };
    case GET_GALLEGA_LIST_ERR:
      return {
        ...state,
        loading: false,
        gallega: [],
        err: err
      };

    //
    case GET_GALLEGA_EMAILS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        gallegaEmails: []
      };
    case GET_GALLEGA_EMAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        gallegaEmails: data?.emails,
        gallegaEmailCount: data?.totalCount,
        limit: data?.per_page,
        page: data?.page
      };
    case GET_GALLEGA_EMAILS_LIST_ERR:
      return {
        ...state,
        loading: false,
        gallegaEmails: [],
        gallegaEmailCount: 0,
        limit: 10,
        page: 1,
        err: err
      };
    //

    case GET_SALES_SCOUT_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SALES_SCOUT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sales: data
      };
    case GET_SALES_SCOUT_LIST_ERR:
      return {
        ...state,
        loading: false,
        sales: [],
        err: err
      };
    case GET_DELIVERY_SCOUT_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_DELIVERY_SCOUT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveries: data
      };
    case GET_DELIVERY_SCOUT_LIST_ERR:
      return {
        ...state,
        loading: false,
        deliveries: [],
        err: err
      };

    case GET_SALES_MANAGER_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SALES_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        salesManagers: data
      };
    case GET_SALES_MANAGER_LIST_ERR:
      return {
        ...state,
        loading: false,
        salesManagers: [],
        err: err
      };

    case GET_MARKETING_MANAGER_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_MARKETING_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        marketingManagers: data
      };
    case GET_MARKETING_MANAGER_LIST_ERR:
      return {
        ...state,
        loading: false,
        marketingManagers: [],
        err: err
      };
    case UPDATE_USER_STATUS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserUpdated: true
      };
    case UPDATE_USER_STATUS_ERR:
      return {
        ...state,
        loading: false,
        // deliveries: [],
        err: err
      };
    case RESET_FLAGS:
      return {
        isUserUpdated: false
      };
    default:
      return state;
  }
};

export default adminReducer;
