import actions from './actions';

const initialState = {
  reviews: [],
  loading: false,
  error: null,
};

const { GET_REVIEWS_LIST_BEGIN, GET_REVIEWS_LIST_SUCCESS, GET_REVIEWS_LIST_ERR } = actions;

const reviewsReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_REVIEWS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_REVIEWS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: data,
      };
    case GET_REVIEWS_LIST_ERR:
      return {
        ...state,
        reviews: [],
        loading: false,
        err: err,
      };
    default:
      return state;
  }
};

export default reviewsReducer;
