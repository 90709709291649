import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Rfq = lazy(() => import('../../container/rfq'));
const RfqExcelDetails = lazy(() => import('../../container/rfq/RfqDetails'));
const RfqPDetails = lazy(() => import('../../container/rfq/RfqPDetails'));
const RfqEditDetails = lazy(() => import('../../container/rfq/RfqEdit'));

const RfqRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/view-excel/:id`} component={RfqExcelDetails} />
      <Route path={`${path}/view-rfq-detail/:id`} component={RfqPDetails} />
      <Route path={`${path}/edit-excel/:id`} component={RfqEditDetails} />
      <Route path={`${path}`} component={Rfq} />
    </Switch>
  );
};

export default RfqRoute;
