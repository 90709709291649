import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Row, Col, Skeleton, Button } from 'antd';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { LeftOutlined } from '@ant-design/icons';

const CartTable = lazy(() => import('./CartTable'));

export const getCartLength = (items, value) => {
  let count = 0;
  for (let i = 0; i < items.length; ++i) {
    if (items[i] === value) count++;
  }
  return count;
};

const Cart = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [buyer, setSelectedBuyer] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  const { cart, usseraddress, totalPrice, cartListings, buyerSelectedRedux, buyerAddressSelectedRedux } = useSelector(
    state => {
      return {
        cart: state.order.cart,
        cartListings: state.order.cartListings,
        totalPrice: state.order.totalPrice,
        usseraddress: state.order.usseraddress,
        buyerSelectedRedux: state.order.selectedBuyer,
        buyerAddressSelectedRedux: state.order.selectedAddress
      };
    }
  );

  useEffect(() => {
    if (buyerAddressSelectedRedux) {
      setSelectedAddress(buyerAddressSelectedRedux);
    }
    if (buyerSelectedRedux) {
      setSelectedBuyer(buyerSelectedRedux);
    }
  }, [buyerAddressSelectedRedux, buyerSelectedRedux]);

  return (
    <>
      <PageHeader ghost title="Shopping Cart" />
      <Main>
        <div className="cartWraper">
          <Button
            type="primary"
            icon={<LeftOutlined />}
            size="large"
            style={{ marginBottom: 10 }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <Row gutter={15}>
            <Col md={24}>
              <Cards headless>
                <Row gutter={30}>
                  <Col xxl={17} xs={24}>
                    <Switch>
                      <Suspense
                        fallback={
                          <Cards headless>
                            <Skeleton paragraph={{ rows: 10 }} active />
                          </Cards>
                        }
                      >
                        <CartTable buyer_id={buyer} selectedAddress={selectedAddress} />
                      </Suspense>
                    </Switch>
                  </Col>
                </Row>
              </Cards>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
};

export default Cart;
