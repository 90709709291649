import React from 'react';
import { Col, Skeleton } from 'antd';

import { Cards } from '../cards/frame/cards-frame';
import Heading from '../heading/heading';
import { EChartCard, CardBarChart2 } from '../../container/dashboard/style';
import { ChartjsBarChartTransparent } from '../charts/chartjs';
import { noOfOrdersPerDayOnWeek } from '../../utility/revenue';

const index = ({userRevenue,loader}) => {
  const chartOptions = {
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  };
  return (
    <>
      {/* <Row gutter={25}> */}
      <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
        <Cards headless>
          <EChartCard>
            <div className="card-chunk">
              <CardBarChart2>
                {loader?(
                  <Skeleton active/>
                ):(
                  <>
                    <Heading as="h1">{userRevenue?.thisWeekOrders?.length}</Heading>
                    <span>Orders</span>
                    <p>
                      <span>This Week Order</span>
                    </p>
                  </>
                )}
              </CardBarChart2>
            </div>
            <div className="card-chunk">
              <ChartjsBarChartTransparent
                labels={['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursdaay', 'Friday', 'Saturday']}
                datasets={[
                  {
                    data: noOfOrdersPerDayOnWeek(userRevenue?.thisWeekOrders || []),
                    backgroundColor: '#EFEFFE',
                    hoverBackgroundColor: '#5F63F2',
                    label: 'Orders',
                    barPercentage: 1,
                  },
                ]}
                options={chartOptions}
              />
            </div>
          </EChartCard>
        </Cards>
      </Col>
      {/* </Row> */}
    </>
  );
};

export default index;
