import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Upload, Select, Button, Checkbox, TimePicker, message, Space } from 'antd';
import axios from 'axios';
import { UserOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useHistory, useParams } from 'react-router-dom';
import { AddUser } from '../../listing/Style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import { Main, BasicFormWrapper } from '../../styled';
import { countryList,TIRES_CATEGORY_IDS } from '../../../constants';
import '../../listing/style.css';
import {
  getAllCategories,
  getSubCategories,
  getAllCarMakes,
  getCarModels,
  resetFlags,
  approveBulkList
} from '../../../redux/listing/actionCreator';
import { ProductImage, validateSize } from '../../../utility/utility';
import { uploadFileToAssets } from '../../../utility/apiUtils';

const { Option } = Select;

const AddListingForm = ({ list: listing, onClose, fileId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tireSubCategories, setTireSubCategories] = useState([])
  const [pic, setPic] = useState(null);
  const [picErr, setPicErr] = useState(false);
  const [showTireSubCategories,setShowTireSubCategories] = useState(false);
  const [serviceInfo, setServiceInfo] = useState('');
  const [description, setDescription] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  // const [clickCollect, setClickCollect] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const { categories, subcategories, carMakes, carModels, isListingCreated } = useSelector(state => {
    return {
      categories: state.listings.categories,
      subcategories: state.listings.subcategories,
      carMakes: state.listings.carMakes,
      carModels: state.listings.carModels,
      isListingCreated: state.listings.isListingCreated
    };
  });
  const [form] = Form.useForm();

  const getTireSubCategories = () => {
    axios.get('/api/subcategories/get-tire-subcategories').then(res => {
      setTireSubCategories(res.data.data);
    }
    );
  }

  const clearAll = () => {
    setLoading(false);
    setPic(null);
    setPicErr(false);
    setDescription('');
    setAdditionalNotes('');
    form.resetFields();
  };

  useEffect(() => {
    if (listing?.category) {
      handleCategorySelect(listing.category);
    }
    let desc = listing?.description?.replaceAll('\n', '<br>');
    let additionalNotes = listing?.additionalNotes?.replaceAll('\n', '<br>');
    let isIncludes = TIRES_CATEGORY_IDS.find(s => s === listing?.subCategory?._id);
    if (isIncludes) {
      setShowTireSubCategories(true);                                          
    }
    setDescription(desc || '');
    setAdditionalNotes(additionalNotes || '');
    setServiceInfo(listing?.serviceDescription || '');
  }, [listing]);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllCarMakes());
    getTireSubCategories();

  }, []);

  useEffect(() => {
    if (isListingCreated) {
      dispatch(resetFlags());
    }
  }, [isListingCreated]);

  const handleChangeDescription = value => {
    setDescription(value);
  };
  const handleChangeAdditionalNotes = value => {
    setAdditionalNotes(value);
  };

  const handleSeriviceInfo = value => {
    setServiceInfo(value);
  };


  const handleCategorySelect = value => {
    form.setFieldsValue({
      subCategory: ''
    });
    dispatch(getSubCategories(value));
  };

  const handleCarMakeSelect = value => {
    dispatch(getCarModels(value));
  };

  const handleSubmit = async values => {
    try {
      setLoading(true);
      if(parseInt(values.quantity) > parseInt(values.inventoryStock)){
        throw ({
          message:"Inventory stock should be greater or equals to Quantity Available!"
        })
      }
      let uploadedPic;
      values.description = description;
      values.additionalNotes = additionalNotes;
      values.serviceDescription = serviceInfo
      if (pic) {
        let response = await uploadFileToAssets({
          file: pic,
          type: "listing"
        });
        uploadedPic = response?._id;
      }
      let { makes, models, ...newObj } = values;
  
      newObj = {
        ...newObj,
        makes: makes?.length ? makes : [],
        models: models?.length ? models : []
      };
  
      dispatch(
        approveBulkList(
          {
            ...newObj,
            pic: uploadedPic,
            priceExclVat: values.priceExclVat,
            vatPercent: values.vatPercent,
            quantity: parseInt(values.quantity, 10),
            currency: 'AED',
            list_id: listing?._id,
            user: listing?.user?._id,
            delivery
          },
          setLoading,
          onClose,
          fileId,
          history,
          clearAll
        )
      );
    } catch(error) {
      setLoading(false);
      message.error(error?.message || "Error while approving the bulk listing.")
    }
  };

  useEffect(() => {
    if (listing && carModels.length) {
      let modelIds = listing?.models?.map(modelId => {
        let finedModel = carModels.find(c => {
          return c._id === modelId;
        });
        if (finedModel) return finedModel?._id;
      });
      modelIds = modelIds?.filter(e => e);
      form.setFieldsValue({
        models: modelIds
      });
    }
  }, [listing, carModels]);

  useEffect(() => {
    if (categories?.length && carMakes.length) {
      let category = categories.find(cat => cat._id === listing.category);

      let makesIds = listing?.makes?.map(makeId => {
        let finedMake = carMakes.find(c => {
          return c._id === makeId;
        });
        if (finedMake) return finedMake?._id;
      });
      makesIds = makesIds?.filter(e => e);

      // let makes = carMakes.find(c => c._id === listing?.makes[0]);

      if (makesIds?.length) {
        // handleCarMakeSelect(makes._id);
        handleCarMakeSelect(makesIds);
      }
      category &&
        form.setFieldsValue({
          partName: listing ? listing.partName : '',
          partBrand: listing ? listing.partBrand : '',
          partHSCode: listing ? listing.partHSCode : '',
          partSKU: listing ? listing.partSKU : '',
          category: listing?.category,
          subCategory: listing ? listing.subCategory : '',
          subCategoryTire: listing ? listing.subCategoryTire?._id : '',
          price: listing ? listing.price : null,
          priceExclVat: listing ? listing.priceExclVat : null,
          vatPercent: listing ? listing.vatPercent : null,
          quantity: listing ? listing.quantity : null,
          inventoryStock:listing ? listing.inventoryStock :null,
          // currency:"AED",
          type: listing ? listing.type : '',
          // makes: makes?._id || '',
          makes: makesIds,
          imageURLs: listing ? listing.imageArray.map((img, i) => ({ ['imgurl']: img })) : [],
          fittingPosition: listing ? listing.fittingPosition : '',
          heightDimension: listing ? Number(listing.heightDimension) : '',
          widthDimension: listing ? Number(listing.widthDimension) : '',
          depthDimension: listing ? Number(listing.depthDimension) : '',
          weight: listing ? Number(listing.weight) : null,
          countryOrigin: listing ? listing.countryOrigin : '',
          // clickCollect: listing ? listing.clickCollect : false,
          delivery: listing ? listing.delivery : false,
          // description: listing ? listing.description : '',
          business_model: listing ? listing.business_model : '',
          rimSize: listing ? listing.rimSize : '',
          amp: listing ? listing.amp : '',
          voltage: listing ? listing.voltage : '',
          utog: listing ? listing.utog : '',
          makeYear: listing ? listing.makeYear : '',
        });
    }
  }, [listing, categories, carMakes]);

  const getInitialValues = () => {
    return {
      partName: '',
      partBrand: '',
      partHSCode: '',
      partSKU: '',
      category: '',
      subCategory: '',
      imageURLs: [],
      price: null,
      priceExclVat: null,
      vatPercent: null,
      quantity: null,
      fittingPosition: '',
      heightDimension: '',
      widthDimension: '',
      depthDimension: '',
      weight: '',
      countryOrigin: '',
      clickCollect: false,
      delivery: false,
      business_model: '',
      inventoryStock:null
    };
  };
  let url = pic?.preview || listing?.imageArray?.[0];
  return (
    <>
      <Main>
        <AddUser>
          <div className="user-info-form">
            <BasicFormWrapper>
              <Form
                style={{ width: '100%' }}
                form={form}
                name="info"
                onFinish={handleSubmit}
                initialValues={getInitialValues()}
                layout='vertical'
                onFieldsChange={(changedFields, allFields) => {
                  setShowTireSubCategories(false);                                          
                  if (changedFields[0]?.name[0] === 'subCategory') {
                    let isIncludes = TIRES_CATEGORY_IDS.find(s => s === changedFields[0].value);
                    if (isIncludes) {
                      setShowTireSubCategories(true);                                          
                    }
                  }
            }}
              >
                <Cards
                  title={
                    <>
                      <FeatherIcon icon="user" size={14} /> General Info
                    </>
                  }
                >
                  <Row>
                    <Col xs={12}>
                      <figure className="photo-upload">
                        <ProductImage avatar={url} alt="Listing" width={120} height={120} />
                        <figcaption>
                          <Row>
                            <Col xs={24}>
                              <Upload
                                name="pic"
                                maxCount={1}
                                beforeUpload={file => {
                                  let isLt5M = validateSize(file);
                                  if (!isLt5M) {
                                    return Upload.LIST_IGNORE;
                                  }
                                  const _pic = Object.assign(file, {
                                    preview: URL.createObjectURL(file)
                                  });
                                  setPic(_pic);
                                  setPicErr(false);
                                  return false;
                                }}
                                style={{ width: '100%' }}
                              >
                                <Link className="btn-upload" to="#">
                                  <FeatherIcon icon="camera" size={16} />
                                </Link>
                              </Upload>
                            </Col>

                            <Col xs={24}>
                              <div className="info">
                                {picErr && <span style={{ color: 'red' }}>pic is required</span>}
                                <div style={{ display: 'flex' }}>
                                  {<span style={{ color: 'red' }}>* </span>} &nbsp;{' '}
                                  <Heading as="h4">{pic ? ' Change Photo' : ' Upload Photo'}</Heading>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </figcaption>
                      </figure>
                    </Col>
                    <Col>
                      <Form.List name="imageURLs" width={100}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(field => {
                              let { key, name, fieldKey, ...restField } = field;
                              return (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                  <Form.Item {...restField} name={[name, 'imgurl']} fieldKey={[fieldKey, 'imgurl']}>
                                    <Input placeholder="http://example.com/image.png" />
                                  </Form.Item>
                                  <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                              );
                            })}
                            <Form.Item>
                              <Button disabled={
                                form.getFieldValue("imageURLs")?.length >= 5 ? true : false
                              } type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Image URL
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="partName"
                        label="Part Name"
                        rules={[
                          {
                            message: 'Please input part name',
                            required: true
                          }
                        ]}
                      >
                        <Input placeholder="Part Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="partBrand"
                        rules={[
                          {
                            message: 'Please input part brand',
                            required: true
                          }
                        ]}
                        label="Part Brand"
                      >
                        <Input placeholder="Part Brand" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="category"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: 'Please select category'
                          }
                        ]}
                      >
                        <Select
                          placeholder={'Please select category'}
                          style={{ width: '100%' }}
                          onChange={value => handleCategorySelect(value)}
                        >
                          <Option value="" disabled>
                            Please Select Category
                          </Option>
                          {categories.map((cat, index) => {
                            return (
                              <Option value={cat._id} key={cat._id}>
                                {cat.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="subCategory"
                        label="Sub Category"
                        rules={[
                          {
                            required: true,
                            message: 'Please select sub-category'
                          }
                        ]}
                      >
                        <Select placeholder={'Please select sub-category'} style={{ width: '100%' }}>
                          <Option value="" disabled>
                            Please Select Sub Category
                          </Option>

                          {subcategories.map((cat, index) => {
                            return (
                              <Option value={cat._id} key={cat._id}>
                                {cat.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {showTireSubCategories && <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <Form.Item
                        name="subCategoryTire"
                        label="Sub Category Tire"
                        rules={[
                          {
                            required: true,
                            message: 'Please select type'
                          }
                        ]}
                      >
                        <Select placeholder={'Please select type'} style={{ width: '100%' }} 
                        options={tireSubCategories.filter(s => s.category_id === form.getFieldValue('subCategory')).map(s => ({value:s._id, label:s.name}))}
                        >
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>}
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="partHSCode"
                        rules={[
                          {
                            message: 'Please input part HS code.',
                            required: false
                          }
                        ]}
                        label="Part HS Code"
                      >
                        <Input placeholder="Part HS Code" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="partSKU"
                        rules={[
                          {
                            message: 'Please input part sku.',
                            required: true
                          }
                        ]}
                        label="Part SKU"
                      >
                        <Input placeholder="SKU Share" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <Form.Item
                        name="type"
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: 'Please select type'
                          }
                        ]}
                      >
                        <Select placeholder={'Please select type'} style={{ width: '100%' }}>
                          <Option value="" disabled>
                            Please Select Type
                          </Option>
                          <Option value="Genuine">Genuine</Option>
                          <Option value="Aftermarket">Aftermarket</Option>
                          <Option value="TBA">TBA</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <ReactQuill theme="snow" value={description} onChange={handleChangeDescription} />
                    </Col>
                  </Row>
                </Cards>

                <Cards
                  title={
                    <>
                      <FeatherIcon icon="user" size={14} /> Pricing & Availability (required)
                    </>
                  }
                >
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item
                       name="priceExclVat"
                       rules={[
                         {
                           message: 'Please input price.',
                           required: true
                         }
                       ]}
                       label="Price (Exc. VAT)"
                      >
                        <Input type="number" addonAfter={'AED'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="vatPercent"
                        rules={[
                          {
                            message: 'Please input VAT percent.',
                            required: true
                          }
                        ]}
                        label="VAT Percent"
                      >
                        <Input placeholder="VAT Percent" type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            message: 'Please input quantity.',
                            required: true
                          }
                        ]}
                        label="Quantity Available"
                      >
                        <Input placeholder="Quantity" type="number" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name="inventoryStock"
                        rules={[
                          {
                            message: 'Please input Inventory Stock.',
                            required: true
                          }
                        ]}
                        label="Inventory Stock"
                      >
                        <Input placeholder="Quantity" type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Cards>
                <Cards
                  title={
                    <>
                      <FeatherIcon icon="user" size={14} /> Fitting & Compatibility (optional)
                    </>
                  }
                >
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item label="Make" name="makes">
                        <Select
                          placeholder={'Please select make'}
                          mode="multiple"
                          style={{ width: '100%' }}
                          onChange={value => handleCarMakeSelect(value)}
                        >
                          <Option value="" disabled>
                            Please Select Make
                          </Option>
                          {carMakes.map((make, index) => {
                            return (
                              <Option value={make._id} key={index}>
                                {make.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="models" label="Model">
                        <Select placeholder={'Please select model'} style={{ width: '100%' }} mode="multiple">
                          <Option value="" disabled>
                            Please Select model
                          </Option>
                          {carModels &&
                            carModels.length > 0 &&
                            carModels.map((model, index) => {
                              return (
                                <Option value={model._id} key={index}>
                                  {model.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <Form.Item name="fittingPosition" label="Fitting Position">
                        <Select placeholder={'Please select fitting position'} style={{ width: '100%' }}>
                          <Option value="" disabled>
                            Please Select Fitting Position
                          </Option>
                          <Option value="Front">Front</Option>
                          <Option value="Rear">Rear</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Cards>
                <Cards
                  title={
                    <>
                      <FeatherIcon icon="user" size={14} /> Details (optional)
                    </>
                  }
                >
                  {/* <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <Form.Item name="countryOrigin" label="Country of Origin:">
                        <Select placeholder={'Select Country of Origin'} style={{ width: '100%' }}>
                          <Option value="" disabled>
                            Select Country of Origin
                          </Option>
                          {countryList.map(item => (
                            <Option value={item.label} key={`country_list_${item.code}`}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item name="heightDimension" label="Height Dimension:">
                        <Input placeholder="0" type="number" addonAfter={'MM'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="widthDimension" label="Width Dimension:">
                        <Input placeholder="0" type="number" addonAfter={'MM'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={12}>
                      <Form.Item name="depthDimension" label="Depth Dimension:">
                        <Input placeholder="0" type="number" addonAfter={'MM'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="weight" label="Weight Dimension:">
                        <Input placeholder="0" type="number" addonAfter={'Grams'} />
                      </Form.Item>
                    </Col>
                    {showTireSubCategories && <Col xs={24} md={12}>
                      <Form.Item name="rimSize" label="RIM Size:">
                        <Input placeholder="0" type="number" addonAfter={'RIM'} />
                      </Form.Item>
                    </Col>}
                    <Col xs={24} md={12}>
                      <Form.Item name="amp" label="Amp:">
                        <Input placeholder="0" type="number" addonAfter={'amp/hr'} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="voltage" label="Voltage:">
                        <Input placeholder="0" type="number" addonAfter={'Volt'} />
                      </Form.Item>
                    </Col>
                    {showTireSubCategories && <Col xs={24} md={12}>
                      <Form.Item name="utqg" label="UTQG:">
                        <Input placeholder="0" type="number" addonAfter={'utqg'} />
                      </Form.Item>
                    </Col>}
                    <Col xs={24} md={12}>
                      <Form.Item name="makeYear" label="Make Year:">
                        <Input placeholder="2020" type="number" addonAfter={'YYYY'} />

                        {/* <DatePicker  style={{ width: '100%' }} picker="year"  /> */}
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="countryOrigin" label="Country of Origin:">
                        <Select placeholder={'Select Country of Origin'} style={{ width: '100%' }}>
                          <Option value="" disabled>
                            Select Country of Origin
                          </Option>
                          {countryList.map(item => (
                            <Option value={item.label} key={`country_list_${item.code}`}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <label style={{ fontWeight: 500 }}>Additional Notes:</label>
                      <ReactQuill theme="snow" value={additionalNotes} onChange={handleChangeAdditionalNotes} />
                    </Col>
                  </Row>
                  {showTireSubCategories && <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24} md={24}>
                      <label style={{ fontWeight: 500 }}>Service Information:</label>
                      <ReactQuill theme="snow" value={serviceInfo} onChange={handleSeriviceInfo} />
                    </Col>
                  </Row>}
                </Cards>
                <Cards
                  title={
                    <>
                      <FeatherIcon icon="shopping-cart" size={14} /> Shipping Options
                    </>
                  }
                >
                  <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                    <Col xs={24}>
                      <Form.Item name="business_model" label="Business Model:">
                        <Select placeholder={'Select Business Model'} style={{ width: '100%' }}>
                          <Option value="" disabled>
                            Select business Model
                          </Option>
                          <Option value="Pure Play">Pure Play</Option>
                          <Option value="Consolidation">Consolidation</Option>
                          <Option value="Fulfilment">Fulfilment</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Cards>
                <Form.Item>
                  <Button disabled={loading} className="btn-signin" htmlType="submit" type="primary" size="large">
                    Approve and Update
                  </Button>
                </Form.Item>
              </Form>
            </BasicFormWrapper>
          </div>
        </AddUser>
      </Main>
    </>
  );
};

export default AddListingForm;
