const initState = {
  rooms: [],
  onlineUsers: null,
  typingUsers: [],
  unreadMsgCnt: 0,
  activeRoom: {},
  messages: [],
  loading: false,
  socket:null
}

const ChatReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SAVE_SOCKET_USER_AB':
      return {
        ...state,
        socket:action.data
      }
    case "INITIALIZE_AB":
      return {
        ...state,
        activeRoom: {},
        rooms: action.rooms,
        unreadMsgCnt: action.unreadMsgCnt,
        // onlineUsers: null,
        typingUsers: [],
        messages: [],
        loading: false,
      };
    case "SET_ONLINE_USERS_AB":
      return {
        ...state,
        onlineUsers: action.onlineUsers
      };
    case "SET_MESSAGES_AB":
      return {
        ...state,
        messages: action.messages,
        unreadMsgCnt: state.unreadMsgCnt - action.newReadMsg
      };
    case "ADD_MESSAGE_AB":
      let newMessages = state.messages;
      let obj = {
        room: action.data.roomId,
        message: action.data.message,
        sender: action.data.sender,
        status: "read",
        createdAt: new Date(),
      }
      newMessages.push({...obj});

      return {
        ...state,
        messages: newMessages
      };
    case "UPDATE_ROOMS_AB":
      return {
        ...state,
        rooms: action.data
      }
    case "UPDATE_ACTIVE_ROOM_AB":
      return {
        ...state,
        activeRoom: action.data
      };
    case "RESET_ACTIVE_ROOM_AB":
      return {
        ...state,
        activeRoom: {}
      }
    case "ADD_TYPING_USER_AB":
      const info = action.data;
      let newTypingUsers = state.typingUsers;
      newTypingUsers.push(info);
      return {
        ...state,
        typingUsers: newTypingUsers
      };
    case "REMOVE_TYPING_USER_AB":
      const rInfo = action.data;
      newTypingUsers = state.typingUsers;
      newTypingUsers.filter(function (e) { return e.sender !== rInfo.sender && e.roomId !== rInfo.roomId });
      return {
        ...state,
        typingUsers: newTypingUsers
      };
    case "SET_ACTIVE_ROOM_AB":
      const foundIndex = state.rooms.findIndex(
        (x) => x.roomId === action.roomId
      );
      return {
        ...state,
        activeRoom: state.rooms[foundIndex],
      };
    case "UPDATE_MESSAGES_AB":
      return {
        ...state,
        messages: action.data
      }
    case "INCREASE_UNREAD_MESSAGE_AB":
      return {
        ...state,
        unreadMsgCnt: state.unreadMsgCnt + 1
      }
    default:
      return state;
  }
};
export default ChatReducer;
