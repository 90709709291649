import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import userReducer from './users/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { listingReducer, SingleListingReducer } from './listing/reducers';
import chartContentReducer from './chartContent/reducers';
import dashboardContentReducer from './dashboard/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import orderReducer from './orders/reducers';
import rfqReducer from './rfq/reducers';
import requestReducer from './request/reducers';
import adminReducer from './admin_user/reducers';
import paymentReducer from './payment/reducers';
import newsReducer from './news/reducers';
import chartTotalRevenueReducer from './TotalRevenue/reducer';
import userChartContentReducer from './UserChart/reducer';
import chatRoomReducer from './chat/reducers';
import staffChat from './staffChat/reducers'
import buyerAdminChat from './buyerAdminChat/reducers'
import warehouseReducer from './warehouse/reducer';
import walletReducer from './wallet/reducer';
import couponReducer from './coupon/reducer';
import priceReducer from './price/reducer';

import bannerReducer from './banner/reducers';
import reviewsReducer from './reviews/reducers';
import clockReducer from './clockinout/reducers';
import newsletterSubsReducer from './newsletter/reducers';
import { initialRootState } from './store';
import categoriesReducer from './categories/reducers';
import collectionReducer from './collection/reducers';
import brandReducer from './brand/reducers';
import listingBrandReducer from './listingbrand/reducers';
import makeReducer from './makes/reducers';
import modelReducer from './models/reducers';
import erpLogsReducer from './erpLogs/reducer';
import settingReducer from './settings/reducers';
import cartReducer from './cart/reducers';
import addressReducer from './address/reducers';
import carInspectionReducer from './carInspection/reducers';

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  wallet: walletReducer,
  auth: authReducer,
  ChangeLayoutMode,
  listings: listingReducer,
  listing: SingleListingReducer,
  dsahboardContent: dashboardContentReducer,
  chartContent: chartContentReducer,
  userChartContent: userChartContentReducer,
  totalRevenueContent: chartTotalRevenueReducer,
  email: emailReducer,
  order: orderReducer,
  rfq: rfqReducer,
  request: requestReducer,
  admin_user: adminReducer,
  payment: paymentReducer,
  news: newsReducer,
  reviews: reviewsReducer,
  chatRooms: chatRoomReducer,
  warehouse: warehouseReducer,
  banner: bannerReducer,
  staffChat,
  buyerAdminChat,
  clock: clockReducer,
  newsletter: newsletterSubsReducer,
  categories: categoriesReducer,
  coupon: couponReducer,
  price: priceReducer,
  brand: brandReducer,
  listingBrand: listingBrandReducer,
  make: makeReducer,
  model: modelReducer,
  erpLog: erpLogsReducer,
  collection: collectionReducer,
  settings: settingReducer,
  cart: cartReducer,
  address: addressReducer,
  carInspection: carInspectionReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    return rootReducers(initialRootState, action)
  }

  return rootReducers(state, action)
}

export default rootReducer;
