import actions from './actions';

const initialState = {
  allChatRooms: [],
  chatRoomsLoading: false,
  chatRoomMessage: [],
  chatRoomMessageLoading: false,
  error: null
};

const {
  ALL_CHATROOM_BEGIN,
  ALL_CHATROOM_SUCCESS,
  ALL_CHATROOM_ERR,
  CHATROOM_MESSAGE_BEGIN,
  CHATROOM_MESSAGE_SUCCESS,
  CHATROOM_MESSAGE_ERR
} = actions;

const chatRoomReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ALL_CHATROOM_BEGIN:
      return {
        ...state,
        chatRoomsLoading: true
      };
    case ALL_CHATROOM_SUCCESS:
      return {
        ...state,
        allChatRooms: data,
        chatRoomsLoading: false
      };
    case ALL_CHATROOM_ERR:
      return {
        ...state,
        error: err,
        chatRoomsLoading: false
      };

    case CHATROOM_MESSAGE_BEGIN:
      return {
        ...state,
        chatRoomMessageLoading: true
      };
    case CHATROOM_MESSAGE_SUCCESS:
      return {
        ...state,
        chatRoomMessage: data,
        chatRoomMessageLoading: false
      };
    case CHATROOM_MESSAGE_ERR:
      return {
        ...state,
        error: err,
        chatRoomMessageLoading: false
      };

    default:
      return state;
  }
};

export default chatRoomReducer;
