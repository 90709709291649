import React from 'react';
import Files from './Files';
import { Main, CardToolbox } from '../../styled';
import { PageHeader } from '../../../components/page-headers/page-headers';
import UploadZip from './UploadZip'
import { Row, Col, Button, message, Breadcrumb } from 'antd';
import { LeftSquareFilled } from '@ant-design/icons';
import axios from 'axios';

const review = () => {
  const [files, setFiles] = React.useState([]);
  const [breadcrumbStack, setBreadcrumbStack] = React.useState([])


  React.useEffect(() => {
    if (breadcrumbStack.length > 0) {
      fetchFolder(breadcrumbStack.join('/'))
    } else {
      fetchFolder('all')
    }
  }, [breadcrumbStack]);


  const fetchFolder = async (folder) => {
    try {
      const { data } = await
        axios({
          method: "GET",
          baseURL: `${process.env.REACT_APP_ASSETS_URL}`,
          url: `/api/imageupload/open-folder?folder=${folder}`,
        })
      // axios.get(`${process.env.REACT_APP_ASSETS_URL}/api/imageupload/open-folder?folder=${folder}`);
      setFiles([...data]);
    } catch (error) {
      message.error("Cannot open the folder");
    }
  }

  const getFolders = () => {
    fetchFolder('all')
  }

  const openFolder = async item => {
    let folder = ''
    if (item.type === 'folder') {
      let splittedPath = item.url.split('/')
      let requiredPath = splittedPath.splice(4, splittedPath.length - 1)
      setBreadcrumbStack([...new Set([...breadcrumbStack, ...requiredPath])])
      folder = requiredPath.join('/')
    }
    fetchFolder(folder)
  }

  const goBack = () => {
    let newBreadcrumbStack = [...breadcrumbStack]
    newBreadcrumbStack.pop()
    setBreadcrumbStack(newBreadcrumbStack)
  }

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="SKU Images"
          subTitle={
            <>
              <span className="title-counter"> {files.length} Found</span>
              {/* <AutoCompleteStyled>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  onChange={handleSearch}
                  value={searchKeyword}
                />
              </AutoCompleteStyled> */}
            </>
          }
        />

        <div key="1" className="page-header-actions" style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
          {breadcrumbStack.length ? <Button style={{ height: '124px', marginTop: '-4px', color: '#8a8a8a' }} onClick={() => { goBack() }}>
            <LeftSquareFilled style={{ fontSize: '50px' }} />
          </Button> : null}
          <UploadZip getFolders={getFolders} />
        </div>
      </CardToolbox>

      <Main>
        <Row>
          {breadcrumbStack.length ? <Col style={{ marginLeft: '52px' }} xl={24} md={24} xs={24}>
            <Breadcrumb>
              <Breadcrumb.Item>All</Breadcrumb.Item>
              {
                breadcrumbStack.map((item, i) => {
                  return (
                    <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item>
                  )
                })
              }
            </Breadcrumb>
          </Col> : null}
          <Col md={24}>
            <Files files={files} openFolder={openFolder} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default React.memo(review);
