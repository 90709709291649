import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { HomeOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

import { UserBioBox } from './style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { formatPhoneNumber, getGarageType } from '../../../utility/utility';

const UserBioBuyer = ({ data }) => {
  return (
    <UserBioBox>
      <Cards headless>
        <article className="user-info">
          <h5 className="user-info__title">User Bio</h5>
          <Row>
            {data?.details?.owner_name && (
              <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 600, color: '#656c90' }}>
                    Owner's Name:
                  </Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 500 }}>
                    {data?.details?.owner_name}
                  </Col>
                </Row>
              </Col>
            )}
            {data?.details?.garage_name && (
              <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 600, color: '#656c90' }}>
                    Garage Name:
                  </Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 500 }}>
                    {data?.details?.garage_name}
                  </Col>
                </Row>
              </Col>
            )}
            {data?.details?.garage_type && (
              <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 600, color: '#656c90' }}>
                    Garage Type:
                  </Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 500 }}>
                    {getGarageType(data?.details?.garage_type)}
                  </Col>
                </Row>
              </Col>
            )}
            {data?.details?.emirate && (
              <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 600, color: '#656c90' }}>
                    Garage Emirate:
                  </Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 500 }}>
                    {data?.details?.emirate}
                  </Col>
                </Row>
              </Col>
            )}
            {data?.status && (
              <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 600, color: '#656c90' }}>
                    Garage Status:{' '}
                  </Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{ fontWeight: 500 }}>
                    {data?.status}
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </article>
        <address className="user-info">
          <h5 className="user-info__title">Contact Info</h5>
          <ul className="user-info__contact">
            {
              data?.email &&
              <li>
                <FeatherIcon icon="mail" size={14} /> <span>{data?.email}</span>
              </li>
            }
            <li>
              <FeatherIcon icon="phone" size={14} /> <span>{data?.phone && formatPhoneNumber(data?.phone)}</span>
            </li>
            {
              data?.details?.garage_address &&
              <li>
                <HomeOutlined size={14} style={{ marginBottom: '3px' }} /> <span>{data?.details?.garage_address}</span>
              </li>
            }
          </ul>
        </address>
      </Cards>
    </UserBioBox>
  );
};

export default UserBioBuyer;
