import axios from 'axios';
import actions from './actions';
import { message } from 'antd';

const {
  getAdminUserListBegin,
  getAdminUserListSuccess,
  getAdminUserListError,

  adminUserSearchBegin,
  adminUserSearchSuccess,
  adminUserSearchError,

  getSalesScoutListBegin,
  getSalesScoutListSuccess,
  getSalesScoutListErr,

  getMerchandiserListBegin,
  getMerchandiserListSuccess,
  getMerchandiserListErr,

  getFinanceListBegin,
  getFinanceListSuccess,
  getFinanceListErr,

  getStaffListBegin,
  getStaffListSuccess,
  getStaffListErr,

  getGallegaListBegin,
  getGallegaListSuccess,
  getGallegaListErr,

  getGallegaEmailsListBegin,
  getGallegaEmailsListSuccess,
  getGallegaEmailsListErr,

  getDeliveryScoutListBegin,
  getDeliveryScoutListSuccess,
  getDeliveryScoutListErr,

  getSalesManagerListBegin,
  getSalesManagerListSuccess,
  getSalesManagerListErr,

  getMarketingManagerListBegin,
  getMarketingManagerListSuccess,
  getMarketingManagerListErr,

  updateUserStatusBegin,
  updateUserStatusSuccess,
  updateUserStatusErr,

  resetFlags
} = actions;

const getAdminUserList = ({filterQuery, page = 1, limit = 10}) => {
  return async (dispatch) => {
    try {
      dispatch(getAdminUserListBegin());

      let URL = `/api/admin_users?per_page=${limit}&page=${page}`

      if (filterQuery && Object.keys(filterQuery).length > 0) {
        Object.keys(filterQuery).map((key) => {
          if (filterQuery[key] !== null && filterQuery[key] !== undefined) {
            URL = `${URL}&${key}=${filterQuery[key]}`
          }
        })
      }

      const resp = await axios.get(URL);
      
      dispatch(getAdminUserListSuccess(resp.data));
    } catch (err) {
      message.error(err?.response?.data?.message || `Error while fetching the user.`)
      dispatch(getAdminUserListError(err));
    }
  }
}

const adminUserSearch = (filterQuery = {}) => {
  return async (dispatch) => {
    try {
      dispatch(adminUserSearchBegin());

      let URL = `/api/admin_users?per_page=10&page=1`

      if (filterQuery && Object.keys(filterQuery).length > 0) {
        Object.keys(filterQuery).map((key) => {
          if (filterQuery[key] !== null && filterQuery[key] !== undefined) {
            URL = `${URL}&${key}=${filterQuery[key]}`
          }
        })
      }

      const resp = await axios.get(URL);
      const users = resp?.data?.users ?? [];

      const data = users?.length ? users?.map(a => ({
        label: a?.email,
        value: a?._id
      })) : [];

      dispatch(adminUserSearchSuccess(data));
    } catch (err) {
      console.log(err)
      message.error(err?.response?.data?.message || `Error while fetching the user.`)
      dispatch(adminUserSearchError(err));
    }
  }
}

const getSalesScoutList = () => {
  return async dispatch => {
    try {
      dispatch(getSalesScoutListBegin());
      const resp = await axios.get(`/api/admin_users?role=sales&per_page=all`);
      dispatch(getSalesScoutListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getSalesScoutListErr(err));
    }
  };
};

const getMerchandiserList = () => {
  return async dispatch => {
    try {
      dispatch(getMerchandiserListBegin());
      const resp = await axios.get('/api/admin_users?role=merchandiser&per_page=all');
      dispatch(getMerchandiserListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getMerchandiserListErr(err));
    }
  };
};

const getFinanceList = () => {
  return async dispatch => {
    try {
      dispatch(getFinanceListBegin());
      const resp = await axios.get('/api/admin_users?role=finance&per_page=all');
      dispatch(getFinanceListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getFinanceListErr(err));
    }
  };
};

const getStaffList = (role = '') => {
  return async dispatch => {
    try {
      dispatch(getStaffListBegin());
      const resp = await axios.get(`/api/admin_users?role=${role}&per_page=all`);
      dispatch(getStaffListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getStaffListErr(err));
    }
  };
};

const getGallegaList = () => {
  return async dispatch => {
    try {
      dispatch(getGallegaListBegin());
      const resp = await axios.get('/api/admin_users?role=logistics_admin&per_page=all');
      dispatch(getGallegaListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getGallegaListErr(err));
    }
  };
};

const addGallegaEmail = (values, resetField) => {
  return async dispatch => {
    try {
      await axios.post('/api/admin_users/add-gallega-email', values);
      resetField();
      dispatch(getGallegaEmails());
      message.success('Successfully added email');
    } catch (error) {
      resetField(true);
      message.error(error?.response?.data?.message || "Error while adding new gallega email.")
    }
  }
}

const deleteGallegaEmail = (id) => {
  return async dispatch => {
    try {
      let res = await axios.delete(`/api/admin_users/delete-gallega-email/${id}`);
      dispatch(getGallegaEmails());
      message.success('Successfully added email');
    } catch (error) {
      resetField(true);
      message.error(error?.response?.data?.message || "Error while deleting gallega email.")
    }
  }
}

const getGallegaEmails = (page = 1, limit = 10) => {
  return async dispatch => {
    try {
      dispatch(getGallegaEmailsListBegin());
      const resp = await axios.get(`/api/admin_users/get-gallega-emails?per_page=${limit}&page=${page}`);
      dispatch(getGallegaEmailsListSuccess(resp.data));
    } catch (err) {
      dispatch(getGallegaEmailsListErr(err));
    }
  };
};

const getDeliveryScoutList = () => {
  return async dispatch => {
    try {
      dispatch(getDeliveryScoutListBegin());
      const resp = await axios.get('/api/admin_users?role=delivery&per_page=all');
      dispatch(getDeliveryScoutListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getDeliveryScoutListErr(err));
    }
  };
};

const getSalesManagerList = () => {
  return async dispatch => {
    try {
      dispatch(getSalesManagerListBegin());
      const resp = await axios.get('/api/admin_users?role=sales_manager&per_page=all');
      dispatch(getSalesManagerListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getSalesManagerListErr(err));
    }
  };
};


const getMarketingManagerList = () => {
  return async dispatch => {
    try {
      dispatch(getMarketingManagerListBegin());
      const resp = await axios.get('/api/admin_users?role=marketing_manager&per_page=all');
      dispatch(getMarketingManagerListSuccess(resp.data?.users));
    } catch (err) {
      dispatch(getMarketingManagerListErr(err));
    }
  };
};

const updateUser = payload => {
  return async dispatch => {
    try {
      dispatch(updateUserStatusBegin());
      let formData = new FormData();
      const { _id, details, ...rest } = payload;
      for (const key in rest) {
        formData.append(key, rest[key]);
      }
      const newdetails = JSON.stringify({
        ...details
      });
      formData.append('id', _id);
      formData.append('details', newdetails);

      const resp = await axios.post(`/api/admin_users/update`, formData);
      dispatch(updateUserStatusSuccess(resp));
    } catch (err) {
      dispatch(updateUserStatusErr(err));
    }
  };
};

const deleteUser = (user_id, role = "") => {
  return async dispatch => {
    try {
      await axios.delete(`/api/admin_users/delete-admin-user/${user_id}`);
      dispatch({ type: 'deleted_user' });

      const filterQuery = role ? { role } : {}
      dispatch(getAdminUserList({ filterQuery }))
      return true;
    } catch (error) {
      return false;
    }
  };
};

const resetUserFlags = () => {
  return async dispatch => {
    dispatch(resetFlags());
  };
};

export {
  getAdminUserList,
  adminUserSearch,
  getSalesScoutList,
  getDeliveryScoutList,
  getSalesManagerList,
  getMarketingManagerList,
  updateUser,
  resetUserFlags,
  deleteUser,
  getMerchandiserList,
  getFinanceList,
  getGallegaList,
  getGallegaEmails,
  getStaffList,
  addGallegaEmail,
  deleteGallegaEmail
};
