import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Spin, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { RevenueWrapper } from './style';
import { ChartjsAreaChart } from '../TotalRevenue/chart';
import { customTooltips, chartLinearGradient } from '../../components/utilities/utilities.js';
import { performanceFilterData, performanceGetData } from '../../redux/TotalRevenue/actionCreator';
import { Cards } from '../../../src/components/cards/frame/cards-frame';
import axios from 'axios';
import {
  noOfOrdersPerDayOnWeek,
  noOfOrdersPerMonth,
  noOfOrdersPerYears,
  calcPrevRevenue,
  calcCustomRevenue,
  calcCurrentRevenue,
  noOfOrdersPerRange,
  labelsForRange
} from '../../utility/revenue';
import moment from 'moment-timezone';

const moreContent = (
  <>
    <NavLink to="#">
      <FeatherIcon size={16} icon="printer" />
      <span>Printer</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="book-open" />
      <span>PDF</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="file-text" />
      <span>Google Sheets</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="x" />
      <span>Excel (XLSX)</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="file" />
      <span>CSV</span>
    </NavLink>
  </>
);
const TotalRevenue = ({ title, setLastFiveYearRevenue }) => {
  const dispatch = useDispatch();
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [dateRange, setDateRange] = useState([])
  const { performanceState, preIsLoading } = useSelector(state => {
    return {
      performanceState: state.totalRevenueContent.performanceData,
      preIsLoading: state.totalRevenueContent.perLoading
    };
  });

  const { RangePicker } = DatePicker;

  const [state, setState] = useState({
    revenue: 'year'
  });


  const getRevenueAdmin = async () => {
    const result = await axios.get(`/api/order/get-revenue-admin`);
    setTotalRevenue(result.data);
    setLastFiveYearRevenue(calcCurrentRevenue(result.data, 'year'));

    if (performanceGetData) {
      const chartData = getDataAccordingly(result.data);
      setChartData(chartData);
      dispatch(performanceGetData(chartData));
    }
  };

  useEffect(() => {
    getRevenueAdmin();
  }, [dispatch]);

  const onOk = value => {
  };

  const onChange = async dateString => {
    if (dateString) { 
      setDateRange([...dateString])
      const res = await axios.get(`/api/order/get-revenue-admin?startDate=${dateString[0]}&endDate=${dateString[1]}`);
      setTotalRevenue(res.data);
      
      if (performanceGetData) {
        const chartData = getDataAccordingly(res.data, dateString);
        setChartData(chartData);
        dispatch(performanceGetData(chartData));
        dispatch(performanceFilterData({
          orders: chartData.performance.custom.orders,
          labels: chartData.performance.custom.labels
        }));
      }
    }
  };
  const getDataAccordingly = (totalRevenue,_dateRange=[]) => {
    const thisWeekOrder = noOfOrdersPerDayOnWeek(totalRevenue?.thisWeekOrders || []);
    const previousWeekOrder = noOfOrdersPerDayOnWeek(totalRevenue?.lastWeekOrders || []);
    const thisYearMonthsOrders = noOfOrdersPerMonth(totalRevenue?.thisYearOrders || []);
    const prevYearMonthsOrders = noOfOrdersPerMonth(totalRevenue?.lastYearOrders || []);
    const dateRangeOrders = noOfOrdersPerRange(totalRevenue?.dateRangeOrders || [], _dateRange) ;
    const dateLabels = labelsForRange(_dateRange)
    const yearsOrder = noOfOrdersPerYears(totalRevenue || []);
    return {
      performance: {
        week: {
          orders: [thisWeekOrder, previousWeekOrder],
          labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        },
        month: {
          orders: [thisYearMonthsOrders, prevYearMonthsOrders],
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        year: {
          orders: [yearsOrder],
          labels: [
            moment()
              .subtract(5, 'years')
              .year(),
            moment()
              .subtract(4, 'years')
              .year(),
            moment()
              .subtract(3, 'years')
              .year(),
            moment()
              .subtract(2, 'years')
              .year(),
            moment()
              .subtract(1, 'years')
              .year(),
            moment()
              .subtract(0, 'years')
              .year()
          ]
        },
        custom: {
          orders: [dateRangeOrders],
          labels: [...dateLabels]
        }
      }
    };
  };

  const handleActiveChangeRevenue = value => {
    setState({
      ...state,
      revenue: value
    });
    const { week, month, year } = chartData?.performance || {};
    let filteredData = null;
    if (value === 'week') {
      filteredData = week;
    } else if (value === 'month') {
      filteredData = month;
    } else {
      filteredData = year;
    }
    return dispatch(performanceFilterData(filteredData));
  };

  const performanceDatasetsForYearOnly = performanceState !== null && [
    {
      data: performanceState.orders[0],
      borderColor: '#1D71B8',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#1D71B830',
          end: '#ffffff05'
        }),
      label: 'Last Five Year Sales',
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#1D71B8',
      hoverBorderWidth: 5,
      amount: `AED ${calcCurrentRevenue(totalRevenue, state.revenue)}`,
      amountClass: 'current-amount'
    }
  ];
  const performanceDatasetsForCustomDate = performanceState !== null && [
    {
      data: performanceState.orders[0],
      borderColor: '#1D71B8',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#1D71B830',
          end: '#ffffff05'
        }),
      label: 'custom date range data',
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#1D71B8',
      hoverBorderWidth: 5,
      amount: `AED ${calcCustomRevenue(totalRevenue)}`,
      amountClass: 'current-amount'
    }
  ];
  const performanceDatasetsForOthers = performanceState !== null && [
    {
      data: performanceState.orders[0],
      borderColor: '#1D71B8',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#1D71B830',
          end: '#ffffff05'
        }),
      label: `This ${state.revenue}`,
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#1D71B8',
      hoverBorderWidth: 5,
      amount: `AED ${calcCurrentRevenue(totalRevenue, state.revenue)}`,
      amountClass: 'current-amount'
    },
    {
      data: performanceState.orders[1],
      borderColor: 'rgb(32, 201, 151)',
      borderWidth: 2,
      fill: false,
      backgroundColor: '#00173750',
      label: `Previous ${state.revenue}`,
      // borderDash: [3, 3],
      pointBorderColor: '#fff',
      pointBackgroundColor: 'rgb(32, 201, 151)',
      pointRadius: '0',
      hoverRadius: '5',
      hoverBorderWidth: 3,
      amount: `AED ${calcPrevRevenue(totalRevenue, state.revenue)}`,
      amountClass: 'prev-amount'
    }
  ];

  const performanceDatasets = state.revenue === 'year' ? performanceDatasetsForYearOnly : state.revenue === 'custom' ? performanceDatasetsForCustomDate :  performanceDatasetsForOthers;

  return (
    <RevenueWrapper>
      {performanceState !== null && (
        <Cards
          isbutton={
            <div className="card-nav">
              <ul>
                <li className={state.revenue === 'week' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('week')} to="#">
                    Week
                  </Link>
                </li>
                <li className={state.revenue === 'month' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('month')} to="#">
                    Month
                  </Link>
                </li>
                <li className={state.revenue === 'year' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('year')} to="#">
                    Year
                  </Link>
                </li>
                <li className={state.revenue === 'custom' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('custom')} to="#">
                    <RangePicker
                      style={{ padding: '0 10px', width: '250px' }}
                      onChange={onChange}
                      disabledDate={(current) => {
                        return current && current > moment().endOf('day');
                      }}
                      suffixIcon={null}
                      onOk={onOk}
                      format="MM/DD/YYYY"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          }
          // more={moreContent}
          title={title}
          size="large"
        >
          {preIsLoading ? (
            <div className="sd-spin">
              <Spin />
            </div>
          ) : (
            <div className="performance-lineChart">
              <ul>
                {performanceDatasets &&
                  performanceDatasets.map((item, key) => {
                    return (
                      <li key={key + 1} className="custom-label">
                        <strong className={item.amountClass}>{item.amount}</strong>
                        <div>
                          <span
                            style={{
                              backgroundColor: item.borderColor
                            }}
                          />
                          {item.label}
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <ChartjsAreaChart
                id="performance"
                labels={performanceState.labels?.map(l => String(l))}
                datasets={performanceDatasets}
                options={{
                  maintainAspectRatio: true,
                  elements: {
                    z: 9999
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                      boxWidth: 6,
                      display: false,
                      usePointStyle: true
                    }
                  },
                  hover: {
                    mode: 'index',
                    intersect: false
                  },
                  tooltips: {
                    mode: 'label',
                    intersect: false,
                    backgroundColor: '#ffffff',
                    position: 'average',
                    enabled: false,
                    custom: customTooltips,
                    callbacks: {
                      title() {
                        return `Total Orders`;
                      },
                      label(t, d) {
                        const { yLabel, datasetIndex } = t;
                        return `<span class="chart-data">${yLabel}</span> <span class="data-label"> order</span>`;
                      }
                    }
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: '#e5e9f2',
                          borderDash: [3, 3],
                          zeroLineColor: '#e5e9f2',
                          zeroLineWidth: 1,
                          zeroLineBorderDash: [3, 3]
                        },
                        ticks: {
                          beginAtZero: true,
                          fontSize: 13,
                          fontColor: '#182b49'
                        }
                      }
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          display: true,
                          zeroLineWidth: 2,
                          zeroLineColor: 'transparent',
                          color: 'transparent',
                          z: 1,
                          tickMarkLength: 0
                        },
                        ticks: {
                          padding: 10
                        }
                      }
                    ]
                  }
                }}
                height={window.innerWidth <= 575 ? 200 : 120}
              />
            </div>
          )}
        </Cards>
      )}
    </RevenueWrapper>
  );
};

TotalRevenue.defaultProps = {
  title: 'Total Sales'
};

TotalRevenue.propTypes = {
  title: PropTypes.string
};

export default TotalRevenue;
