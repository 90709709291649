const actions = {
  WALLET_BEGIN: 'WALLET_BEGIN',
  GET_WALLET_SUCCESS: 'GET_WALLET_SUCCESS',
  WALLETLOG_BEGIN: 'WALLETLOG_BEGIN',
  GET_WALLETLOG_SUCCESS: 'GET_WALLETLOG_SUCCESS',
  GET_SINGLE_WALLET_SUCCESS: 'GET_SINGLE_WALLET_SUCCESS',
  ADD_WALLET_SUCCESS: 'WALLET_SUCCESS',
  EDIT_WALLET_SUCCESS: 'EDIT_WALLET_SUCCESS',
  DELETE_WALLET_SUCCESS: 'DELETE_WALLET_SUCCESS',
  WALLET_ERR: 'WALLET_ERR',
  walletLogBegin: () => {
    return {
      type: actions.WALLETLOG_BEGIN
    };
  },
  getWalletLogSuccess: data => {
    return {
      type: actions.GET_WALLETLOG_SUCCESS,
      data
    };
  },

  walletBegin: () => {
    return {
      type: actions.WALLET_BEGIN
    };
  },
  getWalletSuccess: data => {
    return {
      type: actions.GET_WALLET_SUCCESS,
      data
    };
  },

  getSingleWalletSuccess: data => {
    return {
      type: actions.GET_SINGLE_WALLET_SUCCESS,
      data
    };
  },
  addWalletSuccess: data => {
    return {
      type: actions.ADD_WALLET_SUCCESS,
      data
    };
  },
  editWalletSuccess: data => {
    return {
      type: actions.EDIT_WALLET_SUCCESS,
      data
    };
  },
  deleteWalletSuccess: data => {
    return {
      type: actions.DELETE_WALLET_SUCCESS,
      data
    };
  },
  walletErr: err => {
    return {
      type: actions.WALLET_ERR,
      err
    };
  }
};

export default actions;
