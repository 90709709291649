import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Table, Avatar } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { TableWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';

const deliveriesTable = ({ deliveryList, loading }) => {
  const numberSeperator = num => {
    var n = parseFloat(num).toFixed(2);
    n = Math.floor(n);
    var withCommas = Number(n).toLocaleString('en');
    return withCommas;
  };

  const getTotalAmount = amount => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span style={{ fontSize: '10px', marginRight: '6px', color: 'grey', lineHeight: '2.5' }}>
          {amount?.currency}
        </span>
        <span>{numberSeperator(amount?.total_price)}</span>
      </div>
    );
  };

  const getViewContent = id => {
    return (
      <Button className="btn-icon" to="#" shape="circle">
        <NavLink to={`/admin/orders/view/${id}`}>
          <FeatherIcon icon="eye" size={16} />
        </NavLink>
      </Button>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: 'id',
      align: 'center',
      render: deliveryList => deliveryList?.order_id
    },
    {
      title: 'Buyer',
      dataIndex: '',
      align: 'center',
      width: '30%',
      render: deliveryList => (
        <div className="user-info">
          <figure>
            <Avatar src={deliveryList?.user?.avatar} size={64} />
          </figure>
          <figcaption>
            <div className="user-name" style={{ textAlign: 'left' }}>
              {deliveryList?.user?.name}
            </div>
            <div className="user-designation">{deliveryList?.user?.email}</div>
            <div className="user-designation">{deliveryList?.user?.phone}</div>
          </figcaption>
        </div>
      )
    },
    {
      title: 'Created Date',
      dataIndex: '',
      key: 'order_date',
      align: 'center',
      render: deliveryList => (
        <>
          <div>
            {moment(deliveryList?.order_date)
              .tz('Asia/Dubai')
              .format('DD/MM/YYYY')}
          </div>{' '}
          <div>
            {moment(deliveryList?.order_date)
              .tz('Asia/Dubai')
              .format('LTS')}
          </div>{' '}
        </>
      )
    },
    {
      title: 'Price',
      dataIndex: '',
      key: 'total',
      align: 'center',
      render: deliveryList => getTotalAmount(deliveryList)
    },
    {
      title: 'View',
      dataIndex: '',
      key: 'total',
      align: 'center',
      render: deliveryList => getViewContent(deliveryList?._id)
    }
  ];

  return (
    <Cards>
      <TableWrapper>
        <Table
          columns={columns}
          loading={loading}
          dataSource={deliveryList}
          pagination={{
            defaultPageSize: 10,
            total: deliveryList?.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />
      </TableWrapper>
    </Cards>
  );
};

export default deliveriesTable;
