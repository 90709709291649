const actions = {
  PRICE_BEGIN: 'PRICE_BEGIN',
  GET_PRICE_SUCCESS: 'GET_PRICE_SUCCESS',
  ADD_PRICE_SUCCESS: 'PRICE_SUCCESS',
  EDIT_PRICE_SUCCESS: 'EDIT_PRICE_SUCCESS',
  DELETE_PRICE_SUCCESS: 'DELETE_PRICE_SUCCESS',
  PRICE_ERR: 'PRICE_ERR',
  priceBegin: () => {
    return {
      type: actions.PRICE_BEGIN
    };
  },
  getPriceSuccess: data => {
    return {
      type: actions.GET_PRICE_SUCCESS,
      data
    };
  },
  addPriceSuccess: data => {
    return {
      type: actions.ADD_PRICE_SUCCESS,
      data
    };
  },
  editPriceSuccess: data => {
    return {
      type: actions.EDIT_PRICE_SUCCESS,
      data
    };
  },
  deletePriceSuccess: data => {
    return {
      type: actions.DELETE_PRICE_SUCCESS,
    };
  },
  priceErr: err => {
    return {
      type: actions.PRICE_ERR,
      err
    };
  }
};

export default actions;
