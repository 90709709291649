const actions = {
    GET_ALL_CART_BEGIN: "GET_ALL_CART_BEGIN",
    GET_ALL_CART_SUCCESS: "GET_ALL_CART_SUCCESS",
    GET_ALL_CART_ERROR: "GET_ALL_CART_ERROR",
    DELETE_CART_SUCCESS: "DELETE_CART_SUCCESS",

    getAllCartBegin: _ => {
        return {
            type: actions.GET_ALL_CART_BEGIN
        };
    },
    getAllCartSuccess: data => {
        return {
            type: actions.GET_ALL_CART_SUCCESS,
            data
        };
    },
    getAllCartError: _ => {
        return {
            type: actions.GET_ALL_CART_ERROR
        };
    },
    deleteCartSuccess: _ => {
        return {
            type: actions.DELETE_CART_SUCCESS
        };
    }
}

export default actions;
