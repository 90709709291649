const actions = {
  COUPON_BEGIN: 'COUPON_BEGIN',
  GET_COUPON_SUCCESS: 'GET_COUPON_SUCCESS',
  GET_USER_COUPON_SUCCESS: 'GET_USER_COUPON_SUCCESS',
  ADD_COUPON_SUCCESS: 'COUPON_SUCCESS',
  EDIT_COUPON_SUCCESS: 'EDIT_COUPON_SUCCESS',
  DELETE_COUPON_SUCCESS: 'DELETE_COUPON_SUCCESS',
  COUPON_ERR: 'COUPON_ERR',
  couponBegin: () => {
    return {
      type: actions.COUPON_BEGIN
    };
  },
  getCouponSuccess: data => {
    return {
      type: actions.GET_COUPON_SUCCESS,
      data
    };
  },
  getUserCouponSuccess: data => {
    return {
      type: actions.GET_USER_COUPON_SUCCESS,
      data
    };
  },
  addCouponSuccess: data => {
    return {
      type: actions.ADD_COUPON_SUCCESS,
      data
    };
  },
  editCouponSuccess: data => {
    return {
      type: actions.EDIT_COUPON_SUCCESS,
      data
    };
  },
  deleteCouponSuccess: data => {
    return {
      type: actions.DELETE_COUPON_SUCCESS,
    };
  },
  couponErr: err => {
    return {
      type: actions.COUPON_ERR,
      err
    };
  }
};

export default actions;
