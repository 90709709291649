import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Input, Form } from 'antd';
import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateCategory } from '../../redux/categories/actionCreator';
import { uploadFileToAssets } from '../../utility/apiUtils';

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const EditCatModal = ({ parentCategories, showmodal, onClose, modalClose, name, id, pid, pname, imgUrl, code }) => {
  const [loader, setLoader] = React.useState(false);
  const [fileList, setFileList] = useState([]);
  const [imgError, setImgError] = useState(false);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { Option } = Select;

  useEffect(() => {
    let imgName = imgUrl?.split('/').pop();
    form.setFieldsValue({
      name: name || '',
      parent_category: pid || '',
      erpCode: code || '',
      category_upload: imgName
        ? [
          {
            uid: '-1',
            name: imgName,
            status: 'done',
            url: imgName
          }
        ]
        : null
    });
    if (imgName) {
      setFileList([
        {
          uid: '-1',
          name: `${imgName}`,
          status: 'done',
          url: imgUrl,
          thumbUrl: imgUrl
        }
      ]);
    }
  }, [name, pid, imgUrl, code, showmodal]);

  const closeLoader = () => {
    setLoader(false);
  };

  const handleClose = () => {
    setFileList([]);
    onClose();
  };

  const handelModalClose = () => {
    setFileList([]);
    modalClose();
  };

  const handleSubmit = async () => {
    await form.submit()
  };

  const onFinish = async(values) => {
    setLoader(true);
    if (isEmpty(fileList)) {
      message.error('Please upload file!');
      setLoader(false);
    } else {
      try {
        let data = {
          name: values?.name,
          parent_category: values.parent_category,
          erpCode: values.erpCode
        };

        if (fileList?.length) {
          let category_upload = fileList[fileList.length - 1];
          if (!category_upload?.url) {
            category_upload = category_upload?.originFileObj;
            category_upload = await uploadFileToAssets({
              file: category_upload,
              type: "category"
            })
            data['file_id'] = category_upload?._id
          } else {
            data['imgUrl']= category_upload?.url
          }
        }
        dispatch(updateCategory(data, id, handelModalClose, closeLoader));
      } catch (error) {
        setLoader(false);
        message.error(error?.message || "Error while editing category")
      }
    }
  }

  const props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setFileList(info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    let fileTypeCheckErr = false;
    let fileSizeCheckErr = false;
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      fileTypeCheckErr = true;
    }
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.height !== 450 && img.width !== 450) {
        message.error('Please make sure height and width of image is 450px.');
        fileSizeCheckErr = true;
      }
      if (fileTypeCheckErr || fileSizeCheckErr) {
        setImgError(true);
      } else {
        setImgError(false);
      }
    };
    return false;
  };

  return (
    <>
      <Modal
        title={<div>Please select parent category and add new category.</div>}
        open={showmodal}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <Button onClick={handleSubmit} loading={loader} type="primary" disabled={imgError}>
            Update Category
          </Button>
        ]}
      >
        <div style={{ height: 'auto' }}>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            size="default"
            layout={'vertical'}
          >
            <Form.Item
              style={{ width: '100%', display: 'block' }}
              label="Parent Category"
              name={"parent_category"}
              rules={[{ required: true, message: 'Parent Category is required' }]}
            >
              <Select
                placeholder="Choose Parent Category"
                size="large"
                style={{ width: '100%', border: '1px solid #E3E6EF', marginTop: 0 }}
                bordered={false}
              >
                {parentCategories &&
                  parentCategories.map((item, i) => {
                    return (
                      <Option key={i} value={item._id}>
                        {item.category_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Category"
              name={"name"}
              rules={[{ required: true, message: 'Category is required' }]}
            >
              <Input placeholder='Category' />
            </Form.Item>

            <Form.Item
              label="ERP Code"
              name={"erpCode"}
              rules={[{ required: true, message: 'ERP Code is required' }]}
            >
              <Input placeholder='ERP Code' />
            </Form.Item>

            <Form.Item
              label="Category Image"
              name={"category_upload"}
              rules={[{ required: true, message: 'Category Image is required' }]}
            >
              <Upload
                {...props}
                beforeUpload={beforeUpload}
                listType="picture"
                className="upload-list-inline"
                maxCount={1}
                fileList={[...fileList]}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default EditCatModal;
