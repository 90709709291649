import actions from './actions';

const initialState = {
    erpLogs: [],
    erpLogCount: 0,
    isErpLogsLoading: false,
};

const {
    GET_ERP_LOG_BEGIN,
    GET_ERP_LOG_SUCCESS,
    GET_ERP_LOG_ERROR
} = actions;

const erpLogsReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ERP_LOG_BEGIN:
            return {
                ...state,
                isErpLogsLoading: true
            };
        case GET_ERP_LOG_SUCCESS:
            return {
                ...state,
                erpLogs: data?.erpLogs,
                erpLogCount: data?.erpLogCount,
                isErpLogsLoading: false
            };
        case GET_ERP_LOG_ERROR:
            return {
                ...state,
                isErpLogsLoading: false
            };
        default:
            return state;
    }
};

export default erpLogsReducer;
