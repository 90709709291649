import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Orders = lazy(() => import('../../container/OrderDeliveries'));
// const OrderDetails = lazy(() => import('../../container/OrderDeliveires/OrderDetails'));

const OrderDeliveriesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/view/:id`} component={OrderDetails} /> */}
      <Route path={`${path}`} component={Orders} />
    </Switch>
    // <div>this is order route</div>
  );
};

export default OrderDeliveriesRoute;
