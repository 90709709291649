import axios from "axios";
import actions from './actions';

const {
  getRfqPListBegin,
  getRfqPListSuccess,
  getRfqPListError,
  getRfqListBegin,
  getRfqListSuccess,
  getRfqListError,
  getRfqExcelBegin,
  getRfqExcelSuccess,
  getRfqExcelError
} = actions;

const getRfqList = () => {
  return async dispatch => {
    try {
      dispatch(getRfqListBegin());
      const resp = await axios.get("/api/rfq/list-excel");
      dispatch(getRfqListSuccess(resp.data));
    } catch (err) {
      dispatch(getRfqListError(err));
    }
  };
};

const getRfqPList = () => {
  return async dispatch => {
    try {
      dispatch(getRfqPListBegin());
      const resp = await axios.get("/api/quotation/list-rfq");
      dispatch(getRfqPListSuccess(resp.data?.list));
    } catch (err) {
      dispatch(getRfqPListError(err));
    }
  };
};

const getRfqExcel = (id) => {
  return async dispatch => {
    try {
      dispatch(getRfqExcelBegin());
      const resp = await axios.get("/api/rfq/get-excel?_id=" + id);
      dispatch(getRfqExcelSuccess(resp.data));
    } catch (err) {
      dispatch(getRfqExcelError(err));
    }
  }
}


const getRfqP = (id) => {
  return async dispatch => {
    try {
      const resp = await axios.get("/api/products/get-quotation-detail/" + id);
      dispatch({type:'GET_RFQ_P', data: resp.data});
    } catch (err) {
    }
  }
}

export { getRfqList, getRfqExcel,getRfqPList, getRfqP};