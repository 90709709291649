import React from 'react';
import { Table } from 'antd';
import { TableWrapper } from '../../styled';

const reviewTable = ({ reviewDetails, total, setCurrentPage, setPerPage, loader }) => {
  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'name',
      key: 'keyword',
      align: 'start',
      render: name => (
        <div className="user-info">
          <p>
            {name}
          </p>
        </div>
      ),
    },
    {
      title: 'Count',
      dataIndex: 'count',
      align: 'start',
      key: 'part_name',
      render: count => {
        return <div className="user-info">
          <p>
            {count}
          </p>
        </div>
      },
    },
  ];
  return (
    <TableWrapper>
      <Table
        columns={columns}
        loading={loader}
        dataSource={reviewDetails}
        onChange={(e) => {
          setPerPage(e.pageSize)
          setCurrentPage(e.current)
        }
        }
        pagination={{
          defaultPageSize: 50,
          total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </TableWrapper>
  );
};

export default reviewTable;
