import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const CouponList = lazy(() => import('../../container/pages/coupon/'));
const AddCoupon = lazy(() => import('../../container/pages/coupon/AddCoupon'));
const EditCoupon = lazy(() => import('../../container/pages/coupon/EditCoupon'));

const CouponListUser = lazy(() => import('../../container/pages/coupon/User'));
const AddCouponUser = lazy(() => import('../../container/pages/coupon/User/AddCoupon'));


const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={CouponList} />
      <Route exact path={`${path}/add`} component={AddCoupon} />
      <Route exact path={`${path}/user/add/:id`} component={AddCouponUser} />
      <Route exact path={`${path}/edit/:id`} component={EditCoupon} />
      <Route exact path={`${path}/view/:id`} component={CouponListUser} />
    </Switch>
  );
};

export default PagesRoute;
