import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  let { path } = useRouteMatch();
  path = '/admin'
  const [_isLoggedIn, setIsLoggedIn] = useState(null);
  const routePermissions = useSelector(state => state.auth.routePermissions);
  const isLoggedIn = useSelector(state => (state.auth.login ? state.auth.login : null));
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath?.split('/') || [];

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []
  );

  useEffect(() => {
    if (typeof isLoggedIn === 'string') {
      setIsLoggedIn(JSON.parse(isLoggedIn));
    } else {
      setIsLoggedIn(isLoggedIn);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if ((pathName.includes('/admin/rfq') === false)) {
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      localStorage.removeItem("rfqtab");
      localStorage.removeItem("rfqcurrentPage");
      localStorage.removeItem("rfqpageSize");
    }
  }, [pathName])

  const onOpenChange = keys => {
    let newKeys = [keys.length && keys[keys.length - 1]];

    if (keys.length && keys[0] === "settings" && keys[keys.length - 1].includes("settings")) {
      newKeys = keys
    }
    setOpenKeys(newKeys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  const isIncludes = (paths) => paths?.some(path => routePermissions.includes(path));
  return (
    <>
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        theme={darkMode && 'dark'}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
              `${mainPathSplit.length === 1
                ? 'home'
                : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`
            ]
            : []
        }
        defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        openKeys={openKeys}
      >
        {isIncludes(['/']) && <Menu.Item key="home" icon={!topMenu && <FeatherIcon icon="home" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            DASHBOARD
          </NavLink>
        </Menu.Item>}

        {/* user */}
        {isIncludes([
          '/users/buyers', '/users/new-buyers', '/users/add/buyer', '/users/bulkbuyer', '/users/sellers', '/users/add/seller', '/users/bulkseller', '/users/opt'
        ]) && <SubMenu key="user" icon={<FeatherIcon icon="users" />} title="USERS">
            {isIncludes([
              '/users/buyers', '/users/new-buyers', '/users/add/buyer', '/users/bulkbuyer'
            ]) && <Menu.ItemGroup key="g1" title="Buyers">
                {isIncludes(['/users/buyers']) && <Menu.Item key="all-buyer-1">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/buyers`}>
                    All Buyers
                  </NavLink>
                </Menu.Item>}
                {isIncludes(['/users/new-buyers']) && <Menu.Item key="new-buyer">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/new-buyers`}>
                    New Buyers
                  </NavLink>
                </Menu.Item>}
                {isIncludes(['/users/address']) && <Menu.Item key="buyer-address">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/address`}>
                    Buyer Address
                  </NavLink>
                </Menu.Item>}
                {isIncludes(['/users/add/buyer']) && <Menu.Item key="add-buyer">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/add/buyer`}>
                    Add Buyers
                  </NavLink>
                </Menu.Item>}
                {isIncludes(['/users/bulkbuyer']) && <Menu.Item key="add-bulkbuyer">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/bulkbuyer`}>
                    Bulk Add Buyer
                  </NavLink>
                </Menu.Item>}

                {isIncludes(['/users/hidden-customer']) && <Menu.Item key="hidden-customer">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/hidden-customer`}>
                    Hidden Customers
                  </NavLink>
                </Menu.Item>}
              </Menu.ItemGroup>}
            {isIncludes(['/users/sellers', '/users/add/seller', '/users/bulkseller'
            ]) && <Menu.ItemGroup key="g2" title="Sellers">
                {isIncludes(['/users/sellers']) && <Menu.Item key="all-seller">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/sellers`}>
                    All Sellers
                  </NavLink>
                </Menu.Item>}
                {isIncludes(['/users/add/seller']) && <Menu.Item key="add-seller">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/add/seller`}>
                    Add Seller
                  </NavLink>
                </Menu.Item>}
                {isIncludes(['/users/bulkseller']) && <Menu.Item key="add-bulkseller">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/bulkseller`}>
                    Bulk Add Seller
                  </NavLink>
                </Menu.Item>}
              </Menu.ItemGroup>}
            {isIncludes(['/users/opt']) && <Menu.Item key="opt">
              <NavLink onClick={toggleCollapsed} to={`${path}/users/opt`}>
                OPT
              </NavLink>
            </Menu.Item>}
          </SubMenu>}
        {/* user */}

        {/* staff */}
        {
          isIncludes(['/staff', '/staff/add', '/sales-manager', '/sales-manager/add', '/sales', '/sales/add', '/delivery', '/delivery/add', '/merchandiser', '/merchandiser/add', '/marketing', '/marketing/add', '/finance', '/finance/add', '/gallega', '/gallega/add', '/gallega/emails']) &&
          <SubMenu key="staff" icon={<FeatherIcon icon="user-check" />} title="STAFF">
            {isIncludes(['/staff', '/staff/add']) && <Menu.ItemGroup key="all-staff" title="Staffs">
              {isIncludes(['/staff/add']) && <Menu.Item key="1fseg">
                <NavLink onClick={toggleCollapsed} to={`${path}/staff`}>
                  All Staffs
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/staff/add']) && <Menu.Item key="add-staff">
                <NavLink onClick={toggleCollapsed} to={`${path}/staff/add`}>
                  Add Staffs
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}
            {isIncludes(['/sales-manager', '/sales-manager/add']) && <Menu.ItemGroup key="all-sales_manager" title="Sales Manager">
              {isIncludes(['/sales-manager/add']) && <Menu.Item key="1">
                <NavLink onClick={toggleCollapsed} to={`${path}/sales-manager`}>
                  All Sales Manager
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/sales-manager/add']) && <Menu.Item key="add-sales-manager">
                <NavLink onClick={toggleCollapsed} to={`${path}/sales-manager/add`}>
                  Add Sales Manager
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}
            {isIncludes(['/sales', '/sales/add']) && <Menu.ItemGroup key="all_sales_scout" title="Sales Scout">
              {isIncludes(['/sales']) && <Menu.Item key="3">
                <NavLink onClick={toggleCollapsed} to={`${path}/sales`}>
                  All Sales Scout
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/sales/add']) && <Menu.Item key="add-sales-scout">
                <NavLink onClick={toggleCollapsed} to={`${path}/sales/add`}>
                  Add Sales Scout
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}
            {isIncludes(['/delivery', '/delivery/add']) && <Menu.ItemGroup key="alll_delivery_scout" title="Delivery Scout">
              {isIncludes(['/delivery']) && <Menu.Item key="5">
                <NavLink onClick={toggleCollapsed} to={`${path}/delivery`}>
                  All Delivery Scout
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/delivery/add']) && <Menu.Item key="add_delivery_scout">
                <NavLink onClick={toggleCollapsed} to={`${path}/delivery/add`}>
                  Add Delivery Scout
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}
            {isIncludes(['/merchandiser', '/merchandiser/add']) && <Menu.ItemGroup key="all-merchandiser" title="E-merchandiser">
              {isIncludes(['/merchandiser']) && <Menu.Item key="fvsv">
                <NavLink onClick={toggleCollapsed} to={`${path}/merchandiser`}>
                  All Merchandiser
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/merchandiser/add']) && <Menu.Item key="add-merchandiser">
                <NavLink onClick={toggleCollapsed} to={`${path}/merchandiser/add`}>
                  Add Merchandiser
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}

            {isIncludes(['/marketing', '/marketing/add']) && <Menu.ItemGroup key="all-marketing-accounts" title="Marketing Accounts">
              {isIncludes(['/marketing']) && <Menu.Item key="fvsdfsfv">
                <NavLink onClick={toggleCollapsed} to={`${path}/marketing`}>
                  All Marketing Accounts
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/marketing/add']) && <Menu.Item key="add-marketting-accounts">
                <NavLink onClick={toggleCollapsed} to={`${path}/marketing/add`}>
                  Add Marketing Account
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}

            {isIncludes(['/finance', '/finance/add']) && <Menu.ItemGroup key="all-finance-accounts" title="Finance Accounts">
              {isIncludes(['/finance']) && <Menu.Item key="fvssverdfsfv">
                <NavLink onClick={toggleCollapsed} to={`${path}/finance`}>
                  All Finance Accounts
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/finance/add']) && <Menu.Item key="add-finance-account">
                <NavLink onClick={toggleCollapsed} to={`${path}/finance/add`}>
                  Add Finance Account
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}


            {isIncludes(['/gallega', '/gallega/add', '/gallega/emails']) && <Menu.ItemGroup key="all-gallega" title="Gallega">
              {isIncludes(['/gallega']) && <Menu.Item key="fvsvcc">
                <NavLink onClick={toggleCollapsed} to={`${path}/gallega`}>
                  All Gallega
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/gallega/add']) && <Menu.Item key="add-gallega">
                <NavLink onClick={toggleCollapsed} to={`${path}/gallega/add`}>
                  Add Gallega
                </NavLink>
              </Menu.Item>}
              {isIncludes(['/gallega/emails']) && <Menu.Item key="magane-emails">
                <NavLink onClick={toggleCollapsed} to={`${path}/gallega/emails`}>
                  Manage Emails
                </NavLink>
              </Menu.Item>}
            </Menu.ItemGroup>}
          </SubMenu>
        }
        {/* staff */}

        {/* inventory */}
        {isIncludes(['/listings', '/listings/add', '/listings/bulklistings', '/category-mapping', '/brand-mapping', '/makes', '/reviews', '/bulk-image', '/search-keywords', '/price', '/coupon']) && <SubMenu key="inventory" icon={<FeatherIcon icon="truck" />} title="INVENTORY">
          {isIncludes(['/listings']) && <Menu.Item key="all-listings">
            <NavLink onClick={toggleCollapsed} to={`${path}/listings`}>
              All Listings
            </NavLink>
          </Menu.Item>}

          {isIncludes(['/listings/add']) && <Menu.Item key="add-listings">
            <NavLink onClick={toggleCollapsed} to={`${path}/listings/add`}>
              Add Listing
            </NavLink>{' '}
          </Menu.Item>}

          {isIncludes(['/listings/bulklistings']) && <Menu.Item key="bulk-uploaded-listing">
            <NavLink onClick={toggleCollapsed} to={`${path}/listings/bulklistings`}>
              Bulk Uploaded Listing
            </NavLink>{' '}
          </Menu.Item>}

          {isIncludes(['/category-mapping']) && <Menu.Item key="category-mapping">
            <NavLink onClick={toggleCollapsed} to={`${path}/category-mapping`}>
              Category Mapping
            </NavLink>
          </Menu.Item>}

          {isIncludes(['/brand-mapping']) && <Menu.Item key="brand-mapping">
            <NavLink onClick={toggleCollapsed} to={`${path}/brand-mapping`}>
              Brand Mapping
            </NavLink>
          </Menu.Item>}

          {/* {isIncludes(['/makes']) && <Menu.Item key="listing-makes">
            <NavLink onClick={toggleCollapsed} to={`${path}/makes`}>
              Make & Model
            </NavLink>
          </Menu.Item>} */}

          {isIncludes(['/reviews']) && <Menu.Item key="reviews">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/reviews`}>
              Reviews
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/bulk-image']) && <Menu.Item key="sku-images">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/bulk-image`}>
              SKU Images
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/search-keywords']) && <Menu.Item key="keyword-logs">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/search-keywords`}>
              Keyword Logs
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/price']) && <Menu.Item key="price-differential">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/price`}>
              Price Diffential
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/coupon']) && <Menu.ItemGroup key="all-coupons" title="Couponing">
            <Menu.Item key="13">
              {' '}
              <NavLink onClick={toggleCollapsed} to={`${path}/coupon`}>
                All Coupons
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>}
        </SubMenu>}

        {isIncludes(['/collections', '/collections/add']) && <SubMenu key="all-collection" icon={<FeatherIcon icon="copy" />} title="COLLECTION">
          {isIncludes(['/collections']) && <Menu.Item key="collection1">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/collections`}>
              All Collections
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/collections/add']) && <Menu.Item key="add-collection2">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/collections/add`}>
              Add Collection
            </NavLink>
          </Menu.Item>}
        </SubMenu>}

        {/* inventory */}
        {isIncludes(['/wallets']) && <Menu.Item icon={<FeatherIcon icon="dollar-sign" />} key="wallet">
          <NavLink onClick={toggleCollapsed} to={`${path}/wallets`}>
            WALLET
          </NavLink>
        </Menu.Item>}
        {isIncludes(['/roles-permissions']) && <Menu.Item icon={<FeatherIcon icon="user-check" />} key="role-and-permission">
          <NavLink onClick={toggleCollapsed} to={`${path}/roles-permissions`}>
            ROLES & PERMISSIONS
          </NavLink>
        </Menu.Item>}

        {/* ORDER */}
        {isIncludes(['/orders', '/rfq', '/users/request', '/orders/createorder']) && <SubMenu key="order" icon={<FeatherIcon icon="shopping-cart" />} title="ORDER">
          {isIncludes(['/orders']) && <Menu.Item key="orders">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
              Orders
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/rfq']) && <Menu.Item key="rfq">
            {' '}
            <NavLink onClick={toggleCollapsed} to={`${path}/rfq`}>
              RFQ
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/users/request']) && <Menu.Item key="user-request">
            <NavLink onClick={toggleCollapsed} to={`${path}/users/request`}>
              Request
            </NavLink>
          </Menu.Item>}
          {/* {isIncludes(['/orders/createorder']) && <Menu.Item key="14">
            <NavLink onClick={toggleCollapsed} to={`${path}/orders/createorder`}>
              Create Order
            </NavLink>
          </Menu.Item>} */}

          {isIncludes(['/orders/carts', '/orders/createorder']) && <Menu.ItemGroup key="order-all-carts" title="Build A Cart">
            {isIncludes(['/orders/carts']) && <Menu.Item key="order-1-1">
              <NavLink onClick={toggleCollapsed} to={`${path}/orders/carts`}>
                All Carts
              </NavLink>
            </Menu.Item>}
            {isIncludes(['/orders/createorder']) && <Menu.Item key="create-order">
              <NavLink onClick={toggleCollapsed} to={`${path}/orders/createorder`}>
                Create Order
              </NavLink>
            </Menu.Item>}
          </Menu.ItemGroup>}
        </SubMenu>}
        {/* ORDER */}

        {/* Support */}
        {/* exception routes */}
        {isIncludes(['/chats', '/enquiries', '/messages']) && <SubMenu key="chat-and-support" icon={<FeatherIcon icon="message-circle" />} title="SUPPORT">
          {isIncludes(['/chats']) && <Menu.Item key="chat">
            <NavLink onClick={toggleCollapsed} to={`${path}/chats/`}>
              Chat
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/enquiries']) && <Menu.Item key="enquiries">
            <NavLink onClick={toggleCollapsed} to={`${path}/enquiries`}>
              Enquiries
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/messages']) && <Menu.Item key="messages">
            <NavLink onClick={toggleCollapsed} to={`${path}/messages`}>
              Messages
            </NavLink>
          </Menu.Item>}
        </SubMenu>}
        {/* Support */}

        {/* Support */}
        {isIncludes(['/news', '/news/add']) && <SubMenu key="all-news" icon={<FeatherIcon icon="paperclip" />} title="CONTENT">
          {isIncludes(['/news']) && <Menu.Item key="9">
            <NavLink onClick={toggleCollapsed} to={`${path}/news`}>
              All News
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/news/add']) && <Menu.Item key="add-news">
            <NavLink onClick={toggleCollapsed} to={`${path}/news/add`}>
              Add News
            </NavLink>
          </Menu.Item>}
        </SubMenu>}
        {/* Support */}

        {/* Banner */}
        {isIncludes(['/brand', '/brand/categories']) && <SubMenu key="brand" icon={<FeatherIcon icon="layout" />} title="BRAND">
          {isIncludes(['/brand']) && <Menu.Item key="all-brands-list">
            <NavLink onClick={toggleCollapsed} to={`${path}/brand`}>
              All Brands
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/brand/categories']) && <Menu.Item key="brand3-categories">
            <NavLink onClick={toggleCollapsed} to={`${path}/brand/categories`}>
              Brand Categories
            </NavLink>
          </Menu.Item>}
        </SubMenu>}

        {/* Banner */}
        {isIncludes(['/banner', '/banner/add']) && <SubMenu key="banner" icon={<FeatherIcon icon="image" />} title="BANNER">
          {isIncludes(['/banner']) && <Menu.Item key="all-banner1">
            <NavLink onClick={toggleCollapsed} to={`${path}/banner`}>
              All Banner
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/banner/add']) && <Menu.Item key="add-banner2">
            <NavLink onClick={toggleCollapsed} to={`${path}/banner/add`}>
              Add Banner
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/banner/archive']) && <Menu.Item key="archieve-banner3">
            <NavLink onClick={toggleCollapsed} to={`${path}/banner/archive`}>
              Archived Banner
            </NavLink>
          </Menu.Item>}
        </SubMenu>}
        {/* Banner*/}

        {/* WareHouse */}
        {isIncludes(['/warehouse', '/warehouse/add']) && <SubMenu key="warehouse" icon={<FeatherIcon icon="package" />} title="WAREHOUSE">
          {isIncludes(['/warehouse']) && <Menu.Item key="all-warehouse">
            <NavLink onClick={toggleCollapsed} to={`${path}/warehouse`}>
              All Warehouse
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/warehouse/add']) && <Menu.Item key="add-warehouse">
            <NavLink onClick={toggleCollapsed} to={`${path}/warehouse/add`}>
              Add Warehouse
            </NavLink>
          </Menu.Item>}
        </SubMenu>}

        {/* Car Inspection */}
        {isIncludes(['/car-inspection/add', '/car-inspection/edit/:id', '/car-inspection/list']) && <SubMenu key="car-inspection" icon={<FeatherIcon icon="package" />} title="CAR INSPECTION">
          {isIncludes(['/car-inspection/list']) && <Menu.Item key="all-car-inspection">
            <NavLink onClick={toggleCollapsed} to={`${path}/car-inspection/list`}>
              Car Inspection List
            </NavLink>
          </Menu.Item>}
          {/* {isIncludes(['/car-inspection/add']) && <Menu.Item key="add-car-inspection">
            <NavLink onClick={toggleCollapsed} to={`${path}/car-inspection/add`}>
              Add Car Inspection
            </NavLink>
          </Menu.Item>} */}
        </SubMenu>}

        {/* Newsletter */}
        {isIncludes([`/newsletter`]) && <Menu.Item key="newsletter" icon={<FeatherIcon icon="clipboard" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/newsletter`}>
            NEWSLETTER
          </NavLink>
        </Menu.Item>}

        {/* ERP LOGS */}
        {isIncludes(['/erp-logs']) && <SubMenu key="system-logs" icon={<FeatherIcon icon="file-text" />} title="SYSTEM LOGS">
          {isIncludes(['/erp-logs']) && <Menu.Item key="erp-log">
            <NavLink onClick={toggleCollapsed} to={`${path}/erp-logs`}>
              ERP Logs
            </NavLink>
          </Menu.Item>}
        </SubMenu>}

        {/* SETTINGS LOGS */}
        {isIncludes(['/homepage-sections', '/homepage-sections/add', '/customer-vat-setting']) && <SubMenu key="settings" icon={<FeatherIcon icon="settings" />} title="SETTINGS">
          {isIncludes(['/homepage-sections', '/homepage-sections/add']) && <Menu.ItemGroup key="order-1" title="Homepage Sections">
            {isIncludes(['/homepage-sections']) && <Menu.Item key="settings-1-1">
              <NavLink onClick={toggleCollapsed} to={`${path}/homepage-sections`}>
                All Homepage Sections
              </NavLink>
            </Menu.Item>}
            {isIncludes(['/homepage-sections/add']) && <Menu.Item key="settings-1-2">
              <NavLink onClick={toggleCollapsed} to={`${path}/homepage-sections/add`}>
                Add Homepage Sections
              </NavLink>
            </Menu.Item>}
          </Menu.ItemGroup>}
          {isIncludes(['/customer-vat-setting']) && <Menu.Item key="vat-customer-setting">
            <NavLink onClick={toggleCollapsed} to={`${path}/customer-vat-setting`} style={{ marginLeft: -35 }}>
              VAT Free Customer
            </NavLink>
          </Menu.Item>}
          {isIncludes(['/delivery-time']) && <Menu.Item key="delivery-time">
            <NavLink onClick={toggleCollapsed} to={`${path}/delivery-time`} style={{ marginLeft: -35 }}>
              Delivery Time
            </NavLink>
          </Menu.Item>}
        </SubMenu>}
      </Menu>
    </>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func
};

export default MenuItems;
