/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { message } from 'antd'
import { emiratesOptions } from '../constants'
import { getCartLength } from '../container/pages/Cart';
export const UserAvatar = (props) => {
  const [image, setImage] = useState(process.env.PUBLIC_URL + '/assets/img/user-default.png')
  useEffect(() => {
    setImage(props.avatar || process.env.PUBLIC_URL + '/assets/img/user-default.png')
  }, [props.avatar])

  function handleErr() {
    setImage(process.env.PUBLIC_URL + '/assets/img/user-default.png')
  }
  return (
    <img
      src={image}
      onError={handleErr}
      {...props}
    />)
}


export const userStatus = [
  "Approved",
  "Pending",
  "Blocked"
]

export const kycStatus = [
  "Pending",
  "Done"
]

export const emirates = [
  "Abu Dhabi",
  "Dubai",
  "Ajman",
  "Sharjah",
  "Fujairah",
  "Ras Al Khaimah",
  "Umm Al Quwain"
]

export const sellerType = [
  "Local OEM Authorized distributors",
  "Local OES Distributors / Importers [OES, IAM]",
  "Sub-distributors [OEM, PES, IAM] / Retailer / Traders / Wholesalers"
]

export const sellerCategories = [
  'Category 1',
  'Category 2',
  'Category 3',
  'Category 4a',
  'Category 4b',
];

export const partTypes = [
  "Genunie - OE Packaging",
  "Alternative - OES Packaging & IAM",
  "Alternative - OE 2nd Grade",
  "Alternative - Generic",
  "Usd Parts"
]

export const garageTypes = [
  'Fleet & Corporate',
  'Chained/Third-party [Independent] Garages',
  'Independent Mom & Pop Garages',
  'Spare Parts & Traders',
  'Gas Station Network',
  'Tyre & Brake Outlets'
];

export const getGarageType = type => {
  switch (type) {
    case 'Authorized':
    case 'Authorized Garages':
    case 'Fleet & Corporate':
      return 'Fleet & Corporate';
    case 'Chained/Third-party [Independent] Garages':
    case 'Chained/Third-party [Independent] garages':
    case 'Chained/Third-party Garages':
      return 'Chained/Third-party [Independent] Garages';
    case 'Independent Mom & Pop Garages':
    case 'Independent Garages':
    case 'Independent mom & pop Garages':
      return 'Independent Mom & Pop Garages';
    case 'Gas Station Network':
      return 'Gas Station Network';
    case 'Tyre & Brake Outlets':
      return 'Tyre & Brake Outlets';
    case 'Unauthorized Garages':
    case 'Fleet/Corporate Garages':
    case 'Spare Parts & Traders':
    case 'unauthorized':
      return 'Spare Parts & Traders';
    // default:
    // return 'Spare Parts & Traders';
    default: return null;
  }
};


export const getDiscountAmount = (price, qty, listing, discountPerUnit, coupon, status) => {
  let discountAmount = 0;
  if (status === 'cancelled') return discountAmount = 0;
  if (coupon?.parameters?.label === "Category" && coupon?.parameters?.value?.includes(listing?.category)) {
    discountAmount = discountPerUnit * price * qty
  }
  if (coupon?.parameters?.label === "Sub-Category" && coupon?.parameters?.value?.includes(listing?.subCategory)) {
    discountAmount = discountPerUnit * price * qty
  }
  if (coupon?.parameters?.label === "Brand" && coupon?.parameters?.value?.includes(listing?.partBrand)) {
    discountAmount = discountPerUnit * price * qty
  }
  if (coupon?.parameters?.label === "SKU" && !coupon?.parameters?.selectedAll && coupon?.parameters?.value?.includes(listing?._id)) {
    discountAmount = discountPerUnit * price * qty
  }
  if (coupon?.parameters?.label === "SKU" && coupon?.parameters?.selectedAll && !coupon?.excludedSKUs?.includes(listing._id)) {
    discountAmount = discountPerUnit * price * qty
  }
  if (coupon?.parameters?.label === "Seller" && coupon?.parameters?.value?.includes(listing?.user?._id || listing?.user)) {
    discountAmount = discountPerUnit * price * qty
  }
  return discountAmount
}

export function validateSize(file, hideMessage = false) {
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    if (!hideMessage) message.error("File must smaller than 5MB!")
    return false
  } else {
    return true
  }
}

export const getParameterListings = ({ _listings, parameters }) => {
  let listings = []

  if (parameters?.label == "Seller") {
    listings = _listings.filter(l => parameters?.value?.includes(l?.user?._id || l?.user))
    // listings = await Listing.find({ user: { $in: parameters.value }, isDeleted: { $ne: true } }).distinct('_id')
  }
  if (parameters?.label == "Category") {
    listings = _listings.filter(l => parameters?.value?.includes(l?.category?._id || l?.category))
    // listings = await Listing.find({ category: { $in: parameters.value }, isDeleted: { $ne: true } }).distinct('_id')
  }

  if (parameters?.label == "Brand") {
    listings = _listings.filter(l => parameters?.value?.includes(l?.partBrand))
    // listings = await Listing.find({ partBrand: { $in: parameters.value }, isDeleted: { $ne: true } }).distinct('_id')
  }

  if (parameters?.label == "Sub-Category") {
    listings = _listings.filter(l => parameters?.value?.includes(l?.subCategory?._id || l?.subCategory))

    // listings = await Listing.find({ subCategory: { $in: parameters.value }, isDeleted: { $ne: true } }).distinct('_id')
  }

  if (parameters?.label == "SKU") {
    if (parameters?.selectedAll) {
      listings = _listings

    } else {
      listings = _listings.filter(l => parameters?.value?.includes(l?._id))

      // listings = await Listing.find({ _id: { $in: parameters.value }, isDeleted: { $ne: true } }).distinct('_id')
    }
  }
  return listings
}


export const ProductImage = (props) => {
  const [image, setImage] = useState(process.env.PUBLIC_URL + "/assets/img/product-default.jpg");
  const { avatar } = props;
  useEffect(() => {
    if (avatar) {
      setImage(avatar);
    }
  }, [avatar]);
  function handleErr() {
    const defaultImage = process.env.PUBLIC_URL + '/assets/img/product-default.jpg'
    setImage(defaultImage)
  }
  return <img style={{ objectFit: "contain", width: props.width ? props.width : "auto" }} src={image} onError={handleErr} {...props} />;
};


const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const formatPhoneNumber = phone => {
  return [phone.slice(0, 4), ' ', phone.slice(4, 6), ' ', phone.slice(6, 9), ' ', phone.slice(9, phone.length)].join(
    ''
  );
};

const numberSeperator = num => {
  var n = parseFloat(num).toFixed(2);
  n = Math.floor(n);
  var withCommas = Number(n).toLocaleString('en');
  return withCommas;
};

export const formatString = (str) => {
  str = str.toLowerCase(); // convert string to lowercase
  str = str.replace(/-/g, ' '); // replace all hyphens with spaces
  str = str.replace(/^\w|\s\w/g, c => c.toUpperCase()); // capitalize first letter of each word
  return str;
}

export const getEmirateAndRegion = (emirate, region, regions) => {
  let selectedEmirate = "", selectedRegion = "", filteredRegions = regions;

  if (emiratesOptions.includes(emirate)) {
    selectedEmirate = emirate;
  }

  if (!selectedEmirate) {
    message.warning("Cannot find emirate and region for this location. Please select it.")
    return { selectedEmirate, selectedRegion, filteredRegions }
  }

  filteredRegions = filteredRegions && filteredRegions?.length !== 0 ? filteredRegions.filter(region => region?.state?.name === selectedEmirate) : []

  if (filteredRegions.findIndex(state => state?.name === region) !== -1) {
    selectedRegion = region
  }

  return { selectedEmirate, selectedRegion, filteredRegions }
}

export { ellipsis, formatPhoneNumber, numberSeperator };
export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().startOf('day');
};
export const disableEndDate = (startDate) => {
  return startDate && startDate < dayjs().endOf('day');

}
export const range = (end) => {
  const result = [];
  for (let i = 0; i < end; i++) {
    result.push(i);
  }
  return result;
};
export const disabledDateTime = (h, m, s) => ({
  disabledHours: () => range(h),
  disabledMinutes: () => range(m + 1),
  // disabledSeconds: () => range(s+1)
});

export const DateFormat = "YYYY-MM-DD h:mm:ss A"
export const TimeFormat = 'h:mm:ss A'  //12 hours formate

export const ignoredUrls = ["https://apilayer.net"]

export const formatERPActionModule = (data = "") => {
  let actionModule = ""

  switch (data) {
    case "order":
      actionModule = "ORDER"
      break;
    case "user":
      actionModule = "USER"
      break;
    case "sales_line_status":
      actionModule = "SALES LINE STATUS"
      break;
    case "Collection":
      actionModule = "COLLECTION"
      break;
    case "ReturnSales":
      actionModule = "RETURN SALES"
      break;
    default:
      actionModule = ""
  }

  return actionModule;
}

export const getRoleFromPath = (pathname = "") => {
  const rolePath = pathname ? pathname?.split("/")[2] : "";
  let role = "", title = "";

  switch (rolePath) {
    case "sales-manager":
      role = "sales_manager"
      title = "Sales Manager"
      break;
    case "sales":
      role = "sales"
      title = "Sales"
      break;
    case "delivery":
      role = "delivery"
      title = "Delivery Scout"
      break;
    case "merchandiser":
      role = "merchandiser"
      title = "Merchandiser"
      break;
    case "marketing":
      role = "marketing_manager"
      title = "Marketing Manager"
      break;
    case "finance":
      role = "finance"
      title = "Finance Manager"
      break;
    case "gallega":
      role = "logistics_admin"
      title = "Gallega User"
      break;
    default:
      role = ""
      title = ""
  }

  return { role, title, rolePath }
}

export const getStatusColor = (status = "") => {
  switch (status) {
    case "published":
    case "visible":
      return "#20C997";
    case "draft":
      return "#fa8b0c"
    case "archive":
    case "hidden":
      return "#f5222d"
  }
}

export const calcInclVatFromExcl = ({ exclPrice, vatPercent }) => {
  const inclPrice = exclPrice * (1 + vatPercent / 100)
  const taxAmount = inclPrice - exclPrice
  return { inclPrice, taxAmount }
}

export const isValidObject = (value) => {
  return typeof value === "object" && value !== null && !Array.isArray(value)
}

export const calcDiscountPerUnit = (listings, coupon, payment_method, cart) => {
  let newTaxAmount = 0
  if (coupon) {
    let _filteredListings = listings.filter((l) => {
      if (
        coupon.parameters.label === 'Category' &&
        coupon.parameters.value?.includes(l.category)
      ) {
        return l
      }
      if (
        coupon.parameters.label === 'Brand' &&
        coupon.parameters.value?.includes(l.partBrand)
      ) {
        return l
      }
      if (
        coupon.parameters.label === 'Sub-Category' &&
        coupon.parameters.value?.includes(l.subCategory)
      ) {
        return l
      }
      if (
        coupon.parameters.label === 'SKU' &&
        !coupon.parameters.selectedAll &&
        coupon.parameters.value?.includes(l._id)
      ) {
        return l
      }
      if (
        coupon.parameters.label === 'SKU' &&
        coupon?.parameters?.selectedAll &&
        !coupon?.excludedSKUs?.includes(l._id)
      ) {
        return l
      }
      if (
        coupon.parameters.label === 'Seller' &&
        coupon.parameters.value?.includes(l?.user?._id)
      ) {
        return l
      }
    })

    let filteredListings = listings
    let totalVal = _filteredListings.reduce((total, obj) => {
      return total + obj.priceExclVat * obj?.orderQty
    }, 0)

    if (totalVal == 0) {
      return {
        discount: 0,
        discountPerUnit: 0,
        newTaxAmount: listings.reduce((total, obj) => {
          return total + obj.vatAmount * obj?.orderQty
        }, 0),
      }
    }

    let discountPerUnit = 0

    if (
      totalVal >= coupon?.min_cart_value &&
      coupon?.discount_type?.label === 'by_percent'
    ) {
      discountPerUnit = coupon?.discount_type?.value / 100
      let cap_discountAmt = totalVal * discountPerUnit
      if (
        coupon?.discount_cap_amount &&
        cap_discountAmt > coupon?.discount_cap_amount
      ) {
        discountPerUnit = coupon?.discount_cap_amount / totalVal
      }

    }
    if (
      totalVal >= coupon?.min_cart_value &&
      coupon?.discount_type?.label === 'by_value'
    ) {
      discountPerUnit = coupon?.discount_type?.value / totalVal
    }

    if (totalVal < coupon?.min_cart_value) {
      discountPerUnit = 0
    }

    if (
      coupon.payment_method !== 'All' &&
      coupon.payment_method !== payment_method
    ) {
      discountPerUnit = 0
    }

    const newTaxAmountForDiscountListing = _filteredListings.reduce(
      (total, obj) => {
        const discountAmount = discountPerUnit * obj.priceExclVat
        return (
          total +
          calcInclVatFromExcl({
            exclPrice: obj.priceExclVat - discountAmount,
            vatPercent: obj.vatPercent,
          }).taxAmount *
          obj?.orderQty
        )
      },
      0
    )

    const remainingListing = filteredListings.filter(
      (x) => !_filteredListings.includes(x)
    )
    const newTaxAmountForRemainingListing = remainingListing.reduce(
      (total, obj) => {
        return total + obj.vatAmount * obj?.orderQty
      },
      0
    )

    newTaxAmount =
      newTaxAmountForDiscountListing + newTaxAmountForRemainingListing

    return {
      discount: discountPerUnit * totalVal,
      discountPerUnit,
      newTaxAmount,
    }
  } else {
    return {
      discount: 0,
      discountPerUnit: 0,
      newTaxAmount: listings.reduce((total, obj) => {
        return total + obj.vatAmount * obj?.orderQty
      }, 0),
    }
  }
}

export const formatWalletReason = (reason) => {
  let formattedData = ""
  switch (reason) {
    case "cheque_cleared":
      formattedData = "Cheque Cleared"
      break;
    case "online_transferred":
      formattedData = "Online Transferred"
      break;
    case "credit_note":
      formattedData = "Credit Note"
      break;
    case "others":
      formattedData = "Others"
      break;
    default:
      formattedData = reason
      break;
  }

  return formattedData;
}

export const getCanceledDisabledStatus = (orderSelectState) => (business_model, status) => {
  if (orderSelectState.indexOf('Out for Delivery') <= orderSelectState.indexOf(status)) {
    return false;
  }
  const orderIndex = orderSelectState.indexOf(status);
  switch (business_model) {
    case 'Fulfilment':
      return orderSelectState.indexOf('Preparing') <= orderIndex;
    case 'Consolidation':
      return orderSelectState.indexOf('PENDING') <= orderIndex;
    default:
      return false
  }
}