import actions from './action';
import axios from 'axios';
import { message } from 'antd'

const {
  walletLogBegin,
  getWalletLogSuccess,
  walletBegin,
  getWalletSuccess,
  addWalletSuccess,
  editWalletSuccess,
  deleteWalletSuccess,
  getSingleWalletSuccess,
  walletErr
} = actions;

export const addWallet = (payload, history) => {
  return async (dispatch, getState) => {
    try {
      let user = getState().auth.login;
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }
      payload.updatedBy = user?._id;
      dispatch(walletBegin());
      const resp = await axios.post('/api/wallet', payload);
      message.success('Wallet added successfully !')
      dispatch(addWalletSuccess(resp.data));
      history.push('/admin/wallets');
    } catch (err) {
      message.error(err.response.data.message)
      dispatch(walletErr(err));
    }
  };
};

export const editWallet = (id, payload, history) => {
  return async (dispatch, getState) => {
    try {
      let user = getState().auth.login;
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }
      dispatch(walletBegin());
      const resp = await axios.put(`/api/wallet/${id}`, payload);
      message.success('Wallet edited successfully !')
      dispatch(editWalletSuccess(resp.data));
      history.push('/admin/wallets');
    } catch (err) {
      message.error(err?.response?.data?.message || "Error while editing the wallet")
      dispatch(walletErr(err));
    }
  };
};

export const getWallet = (page, limit, search) => {
  const encodedSearch = search ? encodeURIComponent(search) : ""
  return async dispatch => {
    try {
      dispatch(walletBegin());
      const resp = await axios.get(`/api/wallet?page=${page}&limit=${limit}&search=${encodedSearch}`);
      dispatch(getWalletSuccess(resp?.data));
    } catch (err) {
      dispatch(walletErr(err));
    }
  };
};

export const getWalletLog = (id, page, limit) => {
  return async dispatch => {
    try {
      dispatch(walletLogBegin());
      const resp = await axios.get(`/api/wallet/wallet-log/${id}?page=${page}&limit=${limit}`);
      dispatch(getWalletLogSuccess(resp?.data));
    } catch (err) {
      dispatch(walletErr(err));
    }
  };
};

export const getWalletById = id => {
  return async dispatch => {
    try {
      dispatch(walletBegin());
      const resp = await axios.get(`/api/wallet/${id}`);
      dispatch(getSingleWalletSuccess(resp?.data));
    } catch (err) {
      dispatch(walletErr(err));
    }
  };
};

export const getWalletByUserId = id => {
  return async dispatch => {
    try {
      dispatch(walletBegin());
      const resp = await axios.get(`/api/wallet/wallet-user/${id}`);
      dispatch(getSingleWalletSuccess(resp?.data));
    } catch (err) {
      dispatch(walletErr(err));
    }
  };
};

export const resetWallet = () => {
  return dispatch => {
    dispatch(getSingleWalletSuccess({}));
  }
}

export const deleteWallet = id => {
  return async dispatch => {
    try {
      dispatch(walletBegin());
      await axios.delete(`/api/wallet/${id}`);
      dispatch(deleteWalletSuccess(id));
      return true;
    } catch (err) {
      dispatch(walletErr(err));
      return false;
    }
  };
};
