import React, { useEffect } from 'react';
import { Modal, Button, Spin, Select, Input, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { updateSubCategory } from '../../redux/categories/actionCreator'

const EditSubCatModal = ({ parentCategories, showmodal, onClose, modalClose, name, id, pid, pname, paramsId, code }) => {
  const [loader, setLoader] = React.useState(false);

  const [form] = Form.useForm();

  const dispatch = useDispatch()
  const { Option } = Select;

  useEffect(() => {
    form.setFieldsValue({
      category: pid || '',
      subCategory: name || '',
      erpCode: code || ''
    });
  }, [name, pid, code])

  const closeLoader = () => {
    setLoader(false)
  }

  const handleSubmit = async () => {
    await form.submit();
  }

  const onFinish = async values => {
    setLoader(true);

    const data = {
      'name': values?.subCategory,
      'category_id': values?.category,
      'erpCode': values?.erpCode
    }
    dispatch(updateSubCategory(data, id, modalClose, closeLoader, paramsId))
  };
  
  return (
    <Modal title={
      <div>
        Please select parent category and add new category.
      </div>
    } visible={showmodal} onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" onClick={handleSubmit} disabled={loader} type="primary">
          {loader && <Spin style={{ color: '#fff', fontSize: 18 }} />} Update SubCategory
        </Button>
      ]}
    >
      <div style={{ height: 'auto' }}>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          size="default"
          layout={'vertical'}
        >
          <Form.Item
            style={{ width: '100%', display: 'block' }}
            label="Category"
            name={"category"}
            rules={[{ required: true, message: 'Category is required' }]}
          >
            <Select placeholder="Choose Parent Category" >
              {parentCategories && parentCategories.map((item, i) => {
                return <Option key={i} value={item._id}>{item.name}</Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: '100%', display: 'block' }}
            label="Sub Category"
            name={"subCategory"}
            rules={[{ required: true, message: 'Sub Category is required' }]}
          >
            <Input placeholder='SubCategory' style={{ marginTop: 0 }} />
          </Form.Item>
          <Form.Item
            style={{ width: '100%', display: 'block' }}
            label="ERP Code"
            name={"erpCode"}
            rules={[{ required: true, message: 'ERP Code is required' }]}
          >
            <Input placeholder='ERP Code' style={{ marginTop: 0 }} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default EditSubCatModal;



