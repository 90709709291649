import { de } from 'date-fns/locale';
import actions from './actions';

const initialState = {
  clockData: [],
  isClockInOutLoading: false,
  error: null
};

const { CLOCKINOUT_BEGIN, GET_CLOCKINOUT_SUCCESS, CLOCKINOUT_ERR } = actions;

const clockReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CLOCKINOUT_BEGIN:
      return {
        ...state,
        isClockInOutLoading: true
      };
    case GET_CLOCKINOUT_SUCCESS:
      return {
        ...state,
        clockData: data,
        isClockInOutLoading: false
      };
    case CLOCKINOUT_ERR:
      return {
        ...state,
        error: err,
        isClockInOutLoading: false
      };
    default:
      return state;
  }
};

export default clockReducer;
