import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, message } from 'antd';

import { UserTableStyleWrapper } from './style';
import { TableWrapper, StatusWrapper } from '../styled';
import Heading from '../../components/heading/heading';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button as AntdButton, Radio } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';
import EditCatModal from '../../components/modals/EditCatModal';
import ConfirmModal from '../../components/modals/confirmation';
import axios from 'axios';
import { getParentCategories, getCategories } from '../../redux/categories/actionCreator';

// handleSearchByOrderStatus, fitlerByOrderStatus
const OrderListTable = ({ parent, categories }) => {
  const [orderFilter, setOrderFilter] = useState('all');
  const [id, setId] = useState('');
  const [pid, setPId] = useState('');
  const [modalOpen, setModalopen] = useState(false);
  const [name, setName] = useState('');
  const [categoryImgUrl, setCategoryImageUrl] = useState('');
  const [pname, setPname] = useState('');
  const [erpCode, setErpCode] = useState('');
  const [confirm, setConfirm] = useState(false);

  const dispatch = useDispatch();
  const handleEdit = (id, pid, pname, name, imgUrl, erpCode) => {
    setModalopen(true);
    setId(id);
    setPId(pid);
    setCategoryImageUrl(imgUrl);
    setName(name);
    setPname(pname);
    setErpCode(erpCode);
  };
  const modalClose = () => {
    setModalopen(false);
  };
  const openConfirm = (id, name) => {
    setConfirm(true);
    setId(id);
    setName(name);
  };

  const deletecategory = async id => {
    await axios
      .patch(`/api/categories/delete-category?cat_id=${id}`)
      .then(res => {
        message.success('Successfully deleted category!');
        dispatch(getParentCategories());
        dispatch(getCategories());
      })
      .catch(err => {
        message.err('Failed to delete category!');
      });
  };

  const categoriesTableData = [];

  categories &&
    categories.length > 0 &&
    categories.map((category, index) => {
      const { _id, name, parent_category, imgUrl, erpCode } = category;

      return categoriesTableData.push({
        key: _id,
        sNo: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {parent_category?.category_name}
            </Heading>
          </div>
        ),
        Name: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {name?.toUpperCase()}
            </Heading>
          </div>
        ),
        erpCode,
        action: (
          <div className="table-actions">
            <>
              <Button className="btn-icon" type="primary" to="#" shape="circle">
                <NavLink to={`/admin/view-subcategories/${_id}`}>
                  <FeatherIcon icon="eye" size={16} />
                </NavLink>
              </Button>
              <Button
                onClick={() => handleEdit(_id, parent_category._id, parent_category.category_name, name, imgUrl, erpCode)}
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="edit" size={16} />
              </Button>
              <Button onClick={() => openConfirm(_id, name)} className="btn-icon" type="danger" to="#" shape="circle">
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </>
          </div>
        )
      });
    });

  const ordersTableColumns = [
    {
      title: 'Parent Categories',
      dataIndex: 'sNo',
      key: 'sNo'
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'ERP Code',
      dataIndex: 'erpCode',
      key: 'erpCode'
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      fixed:'right'


    }
  ];

  const rowSelection = {
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <>
      <ConfirmModal
        showmodal={confirm}
        onClose={() => setConfirm(false)}
        eventFunc={deletecategory}
        id={id}
        name={name}
      />
      <Cards headless>
        <UserTableStyleWrapper>
          <EditCatModal
            parentCategories={parent}
            showmodal={modalOpen}
            onClose={() => setModalopen(false)}
            modalClose={modalClose}
            name={name}
            id={id}
            pid={pid}
            pname={pname}
            imgUrl={categoryImgUrl}
            code={erpCode}
          />
          <TableWrapper className="table-responsive">
            <div>
              <Radio.Group
                // onChange={e => handleSearchByOrderStatus(e.target.value)}
                style={{ margin: 16 }}
              >
                {parent &&
                  parent.map((item, i) => {
                    return (
                      <Radio.Button key={i} value={item._id}>
                        {item.category_name}
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
            </div>
            <Table
              // rowSelection={rowSelection}
              dataSource={categoriesTableData}
              columns={ordersTableColumns}
              pagination={{
                defaultPageSize: 20,
                total: categoriesTableData.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </Cards>
    </>
  );
};

export default OrderListTable;
