const actions = {
  BANNER_BEGIN: 'BANNER_BEGIN',
  GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
  ADD_BANNER_SUCCESS: 'BANNER_SUCCESS',
  EDIT_BANNER_SUCCESS: 'EDIT_BANNER_SUCCESS',
  DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
  ARCHIVE_BANNER_SUCCESS: 'ARCHIVE_BANNER_SUCCESS',
  BANNER_ERR: 'BANNER_ERR',
  BANNER_COLLECTION_BEGIN: 'BANNER_COLLECTION_BEGIN',
  BANNER_COLLECTION_SUCCESS: 'BANNER_COLLECTION_SUCCESS',
  BANNER_COLLECTION_ERROR: 'BANNER_COLLECTION_ERROR',
  bannerBegin: () => {
    return {
      type: actions.BANNER_BEGIN
    };
  },
  getBannerSuccess: data => {
    return {
      type: actions.GET_BANNER_SUCCESS,
      data
    };
  },
  addBannerSuccess: data => {
    return {
      type: actions.ADD_BANNER_SUCCESS,
      data
    };
  },
  editBannerSuccess: data => {
    return {
      type: actions.EDIT_BANNER_SUCCESS,
      data
    };
  },
  deleteBannerSuccess: data => {
    return {
      type: actions.DELETE_BANNER_SUCCESS,
      data
    };
  },
  archiveBannerSuccess: data => {
    return {
      type: actions.ARCHIVE_BANNER_SUCCESS,
      data
    };
  },
  bannerErr: err => {
    return {
      type: actions.BANNER_ERR,
      err
    };
  },
  bannerCollectionBegin: () => {
    return {
      type: actions.BANNER_COLLECTION_BEGIN
    };
  },
  getBannerCollectionSuccess: data => {
    return {
      type: actions.BANNER_COLLECTION_SUCCESS,
      data
    };
  },
  bannerCollectionErr: err => {
    return {
      type: actions.BANNER_COLLECTION_ERROR,
      err
    };
  },
};

export default actions;
