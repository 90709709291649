import actions from './actions';

const initialState = {
  news: [],
  loading: false,
  error: null,
  isNewsUpdated: false
};

const {
  GET_NEWS_LIST_BEGIN,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_ERR,

  UPDATE_NEWS_BEGIN,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_ERR,

  RESET_FLAGS
} = actions;

const newsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch(type) {
    case GET_NEWS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        news: data,
     
      };
    case GET_NEWS_LIST_ERR:
      return {
        ...state,
        loading: false,
        news: [],
        err: err,
      };
      case UPDATE_NEWS_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_NEWS_SUCCESS:
        return {
          ...state,
          loading: false,
          isNewsUpdated:true
        };
      case UPDATE_NEWS_ERR:
        return {
          ...state,
          loading: false,
          err: err,
        };
        case RESET_FLAGS:
          return{
            isNewsUpdated:false
          }
    default:
      return state;
  }
};

export default newsReducer;
