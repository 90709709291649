const actions = {
    GET_BUYER_ADDRESS_BEGIN: 'GET_BUYER_ADDRESS_BEGIN',
    SET_BUYER_ADDRESS: 'SET_BUYER_ADDRESS',
    GET_BUYER_ADDRESS_ERROR: 'GET_BUYER_ADDRESS_ERROR',

    UPSERT_ADDRESS_BEGIN: 'UPSERT_ADDRESS_BEGIN',
    UPSERT_ADDRESS_SUCCESS: 'UPSERT_ADDRESS_SUCCESS',
    UPSERT_ADDRESS_ERROR: 'UPSERT_ADDRESS_ERROR',
    
    GET_STATE_BEGIN: 'GET_STATE_BEGIN',
    GET_STATE_SUCCESS: 'GET_STATE_SUCCESS',
    GET_STATE_ERROR: 'GET_STATE_ERROR',

    GET_REGION_BEGIN: 'GET_REGION_BEGIN',
    GET_REGION_SUCCESS: 'GET_REGION_SUCCESS',
    GET_REGION_ERROR: 'GET_REGION_ERROR',

    GET_LOCATION_BEGIN: 'GET_LOCATION_BEGIN',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_LOCATION_ERROR: 'GET_LOCATION_ERROR',

    getBuyerAddressBegin: _ => {
        return {
            type: actions.GET_BUYER_ADDRESS_BEGIN
        }
    },

    setBuyerAddress: data => {
        return {
            type: actions.SET_BUYER_ADDRESS,
            data
        }
    },

    getBuyerAddressError: _ => {
        return {
            type: actions.GET_BUYER_ADDRESS_ERROR
        }
    },

    upsertAddressBegin: _ => {
        return {
            type: actions.UPSERT_ADDRESS_BEGIN
        }
    },

    upsertAddressSuccess: data => {
        return {
            type: actions.UPSERT_ADDRESS_SUCCESS,
            data
        }
    },

    upsertAddressError: _ => {
        return {
            type: actions.UPSERT_ADDRESS_ERROR
        }
    },

    getStateBegin: _ => {
        return {
            type: actions.GET_STATE_BEGIN
        }
    },

    getStateSuccess: data => {
        return {
            type: actions.GET_STATE_SUCCESS,
            data
        }
    },

    getStateError: _ => {
        return {
            type: actions.GET_STATE_ERROR
        }
    },

    getRegionBegin: _ => {
        return {
            type: actions.GET_REGION_BEGIN
        }
    },

    getRegionSuccess: data => {
        return {
            type: actions.GET_REGION_SUCCESS,
            data
        }
    },

    getRegionError: _ => {
        return {
            type: actions.GET_REGION_ERROR
        }
    },

    getLocationBegin: _ => {
        return {
            type: actions.GET_LOCATION_BEGIN
        }
    },

    getLocationSuccess: data => {
        return {
            type: actions.GET_LOCATION_SUCCESS,
            data
        }
    },

    getLocationError: _ => {
        return {
            type: actions.GET_LOCATION_ERROR
        }
    },

}

export default actions;