import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { Col, Row, Image } from 'antd';

import { UserCard } from '../style';
import Heading from '../../../components/heading/heading';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import DefaultImg from '../../../static/img/defaultImg.png';

function ImageDemo(img) {
  return (
    <Image
      src={img || DefaultImg}
      fallback={DefaultImg}
    />
  );
}
const UserCardsBuyer = ({ data, orderDetails, userdata }) => {
  const [received, setReceived] = React.useState([]);
  const [orderCount, setOrderCount] = useState(0);

  const firstUpdate = useRef(true);

  const count = useSelector(state => state.order.orderCount);

  React.useEffect(() => {
    let SellerCategory = [];
    orderDetails && orderDetails.length > 0 && orderDetails.forEach(item => SellerCategory.push(item.products));
    let flattened = SellerCategory.reduce((acc, curVal) => acc.concat(curVal), []);
    const filteredProducts = flattened.filter(item => item.status === 'delivered');
    setReceived(filteredProducts);
  }, [orderDetails]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setOrderCount(count);
  }, [count]);

  const numberWithCommas = x => {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else return 0;
  };

  return (
    <UserCard>
      <div className="card user-card">
        <Cards headless>
          <figure style={{ cursor: 'pointer' }}>{ImageDemo(data?.avatar)}</figure>
          <figcaption>
            <div className="card__content">
              <Heading className="card__name" as="h6">
                {data?.details?.garage_name}
              </Heading>
              <p className="card__designation">{data?.status}</p>
            </div>

            <div className="card__actions">
              <Button size="default" type="white">
                <FeatherIcon icon="mail" size={14} />
                Message
              </Button>
            </div>
            <div className="card__info">
              <Row gutter={15}>
                <Col xs={8}>
                  <div className="info-single">
                    <Heading className="info-single__title" as="h2">
                      AED {numberWithCommas(userdata?.totalDeliveries?.toFixed(2))}
                    </Heading>
                    <p>Total Sales</p>
                  </div>
                </Col>
                <Col xs={8}>
                  <div className="info-single">
                    <Heading className="info-single__title" as="h2">
                      {count !== 0 ? count : 'No'}
                    </Heading>
                    <p>Orders {count !== 0 ? '' : 'Yet'}</p>
                  </div>
                </Col>
                <Col xs={8}>
                  <div className="info-single">
                    <Heading className="info-single__title" as="h2">
                      {received && received.length > 0 ? Number(received?.length).toLocaleString() : 'No'}
                    </Heading>
                    <p>Reveived Products {received && received?.length > 0 ? '' : 'Yet'}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </figcaption>
        </Cards>
      </div>
    </UserCard>
  );
};

UserCardsBuyer.propTypes = {
  user: PropTypes.object
};

export default UserCardsBuyer;
