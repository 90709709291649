const actions = {
  BRAND_BEGIN: 'BRAND_BEGIN',
  GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
  ADD_BRAND_SUCCESS: 'ADD_BRAND_SUCCESS',
  EDIT_BRAND_SUCCESS: 'EDIT_BRAND_SUCCESS',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  BRAND_ERR: 'BRAND_ERR',
  GET_SINGLE_BRAND_BEGIN: 'GET_SINGLE_BRAND_BEGIN',
  GET_SINGLE_BRAND_SUCCESS: 'GET_SINGLE_BRAND_SUCCESS',
  GET_SINGLE_BRAND_ERROR: 'GET_SINGLE_BRAND_ERROR',
  CATEGORY_BEGIN: 'CATEGORY_BEGIN',
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  CATEGORY_ERROR: 'CATEGORY_ERROR',
  SUBCATEGORY_BEGIN: 'SUBCATEGORY_BEGIN',
  GET_SUBCATEGORY_SUCCESS: 'GET_SUBCATEGORY_SUCCESS',
  SUBCATEGORY_ERROR: 'SUBCATEGORY_ERROR',
  GET_CATEGORY_SUBCATEGORY: 'GET_CATEGORY_SUBCATEGORY',
  ADD_CATEGORY_BEGIN: 'ADD_CATEGORY_BEGIN',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',

  brandBegin: () => {
    return {
      type: actions.BRAND_BEGIN
    };
  },


  getBrandSuccess: data => {
    return {
      type: actions.GET_BRAND_SUCCESS,
      data
    };
  },
  addBrandSuccess: _ => {
    return {
      type: actions.ADD_BRAND_SUCCESS
    };
  },
  editBrandSuccess: _ => {
    return {
      type: actions.EDIT_BRAND_SUCCESS
    };
  },
  deleteBrandSuccess: _ => {
    return {
      type: actions.DELETE_BRAND_SUCCESS
    };
  },
  brandErr: err => {
    return {
      type: actions.BRAND_ERR,
      err
    };
  },
  getSingleBrandBegin: () => {
    return {
      type: actions.GET_SINGLE_BRAND_BEGIN
    };
  },
  getSingleBrandSuccess: data => {
    return {
      type: actions.GET_SINGLE_BRAND_SUCCESS,
      data
    };
  },
  getSingleBrandError: () => {
    return {
      type: actions.GET_SINGLE_BRAND_ERROR
    };
  },
  categoryBegin: _ => {
    return {
      type: actions.CATEGORY_BEGIN
    }
  },
  getCategorySucess: data => {
    return {
      type: actions.GET_CATEGORY_SUCCESS,
      data
    };
  },
  categoryError: _ => {
    return {
      type: actions.CATEGORY_ERROR
    }
  },
  subCategoryBegin: _ => {
    return {
      type: actions.SUBCATEGORY_BEGIN
    }
  },
  getSubCategorySucess: data => {
    return {
      type: actions.GET_SUBCATEGORY_SUCCESS,
      data
    };
  },
  subCategoryError: _ => {
    return {
      type: actions.CATEGORY_ERROR
    }
  },
  getCategorySubCategory: data => {
    return {
      type: actions.GET_CATEGORY_SUBCATEGORY,
      data
    }
  },
  addCategoryBegin: () => {
    return {
      type: actions.ADD_CATEGORY_BEGIN
    };
  },
  addCategorySuccess: () => {
    return {
      type: actions.ADD_CATEGORY_SUCCESS
    };

  },
 
};

export default actions;
