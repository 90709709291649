import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Drawer
} from "antd";
import moment from 'moment';

import FeatherIcon from "feather-icons-react";
import { UserTableStyleWrapper } from '../style';
import { Cards } from "../../../components/cards/frame/cards-frame";
import { TableWrapper, StatusWrapper } from "../../styled";
import { Button } from '../../../components/buttons/buttons';
import { updateUser } from '../../../redux/users/actionCreator';

const MySales = ({ orders, setPage, setPerPage }) => {

  const dispatch = useDispatch();
  const { isUserUpdated } = useSelector(state => {
    return {
      isUserUpdated: state.users.isUserUpdated
    };
  });
  const [approvalModal, setApprovalModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedSeller, setSelectedSeller] = useState(null);

  const [confirm, setConfirm] = useState(false)
  const [id, setId] = useState("")
  const [name, setName] = useState("")

  const loading = useSelector(state => state.order.loading);
  const orderCount = useSelector(state => state.order.orderCount);

  useEffect(() => {
    if (isUserUpdated) {
      closeModal()
    }
  }, [isUserUpdated])


  const showModal = (value) => {
    setSelectedSeller(value)
    setApprovalModal(true);
  };

  const closeModal = () => {
    setSelectedSeller(null)
    setApprovalModal(false);
  };

  const handleUpdateStatus = (status) => {
    if (status) {
      dispatch(updateUser({ ...selectedSeller, status }))
    } else {
      dispatch(updateUser({ ...selectedSeller, status: selectedSeller.status === "Approved" ? "Blocked" : "Approved" }));
    }
  }


  const getSeller = async id => {
    setOpenDrawer(true)
  }
  const openConfirm = (id, name) => {
    setConfirm(true)
    setId(id)
    setName(name)

  }
  const numberWithCommas = (x) => {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return 0;
  };

  const usersTableData = [];

  const getItems = (products) => {
    return (
      <div className="d-flex order-listings-wrapper">
        <>
          <div
            className="listing-item d-inline-block p-1 border rounded-circle"
            style={{ width: 40, height: 40, border: '1px solid #e3e6ef', borderRadius: '100%' }}
          >
            <img
              src={
                products[0]?.listing?.imageArray?.length > 0
                  ? products[0]?.listing?.imageArray[0]
                  : "/assets/img/product-default.jpg"
              }
              // width={17}
              // height={17}
              alt={products[0]?.listing?.partName}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          </div>
          {products.length > 1 ? (
            <span
              className="badge badge-light rounded-circle border p-1"
              style={{
                width: 40,
                height: 40,
                border: '1px solid #e3e6ef',
                lineHeight: "30px",
                textAlign: "center",
                borderRadius: "50%",
                fontSize: 14,
                paddingTop: 3
              }}
            >
              +{products.length - 1}
            </span>
          ) : (
            <></>
          )}
        </>
      </div>
    );
  }

  orders && orders.length > 0 && orders.map(users => {
    const { _id, order_id, details, total_price, user, order_date, products } = users;
    const numberSeperator = (num) => {
      var n = parseFloat(num).toFixed(2);
      n = Math.floor(n)
      var withCommas = Number(n).toLocaleString("en");
      return withCommas;
    };
    return usersTableData.push({
      key: _id,
      o_id: order_id,
      o_date: (
        <div>

          <span>{moment(order_date).tz("Asia/Dubai").format("DD/MM/YYYY")}</span>
          <span>{moment(order_date).tz("Asia/Dubai").format("hh:mm A")}</span>
        </div>
      ),
      buyer: user?.details?.garage_name,
      items: getItems(products),
      price: `AED ${numberWithCommas(total_price?.toFixed(2)).toLocaleString()}`,
      priceExc: `AED ${numberWithCommas((total_price?.toFixed(2))).toLocaleString()}`,
      status: (
        <StatusWrapper>
          <div className="user-status" onClick={() => showModal(user)}>
            status
          </div>
          {details && details?.seller_type && <div className="user-type" style={{ width: '200px', whiteSpace: 'break-spaces' }}>{details?.seller_type}</div>}
        </StatusWrapper>
      ),
      action: (
        <div className="table-actions">
          <>
            <NavLink to={`/admin/orders/view/${_id}`}>
              <Button className="btn-icon" type="primary" to="#" shape="circle"> <FeatherIcon icon="eye" size={16} />View</Button>
            </NavLink>
          </>
        </div>
      ),
    });
  });

  const usersTableColumns = [
    {
      title: 'Order ID',
      dataIndex: 'o_id',
      key: 'o_id',
    },
    {
      title: 'Order Date',
      dataIndex: 'o_date',
      key: 'o_date',
    },
    {
      title: 'Customer',
      dataIndex: 'buyer',
      key: 'buyer',
    },
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '20%',
    },
    {
      title: 'Price(Exc.VAT)',
      dataIndex: 'priceExc',
      key: 'priceExc',
      width: '20%',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    },
  ];

  const handlePagination = (page, perPage) => {
    setPage(page);
    setPerPage(perPage)
  }


  return (
    <>
      <Cards headless>
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              // rowSelection={rowSelection}
              dataSource={usersTableData}
              columns={usersTableColumns}
              loading={loading}
              pagination={{
                onChange: handlePagination,
                defaultPageSize: 20,
                total: orderCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${orderCount} items`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>

        <Drawer
          width={800}
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawer(false)}
          visible={openDrawer}
          getContainer={false}
        >
          <div className="drawaer-container">
            <Row justify="center">
              <Col xl={10} md={16} xs={24}>
                <div className="user-info-form">
                  {
                    'helloworld'
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Drawer>
      </Cards>
    </>
  );
};

export default MySales;


