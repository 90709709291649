const actions = {
    GET_ERP_LOG_BEGIN: 'GET_ERP_LOG_BEGIN',
    GET_ERP_LOG_SUCCESS: 'GET_ERP_LOG_SUCCESS',
    GET_ERP_LOG_ERROR: 'GET_ERP_LOG_ERROR',

    getERPLogBegin: () => {
        return {
            type: actions.GET_ERP_LOG_BEGIN
        };
    },
    getERPLogSuccess: data => {
        return {
            type: actions.GET_ERP_LOG_SUCCESS,
            data
        };
    },
    getERPLogErr: _ => {
        return {
            type: actions.GET_ERP_LOG_ERROR
        };
    },
}

export default actions;