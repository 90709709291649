import axios from 'axios';
import actions from './actions';

const {
    getPaymentListBegin,
    getPaymentListSuccess,
    getPaymentListErr
} = actions;

export const getPaymentList = () => async(dispatch) =>{
    
   try{
        dispatch(getPaymentListBegin());
       const resp = await axios.get('/api/payment/get-payments');
        dispatch(getPaymentListSuccess(resp.data));
    }catch (err) {
        dispatch(getPaymentListErr(err))
    }

    }



// export {getPaymentList};