import actions from './actions';

const initialState = {
  payment: [],
  loading: false,
  error: null
};

const { GET_PAYMENT_LIST_BEGIN, GET_PAYMENT_LIST_SUCCESS, GET_PAYMENT_LIST_ERR } = actions;

const paymentReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_PAYMENT_LIST_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        payment: data
      };
    case GET_PAYMENT_LIST_ERR:
      return {
        ...state,
        payment: [],
        loading: false,
        err: err
      };
    default:
      return state;
  }
};

export default paymentReducer;
