import axios from "axios";

export const categories = [
  {
    img: '/assets/img/category/icon-service-parts.svg',
    value: 'Service Parts',
    _id: 'Service Parts',
    name: 'Service Parts'
  },
  {
    img: '/assets/img/category/icon-engine-parts.svg',
    value: 'Engine Parts',
    _id: 'Engine Parts',
    name: 'Engine Parts'
  },
  {
    img: '/assets/img/category/icon-engine-cooling.svg',
    value: 'Engine Cooling',
    _id: 'Engine Cooling',
    name: 'Engine Cooling'
  },
  {
    img: '/assets/img/category/icon-fuel-parts.svg',
    value: 'Fuel Parts',
    _id: 'Fuel Parts',
    name: 'Fuel Parts'
  },
  {
    img: '/assets/img/category/icon-transmission-parts.svg',
    value: 'Transmission Parts',
    _id: 'Transmission Parts',
    name: 'Transmission Parts'
  },
  {
    img: '/assets/img/category/icon-clutch-parts.svg',
    value: 'Clutch Parts',
    _id: 'Clutch Parts',
    name: 'Clutch Parts'
  },
  {
    img: '/assets/img/category/icon-lamp-parts.svg',
    value: 'Lamp Items',
    _id: 'Lamp Items',
    name: 'Lamp Items'
  },
  {
    img: '/assets/img/category/icon-electricals-parts.svg',
    value: 'Electricals',
    _id: 'Electricals',
    name: 'Electricals'
  },
  {
    img: '/assets/img/category/icon-suspension-parts.svg',
    value: 'Suspension Parts',
    _id: 'Suspension Parts',
    name: 'Suspension Parts'
  },
  {
    img: '/assets/img/category/icon-steering-parts.svg',
    value: 'Steering Parts',
    _id: 'Steering Parts',
    name: 'Steering Parts'
  },
  {
    img: '/assets/img/category/icon-body-parts.svg',
    value: 'Body Parts',
    _id: 'Body Parts',
    name: 'Body Parts'
  },
  {
    img: '/assets/img/category/icon-brake-parts.svg',
    value: 'Brake Parts',
    _id: 'Brake Parts',
    name: 'Brake Parts'
  },
  {
    img: '/assets/img/category/icon-rubber-parts.svg',
    value: 'Rubber Parts',
    _id: 'Rubber Parts',
    name: 'Rubber Parts'
  },
  {
    img: '/assets/img/category/icon-batteries-parts.svg',
    value: 'Batteries, Lubricants, Tires',
    _id: 'Batteries, Lubricants, Tires',
    name: 'Batteries, Lubricants, Tires'
  },
  {
    img: '/assets/img/category/icon-interior-parts.svg',
    value: 'Interior & Exterior Accessories',
    _id: 'Interior & Exterior Accessories',
    name: 'Interior & Exterior Accessories'
  }
];

const serviceParts = [
  { label: 'Oil Filters', value: 'Oil Filters' },
  { label: 'Oil', value: 'Oil' },
  { label: 'Air Filters', value: 'Air Filters' },
  { label: 'Fuel Filters', value: 'Fuel Filters' },
  { label: 'AC Filters', value: 'AC Filters' },
  { label: 'Spark Plugs', value: 'Spark Plugs' },
  { label: 'Drive Belt', value: 'Drive Belt' },
  { label: 'Brake Parts', value: 'Brake Parts' },
  { label: 'Cabin Filters', value: 'Cabin Filters' },
  { label: 'Glow plugs', value: 'Glow plugs' }
];
const engineParts = [
  { label: 'Gasket Kit Engine', value: 'Gasket Kit Engine' },
  { label: 'Piston', value: 'Piston' },
  { label: 'Piston Ring', value: 'Piston Ring' },
  { label: 'Main Bearing', value: 'Main Bearing' },
  { label: 'Begin Bearing', value: 'Begin Bearing' },
  { label: 'Connecting Rods', value: 'Connecting Rods' },
  { label: 'Cam Shaft', value: 'Cam Shaft' },
  { label: 'Thrust Washer', value: 'Thrust Washer' },
  { label: 'Timing Chain & Belt', value: 'Timing Chain & Belt' },
  { label: 'Idler', value: 'Idler' }
];
const engineCooling = [
  { label: 'Exapnsion Tanks', value: 'Exapnsion Tanks' },
  { label: 'Intercoolers', value: 'Intercoolers' },
  { label: 'Thermostats', value: 'Thermostats' },
  { label: 'Fans & Parts', value: 'Fans & Parts' },
  {
    label: 'Thermostat - Housing & Gaskets',
    value: 'Thermostat - Housing & Gaskets'
  },
  {
    label: 'Oil Coolers & Car Oil Pipes',
    value: 'Oil Coolers & Car Oil Pipes'
  },
  { label: 'Fan Clutches', value: 'Fan Clutches' },
  { label: 'Radiator Caps', value: 'Radiator Caps' },
  { label: 'Water Hoses & Clips', value: 'Water Hoses & Clips' },
  { label: 'Water Pumps & Gaskets', value: 'Water Pumps & Gaskets' }
];
const fuelParts = [
  { label: 'Fuel Pump', value: 'Fuel Pump' },
  { label: 'Injection System', value: 'Injection System' },
  { label: 'Carburetor', value: 'Carburetor' },
  { label: 'Fuel lines', value: 'Fuel lines' },
  { label: 'Fuel tank', value: 'Fuel tank' }
];
const transmissionParts = [
  { label: 'Clutch Kits', value: 'Clutch Kits' },
  { label: 'Flywheels', value: 'Flywheels' },
  { label: 'Clutch Cables', value: 'Clutch Cables' },
  { label: 'Clutch Parts', value: 'Clutch Parts' },
  { label: 'Associated Parts', value: 'Associated Parts' },
  { label: 'CV Boot Kits', value: 'CV Boot Kits' },
  { label: 'Driveshafts', value: 'Driveshafts' },
  { label: 'Wheel Bearings & Hubs', value: 'Wheel Bearings & Hubs' },
  { label: 'Seals', value: 'Seals' },
  { label: 'Transmission Oil', value: 'Transmission Oil' }
];
const clutchParts = [
  { label: 'Clutch Cover', value: 'Clutch Cover' },
  { label: 'Clutch Disc', value: 'Clutch Disc' },
  { label: 'Clutch Bearing', value: 'Clutch Bearing' },
  { label: 'Pilot Bearing', value: 'Pilot Bearing' },
  { label: 'Clutch Cylinder UP/LW', value: 'Clutch Cylinder UP/LW' },
  { label: 'Clutch Cylinder Kit', value: 'Clutch Cylinder Kit' }
];
const lampItems = [
  { label: 'Head lamp', value: 'Head lamp' },
  { label: 'Tail lamp', value: 'Tail lamp' },
  { label: 'Fog lamp', value: 'Fog lamp' },
  { label: 'Reflector', value: 'Reflector' },
  { label: 'Internal lamp', value: 'Internal lamp' },
  { label: 'Headlights', value: 'Headlights' },
  { label: 'Rear Lights', value: 'Rear Lights' },
  { label: 'Indicators', value: 'Indicators' },
  { label: 'Driving & Fog Lamps', value: 'Driving & Fog Lamps' },
  { label: 'Lenses & Other Parts', value: 'Lenses & Other Parts' }
];
const electricals = [
  { label: 'Starter', value: 'Starter' },
  { label: 'Alternator', value: 'Alternator' },
  { label: 'Relay', value: 'Relay' },
  { label: 'Sensors', value: 'Sensors' },
  { label: 'Valves', value: 'Valves' },
  { label: 'Airbags', value: 'Airbags' },
  { label: 'Tire Pressure Sensors', value: 'Tire Pressure Sensors' },
  { label: 'Starter Motors', value: 'Starter Motors' },
  { label: 'Alternators', value: 'Alternators' },
  { label: 'Switches & Sensors', value: 'Switches & Sensors' }
];
const suspensionParts = [
  { label: 'Lower Arm', value: 'Lower Arm' },
  { label: 'Ball Joint', value: 'Ball Joint' },
  { label: 'Link Rod', value: 'Link Rod' },
  { label: 'Stabilizer Bush FR/RR', value: 'Stabilizer Bush FR/RR' },
  { label: 'Arm', value: 'Arm' },
  { label: 'Shock FR/RR & Coil Spring', value: 'Shock FR/RR & Coil Spring' },
  { label: 'Stabilizer Link RR', value: 'Stabilizer Link RR' },
  { label: 'Arm RR', value: 'Arm RR' },
  { label: 'Shock Absorbers', value: 'Shock Absorbers' },
  { label: 'Bushes', value: 'Bushes' }
];
const steeringParts = [
  { label: 'Steering Box', value: 'Steering Box' },
  { label: 'Steering Column', value: 'Steering Column' },
  { label: 'Steering Wheel', value: 'Steering Wheel' },
  { label: 'Steering Tie Rod', value: 'Steering Tie Rod' },
  { label: 'Steering Box Seal Kit', value: 'Steering Box Seal Kit' }
];

const bodyParts = [
  { label: 'FR/RR Bumper parts', value: 'FR/RR Bumper parts' },
  { label: 'Grill Parts', value: 'Grill Parts' },
  { label: 'Bonnet Parts', value: 'Bonnet Parts' },
  { label: 'Doors', value: 'Doors' },
  { label: 'Under Shield', value: 'Under Shield' },
  { label: 'Fenders', value: 'Fenders' },
  { label: 'Roof', value: 'Roof' },
  { label: 'Spoiler', value: 'Spoiler' },
  { label: 'Quarter Panel', value: 'Quarter Panel' },
  { label: 'Body Moldings', value: 'Body Moldings' }
];
const brakeParts = [
  { label: 'Brake Pads FR/RR', value: 'Brake Pads FR/RR' },
  { label: 'Brake Disc', value: 'Brake Disc' },
  { label: 'Caplipers FR/RR', value: 'Caplipers FR/RR' },
  { label: 'Brake Master Cylinder & Kit', value: 'Brake Master Cylinder & Kit' },
  { label: 'Brake Lines', value: 'Brake Lines' },
  { label: 'Speed Sensors', value: 'Speed Sensors' },
  { label: 'Brake Hose', value: 'Brake Hose' },
  { label: 'Brake Pad Wear Sensors', value: 'Brake Pad Wear Sensors' },
  { label: 'Brake Drum', value: 'Brake Drum' },
  { label: 'Brake Shoe', value: 'Brake Shoe' },
  { label: 'Accessories & Fitting Kits', value: 'Accessories & Fitting Kits' },
  { label: 'ABS Sensors', value: 'ABS Sensors' },
  { label: 'Ptjer Jpses & Pipes', value: 'Ptjer Jpses & Pipes' }
];
const rubberParts = [{ label: 'Bushes for Suspension', value: 'Bushes for Suspension' }];
const batteriesParts = [
  { label: 'Lubricants & Fluids', value: 'Lubricants & Fluids' },
  { label: 'Additives', value: 'Additives' },
  { label: 'Batteries & Components', value: 'Batteries & Components' },
  { label: 'Starter Batteries', value: 'Starter Batteries' },
  { label: '60 Amp To 66 Amp', value: '60 Amp To 66 Amp' },
  { label: '70 Amp To 75 Amp', value: '70 Amp To 75 Amp' },
  { label: '80 Amp To 95 Amp', value: '80 Amp To 95 Amp' },
  { label: '100 Amp To 120 Amp', value: '100 Amp To 120 Amp' },
  { label: 'Battery Chargers', value: 'Battery Chargers' },
  { label: 'Battery Maintenance', value: 'Battery Maintenance' },
  { label: 'Booster Cables', value: 'Booster Cables' },
  { label: 'Charger Accessories', value: 'Charger Accessories' },
  { label: 'Diagnostic Station', value: 'Diagnostic Station' },
  { label: 'Jump Starter', value: 'Jump Starter' }
];
const interiorAccessories = [
  { label: 'Floor Mat', value: 'Floor Mat' },
  { label: 'Cargo Net', value: 'Cargo Net' },
  { label: 'Door Handle', value: 'Door Handle' },
  { label: 'Cup Holder', value: 'Cup Holder' },
  { label: 'Running Board & Step Bars', value: 'Running Board & Step Bars' },
  { label: 'Molding', value: 'Molding' },
  { label: 'Mud Flap', value: 'Mud Flap' },
  { label: 'Spare Tire Cover', value: 'Spare Tire Cover' },
  { label: 'Roof Rack', value: 'Roof Rack' },
  { label: 'Horn', value: 'Horn' },
  { label: 'Side Decal', value: 'Side Decal' },
  { label: 'Wiper Arm Cover', value: 'Wiper Arm Cover' },
  { label: 'Pull Bars', value: 'Pull Bars' }
];

export const subCategories = {
  'Service Parts': serviceParts,
  'Engine Parts': engineParts,
  'Engine Cooling': engineCooling,
  'Fuel Parts': fuelParts,
  'Lamp Items': lampItems,
  'Clutch Parts': clutchParts,
  'Transmission Parts': transmissionParts,
  Electricals: electricals,
  'Body Parts': bodyParts,
  'Steering Parts': steeringParts,
  'Suspension Parts': suspensionParts,
  'Brake Parts': brakeParts,
  'Rubber Parts': rubberParts,
  'Batteries, Lubricants, Tires': batteriesParts,
  'Interior & Exterior Accessories': interiorAccessories
};

export const countryList = [
  { label: 'Afghanistan', code: 'AF' },
  { label: 'land Islands', code: 'AX' },
  { label: 'Albania', code: 'AL' },
  { label: 'Algeria', code: 'DZ' },
  { label: 'American Samoa', code: 'AS' },
  { label: 'AndorrA', code: 'AD' },
  { label: 'Angola', code: 'AO' },
  { label: 'Anguilla', code: 'AI' },
  { label: 'Antarctica', code: 'AQ' },
  { label: 'Antigua and Barbuda', code: 'AG' },
  { label: 'Argentina', code: 'AR' },
  { label: 'Armenia', code: 'AM' },
  { label: 'Aruba', code: 'AW' },
  { label: 'Australia', code: 'AU' },
  { label: 'Austria', code: 'AT' },
  { label: 'Azerbaijan', code: 'AZ' },
  { label: 'Bahamas', code: 'BS' },
  { label: 'Bahrain', code: 'BH' },
  { label: 'Bangladesh', code: 'BD' },
  { label: 'Barbados', code: 'BB' },
  { label: 'Belarus', code: 'BY' },
  { label: 'Belgium', code: 'BE' },
  { label: 'Belize', code: 'BZ' },
  { label: 'Benin', code: 'BJ' },
  { label: 'Bermuda', code: 'BM' },
  { label: 'Bhutan', code: 'BT' },
  { label: 'Bolivia', code: 'BO' },
  { label: 'Bosnia and Herzegovina', code: 'BA' },
  { label: 'Botswana', code: 'BW' },
  { label: 'Bouvet Island', code: 'BV' },
  { label: 'Brazil', code: 'BR' },
  { label: 'British Indian Ocean Territory', code: 'IO' },
  { label: 'Brunei Darussalam', code: 'BN' },
  { label: 'Bulgaria', code: 'BG' },
  { label: 'Burkina Faso', code: 'BF' },
  { label: 'Burundi', code: 'BI' },
  { label: 'Cambodia', code: 'KH' },
  { label: 'Cameroon', code: 'CM' },
  { label: 'Canada', code: 'CA' },
  { label: 'Cape Verde', code: 'CV' },
  { label: 'Cayman Islands', code: 'KY' },
  { label: 'Central African Republic', code: 'CF' },
  { label: 'Chad', code: 'TD' },
  { label: 'Chile', code: 'CL' },
  { label: 'China', code: 'CN' },
  { label: 'Christmas Island', code: 'CX' },
  { label: 'Cocos (Keeling) Islands', code: 'CC' },
  { label: 'Colombia', code: 'CO' },
  { label: 'Comoros', code: 'KM' },
  { label: 'Congo', code: 'CG' },
  { label: 'Congo, The Democratic Republic of the', code: 'CD' },
  { label: 'Cook Islands', code: 'CK' },
  { label: 'Costa Rica', code: 'CR' },
  { label: `Cote D"Ivoire`, code: 'CI' },
  { label: 'Croatia', code: 'HR' },
  { label: 'Cuba', code: 'CU' },
  { label: 'Cyprus', code: 'CY' },
  { label: 'Czech Republic', code: 'CZ' },
  { label: 'Denmark', code: 'DK' },
  { label: 'Djibouti', code: 'DJ' },
  { label: 'Dominica', code: 'DM' },
  { label: 'Dominican Republic', code: 'DO' },
  { label: 'Ecuador', code: 'EC' },
  { label: 'Egypt', code: 'EG' },
  { label: 'El Salvador', code: 'SV' },
  { label: 'Equatorial Guinea', code: 'GQ' },
  { label: 'Eritrea', code: 'ER' },
  { label: 'Estonia', code: 'EE' },
  { label: 'Ethiopia', code: 'ET' },
  { label: 'Falkland Islands (Malvinas)', code: 'FK' },
  { label: 'Faroe Islands', code: 'FO' },
  { label: 'Fiji', code: 'FJ' },
  { label: 'Finland', code: 'FI' },
  { label: 'France', code: 'FR' },
  { label: 'French Guiana', code: 'GF' },
  { label: 'French Polynesia', code: 'PF' },
  { label: 'French Southern Territories', code: 'TF' },
  { label: 'Gabon', code: 'GA' },
  { label: 'Gambia', code: 'GM' },
  { label: 'Georgia', code: 'GE' },
  { label: 'Germany', code: 'DE' },
  { label: 'Ghana', code: 'GH' },
  { label: 'Gibraltar', code: 'GI' },
  { label: 'Greece', code: 'GR' },
  { label: 'Greenland', code: 'GL' },
  { label: 'Grenada', code: 'GD' },
  { label: 'Guadeloupe', code: 'GP' },
  { label: 'Guam', code: 'GU' },
  { label: 'Guatemala', code: 'GT' },
  { label: 'Guernsey', code: 'GG' },
  { label: 'Guinea', code: 'GN' },
  { label: 'Guinea-Bissau', code: 'GW' },
  { label: 'Guyana', code: 'GY' },
  { label: 'Haiti', code: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { label: 'Holy See (Vatican City State)', code: 'VA' },
  { label: 'Honduras', code: 'HN' },
  { label: 'Hong Kong', code: 'HK' },
  { label: 'Hungary', code: 'HU' },
  { label: 'Iceland', code: 'IS' },
  { label: 'India', code: 'IN' },
  { label: 'Indonesia', code: 'ID' },
  { label: 'Iran, Islamic Republic Of', code: 'IR' },
  { label: 'Iraq', code: 'IQ' },
  { label: 'Ireland', code: 'IE' },
  { label: 'Isle of Man', code: 'IM' },
  { label: 'Israel', code: 'IL' },
  { label: 'Italy', code: 'IT' },
  { label: 'Jamaica', code: 'JM' },
  { label: 'Japan', code: 'JP' },
  { label: 'Jersey', code: 'JE' },
  { label: 'Jordan', code: 'JO' },
  { label: 'Kazakhstan', code: 'KZ' },
  { label: 'Kenya', code: 'KE' },
  { label: 'Kiribati', code: 'KI' },
  { label: 'Korea, Republic of', code: 'KR' },
  { label: 'Kuwait', code: 'KW' },
  { label: 'Kyrgyzstan', code: 'KG' },
  { label: `Lao People"S Democratic Republic`, code: 'LA' },
  { label: 'Latvia', code: 'LV' },
  { label: 'Lebanon', code: 'LB' },
  { label: 'Lesotho', code: 'LS' },
  { label: 'Liberia', code: 'LR' },
  { label: 'Libyan Arab Jamahiriya', code: 'LY' },
  { label: 'Liechtenstein', code: 'LI' },
  { label: 'Lithuania', code: 'LT' },
  { label: 'Luxembourg', code: 'LU' },
  { label: 'Macao', code: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { label: 'Madagascar', code: 'MG' },
  { label: 'Malawi', code: 'MW' },
  { label: 'Malaysia', code: 'MY' },
  { label: 'Maldives', code: 'MV' },
  { label: 'Mali', code: 'ML' },
  { label: 'Malta', code: 'MT' },
  { label: 'Marshall Islands', code: 'MH' },
  { label: 'Martinique', code: 'MQ' },
  { label: 'Mauritania', code: 'MR' },
  { label: 'Mauritius', code: 'MU' },
  { label: 'Mayotte', code: 'YT' },
  { label: 'Mexico', code: 'MX' },
  { label: 'Micronesia, Federated States of', code: 'FM' },
  { label: 'Moldova, Republic of', code: 'MD' },
  { label: 'Monaco', code: 'MC' },
  { label: 'Mongolia', code: 'MN' },
  { label: 'Montenegro', code: 'ME' },
  { label: 'Montserrat', code: 'MS' },
  { label: 'Morocco', code: 'MA' },
  { label: 'Mozambique', code: 'MZ' },
  { label: 'Myanmar', code: 'MM' },
  { label: 'Namibia', code: 'NA' },
  { label: 'Nauru', code: 'NR' },
  { label: 'Nepal', code: 'NP' },
  { label: 'Netherlands', code: 'NL' },
  { label: 'Netherlands Antilles', code: 'AN' },
  { label: 'New Caledonia', code: 'NC' },
  { label: 'New Zealand', code: 'NZ' },
  { label: 'Nicaragua', code: 'NI' },
  { label: 'Niger', code: 'NE' },
  { label: 'Nigeria', code: 'NG' },
  { label: 'Niue', code: 'NU' },
  { label: 'Norfolk Island', code: 'NF' },
  { label: 'Northern Mariana Islands', code: 'MP' },
  { label: 'Norway', code: 'NO' },
  { label: 'Oman', code: 'OM' },
  { label: 'Pakistan', code: 'PK' },
  { label: 'Palau', code: 'PW' },
  { label: 'Palestinian Territory, Occupied', code: 'PS' },
  { label: 'Panama', code: 'PA' },
  { label: 'Papua New Guinea', code: 'PG' },
  { label: 'Paraguay', code: 'PY' },
  { label: 'Peru', code: 'PE' },
  { label: 'Philippines', code: 'PH' },
  { label: 'Pitcairn', code: 'PN' },
  { label: 'Poland', code: 'PL' },
  { label: 'Portugal', code: 'PT' },
  { label: 'Puerto Rico', code: 'PR' },
  { label: 'Qatar', code: 'QA' },
  { label: 'Reunion', code: 'RE' },
  { label: 'Romania', code: 'RO' },
  { label: 'Russian Federation', code: 'RU' },
  { label: 'RWANDA', code: 'RW' },
  { label: 'Saint Helena', code: 'SH' },
  { label: 'Saint Kitts and Nevis', code: 'KN' },
  { label: 'Saint Lucia', code: 'LC' },
  { label: 'Saint Pierre and Miquelon', code: 'PM' },
  { label: 'Saint Vincent and the Grenadines', code: 'VC' },
  { label: 'Samoa', code: 'WS' },
  { label: 'San Marino', code: 'SM' },
  { label: 'Sao Tome and Principe', code: 'ST' },
  { label: 'Saudi Arabia', code: 'SA' },
  { label: 'Senegal', code: 'SN' },
  { label: 'Serbia', code: 'RS' },
  { label: 'Seychelles', code: 'SC' },
  { label: 'Sierra Leone', code: 'SL' },
  { label: 'Singapore', code: 'SG' },
  { label: 'Slovakia', code: 'SK' },
  { label: 'Slovenia', code: 'SI' },
  { label: 'Solomon Islands', code: 'SB' },
  { label: 'Somalia', code: 'SO' },
  { label: 'South Africa', code: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { label: 'Spain', code: 'ES' },
  { label: 'Sri Lanka', code: 'LK' },
  { label: 'Sudan', code: 'SD' },
  { label: 'Suriname', code: 'SR' },
  { label: 'Svalbard and Jan Mayen', code: 'SJ' },
  { label: 'Swaziland', code: 'SZ' },
  { label: 'Sweden', code: 'SE' },
  { label: 'Switzerland', code: 'CH' },
  { label: 'Syrian Arab Republic', code: 'SY' },
  { label: 'Taiwan, Province of China', code: 'TW' },
  { label: 'Tajikistan', code: 'TJ' },
  { label: 'Tanzania, United Republic of', code: 'TZ' },
  { label: 'Thailand', code: 'TH' },
  { label: 'Timor-Leste', code: 'TL' },
  { label: 'Togo', code: 'TG' },
  { label: 'Tokelau', code: 'TK' },
  { label: 'Tonga', code: 'TO' },
  { label: 'Trinidad and Tobago', code: 'TT' },
  { label: 'Tunisia', code: 'TN' },
  { label: 'Turkey', code: 'TR' },
  { label: 'Turkmenistan', code: 'TM' },
  { label: 'Turks and Caicos Islands', code: 'TC' },
  { label: 'Tuvalu', code: 'TV' },
  { label: 'Uganda', code: 'UG' },
  { label: 'Ukraine', code: 'UA' },
  { label: 'United Arab Emirates', code: 'AE' },
  { label: 'United Kingdom', code: 'GB' },
  { label: 'United States', code: 'US' },
  { label: 'United States Minor Outlying Islands', code: 'UM' },
  { label: 'Uruguay', code: 'UY' },
  { label: 'Uzbekistan', code: 'UZ' },
  { label: 'Vanuatu', code: 'VU' },
  { label: 'Venezuela', code: 'VE' },
  { label: 'Viet Nam', code: 'VN' },
  { label: 'Virgin Islands, British', code: 'VG' },
  { label: 'Virgin Islands, U.S.', code: 'VI' },
  { label: 'Wallis and Futuna', code: 'WF' },
  { label: 'Western Sahara', code: 'EH' },
  { label: 'Yemen', code: 'YE' },
  { label: 'Zambia', code: 'ZM' },
  { label: 'Zimbabwe', code: 'ZW' }
];

export const servers = [
  {
    serverName: 'Gmail',
    serverLogo: 'gmail.png',
    mailer: '',
    host: '',
    port: '',
    username: '',
    password: '',
    encryption: '',
    mailFromAddress: '',
    mailFromName: ''
  },
  {
    serverName: 'Yahoo',
    serverLogo: 'yahoo.png',
    mailer: '',
    host: '',
    port: '',
    username: '',
    password: '',
    encryption: '',
    mailFromAddress: '',
    mailFromName: ''
  },
  {
    serverName: 'Webmail',
    serverLogo: 'cpanel.png',
    mailer: '',
    host: '',
    port: '',
    username: '',
    password: '',
    encryption: '',
    mailFromAddress: '',
    mailFromName: ''
  },
  {
    serverName: 'Mailgun',
    serverLogo: 'mailgun.png',
    mailer: '',
    host: '',
    port: '',
    username: '',
    password: '',
    encryption: '',
    mailFromAddress: '',
    mailFromName: ''
  },
  {
    serverName: 'Zoho',
    serverLogo: 'zoho.jpeg',
    mailer: '',
    host: '',
    port: '',
    username: '',
    password: '',
    encryption: '',
    mailFromAddress: '',
    mailFromName: ''
  },
  {
    serverName: 'Elastic',
    serverLogo: 'elastic.jpeg',
    mailer: '',
    host: '',
    port: '',
    username: '',
    password: '',
    encryption: '',
    mailFromAddress: '',
    mailFromName: ''
  }
];

export const itemsCategories = [
  { label: 'Category 1', value: 'Category 1' },
  { label: 'Category 2', value: 'Category 2' },
  { label: 'Category 3', value: 'Category 3' },
  { label: 'Category 4a', value: 'Category 4a' },
  { label: 'Category 4b', value: 'Category 4b' }
];

export const emiratesOptions = [
  "Abu Dhabi",
  "Ajman",
  "Dubai",
  "Fujairah",
  "Ras Al Khaimah",
  "Sharjah",
  "Umm Al Quwain",
];

export const collectionStatusOptions = [
  { label: "Published", value: "published" },
  { label: "Draft", value: "draft" },
  { label: "Archive", value: "archive" },
]

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const socketURL = process.env.REACT_APP_BASE_URL_SOCKET;

export const DISSCUSSION_LISTING_ID = process.env.REACT_APP_DISCUSSION_LISTING_ID;

export const SLIDER_CHANGE_FACTOR = 10

export const LISTING_UPLOAD_LIMIT = 5;



let ids = []
// axios.get(`${baseUrl}/api/subcategories/get-tire-categories`).then(res => res?.data?.data?.map(item => ids.push(item?._id)))  
export const TIRES_CATEGORY_IDS = ids

// export const TIRES_CATEGORY_IDS = ['61e525951f3a4da7aa478df0','61e525951f3a4da7aa478df1','61e525951f3a4da7aa478df2']


export const orderItemStatus = {
  pending: 'PENDING',
  preparing: 'Preparing',
  onHold: 'On-Hold',
  outForDelivery: 'Out for Delivery',
  issue: 'issue',
  delivered: 'delivered',
  cancel: 'cancelled'
}

export const erpActionModels = [
  { label: "USER", value: "user" },
  { label: "ORDER", value: "order" },
  { label: "SALES LINE STATUS", value: "sales_line_status" },
  { label: "COLLECTION", value: "Collection" },
  { label: "RETURN SALES", value: "ReturnSales" },
];

export const erpStatus = [
  { label: "Success", value: "success" },
  { label: "Fail", value: "fail" },
]

export const sectionModels = [
  { label: "Collections", value: "collection" }
]

export const sectionTypes = [
  { label: "Product Slider", value: "product_slider" },
  { label: "Thumbnail Slider", value: "thumbnail_slider" },
  { label: "Thumbnail Grid", value: "thumbnail_grid" },
  { label: "Banner", value: "banner_slider" }
]

export const sellerBusinessModel = [
  { label: "Fulfilment", value: "Fulfilment" },
  { label: "Consolidation", value: "Consolidation" },
]

export const enableDisableOption = [
  { label: "Disable Listing Deletion", value: "disableListingDeletion" },
  { label: "Enable Listing Deletion", value: "enableListingDeletion" }
]

export const enableDisableFilterOption = [
  { label: "Disabled Listing", value: "disabled" },
  { label: "Enabled Listing", value: "enabled" }
]

export const listingTypes = [
  { label: "Genuine", value: "Genuine" },
  { label: "Aftermarket", value: "Aftermarket" },
  { label: "TBA", value: "TBA" },
]