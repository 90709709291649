import React from 'react';
import { Row, Col } from 'antd';
import { FolderOpenFilled, FileImageOutlined } from '@ant-design/icons';
import { ProductImage } from '../../../utility/utility';

const Files = ({ files, openFolder}) => {
  
  return (
    <Row>
   {
     files.map((item, i) => {
       return (
         <Col xl={3} md={6} xs={6} key={i} style={{marginBottom:'25px'}} >
           {
             item.type === 'folder' ? 
              <div style={{display:'flex',flexDirection:'column', alignItems:'center', cursor:'pointer'}} onClick={() => {openFolder(item)}} >
                <FolderOpenFilled style={{ fontSize: '50px', color:'#8a8a8a' }} />{item.name}
              </div> :
              <div>
                 <ProductImage avatar={item.url} alt={`image`} height="70%" width="70%" style={{ objectFit: 'contain', padding: '4px' }} />
              </div>
           }
        </Col>
       )
     })
   }
  </Row>
  );
};

export default Files;
