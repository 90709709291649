import actions from './action';

const initialState = {
  collections: [],
  isLoading: false,
  totalCollection: 0,
  error: null,
};

const {
  COLLECTION_BEGIN,
  GET_COLLECTION_SUCCESS,
  ADD_COLLECTION_SUCCESS,
  COLLECTION_ERR,
  EDIT_COLLECTION_SUCCESS,
  DELETE_COLLECTION_SUCCESS,
  CHANGE_COLLECTION_POSITION 
} = actions;

const collectionReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COLLECTION_BEGIN:
      return {
        ...state,
        isLoading: true
      };
    case GET_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: data?.collections,
        totalCollection: data?.totalCollection,
        isLoading: false
      };
    case ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case EDIT_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case COLLECTION_ERR:
      return {
        ...state,
        error: err,
        isLoading: false
      };
    case CHANGE_COLLECTION_POSITION: 
    return {
      ...state,
      collections: data,
      isLoading: false,
    }
    default:
      return state;
  }
};

export default collectionReducer;
