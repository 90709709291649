import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Select, Button, Upload, TimePicker, message } from 'antd';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { UploadOutlined } from '@ant-design/icons';
import { WizardWrapper } from '../Style';
import { Steps } from '../../../../components/steps/steps';
import Heading from '../../../../components/heading/heading';
import { register } from '../../../../redux/authentication/actionCreator';

const AddMarketing = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    values: null,
    checked: null
  });
  const handleSubmit = values => {
    values.role = 'marketing_manager';
    setState({ ...state, values });
    dispatch(register(values, history));
  };
  return (
    <WizardWrapper>
      <div className="auth-contents" style={{ padding: '25px' }}>
        <Form name="register" onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Add new<span className="color-secondary"> Marketing Manager</span>
          </Heading>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your Full name!' }]}>
            <Input placeholder="Full name" />
          </Form.Item>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
          >
            <Input placeholder="name@example.com" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message:
                  'Your Password must contain : Minimum eight characters, at least one uppercase letter, one lowercase letter,one number and one special character. !'
              },
              { required: true, message: 'Please input your password!' }
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button className="btn-create" htmlType="submit" type="primary" size="large">
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </div>
    </WizardWrapper>
  );
};

export default AddMarketing;
