import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { RevenueWrapper } from './style';
import { ChartjsAreaChart } from '../TotalRevenue/chart';
import { customTooltips, chartLinearGradient } from '../../components/utilities/utilities.js';
import { performanceFilterData, performanceGetData } from '../../redux/TotalRevenue/actionCreator';
import { Cards } from '../../components/cards/frame/cards-frame';
import axios from 'axios';
import { noOfOrdersPerDayOnWeek, noOfOrdersPerMonth, noOfOrdersPerYears, calcPrevRevenue, calcCurrentRevenue } from '../../utility/revenue';
import moment from 'moment-timezone';

const moreContent = (
  <>
    <NavLink to="#">
      <FeatherIcon size={16} icon="printer" />
      <span>Printer</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="book-open" />
      <span>PDF</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="file-text" />
      <span>Google Sheets</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="x" />
      <span>Excel (XLSX)</span>
    </NavLink>
    <NavLink to="#">
      <FeatherIcon size={16} icon="file" />
      <span>CSV</span>
    </NavLink>
  </>
);
const UserRevenue = ({ title,id,role,loader }) => {
  const dispatch = useDispatch();
  const [userRevenue, setUserRevenue] = useState(null);
  const [chartData, setChartData] = useState(null);
  const { performanceState, preIsLoading } = useSelector(state => {
    return {
      performanceState: state.totalRevenueContent.performanceData,
      preIsLoading: state.totalRevenueContent.perLoading,
    };
  });

  const [state, setState] = useState({
    revenue: 'year',
  });

  const getRevenueBuyer = async( ) => {
      const result = await axios.get(`/api/order/get-total-purchaes?buyer_id=${id}`);
      setUserRevenue(result.data);
      if (performanceGetData) {
        const chartData = getDataAccordingly(result.data);
        setChartData(chartData);
        dispatch(performanceGetData(chartData));
        
      }
   
  }

  const getRevenueSeller = async( ) => {
      const result = await axios.get(`/api/order/get-revenue?seller_id=${id}`);
      setUserRevenue(result.data);
      if (performanceGetData) {
        const chartData = getDataAccordingly(result.data);
        setChartData(chartData);
        dispatch(performanceGetData(chartData));
      }
  }


  useEffect(() => {
      getRevenueBuyer()
  
  }, [dispatch]);
  


  const getDataAccordingly = userRevenue => {
    const thisWeekOrder = noOfOrdersPerDayOnWeek(userRevenue?.thisWeekOrders || []);
    const previousWeekOrder = noOfOrdersPerDayOnWeek(userRevenue?.lastWeekOrders || []);
    const thisYearMonthsOrders = noOfOrdersPerMonth(userRevenue?.thisYearOrders || []);
    const prevYearMonthsOrders = noOfOrdersPerMonth(userRevenue?.lastYearOrders || []);
    const yearsOrder = noOfOrdersPerYears(userRevenue || []);

    return {
      performance: {
        week: {
          orders: [thisWeekOrder, previousWeekOrder],
          labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
        month: {
          orders: [thisYearMonthsOrders, prevYearMonthsOrders],
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        year: {
          orders: [yearsOrder],
          labels: [
            moment()
              .subtract(5, 'years')
              .year(),
            moment()
              .subtract(4, 'years')
              .year(),
            moment()
              .subtract(3, 'years')
              .year(),
            moment()
              .subtract(2, 'years')
              .year(),
            moment()
              .subtract(1, 'years')
              .year(),
            moment()
              .subtract(0, 'years')
              .year(),
          ],
        },
      },
    };
  };

  const handleActiveChangeRevenue = value => {
    setState({
      ...state,
      revenue: value,
    });
    const { week, month, year } = chartData?.performance;
    let filteredData = null;
    if (value === 'week') {
      filteredData = week;
    } else if (value === 'month') {
      filteredData = month;
    } else {
      filteredData = year;
    }

    return dispatch(performanceFilterData(filteredData));
  };

  const performanceDatasetsForYearOnly = performanceState !== null && [
    {
      data: performanceState.orders[0],
      borderColor: '#1D71B8',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#1D71B830',
          end: '#ffffff05',
        }),
      label: 'Last Five Year Sales',
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#1D71B8',
      hoverBorderWidth: 5,
      amount: `AED ${calcCurrentRevenue(userRevenue,state.revenue)}`,
      afterTax: `After 5% tax, AED ${calcCurrentRevenue(userRevenue, state.revenue, true)}`,
      amountClass: 'current-amount',
    },
  ];
  const performanceDatasetsForOthers = performanceState !== null && [
    {
      data: performanceState.orders[0],
      borderColor: '#1D71B8',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#1D71B830',
          end: '#ffffff05',
        }),
      label: `This ${state.revenue}`,
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#1D71B8',
      hoverBorderWidth: 5,
      amount: `AED ${calcCurrentRevenue(userRevenue, state.revenue)}`,
      afterTax: `After 5% tax, AED ${calcCurrentRevenue(userRevenue, state.revenue, true)}`,
      amountClass: 'current-amount',
    },
    {
      data: performanceState.orders[1],
      borderColor: 'rgb(32, 201, 151)',
      borderWidth: 2,
      fill: false,
      backgroundColor: '#00173750',
      label: `Previous ${state.revenue}`,
      // borderDash: [3, 3],
      pointRadius: '0',
      hoverRadius: '0',
      amount: `AED ${calcPrevRevenue(userRevenue, state.revenue)}`,
      afterTax: `After 5% tax, AED ${calcPrevRevenue(userRevenue, state.revenue, true)}`,
      amountClass: 'prev-amount',
    },
  ];

  const performanceDatasets = state.revenue === 'year' ? performanceDatasetsForYearOnly : performanceDatasetsForOthers;

  return (
    <RevenueWrapper>
      {performanceState !== null && (
        <Cards
          isbutton={
            <div className="card-nav">
              <ul>
                <li className={state.revenue === 'week' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('week')} to="#">
                    Week
                  </Link>
                </li>
                <li className={state.revenue === 'month' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('month')} to="#">
                    Month
                  </Link>
                </li>
                <li className={state.revenue === 'year' ? 'active' : 'deactivate'}>
                  <Link onClick={() => handleActiveChangeRevenue('year')} to="#">
                    Year
                  </Link>
                </li>
              </ul>
            </div>
          }
          more={moreContent}
          title={title}
          size="large"
        >
          {loader?(
            <div style={{height:'100%',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center'}}>
                <Spin/>
            </div>
          ):(
            <>
              {preIsLoading ? (
            <div className="sd-spin">
              <Spin />
            </div>
          ) : (
            <div className="performance-lineChart">
              <ul>
                {performanceDatasets &&
                  performanceDatasets.map((item, key) => {
                    return (
                      <li key={key + 1} className="custom-label">
                        <strong className={item.amountClass}>{item.amount}</strong>
                        <div>
                          <span
                            style={{
                              backgroundColor: item.borderColor,
                            }}
                          />
                          {item.label}
                        </div>
                      </li>
                    );
                  })}
              </ul>

              <ChartjsAreaChart
                id="performance"
                labels={performanceState.labels}
                datasets={performanceDatasets}
                options={{
                  maintainAspectRatio: true,
                  elements: {
                    z: 9999,
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                      boxWidth: 6,
                      display: false,
                      usePointStyle: true,
                    },
                  },
                  hover: {
                    mode: 'index',
                    intersect: false,
                  },
                  tooltips: {
                    mode: 'label',
                    intersect: false,
                    backgroundColor: '#ffffff',
                    position: 'average',
                    enabled: false,
                    custom: customTooltips,
                    callbacks: {
                      title() {
                        return `Total Orders`;
                      },
                      label(t, d) {
                        const { yLabel, datasetIndex } = t;
                        return `<span class="chart-data">${yLabel}</span> <span class="data-label"> order</span>`;
                      },
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: '#e5e9f2',
                          borderDash: [3, 3],
                          zeroLineColor: '#e5e9f2',
                          zeroLineWidth: 1,
                          zeroLineBorderDash: [3, 3],
                        },
                        ticks: {
                          beginAtZero: true,
                          fontSize: 13,
                          fontColor: '#182b49',
                        },
                      },
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          display: true,
                          zeroLineWidth: 2,
                          zeroLineColor: 'transparent',
                          color: 'transparent',
                          z: 1,
                          tickMarkLength: 0,
                        },
                        ticks: {
                          padding: 10,
                        },
                      },
                    ],
                  },
                }}
                height={window.innerWidth <= 575 ? 200 : 120}
              />
            </div>
          )}
            </>
          )}
        </Cards>
      )}
    </RevenueWrapper>
  );
};

UserRevenue.defaultProps = {
  title: 'Total Sales',
};

UserRevenue.propTypes = {
  title: PropTypes.string,
};

export default UserRevenue;
