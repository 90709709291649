import React from 'react';
import FeatherIcon from 'feather-icons-react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { UserBioBox } from './style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import { formatPhoneNumber } from '../../../utility/utility';
import { Row, Col } from 'antd';

import {
  HomeOutlined,
} from '@ant-design/icons';
import Details from '../../bulkuploadedlisting/Details';
const UserBio = ({data,links}) => {
  return (
    <UserBioBox>
      <Cards headless>
        <article className="user-info">
          <h5 className="user-info__title">User Bio</h5>
          <Row>
            {
              data?.seller_code &&
              <Col xs={24} md={24} sm={24}>
                  <Row>
                    <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>Seller Code:</Col>
                    <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}} >{data?.seller_code}</Col>
                  </Row>
                </Col>
            }
            <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>Company's Name:</Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}} >{data?.details?.company_name}</Col>
                </Row>
            </Col>
                     <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>Trade No:</Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}}>{data?.details?.trade_license_no}</Col>
                </Row>
            </Col>
            <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>VAT No:</Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}}>{data?.details?.vat_registration_no}</Col>
                </Row>
            </Col>
             <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>Company Emirate:</Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}}>{data?.details?.emirate}</Col>
                </Row>
            </Col>            
            <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>Company Status: </Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}}>{data?.status}</Col>
                </Row>
            </Col>
            <Col xs={24} md={24} sm={24}>
                <Row>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:600,color:'#656c90'}}>Company Type:</Col>
                  <Col lg={12} sm={12} md={12} xs={12} style={{fontWeight:500}}>{data?.details?.seller_type}</Col>
                </Row>
            </Col>
    
          </Row>
        </article>
        <address className="user-info">
          <h5 className="user-info__title">Contact Info</h5>
          <ul className="user-info__contact">
            <li>
              <FeatherIcon icon="mail" size={14} /> <span>{data?.email}</span>
            </li>
            <li>
              <FeatherIcon icon="phone" size={14} /> <span>{data?.phone && formatPhoneNumber(data?.phone)}</span>
            </li>
            <li>
              <HomeOutlined size={14} style={{marginBottom:'3px'}} /> <span>{data?.details?.company_address}</span>
            </li>
          </ul>
        </address>
        {links && links.length>0?(
          <div className="user-info">
          <h5 className="user-info__title">Social Profiles</h5>
          <div className="card__social">
            {links?.map((item)=>{
              return (
                <a href={item.link} target="_blank">
                   <div className="btn-icon facebook">
                      <FeatherIcon icon={item.name} />
                    </div>
               </a>
              )
            })}
           
          </div>
        </div>
        ):null}
        
      </Cards>
    </UserBioBox>
  );
};

export default UserBio;
