import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

const UploadZip = ({getFolders}) => {
    const [fileList, setFileList] = useState([])
    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            let ext = file?.name?.split('.')?.pop()?.toLowerCase()
            if (ext !== 'zip') {
                message.error(`${file.name} is not a ZIP file`);
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        fileList,
        maxCount: 1,
        action: `${process.env.REACT_APP_ASSETS_URL}/api/imageupload/upload-zip`,
        onChange(info) {
            const { status, response } = info.file;
            setFileList(info.fileList);
            if (status !== 'uploading') {

            }
            if (status === 'done') {
                getFolders()
                setFileList([])
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                getFolders()
                setFileList([])
                message.error(`${response}`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };



    return (
        <>
        <div style={{width:"80%"}}>            
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
            <p>Drag a .zip file of images or click to upload</p>
            </Dragger>
        </div>
        </>
    );
};

export default UploadZip