const actions = {
  WAREHOUSE_BEGIN: 'WAREHOUSE_BEGIN',
  GET_WAREHOUSE_SUCCESS: 'GET_WAREHOUSE_SUCCESS',
  ADD_WAREHOUSE_SUCCESS: 'WAREHOUSE_SUCCESS',
  EDIT_WAREHOUSE_SUCCESS: 'EDIT_WAREHOUSE_SUCCESS',
  DELETE_WAREHOUSE_SUCCESS: 'DELETE_WAREHOUSE_SUCCESS',
  WAREHOUSE_ERR: 'WAREHOUSE_ERR',
  warehouseBegin: () => {
    return {
      type: actions.WAREHOUSE_BEGIN
    };
  },
  getWarehouseSuccess: data => {
    return {
      type: actions.GET_WAREHOUSE_SUCCESS,
      data
    };
  },
  addWarehouseSuccess: data => {
    return {
      type: actions.ADD_WAREHOUSE_SUCCESS,
      data
    };
  },
  editWarehouseSuccess: data => {
    return {
      type: actions.EDIT_WAREHOUSE_SUCCESS,
      data
    };
  },
  deleteWarehouseSuccess: data => {
    return {
      type: actions.DELETE_WAREHOUSE_SUCCESS,
      data
    };
  },
  warehouseErr: err => {
    return {
      type: actions.WAREHOUSE_ERR,
      err
    };
  }
};

export default actions;
