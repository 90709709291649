import React, { useState, useEffect } from 'react';
import { Table,message } from 'antd';
import { UserTableStyleWrapper } from './style';
import { TableWrapper, StatusWrapper } from '../styled';
import Heading from '../../components/heading/heading';
import { ProductImage } from '../../utility/utility';
const RfqPDetailsListingList = ({ rfqPList }) => {
  const tableData = [];
 
  rfqPList &&
    rfqPList.length > 0 &&
    rfqPList.map((rfq, index) => {
      const { _id, listingId, quantity} = rfq;
      const {imageArray, _id:imgId, partName, partSKU} = listingId || {};

      return tableData.push({
        key: _id,
        image: (
          <div className="user-info">
            <figure>
            
            <ProductImage
                avatar={imageArray[0]}
                alt={`img${imgId}`}
                height={80}
                width="100%"
                style={{ objectFit: 'cover' }}
                />
            </figure>
          </div>
        ),
        name: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {partName}
              <br/>
              <strong style={{whiteSpace: "pre-wrap"}}>({partSKU})</strong>
            </Heading>
          </div>
        ),
        quantity: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {quantity}
            </Heading>
          </div>
        )
      });
    });

  const ordersTableColumns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ];


  return (
    <>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={tableData}
            columns={ordersTableColumns}
            pagination={{
              defaultPageSize: 10,
              total: tableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
    </>
  );
};

export default RfqPDetailsListingList;
