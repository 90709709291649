import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Select, Button, message, Popconfirm, Tag, Typography, Upload } from 'antd';
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '../../../../components/page-headers/page-headers';
import { LoadingOutlined,PaperClipOutlined, UploadOutlined,SaveOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Main, BasicFormWrapper, CardToolbox } from '../../../styled';
import { addCoupon, getCoupon, getUserCoupons } from '../../../../redux/coupon/actionCreator';
import CouponUserListTable from '../../overview/CouponUserListTable';
import './style.css';

import SearchBuyer from '../../price/SearchBuyer';
import AssignModal from './AssignCouponModal';
import { chunk } from 'lodash';
import { uploadFileToAssets } from '../../../../utility/apiUtils';
const AddCoupon = () => {
  const [showAssignModal, setShowAssignModal] = useState(false)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState({})
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [msg, setMsg] = useState('')
  const [availableCouponCount, setAvailableCouponCount] = useState(0)
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [buyers, setBuyers] = useState([])
  const [buyerCount, setBuyerCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [tablebuyers, setTablebuyers] = useState([])
  const [selectAll, setSelectAll] = useState(false);
  const history = useHistory()
  const { id: couponID } = useParams()
  const { userCoupons, userLoading } = useSelector(state => {
    return {
      userCoupons: state.coupon.userCoupons,
      userLoading: state.users.loading
    };
  });

  const getCouponById = async (id) => {
    axios.get(`/api/coupon/${id}`).then(res => {
      setCouponData(res.data)
    })
  }
  const getAvailableCouponUses = () => {
    // let usedCoupons = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.max_use - a?.total_used, 0);

    let total_used = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.max_use, 0);
    let data = (couponData?.max_use || 0) - total_used
    return data
  }

  useEffect(() => {
    if (userCoupons?.length > 0) {

      let alreadySelectedRows = userCoupons?.filter(s => !s?.isDeleted)?.map(c => ({
        key: c.user._id,
        name: c.user.details.garage_name,
        max_use: c.max_use,
      }))
      setSelectedBuyers(alreadySelectedRows)

    }
  }, [userCoupons])


  useEffect(() => {
    couponID && getCouponById(couponID)
    couponID && dispatch(getUserCoupons(couponID, false))
  }, [couponID, dispatch]);

  useEffect(() => {
    setAvailableCouponCount(getAvailableCouponUses())
  }, [userCoupons, couponData])


  useEffect(() => {
    setTablebuyers(buyers.map(b => ({ ...b, max_use: 0 })))
  }, [buyers])

  const _setSelectedBuyers = (selectedRows) => {
    setSelectedBuyers(selectedRows)
  }


  
  const assignCoupon = (count, type, _selectedBuyers) => {
    let changeRowsId = []
    let existingBuyers = selectedBuyers.filter(c => userCoupons.some(u => u.user._id === c.key))
    let newBuyers = selectedBuyers.filter(c => !userCoupons.some(u => u.user._id === c.key))
    let usedBuyers = selectedBuyers.filter(c => userCoupons.some(u => u.user._id === c.key && u.total_used && u.total_used >= 0))

    if (type === 'all') {
      changeRowsId = selectedBuyers?.map(s => s?.key)
    }
    else if (type === 'existing') {
      changeRowsId = existingBuyers?.map(s => s?.key)
    } else if (type === 'new') {
      changeRowsId = newBuyers?.map(s => s?.key)
    } else if (type === 'used') {
      changeRowsId = usedBuyers?.map(s => s?.key)
    }
    let buyers = selectedBuyers.map(b => {
      if (changeRowsId.includes(b?.key)) {
        if (type === 'used') {
          return ({ ...b, max_use: b.max_use + count })
        } else {
          return ({ ...b, max_use: count })
        }
      }
      return b
    })
    let usage = buyers.reduce((partial_sum, a) => partial_sum + a.max_use, 0);



    let total_used_all = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
    function checkAvailability(arr, val) {
      return arr.some(function (arrVal) {
        return val === arrVal;
      });
    }
    let selectTotal = userCoupons?.filter(u => {
      return checkAvailability(changeRowsId, u?.user?._id)
    })
    let total_used_buyer = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
    setSelectedBuyers(buyers)
    setAvailableCouponCount(((couponData?.max_use || 0) - total_used_all) - usage + total_used_buyer)
  }

  const saveUsers = async () => {
    // if (!msg) {
    //   return message.error("Please enter a message")
    // }
    const data = {
      coupon: couponID,
      users: selectedBuyers.map(c => ({
        user: c.key,
        max_use: couponData?.allow_max_use ? couponData?.max_use : c.max_use,
      })),
      msg
    }
    if (couponData?.allow_max_use) {
      data.users = selectedBuyers.map(c => ({
        user: c.key,
        max_use: couponData.max_use,
      }))

    }
    try {
      await axios.post('/api/coupon/user-update', data)
      message.success('Users added to the coupon successfully.')
      history.push('/admin/coupon')

    } catch (error) {
      message.error(error.response.data.message || 'Failed to add users.')
    }
  }

  const openModal = () => {
    if (availableCouponCount > 0) {
      setShowAssignModal(true)
    } else {
      message.warn(`User exceeds the maximum coupon count.`)
    }
  }


  const hasMaxUse = selectedBuyers?.length > 0 && selectedBuyers.every(c => c?.max_use > 0)
  return (
    <>
      <AssignModal
        showmodal={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        eventFunc={assignCoupon}
        availableCouponCount={availableCouponCount}
        selectedBuyers={selectedBuyers}
        couponData={couponData}
        userCoupons={userCoupons}
      />
      <CardToolbox>
        <PageHeader
          ghost
          title="Add Users"
          subTitle={
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
              <p style={{ margin: 0, marginRight: 15 }}>Coupon: {couponData?.name}</p>
              <p style={{ margin: 0, marginRight: 15 }}>Allow max use: {couponData?.allow_max_use ? <Tag color="blue"> Yes </Tag> : <Tag color='red'>No</Tag>}</p>
              <p style={{ margin: 0, marginRight: 15 }}>Available Coupons: {couponData?.allow_max_use ? couponData?.max_use : availableCouponCount}</p>

              <Button onClick={openModal} disabled={selectedBuyers.length < 1 || couponData?.allow_max_use} icon={<UserOutlined />} style={{ marginRight: 15 }}>
                Assign Users
              </Button>

              <Popconfirm
                title={
                  <>
                    <div>
                      <Input onChange={e => setMsg(e.target.value)} value={msg} placeholder="message" />
                    </div>
                  </>
                }
                onConfirm={saveUsers}
                onCancel={() => {
                  setIsConfirmOpen(false);
                  setMsg('')
                }}
                visible={isConfirmOpen}
                okText="Done"
                cancelText="Cancel"
              >
                <Button disabled={!hasMaxUse && !couponData?.allow_max_use} onClick={() => setIsConfirmOpen(true)} icon={<SaveOutlined />}>
                  Save Assigned Users
                </Button>
              </Popconfirm>
            </div>
          }
        />
        <div key="1" className="page-header-actions" style={{ alignItems: "flex-start", justifyContent: "flex-start", display: 'unset', margin: 0 }}>
          <SearchBuyer setBuyers={setBuyers} coupon={couponData?._id} setBuyerCount={setBuyerCount} setLoading={setLoading} page={page} setPage={setPage} limit={limit} />
        </div>
      

      </CardToolbox>
      <Main>
      <Row>
      <BulkAssignUser 
        title="Bulk Assign Buyer" 
        setSelectedBuyers={setSelectedBuyers}
        />
        </Row>
        <Row  style={{marginTop:'5px'}} gutter={15} >
        
          <Col md={24} >
            <CouponUserListTable
              couponData={couponData}
              setAvailableCouponCount={setAvailableCouponCount}
              availableCouponCount={availableCouponCount}
              selectedRows={selectedBuyers}
              couponList={tablebuyers}
              userCoupons={userCoupons}
              setSelectedBuyers={_setSelectedBuyers}
              buyers={buyers}
              loading={loading || userLoading}
              buyerCount={buyerCount} page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default AddCoupon;


export const BulkAssignUser = ({
  setSelectedBuyers,
  title

}) =>{
  const [bulkFile, setBulkFile] = useState([]);
  const [bulklist,setBulkList] = useState([])
  const [loading,setloading] = useState(true)
  const UploadProps = {
    onRemove: (file) => {
      const index = bulkFile.indexOf(file);
      const newFileList = bulkFile.slice();
      newFileList.splice(index, 1);
      console.log({newFileList})
      setBulkFile(newFileList);
      setSelectedBuyers(prev=>([...prev.filter(item=>!bulklist.includes(item.key))]))
    },
    beforeUpload: (file) => {
      setBulkFile([file]);
      console.log(file)

      return false;
    },
    fileList:bulkFile,
  };
  const onBulkAssignFileChange = async({ file }) => {
    if (file.status !== 'removed') {
      try {
        const uploadedFile = await uploadFileToAssets({
          file: file,
          type:"bulk-user"
        })

        const data = {
          file_id: uploadedFile?._id
        }

        axios.post('/api/users/bulk-assign-user', data)
          .then(response => {
            let chkn = chunk(response.data.data.reverse(), 1000)
            message.success(response.data.message)
            for (let i = 0; i < chkn.length; i++) {
              let list = chkn[i]
              if (i == 0) {
                setBulkList(list.reverse().map(i => i.key))
                setSelectedBuyers((prev) => ([...prev, ...list.reverse()]))
              }
              else {
                setBulkList(list.map(i => i.key))
                setSelectedBuyers((prev) => ([...prev, ...list]))
              }
            }
          }).catch((error) => {
            message.error(error.response.data.message || "Error while uploading bulk user"  )
          })
          .finally(() => setloading(false))
      } catch(error) {
        message.error(error?.message || "Error while uploading bulk user")
      }
    }
  }
return <div >
      <Upload
      name='file'
      {...UploadProps}
      fileList={bulkFile}
      iconRender={(file,listType)=> loading ? <LoadingOutlined />:<PaperClipOutlined />}
      progress={'line'}
      multiple={false}
      onChange={onBulkAssignFileChange}
      accept=".doc,.docx,,application/msword,.xls,.xlsx" 

      >
        <Button icon={<UploadOutlined />}>{title}</Button>
      
      </Upload>
      <a
      href={require('../../../../static/files/bulk_assign_buyer.xlsx')}
      className='btn btn-default btn-xs'
      style={{textDecoration:'underline',marginLeft:5}}
      target='_blank'
      download='bulk_assign_buyer'
      >
      Download excel template
      </a>
        </div>
}