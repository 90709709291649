import { message } from 'antd';
import axios from 'axios';

import actions from './actions';
import { getDiscountAmount } from '../../utility/utility';
import { resetWallet } from '../wallet/actionCreator';

const {
  getOrdersListBegin,
  getOrdersListCountBegin,
  getOrdersListSuccess,
  getOrdersCountSuccess,
  getOrdersPaginationSuccess,
  emptyOrdersPaginationSuccess,
  getOrdersListErr,
  getOrderLogBegin,
  getOrderLogSuccess,
  getOrderLogErr,
  getOrderBegin,
  getOrderSuccess,
  getOrderCountSuccess,
  getOrderErr,
  resetFlags,
  InitializeBegin,
  InitializeSuccess,
  InitializeError,
  addItemInCart,
  getAddressBegin,
  getuserAddress,
  getAddressError,
  getTotalPrice,
  getMycartListing,
  getdeliveryOption,
  getCollectedAmount,
  collectedAmountErr,
  setSelectedAddress,
  setSelectedBuyer,
  getOrdersListFromOrderIdBegin,
  getOrdersListFromOrderIdSucess,
  getOrdersListFromOrderIdError,
  setPaymentMethod,
  applyCouponBegin,
  applyCouponSuccess,
  applyCouponError,
  setOrderSummary,
  resetOrderState,
  setOrderState,
  updateIteminCart,
  setEligibleCouponSuccessOrError,
  upsertBuyerCartBegin,
  upsertBuyerCartSuccess,
  upsertBuyerCartError,
  addToCartBegin,
  addToCartError,
  GET_CART_ITEM_BEGIN,
  GET_CART_ITEM_SUCCESS,
  GET_CART_ITEM_ERROR
} = actions;

const addBuyerAddressSelected = data => {
  return async dispatch => {
    dispatch(setSelectedAddress(data));
  };
};

const addBuyerSelected = data => {
  return async dispatch => {
    dispatch(setSelectedBuyer(data));
  };
};

const getOrdersList = (user_id = '') => {
  return async dispatch => {
    try {
      dispatch(getOrdersListBegin());
      const resp = await axios.get(`/api/order?user_id=${user_id}`);
      dispatch(getOrdersListSuccess(resp.data.orders));
    } catch (err) {
      dispatch(getOrdersListErr(err));
    }
  };
};

const getCollectionAmount = (order_id = '') => {
  return async dispatch => {
    try {
      const resp = await axios.get(`/api/order/get-collected-amount?order_id=${order_id} `);
      dispatch(getCollectedAmount(resp.data));
    } catch (err) {
      dispatch(collectedAmountErr(err));
    }
  };
};


const _setOrderState = (data) => dispatch => {
  dispatch({ type: 'set_order_state', data });
}

const getOrdersCount = (user_id = '', warehouse_id = '', searchKeyword, filtersForOrder, source) => {
  return async dispatch => {
    try {
      dispatch(getOrdersListCountBegin());
      let url = `/api/order?count=${true}&user_id=${user_id}&warehouse_id=${warehouse_id}`;
      if (searchKeyword) {
        url = `/api/order?count=${true}&search=${searchKeyword}&user_id=${user_id}&warehouse_id=${warehouse_id}`;
      }
      if (Object.keys(filtersForOrder).length > 0) {
        Object.keys(filtersForOrder).map(key => {
          if (filtersForOrder[key]) url = `${url}&${key}=${filtersForOrder[key]}`;
        })
      }
      const resp = await axios.get(url, { cancelToken: source.token });
      dispatch(getOrdersCountSuccess(resp.data));
    } catch (err) {
      dispatch(getOrdersListErr(err));
    }
  };
};

const getOrdersListFromPagination = (user_id = '', warehouse_id = '', searchKeyword, filtersForOrder, page, size) => {
  return async dispatch => {
    try {
      dispatch(getOrdersListBegin());
      let url = `/api/order?page=${page}&size=${size}&user_id=${user_id}&warehouse_id=${warehouse_id}`;
      if (searchKeyword) {
        url = `/api/order?search=${searchKeyword}&page=${page}&size=${size}&user_id=${user_id}&warehouse_id=${warehouse_id}`;
      }
      if (Object.keys(filtersForOrder).length > 0) {
        Object.keys(filtersForOrder).map(key => {
          if (filtersForOrder[key]) url = `${url}&${key}=${filtersForOrder[key]}`;
        })
      }
      const resp = await axios.get(url);
      dispatch(getOrdersPaginationSuccess(resp.data, page));
      dispatch({ type: 'orders_excel_file', data: resp.data.file_url });
    } catch (err) {
      dispatch(getOrdersListErr(err));
    }
  };
};

const getOrdersListFromOrderId = (searchKeyword) => {
  return async dispatch => {
    try {
      dispatch(getOrdersListFromOrderIdBegin());
      let url = `/api/order?page=1&size=10`;
      if (searchKeyword) {
        url = `/api/order?search=${searchKeyword}&page=1&size=10`;
      }
      const resp = await axios.get(url);
      dispatch(getOrdersListFromOrderIdSucess(resp.data?.orders));
    } catch (err) {
      dispatch(getOrdersListFromOrderIdError(err));
    }
  };
};

const emptyOrdersFromPagination = (page, size) => {
  return async dispatch => {
    dispatch(emptyOrdersPaginationSuccess());
  };
};

const getOrder = (id, user_id = '', warehouse_id = '') => {
  return async dispatch => {
    try {
      dispatch(getOrderBegin());
      const resp = await axios.get(`/api/order/${id}?user_id=${user_id}&warehouse_id=${warehouse_id}`);
      dispatch(getOrderSuccess(resp.data));
    } catch (err) {
      dispatch(getOrderErr(err));
    }
  };
};

const getOrderSeller = (id, page, perPage) => {
  return async dispatch => {
    try {
      dispatch(getOrderBegin());
      const resp = await axios.get(`/api/order?seller=${id}&page=${page}&perPage=${perPage}`);
      dispatch(getOrderSuccess(resp.data.orders));
      dispatch(getOrderCountSuccess(resp.data?.total))
    } catch (err) {
      dispatch(getOrderErr(err));
    }
  };
};

const getOrderBuyer = (id, page, perPage) => {
  return async dispatch => {
    try {
      dispatch(getOrderBegin());
      const resp = await axios.get(`/api/order?buyer=${id}&page=${page}&perPage=${perPage}`);
      dispatch(getOrderSuccess(resp.data.orders));
      dispatch(getOrderCountSuccess(resp.data?.total));
    } catch (err) {
      dispatch(getOrderErr(err));
    }
  };
};

const updateOrderStatus = (data, user_id, quantity, setLoading) => {
  return async (dispatch, getState) => {
    try {
      setLoading(true)
      let user = getState().auth.login;
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }

      const withdelayed = {
        id: data?.id,
        status: data?.status,
        listing_id: data?.listing_id,
        remarks: data?.remarks,
        cancelledAmount: data?.cancelledAmount || 0,
        updatedBy: user?._id,
        onModel: 'admin_users',
        delivery_date: data?.delivery_date,
        warehouse: data?.warehouse,
        business_modal: data?.business_modal,
        ship_date: data?.ship_date,
        deliveredByAdmin: data?.status === 'delivered' ? true : false,
      };


      const withoutdelayed = {
        id: data?.id,
        status: data?.status,
        listing_id: data?.listing_id,
        remarks: data?.remarks,
        cancelledAmount: data?.cancelledAmount || 0,
        updatedBy: user?._id,
        onModel: 'admin_users',
        warehouse: data?.warehouse,
        business_modal: data?.business_modal,
        ship_date: data?.ship_date,
        deliveredByAdmin: data?.status === 'delivered' ? true : false,
      };

      await axios.patch(
        `/api/order/update-order-by-product`,
        data?.status === 'delayed' ? withdelayed : withoutdelayed
      );

      dispatch(getOrder(data.id));
      dispatch(getOrderLog(data.id));
    } catch (err) {
      message.error(err?.response?.data?.message || "Something went wrong")
      dispatch(getOrder(data.id));
      dispatch(getOrderLog(data.id));
    } finally {
      setLoading(false)
    }
  };
};

const salesReturnCancel = (data, _id, user_id) => {
  return async (dispatch, getState) => {

    try {
      await axios.post(`/api/order/return-sales-cancel`, data);
      dispatch(getOrder(_id));
      dispatch(getOrderLog(_id));
      message.success('Successfully cancelled!');
    } catch (err) {
      message.error(err?.response?.data?.message || "Something went wrong")
    }
  };
};
const updateBulkData = (data, setLoading, user_id) => {
  return async (dispatch, getState) => {

    if (data?.products?.length) {
      setLoading(true)
      const totalNoOfChunks = Math.ceil(data?.products?.length / 5);
      let promises = []

      for (let i = 0; i < totalNoOfChunks; i++) {
        const postData = {
          ...data,
          products: data?.products?.splice(0, 5),
          deliveredByAdmin: data?.status === 'delivered' ? true : false,
        }

        promises.push(axios.patch(`/api/order/bulk-order-update`, postData))
      }

      Promise.all(promises).then(res => {
        message.success(`Bulk update successfully!`);
        dispatch(getOrder(data.order_id));
        dispatch(getOrderLog(data.order_id));
      }).catch(err => {
        message.error(err.response.data.message || "Something went wrong");
        dispatch(getOrder(data.order_id));
        dispatch(getOrderLog(data.order_id));
      }).finally(() => {
        setLoading(false)
      })
    }
  };
};

const bulkUpdateByOrder = (data, setLoading) => {
  return async dispatch => {
    try {
      setLoading(true)
      const res = await axios.patch(`/api/order/bulk-assign-delivery-scout`, data);
      if (res?.status === 200) {
        message.success(res.data.message || 'Successfully assigned to delivery scout!');
        setLoading(false)
      }
    } catch (err) {
      message.error(err?.response?.data?.message || "Something went wrong");
      setLoading(false)
    }
  };
};

const addAssigner = data => {
  return async dispatch => {
    try {
      await axios.patch(`/api/order/add-assigner`, data);
      message.success('Successfully assigned to delivery scout!');
      dispatch(getOrder(data.id));
      dispatch(getOrderLog(data.id));
    } catch (err) {
      message.error(err?.response?.data?.message || "Something went wrong");
    }
  };
};

const getOrderLog = id => {
  return async dispatch => {
    try {
      dispatch(getOrderLogBegin());
      const resp = await axios.get(`/api/order/get-order-logs/${id}`);
      dispatch(getOrderLogSuccess(resp.data.orderLog));
    } catch (err) {
      dispatch(getOrderLogErr(err));
    }
  };
};

const resetOrderFlags = () => {
  return async dispatch => {
    dispatch(resetFlags());
  };
};

const upsertBuyerCart = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedBuyer, selectedAddress } = getState().order;

      let data = {
        user: selectedBuyer
      }

      if (selectedAddress) data = { ...data, delivery_address: selectedAddress }

      dispatch(upsertBuyerCartBegin());
      const resp = await axios.post(`/api/cart/check`, data);
      dispatch(upsertBuyerCartSuccess(resp?.data?.cart));
      dispatch(getProduct({}));

    } catch (error) {
      message.error(error?.response?.data?.message || "Error while fetching the cart");
      dispatch(upsertBuyerCartError());
    }
  }
}

const getCartById = (cartId) => {
  return async (dispatch) => {
    try {
      dispatch(upsertBuyerCartBegin());
      const resp = await axios.get(`/api/cart/${cartId}`);
      const cartDetail = resp?.data?.cart
      dispatch(upsertBuyerCartSuccess(cartDetail));
      dispatch(getAddress(cartDetail?.user, cartDetail?.delivery_address));
    } catch (error) {
      dispatch(upsertBuyerCartError());
      message.error(error?.response?.data?.message);
    }
  }
}

const getProduct = ({ search = "", page = 1, size = 10 }) => {
  return async (dispatch, getState) => {
    const { selectedBuyer } = getState().order;
    const { buyers } = getState().users;

    const buyer = buyers?.length ? buyers?.find(buyer => buyer?._id === selectedBuyer) : null;
    const sellerAccType = buyer?.details?.emirate ? buyer?.details?.emirate : "";

    dispatch(InitializeBegin());
    axios
      .get(`/api/listings?search=${search}&page=${page}&perPage=${size}&buyerId=${selectedBuyer}&sellerAccType=${sellerAccType}&visibility=unhidden`)
      .then(res => {
        dispatch(InitializeSuccess(res?.data));
      })
      .catch(err => {
        dispatch(InitializeError())
      });
  };
};

const addToCart = (data, shouldFetchCoupon = true) => {
  return async (dispatch, getState) => {
    const { cartDetail } = getState().order;
    try {
      dispatch(addToCartBegin())
      const res = await axios.post(`/api/cart/add_to_cart/${cartDetail?._id}`, data);
      const cart = res?.data?.cart
      dispatch(upsertBuyerCartSuccess(cart));
      dispatch(setCartListing(cart?.products, shouldFetchCoupon))
      message.success('Item Added to cart Successfully');
    } catch (err) {
      dispatch(addToCartError())
      message.error(err?.response?.data?.message || "Error while adding item to cart")
    }
  };
}

const clearCart = _ => {
  return async (dispatch, getState) => {
    const { cartDetail } = getState().order;
    try {
      const res = await axios.delete(`/api/cart/clear/${cartDetail?._id}`);
      dispatch(upsertBuyerCartSuccess(res?.data?.cart));
      dispatch(setCartListing([], true))
      message.success('Cart has been cleared successfully');
    } catch (err) {
      message.error(err?.response?.data?.message || "Error while adding item to cart")
    }
  };
}

const removeItemFromCart = listing_id => {
  return async (dispatch, getState) => {
    const { cartDetail } = getState().order;
    try {
      const res = await axios.delete(`/api/cart/remove_from_cart/${cartDetail?._id}/${listing_id}`);
      const cart = res?.data?.cart
      dispatch(upsertBuyerCartSuccess(cart));
      dispatch(setCartListing(cart?.products, true));
      message.success('Item has been removed from cart successfully');
    } catch (err) {
      message.error(err?.response?.data?.message || "Error while removing the item from the cart.")
    }
  };
}

const addItem = data => {
  return async dispatch => {
    try {
      dispatch(addItemInCart(data));
    } catch (err) {
      dispatch(getOrderLogErr(err));
    }
  };
};
const updateCart = data => {
  return async dispatch => {
    try {
      dispatch(updateIteminCart(data));
    } catch (err) {
      dispatch(getOrderLogErr(err));
    }
  };
};

const updateDeliveryoption = data => {
  return async dispatch => {
    try {
      dispatch(getdeliveryOption(data));
    } catch (err) {
      dispatch(getOrderLogErr(err));
    }
  };
};

const getAddress = (buyer, prevAddress = "") => {
  return async dispatch => {
    try {
      dispatch(getAddressBegin())
      axios.get(`/api/addresses/user/${buyer}`).then(res => {
        const addresses = res?.data.addresses.reverse();
        let selectedAddress = "";

        addresses?.length &&
          addresses.map(address => {
            if (address?.default_address) {
              selectedAddress = address?._id
            }
            return address;
          })

        if (prevAddress) selectedAddress = prevAddress;

        dispatch(actions.setOrderState({
          state: "showListing",
          data: false
        }));
        dispatch(getuserAddress(addresses));
        dispatch(addBuyerAddressSelected(selectedAddress))
      });
    } catch (err) {
      message.error(err?.response?.data?.message || "Error while fetching buyer address")
      dispatch(getAddressError())
    }
  };
};

const setCartListing = (products, shouldFetchCoupon = true) => {
  return (dispatch, getState) => {
    const newListings = [];
    let total_price = 0;
    let initalDeliveryOptions = [];
    const { delivery_option } = getState().order;
    products.forEach((item, i) => {
      newListings.push({ user: item?.listing?.user, listings: [item?.listing], quantity: item?.quantity });

      total_price += item.price * item?.quantity;

      return true;
    });
    newListings.map(item => {
      return item.listings.forEach(list => {
        const prevListing = delivery_option?.length ? delivery_option?.find(option => option?._id === list?._id) : null;
        const deliveryOption = prevListing?.delivery_option ?? "next_day"
        initalDeliveryOptions.push({
          ...list,
          item_id: list._id,
          delivery_option: deliveryOption,
          orderQty: item?.quantity
        });
      });
    });
    const mylisting = [];
    newListings.forEach(item => {
      mylisting.push(item.listings[0]);
    });

    if (shouldFetchCoupon) {
      dispatch(getAllUserCoupon(initalDeliveryOptions));
    }
    dispatch(getTotalPrice(total_price));
    dispatch(getMycartListing(mylisting));
    dispatch(getdeliveryOption(initalDeliveryOptions));
    dispatch({ type: GET_CART_ITEM_SUCCESS })
  }
}

//Function to get the list of all items in cart
const getCartListings = _ => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_CART_ITEM_BEGIN })

    const { cartDetail } = getState().order;
    if (cartDetail?._id) {
      axios
        .get(`/api/cart/${cartDetail?._id}`)
        .then(res => {
          dispatch(setCartListing(res?.data?.cart?.products, true))
        })
        .catch(err => {
          dispatch({ type: GET_CART_ITEM_ERROR })
        });
    }
  }
};

//Function to handle the payment method (cash, wallet) change
const changePaymentMethod = data => {
  return async dispatch => {
    dispatch(setPaymentMethod(data));
  };
};

//Function to get the list of all the coupons that are eligible
const getAllUserCoupon = delivery_option => {
  return async (dispatch, getState) => {
    const { selectedBuyer } = getState().order;

    if (selectedBuyer) {

      try {
        dispatch(applyCouponBegin());
        const listings = [];

        delivery_option.forEach(option => {
          if (option.orderQty > 0) {
            for (let i = 0; i < option.orderQty; i++) {
              listings.push(option._id);
            }
          }
        });

        const res = await axios
          .post('/api/coupon/user-coupons/' + selectedBuyer, {
            listings
          })
        const eligibleCoupons =
          res.data.coupons?.length ?
            res.data.coupons.map((val, index) => ({
              index: index + 1,
              label: val.name,
              coupon_code: val.coupon_code,
              value: val._id,
              discount_type: val.discount_type.label,
              discount_value: val.discount_type.value,
            })
            ) : []
        dispatch(setOrderState({
          state: "eligibleCoupons",
          data: eligibleCoupons
        }));
        dispatch(setEligibleCouponSuccessOrError());
      }
      catch (error) {
        dispatch(applyCouponError());
      }
    }
  }
}

//Function to verify if the coupon is valid and apply the coupon
const applyCoupon = data => {
  return async dispatch => {
    try {
      dispatch(applyCouponBegin());
      const res = await axios.post('/api/coupon/verify-coupon', data)
      let _coupon = { ...res.data.data }
      dispatch(applyCouponSuccess(_coupon));
      message.success(res.data.message || 'Coupon applied successfully.')
    }
    catch (error) {
      dispatch(applyCouponError());
      message.error(error.response?.data?.message || 'Invalid coupon code.')
    }
  }
}

//Function to set the value for the order summary
const setTotalOrderSummary = data => {
  return async dispatch => {
    const { tax, discount, totalExclVat, shippingTax, shippingAmount } = data;

    let totalTax = Number(tax) + Number(shippingTax);

    const totalAmount = Number(totalExclVat) -
      Number(discount) +
      Number(shippingAmount) +
      totalTax;

    dispatch(setOrderSummary({
      ...data,
      totalAmount,
      tax: totalTax
    }))
  }
}

//Function to reset the order creation detail from state
const resetOrderCreation = (type = "full") => {
  return async dispatch => {
    const data = {
      showListing: false,
      isListingLoading: false,
      listings: [],
      listingCount: 0,
      cart: [],
      cartListings: [],
      totalPrice: 0,
      usseraddress: [],
      delivery_option: [],
      selectedBuyer: '',
      selectedAddress: '',
      paymentMethod: "cash",
      walletAmount: 0,
      walletError: "",
      coupon: {},
      isApplyingCoupon: false,
      cartDetail: null,
      isCartDetailLoading: false,
      orderSummary: {
        tax: 0,
        discount: 0,
        discountPerUnit: 0,
        totalExclVat: 0,
        shippingTax: 0,
        shippingAmount: 0,
        totalAmount: 0
      }
    }

    dispatch(resetWallet())
    if (type === "partial") {
      const { orderSummary, isApplyingCoupon, coupon, walletAmount, walletError, totalPrice, cartListings, delivery_option, paymentMethod } = data;
      dispatch(resetOrderState({
        orderSummary, isApplyingCoupon, coupon, walletAmount, walletError, totalPrice, cartListings, delivery_option, paymentMethod
      }));

      return;
    }

    dispatch(resetOrderState(data));
  }
}

//Function to place an order
const placeAnOrder = (orderNote, history, onActionHalted) => {
  return async (dispatch, getState) => {
    const { delivery_option, cartDetail, selectedAddress, usseraddress, orderSummary, walletAmount, selectedBuyer, coupon } = getState().order;

    const addressDetail = usseraddress?.length && usseraddress?.find(address => address?._id === selectedAddress)

    try {
      let orderTaxAmount = 0
      let express_count = 0
      let quantityExceededItems = [];

      let products = delivery_option.map((res) => {
        const qty = res?.orderQty

        if (qty > res?.quantity) {
          quantityExceededItems.push(res?.partSKU)
        }

        // for unit_discount quantity is one
        let discountAmount = getDiscountAmount(
          res?.priceExclVat,
          qty,
          res,
          orderSummary?.discountPerUnit,
          coupon
        ),
          unit_discount = discountAmount / qty

        // newTaxAMount
        let newTotalAfterDiscount = res.priceExclVat * qty - discountAmount
        let taxAmount = newTotalAfterDiscount * (res.vatPercent / 100)
        orderTaxAmount = orderTaxAmount + taxAmount

        // + taxAmount

        res = {
          ...res,
          unit_discount,
          netamount_excl_vat: newTotalAfterDiscount,
          item_vat_amount: taxAmount,
        }

        if (res?.delivery_option === 'express') {
          express_count = express_count + 1
        }

        return {
          listing: res._id,
          quantity: qty,
          seller: res?.user,
          status: 'PENDING',
          delivery_option: { ...res, user: { _id: res.user } },
          shipping_price_individual: 0,
          warehouse: null,
        }
      });

      if (quantityExceededItems?.length) {

        message.error(`Order quantity of SKUs (${quantityExceededItems?.join(",")}) are greater than available quantity.`)
        return
      }

      const order = {
        currency: "AED",
        products,
        delivery_address: addressDetail,
        pay_on_card: false,
        discount: orderSummary?.discount,
        coupon: coupon?._id && orderSummary?.discount !== 0 ? { _id: coupon?._id } : {},
        total_price: Number(orderSummary?.totalAmount?.toFixed(2)),
        orderTaxAmount,
        unchanged_total_price: Number(orderSummary?.totalAmount?.toFixed(2)),
        shipping_price: Number(orderSummary?.shippingTax) + Number(orderSummary?.shippingAmount),
        user: selectedBuyer,
        walletAmount: +walletAmount,
        express_count,
        order_note: orderNote,
        priceExclVat: Number(orderSummary?.totalExclVat.toFixed(2)),
        isPaidOnline: false,
        isProductsRemaining: false,
        cartId: cartDetail?._id
      }

      const res = await axios
        .post('/api/order', order, {
          headers: {
            "platform": "admin"
          }
        });
      message.success("Order placed successfully.")
      history.push(`/admin/orders/view/${res?.data?.order?._id}`);
      onActionHalted();
      dispatch(resetOrderCreation("full"));
    }
    catch (error) {
      onActionHalted();
      message.error(error.response?.data?.message || 'Error while placing an order.')
    }
  }
}

export {
  updateCart,
  getAddress,
  getCartListings,
  getOrdersList,
  getOrdersListFromPagination,
  emptyOrdersFromPagination,
  getOrdersCount,
  getOrder,
  resetOrderFlags,
  getOrderBuyer,
  getOrderLog,
  updateOrderStatus,
  getOrderSeller,
  addAssigner,
  getCartById,
  getProduct,
  upsertBuyerCart,
  addToCart,
  clearCart,
  removeItemFromCart,
  addItem,
  updateDeliveryoption,
  getCollectionAmount,
  addBuyerAddressSelected,
  addBuyerSelected,
  _setOrderState,
  updateBulkData,
  getOrdersListFromOrderId,
  salesReturnCancel,
  changePaymentMethod,
  getAllUserCoupon,
  applyCoupon,
  setTotalOrderSummary,
  resetOrderCreation,
  placeAnOrder,
  bulkUpdateByOrder
};
