import actions from './action';

const initialState = {
    inspectionModuleList: [],
    isLoading: false,
    // singleBrand: null,
    // isFetchingSingleBrand: false,
    // categories: [],
    // categoriesCount: 0,
    // isCategoriesLoading: false,
    // subCategories: [],
    // subCategoriesCount: 0,
    // isSubCatgoriesLoading: false,
    // categoryWithSubCategories: [],
    // error: null
};

const {
    INSPECTION_MODULE_BEGIN,
    GET_INSPECTION_MODULE_SUCCESS,
    INSPECTION_MODULE_ERR,
    GET_INSPECTION_MODULE_BY_ID_SUCCESS
} = actions;

const carInspectionReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case INSPECTION_MODULE_BEGIN:
            return {
                ...state,
                isLoading: true,
            };
        case GET_INSPECTION_MODULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                inspectionModuleList: data?.modules,
                totalCount: data?.total
            };
        case GET_INSPECTION_MODULE_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                inspectionModuleByIdList: data
            };
        case INSPECTION_MODULE_ERR:
            return {
                ...state,
                error: err,
                isLoading: false
            };
        default:
            return state;
    }
};

export default carInspectionReducer;
