import React,{useState,useEffect} from 'react';
import { Col, Row,FormGroup,Label } from 'antd';
import { Cards } from '../cards/frame/cards-frame';
import { ChartjsAreaChart } from '../charts/chartjs';
import { Style } from './style';
import { chartLinearGradient, customTooltips } from '../utilities/utilities';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react';
import Grid from 'antd/lib/card/Grid';

const index = () => {

  const performanceDatasets = [
    {
      data: ['0', '0', '1', '0', '0','1','0'],
      borderColor: '#5F63F2',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#5F63F230',
          end: '#ffffff05',
        }),
      label: 'Revenue',
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#5F63F2',
      hoverBorderWidth: 5,
    },
  ];
  const [totalPurchases, setTotalPurchases] = useState(null)


  const getTotalPurchases = async (id) => {
    let res = await axios.get(`/api/order/get-total-purchaes?buyer_id=${id}`)
    setTotalPurchases(res.data)
  }
  useEffect(() => {
    getTotalPurchases('60a6d59f6c9d2301d1e89373')
  }, [])

  const [active, setActive] = React.useState("week");
  const [filterOptions, setFilterOptions] = React.useState("week");


  const getPeriodIndex = (period) => {
    // let period = {current:'', previous: ''};
    if (period == 'today') {
      return ({ current: 'Today', previous: 'Yesterday' })
    }

    if (period == 'week') {
      let prevRevenue = totalPurchases?.lastWeekOrders?.map(item => item.total_price ? item.total_price : 0).reduce((prev, next) => prev + next, 0)
      let currentRevenue = totalPurchases?.thisWeekOrders?.map(item => item.total_price ? item.total_price : 0).reduce((prev, next) => prev + next, 0)
      return ({ current: 'Current Week', previous: 'Previous Week', currentRevenue, prevRevenue })
    }

    if (period == 'month') {
      let prevRevenue = totalPurchases?.lastWeekOrders?.map(item => item.total_price ? item.total_price : 0).reduce((prev, next) => prev + next, 0)
      let currentRevenue = totalPurchases?.thisWeekOrders?.map(item => item.total_price ? item.total_price : 0).reduce((prev, next) => prev + next, 0)
      return ({ current: 'Current Year Months', previous: 'Previous Year Months', currentRevenue, prevRevenue })
    }

    if (period == 'year') {
      let prevRevenue = totalPurchases?.lastWeekOrders?.map(item => item.total_price ? item.total_price : 0).reduce((prev, next) => prev + next, 0)
      let currentRevenue = totalPurchases?.thisWeekOrders?.map(item => item.total_price ? item.total_price : 0).reduce((prev, next) => prev + next, 0)
      return ({ current: 'Last Five Year Revenue', currentRevenue, prevRevenue })
    }
  }
  const numberSeperator = (num) => {
    var n = parseFloat(num).toFixed(2);
    var withCommas = Number(n).toLocaleString("en");
    return withCommas;
  };

  const getTitle=()=>{
    return(
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <div><h5>Total Purchases</h5></div>
        <div >  
        <div 
              style={{display:'flex'}}
              md="24"
              sm="24"
              container
              className=" dashboard-container-time-container"
            >
              
              <Grid
                md="8"
                sm="8"
                className={`${active === "week" && "selected"} date`}
                onClick={() => {
                  setActive("week");
                  setFilterOptions("week");
                }}
              >
                Week
              </Grid>
              <Grid
                              md="8"
                              sm="8"
                className={`${active === "month" && "selected"} date`}
                onClick={() => {
                  setActive("month");
                  setFilterOptions("month");
                }}
              >
                Month
              </Grid>
              <Grid
                              md="8"
                              sm="8"
                className={`${active === "year" && "selected"} date`}
                onClick={() => {
                  setActive("year");
                  setFilterOptions("year");
                }}
              >
                Year
              </Grid>
            </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <Col xs={24}>
        
        <Style>
          <Cards title={getTitle()} size="default">

            <Row>
              <Col xs={24}>
              <i className="las la-spinner la-spin mr-2"></i>
            <Row style={{ paddingLeft: "20px" }}>
              <Col xs={8}>
                <h3 className="totalPurchases__number">AED {getPeriodIndex(active).currentRevenue == undefined ? <i className="las la-spinner la-spin mr-2"></i> : numberSeperator(getPeriodIndex(active).currentRevenue)}</h3>
                <div check className="totalPurchases__currentperiod" style={{ marginTop: '5px' }}>
                  <h4 className="totalPurchases_index">
                    {getPeriodIndex(active).current}
                  
                  </h4>
                </div>
              </Col>
              {getPeriodIndex(active).previous && <Col  xs={8}>
                <h3>AED {getPeriodIndex(active).currentRevenue == undefined ? <i className="las la-spinner la-spin mr-2" /> : numberSeperator(getPeriodIndex(active).prevRevenue)}</h3>
                <div check className="totalPurchases__currentperiod" style={{ marginTop: '5px' }}>
                  <h4 className="totalPurchases_index">
                    {getPeriodIndex(active).previous}
                    {/* Previous Period */}
                  </h4>
                </div>
              </Col>}
            </Row>
              </Col>
            
            </Row>

            <div className="performance-lineChart">
              
              <ChartjsAreaChart
                id="performance"
                labels={["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]}
                datasets={performanceDatasets}
                options={{
                  maintainAspectRatio: true,
                  elements: {
                    z: 9999,
                  },
                  legend: {
                    display: false,
                  },
                  hover: {
                    mode: 'index',
                    intersect: false,
                  },
                  tooltips: {
                    mode: 'label',
                    intersect: false,
                    backgroundColor: '#ffffff',
                    position: 'average',
                    enabled: false,
                    custom: customTooltips,
                    callbacks: {
                      title() {
                        return 'General Statistics';
                      },
                      label(t, d) {
                        const { yLabel, datasetIndex } = t;
                        return `<span class="chart-data">${yLabel}k</span> <span class="data-label">${d.datasets[datasetIndex].label}</span>`;
                      },
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: '#e5e9f2',
                          borderDash: [3, 3],
                          zeroLineColor: '#e5e9f2',
                          zeroLineWidth: 1,
                          zeroLineBorderDash: [1, 1],
                        },
                        ticks: {
                          beginAtZero: true,
                          fontSize: 13,
                          fontColor: '#182b49',
                          max: 1,
                          stepSize: 0.1,
                          callback(label) {
                            return `${label}`;
                          },
                        },
                      },
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          display: true,
                          zeroLineWidth: 2,
                          zeroLineColor: 'transparent',
                          color: 'transparent',
                          z: 1,
                          tickMarkLength: 0,
                        },
                        ticks: {
                          padding: 10,
                        },
                      },
                    ],
                  },
                }}
                height={window.innerWidth <= 575 ? 200 : 86}
              />
            </div>
          </Cards>
        </Style>
      </Col>
    </>
  );
};

export default index;
