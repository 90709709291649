import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Upload, Select, Checkbox, TimePicker, message, Divider, Switch } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { OrderCard } from './style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import Heading from '../../components/heading/heading';
import { Main, BasicFormWrapper } from '../styled';
import { WizardOrder } from '../pages/wizards/Style';
import { Steps } from '../../components/steps/steps';
import { theme } from '../../config/theme/themeVariables';
import { getRfqP } from '../../redux/rfq/actionCreator';
import moment from 'moment';
import config from '../../config/config';
import { Button as CustomButton } from '../../components/buttons/buttons';
import RfqPUpdate from '../../components/modals/RFQPUploadModal';
import axios from 'axios';
import RfqPDetailsListingList from './RfqPDetailsListingList';
const { Option } = Select;

const RfqPDetails = ({ match }) => {
  const dispatch = useDispatch();
  const id = match.params.id;
  const [quotationModal, setQuotationModal] = useState(false)

  const { rfqP } = useSelector(state => {
    return {
      rfqP: state.rfq.rfqP
    };
  });

  const getDetails = () => {
    dispatch(getRfqP(id));
  }
  useEffect(() => {
    getDetails()
  }, [id]);

  const handleClose = () => {
    setQuotationModal(false)
  }
  return (
    <>
      <RfqPUpdate
        showmodal={quotationModal}
        onClose={handleClose}
        user_id={rfqP?.buyer?._id}
        rfq_id={rfqP?._id}
        getDetails={getDetails}
        rfqExcelFile={rfqP?.rfqURL}
      />
      <PageHeader
        ghost
        title="RFQ By Product Details"
      />
      <Main>
        <OrderCard>
          <Cards headless={true}>
            <Row justify="space-between">
              <Col>
                <Heading as="h6">RFQ Number</Heading>
                <span>{rfqP?.number || rfqP?._id}</span>
              </Col>
              <Col>
                <Heading as="h6">Buyer Name</Heading>
                <span>{rfqP?.buyer?.details?.garage_name || rfqP?.buyer?.name || '...'}</span>
                <Heading as="h6">Seller Name</Heading>
                <span>{rfqP?.seller?.details?.company_name || '...'}</span>
              </Col>
              <Col>
                <Heading as="h6">Date</Heading>
                <span>
                  {rfqP
                    ? moment(rfqP?.date)
                      .tz('Asia/Dubai')
                      .format('L')
                    : '...'}
                  {rfqP
                    ? moment(rfqP?.date)
                      .tz('Asia/Dubai')
                      .format('LTS')
                    : '...'}
                </span>
              </Col>
              <Col span={4}>
                <Heading as="h6">
                  {/* Upload Quotation */}
                  {/* <FeatherIcon icon="upload" style={{ color:'green', marginLeft: 5, cursor: 'pointer', position: 'relative', top: 5 }} onClick={() => setQuotationModal(true)} size={22} /> */}
                  <CustomButton onClick={() => setQuotationModal(true)} size="small" type="primary" outlined={true}>
                    <FeatherIcon icon="upload" size={14} />
                    Upload Quotation
                  </CustomButton>
                </Heading>
                {rfqP && rfqP.originalName ? (
                  <>
                    <span>{rfqP?.originalName}</span>
                    <div>
                      <a href={`${rfqP?.name}`} download>
                        Download
                      </a>
                    </div>
                  </>
                ) : (
                  <span>No Quotations Yet!</span>
                )}
              </Col>

              <Col span={4}>
                <Heading as="h6" style={{ marginTop: 3 }}>Status </Heading>
                <span>{rfqP?.status?.toUpperCase()}</span>
              </Col>
            </Row>
          </Cards>
          <Cards
            title="List of Products">
            <Row>
              <Col span={15}>
                <RfqPDetailsListingList rfqPList={rfqP?.listing || []} />
              </Col>
              <Col style={{ marginLeft: '15px' }} span={8}>
                <Cards headStyle={{ backgroundColor: '#f4f5f7' }} bodyStyle={{ backgroundColor: '#f4f5f7' }} title="Buyer Notes">
                  <div>{rfqP?.notes || '...'}</div>
                </Cards>
              </Col>
            </Row>
          </Cards>
        </OrderCard>
      </Main>
    </>
  );
};

RfqPDetails.propTypes = {
  match: propTypes.object
};

export default RfqPDetails;
