import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const WareHouseList = lazy(() => import('../../container/pages/warehouse/'));
const AddWareHouse = lazy(() => import('../../container/pages/warehouse/AddWareHouse'));
const EditWareHouse = lazy(() => import('../../container/pages/warehouse/EditWareHouse'));


const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={WareHouseList} />
      <Route path={`${path}/add`} component={AddWareHouse} />
      <Route path={`${path}/edit/:id`} component={EditWareHouse} />
    </Switch>
  );
};

export default PagesRoute;
