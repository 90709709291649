import React from 'react'
import { Slider, InputNumber } from 'antd'
import FeatherIcon from 'feather-icons-react';
import { SLIDER_CHANGE_FACTOR } from '../../../constants'
const Range = ({ setRange, range, title, style }) => {
    const onRangeChange = value => {
        if (value[0] < value[1]) {
            setRange(value)
        }
    };
    return (
        <div style={style}>
            <small style={{ display: 'inline-block' }}>{title}</small>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputNumber
                    style={{ marginRight: '15px', textAlign: 'center' ,fontSize:12}}
                    value={range[0]}
                    onChange={(value) => {
                        if (value < range[1]) {
                            let newRange = [
                                value,
                                range[1]
                            ]
                            onRangeChange(newRange)
                        }
                    }
                    }
                />
                <div style={{ width: '205px' }}>
                    <Slider
                        max={200000}
                        min={0}
                        onChange={onRangeChange}
                        range={true}
                        value={[...range]}
                    />

                </div>
                <InputNumber
                    style={{ marginLeft: '15px', textAlign: 'center',fontSize:12 }}
                    value={range[1]}
                    onChange={(value) => {
                        if (value > range[0]) {
                            let newRange = [
                                range[0],
                                value
                            ]
                            onRangeChange(newRange)
                        }
                    }
                    }
                />
            </div>
        </div>
    )
}

export default React.memo(Range)
