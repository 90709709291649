import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin } from 'antd';
import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getBulkBuyerList } from '../../redux/users/actionCreator';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../config/config';
import { uploadFileToAssets } from '../../utility/apiUtils';

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const BulkModal = ({ showmodal, onClose }) => {
  const [file, setOrgFile] = React.useState({});
  const [loader, setLoader] = React.useState(false);

  const dispatch = useDispatch();
  const handleclose = () => {
    onClose;
  };

  const props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setOrgFile(info.fileList[0]);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const handleSubmit = async() => {
    setLoader(true);
    if (isEmpty(file)) {
      message.error('Please upload file!');
      setLoader(false);
    } else {
      const fileData = file?.originFileObj;
      const uploadedFile = await uploadFileToAssets({ file: fileData, type: "bulk-user" });

      const data = {
        file_id: uploadedFile?._id
      }

      axios
        .post(`/api/products/upload-bulk-buyer`, data)
        .then(res => {
            onClose();
            dispatch(getBulkBuyerList());
            message.success(res?.data?.message);
            setLoader(false);
        })
        .catch(err => {
          message.error(err?.response?.data?.message);
          setLoader(false);
        });
    }
  };
  return (
    <>
      <Modal
        title={
          <div>
            Please select excel file or{' '}
            <a download="bulkbuyer" href={require(`../../static/files/bulkbuyer.xlsx`)}>
              Download our template
            </a>
          </div>
        }
        visible={showmodal}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="submit" disabled={loader} onClick={handleSubmit} type="primary">
            {loader && <Spin style={{ color: '#fff', fontSize: 18 }} />} Upload
          </Button>
        ]}
      >
        <div>
          <div>
            <a href=""></a>
          </div>
          <Upload
            {...props}
            beforeUpload={() => {
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
      </Modal>
    </>
  );
};
export default BulkModal;
