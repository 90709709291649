const actions = {
    GET_PAYMENT_LIST_BEGIN : 'GET_PAYMENT_LIST_BEGIN',
    GET_PAYMENT_LIST_SUCCESS : 'GET_PAYMENT_LIST_SUCCESS',
    GET_PAYMENT_LIST_ERR : 'GET_PAYMENT_LIST_ERR',




    getPaymentListBegin: () => {
        return{
            type: actions.GET_PAYMENT_LIST_BEGIN,
        }
    },

    getPaymentListSuccess: data => {
        return{
            type: actions.GET_PAYMENT_LIST_SUCCESS,
            data
        }
    },

    getPaymentListErr: err => {
        return{
            type:actions.GET_PAYMENT_LIST_ERR,
            err
        };
    }
};

export default actions;