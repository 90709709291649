import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const SalesList = lazy(() => import('../../container/pages/SalesScoutListTable'));
const EditSales = lazy(() => import('../../container/pages/EditSalesForm'));
const DeliveryList = lazy(() => import('../../container/pages/DeliveryScoutListTable'));
const EditDelivery = lazy(() => import('../../container/pages/EditDeliveryForm'));
const BuyerDetails = lazy(() => import('../../container/pages/BuyerDetails'));

const Sales = () => {
  const { path } = useRouteMatch();

  return (
    // <h1>Comming Soon..</h1>
    <Switch>
      {/* <Route path={`${path}/:id`} component={BuyerDetails} /> */}
      <Route eaxct path={`${path}/`} component={SalesList} />
      {/* <Route  path={`${path}/add`} exact component={() => <h1>hello</h1>} /> */}
      <Route exact path={`${path}/view-sales/:id`} component={EditSales} />
      <Route exact path={`${path}/edit-sales/:id`} component={EditSales} />
    </Switch>
  );
};

export default Sales;
