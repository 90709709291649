
const actions = {
    GET_REQUEST_LIST_BEGIN: 'GET_REQUEST_LIST_BEGIN',
    GET_REQUEST_LIST_SUCCESS: 'GET_REQUEST_LIST_SUCCESS',
    GET_REQUEST_LIST_ERR: 'GET_REQUEST_LIST_ERR',
    
    GET_BUYER_LIST_BEGIN: 'GET_BUYER_LIST_BEGIN',
    GET_BUYER_LIST_SUCCESS: 'GET_BUYER_LIST_SUCCESS',
    GET_BUYER_LIST_ERR: 'GET_BUYER_LIST_ERR',

    UPDATE_REQUEST_STATUS_BEGIN: 'UPDATE_REQUEST_STATUS_BEGIN',
    UPDATE_REQUEST_STATUS_SUCCESS: 'UPDATE_REQUEST_STATUS_SUCCESS',
    UPDATE_REQUEST_STATUS_ERR: 'UPDATE_REQUEST_STATUS_ERR',

    RESET_FLAGS: 'RESET_FLAGS',

    getRequestListBegin: () => {
      return {
        type: actions.GET_REQUEST_LIST_BEGIN,
      };
    },
    
    getRequestListSuccess: data => {
        return {
          type: actions.GET_REQUEST_LIST_SUCCESS,
          data
        };
      },
      
    getRequestListErr: err => {
        return {
          type: actions.GET_REQUEST_LIST_ERR,
          err
        };
      },
      
    updateRequestStatusBegin: () => {
        return {
          type: actions.UPDATE_REQUEST_STATUS_BEGIN,
        };
      },
      
      updateRequestStatusSuccess: response => {
          return {
            type: actions.UPDATE_REQUEST_STATUS_SUCCESS,
            response
          };
        },
        
      updateRequestStatusErr: err => {
          return {
            type: actions.UPDATE_REQUEST_STATUS_ERR,
            err
          };
        },

        resetFlags: () => {
          return {
            type: actions.RESET_FLAGS,   
          };
        },
  };
  
  export default actions;
  