import actions from './action';

const initialState = {
  brandData: [],
  brandCount: 0,
  isBrandLoading: false,
  singleBrand: null,
  isFetchingSingleBrand: false,
  categories: [],
  categoriesCount: 0,
  isCategoriesLoading: false,
  subCategories: [],
  subCategoriesCount: 0,
  isSubCatgoriesLoading: false,
  categoryWithSubCategories: [],
  error: null
};

const {
  BRAND_BEGIN,
  GET_BRAND_SUCCESS,
  ADD_BRAND_SUCCESS,
  EDIT_BRAND_SUCCESS,
  DELETE_BRAND_SUCCESS,
  BRAND_ERR,
  GET_SINGLE_BRAND_BEGIN,
  GET_SINGLE_BRAND_SUCCESS,
  GET_SINGLE_BRAND_ERROR,
  CATEGORY_BEGIN,
  GET_CATEGORY_SUCCESS,
  CATEGORY_ERROR,
  SUBCATEGORY_BEGIN,
  GET_SUBCATEGORY_SUCCESS,
  SUBCATEGORY_ERROR,
  GET_CATEGORY_SUBCATEGORY
} = actions;

const brandReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case BRAND_BEGIN:
      return {
        ...state,
        isBrandLoading: true
      };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        brandData: data?.brands,
        brandCount: data?.brandCount,
        isBrandLoading: false
      };
    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        isBrandLoading: false
      };
    case EDIT_BRAND_SUCCESS:
      return {
        ...state,
        isBrandLoading: false,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        isBrandLoading: false,
      };
    case BRAND_ERR:
      return {
        ...state,
        error: err,
        isBrandLoading: false
      };
    case GET_SINGLE_BRAND_BEGIN:
      return {
        ...state,
        isFetchingSingleBrand: true
      };
    case GET_SINGLE_BRAND_SUCCESS:
      return {
        ...state,
        singleBrand: data,
        isFetchingSingleBrand: false
      };
    case GET_SINGLE_BRAND_ERROR:
      return {
        ...state,
        isFetchingSingleBrand: false
      };
    case CATEGORY_BEGIN:
      return {
        ...state,
        isCategoriesLoading: true
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data?.categories,
        categoriesCount: data?.categoryCount,
        isCategoriesLoading: false
      };
    case CATEGORY_ERROR:
      return {
        ...state,
        isCategoriesLoading: false
      };
    case SUBCATEGORY_BEGIN:
      return {
        ...state,
        isSubCatgoriesLoading: true
      };
    case GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: data?.subCategories,
        subCategoriesCount: data?.subCategoryCount,
        isSubCatgoriesLoading: false
      };
    case SUBCATEGORY_ERROR:
      return {
        ...state,
        isSubCatgoriesLoading: false
      };
    case GET_CATEGORY_SUBCATEGORY:
      return {
        ...state,
        categoryWithSubCategories: data,
        isCategoriesLoading: false
      };
    default:
      return state;
  }
};

export default brandReducer;
