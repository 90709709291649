import React, { useState, useEffect } from 'react';
import { Modal, Button, InputNumber, message, Radio } from 'antd';

const AssignModal = ({ showmodal, eventFunc, onClose, availableCouponCount, selectedBuyers, couponData, userCoupons }) => {
    const [count, setCount] = useState(1)
    const [assignType, setAssignType] = useState('all')
    useEffect(() => {
        showmodal && setCount(1)
    }, [showmodal])

    const handleAssign = () => {
        let _selectedBuyers = []
        if (assignType === 'all') _selectedBuyers = selectedBuyers
        
        if (assignType === 'new') {
            _selectedBuyers = selectedBuyers.filter(c => !userCoupons.some(u => u.user._id === c.key))
        }

        if (assignType === 'existing') {
            _selectedBuyers = selectedBuyers.filter(c => userCoupons.some(u => u.user._id === c.key))
        }

        if (assignType === 'used') {
            _selectedBuyers = selectedBuyers.filter(c => userCoupons.some(u => u.user._id === c.key && u.total_used > 0))
        }

        if (_selectedBuyers.length === 0) {
            message.warn('Please select at least one user.')
            return
        }


        let isLess = _selectedBuyers.length * count <= availableCouponCount + (_selectedBuyers.reduce((a, b) => a + (b?.max_use || 0), 0) - _selectedBuyers.reduce((a, b) => a + (b?.total_used || 0), 0))
        
        if (_selectedBuyers.length * count <= availableCouponCount) {
            eventFunc(count, assignType, _selectedBuyers);
            onClose();
        } else if (isLess) {
            eventFunc(count, assignType, _selectedBuyers);
            onClose();
        } else {
            message.warn(`Assigned counts exceeds the maximum coupon count.`);
        }
    };
    const handleChange = (value) => {
        function checkAvailability(arr, val) {
            return arr.some(function (arrVal) {
                return val === arrVal;
            });
        }
        let _selectedBuyers = selectedBuyers
        let newBuyers = selectedBuyers.filter(c => !userCoupons.some(u => u.user._id === c.key))
        let existingBuyers = selectedBuyers.filter(c => userCoupons.some(u => u.user._id === c.key))
        let usedBuyers = selectedBuyers.filter(c => userCoupons.some(u => u.user._id === c.key && u.total_used > 0))
        

        let total_used_all = userCoupons?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
        let selectedBuyersTotal = 0
        let selectedBuyerUsed = 0
        let changeRowsId = []
        let selectTotal = []
        
        if (assignType === 'new') {
            //if already assigned buyer then
            changeRowsId = newBuyers?.map(s => s?.key)
            selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))
            selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
            selectedBuyersTotal = existingBuyers?.reduce((partial_sum, a) => partial_sum + a?.max_use, 0) 
            selectedBuyersTotal = selectedBuyersTotal + newBuyers.length * value
        }
        if (assignType === 'existing') {
            //if already assigned buyer then
            changeRowsId = existingBuyers?.map(s => s?.key)
            selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))
            // console.log({selectTotal});
            selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
            selectedBuyersTotal = newBuyers?.reduce((partial_sum, a) => partial_sum + a?.max_use, 0) 
            selectedBuyersTotal = selectedBuyersTotal + existingBuyers.length * value
        }

        if (assignType === 'used') {
            //if already assigned buyer then
            changeRowsId = usedBuyers?.map(s => s?.key)
            selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))
            selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
            selectedBuyersTotal = newBuyers?.reduce((partial_sum, a) => partial_sum + a?.max_use, 0) 
            let usedBuyerTotal = usedBuyers?.reduce((partial_sum, a) => partial_sum + a?.max_use, 0)
            selectedBuyersTotal = selectedBuyersTotal + usedBuyers.length * value + usedBuyerTotal
        }

        if (assignType === 'all') {
            changeRowsId = _selectedBuyers?.map(s => s?.key)
            selectTotal = userCoupons?.filter(u => checkAvailability(changeRowsId, u?.user?._id))
            // console.log({selectTotal});
            selectedBuyerUsed = selectTotal?.reduce((partial_sum, a) => partial_sum + a?.total_used, 0);
            
            selectedBuyersTotal = selectedBuyers?.length * value

        }

        let threshold = (couponData?.max_use || 0) - total_used_all 


        let isLess = (selectedBuyersTotal - selectedBuyerUsed) <= threshold
        if (isLess) {
            setCount(value);
        } else {
            message.warn(`Assigned counts exceeds the maximum coupon count.`);
        }
    }

    const onChange = (e) => {
        setAssignType(e.target.value)
    }

    return (
        <>
            <Modal title="Assign coupon" visible={showmodal} onCancel={onClose} onOk={handleAssign}>
                {/* number of coupon to allow */}
                <div style={{display:'flex', justifyContent:'space-evenly'}}>
                    <div style={{ backgroundColor: 'rgb(244, 245, 247)', padding: 10}}>
                        <p>Coupon Per User</p>
                        <InputNumber size='small' value={count} onChange={handleChange} min={1} />
                    </div>
                    <div style={{ backgroundColor: 'rgb(244, 245, 247)', padding: 10}}>
                        <p>Assign To:</p>
                        <Radio.Group onChange={onChange} value={assignType}>
                            <Radio value={'new'}>New</Radio>
                            <Radio value={'existing'}>Existing</Radio>
                            <Radio value={'used'}>Used</Radio>
                            <Radio value={'all'}>All</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default AssignModal;
