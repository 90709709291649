import actions from './actions';

const initialState = {
  rfqList: [],
  rfqPList: [],
  rfqP:null,
  loading: false,
  error: null
};

const {
  GET_RFQ_P_LIST_BEGIN,
  GET_RFQ_P_LIST_SUCCESS,
  GET_RFQ_P_LIST_ERROR,
  GET_RFQ_LIST_BEGIN,
  GET_RFQ_LIST_SUCCESS,
  GET_RFQ_LIST_ERROR,
  GET_RFQ_EXCEL_BEGIN,
  GET_RFQ_EXCEL_SUCCESS,
  GET_RFQ_P_SUCCESS,
  GET_RFQ_EXCEL_ERROR
} = actions;

const rfqReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch(type) {
    case 'GET_RFQ_P':
      return {
        ...state,
        rfqP: data
      }
    case GET_RFQ_P_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_RFQ_P_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        rfqPList: data,  
      };
    case GET_RFQ_P_LIST_ERROR:
      return {
        ...state,
        loading: false,
        rfqPList: [],
        err: err,
      };
    case GET_RFQ_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_RFQ_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        rfqList: data,  
      };
    case GET_RFQ_LIST_ERROR:
      return {
        ...state,
        loading: false,
        rfqList: [],
        err: err,
      };
    case GET_RFQ_EXCEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_RFQ_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        rfqExcel: data
      };
      case GET_RFQ_P_SUCCESS:
        return {
          ...state,
          loading: false,
          rfqP: data
        };
    case GET_RFQ_EXCEL_ERROR:
      return {
        ...state,
        loading: false,
        err: err
      }
    default:
      return state;
  }
};

export default rfqReducer;
