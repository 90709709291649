import React, { useState, useEffect } from 'react';
import { Row, Col, Input,Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';  
import OrderTable from './OrderTable';
import { AutoCompleteStyled } from '../../components/autoComplete/style';
import BulkModal from '../../components/modals/BulkModal';
import AddCategories from '../../components/modals/AddCategories';
import AddSubCategories from '../../components/modals/AddSubCategories';
import {getCategories, getSubCategories} from '../../redux/categories/actionCreator'
import { useSelector, useDispatch } from 'react-redux';
import SubcategoriesTable from './SubcategoriesTable'
import { useParams } from 'react-router';

const SubCatListing = () => {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [filteredData, setFilteredData] = React.useState([]);
  const [fitlerByOrderStatus, setFitlerByOrderStatus] = useState('all');
  const [ordersList, setOrdersList] = useState([]);
  const [catmodal, setCatmodal] = React.useState(false);
  const [subcatmodal, setSubCatmodal] = React.useState(false);

  const dispatch=useDispatch();
  const { id } = useParams();

  useEffect(()=>{
      dispatch(getSubCategories(id))
      dispatch(getCategories())

  },[dispatch])  
  // useEffect(() => {
  //   if (orders && orders.length > 0) {
  //     setOrdersList(orders);
  //     setFilteredData(orders);
  //   }
  // }, [orders]);

  // const handleSearch = e => {
  //   setSearchKeyword(e.target.value);
  //   const filter = ordersList.filter(obj => obj?.name.toLowerCase().indexOf(searchKeyword) > -1);
  //   setFilteredData(filter);
  // };

  // const handleSearchByOrderStatus = status => {
  //   let filter = null;
  //   if (status !== 'all') {
  //     filter = ordersList.filter(obj => obj.parentCat?.toLowerCase() === status);
  //   }
  //   setFilteredData(filter || ordersList);
  // };


  const handleClosecat=()=>{
    setCatmodal(false)
  }
  const handleCloseSubcat=()=>{
    setSubCatmodal(false)
  }

  const {subCategories,categories } = useSelector(state => {
    return {
      subCategories:state.categories.subCategories,
      categories:state.categories.categories
    };
  });
  
    
 

  return (
    <div className="admin_category_table">
      <CardToolbox>
        <PageHeader
          ghost
          title="Sub Categories"
          subTitle={
            <>
              <span className="title-counter">{subCategories && subCategories.length} Subcategories </span>
              <AutoCompleteStyled>
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  // onChange={handleSearch}
                  value={searchKeyword}
                />
              </AutoCompleteStyled>
              
            </>
          }
         
        />
      </CardToolbox>
      <Main>
          {/* <AddCategories
              parentCategories={parentCategories}
              showmodal={catmodal}
              onClose={handleClosecat}
            /> */}
           <AddSubCategories 
            showmodal={subcatmodal}
            onClose={handleCloseSubcat}
           />
        <Row gutter={15}>
          <Col md={24}>
            <SubcategoriesTable
              subCategories={subCategories}
              categories={categories}
              paramsId={id}
            />
          </Col>
        </Row>
      </Main>
    </div>
  );
};

export default SubCatListing;
