import React from 'react';
import { Modal, Button, Spin, Select, Input, Form, Space, message } from 'antd';
import { useDispatch } from 'react-redux';
import { addSubCategory } from '../../redux/categories/actionCreator'
import { MinusCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

const AddSubCategories = ({ categories, showmodal, onClose, modalClose }) => {
  const [loader, setLoader] = React.useState(false);

  const [form] = Form.useForm();

  const dispatch = useDispatch()

  const closeLoader = () => {
    setLoader(false)
  }

  const resetFields = () => {
    form.resetFields()
  }

  const handleSubmit = async () => {
    await form.submit();
  }

  const onFinish = async values => {
    const newArraySubCat = []

    if (!values?.users || !values?.users?.length) {
      message.warning("Please add atleast one subcategory");
      return;
    }

    await values.users.forEach((item) => {
      newArraySubCat.push({ 'name': item.subcategory, 'category_id': values?.category, 'erpCode': item.erpCode })
    })
    setLoader(true)
    dispatch(addSubCategory(newArraySubCat, modalClose, closeLoader, resetFields))
  };

  return (

    <Modal title={
      <div>
        Please select parent category and add new category.
      </div>
    } visible={showmodal} onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" onClick={handleSubmit} disabled={loader} type="primary">
          {loader && <Spin style={{ color: '#fff', fontSize: 18 }} />} Add SubCategory
        </Button>
      ]}
    >
      <div style={{ height: 'auto' }}>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          size="default"
          layout={'vertical'}
        >
          <Form.Item
            style={{ width: '100%', display: 'block' }}
            label="Category"
            name={"category"}
            rules={[{ required: true, message: 'Category is required' }]}
          >
            <Select placeholder="Choose Category" >
              {categories && categories.map((item, i) => {
                return <Option key={i} value={item._id}>{item.name}</Option>
              })}
            </Select>
          </Form.Item>
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} style={{ display: 'flex', marginBottom: 8, width: '100%', gap: "8px", alignItems: "center" }}>
                    <Form.Item
                      style={{ width: '100%', display: 'block' }}
                      {...restField}
                      label="Sub category"
                      name={[name, 'subcategory']}
                      fieldKey={[fieldKey, 'subcategory']}
                      rules={[{ required: true, message: 'Missing SubCategory' }]}
                    >
                      <Input placeholder="Sub Category" style={{ width: '100%', display: 'block' }} />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', display: 'block' }}
                      {...restField}
                      label="ERP Code"
                      name={[name, 'erpCode']}
                      fieldKey={[fieldKey, 'erpCode']}
                      rules={[{ required: true, message: 'Missing ERP Code' }]}
                    >
                      <Input placeholder="ERP Code" style={{ width: '100%', display: 'block' }} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    + Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </Modal>

  );
};
export default AddSubCategories;



