import React, { useState, useEffect } from 'react';
import { Card, Tabs, Row, Col, Image } from 'antd';

import MySales from './MySales';
import DefaultImg from '../../../static/img/defaultImg.png';

const { TabPane } = Tabs;

function ImageDemo(img) {
  return (
    <Image
      width={200}
      src={img || DefautImg}
      fallback={DefaultImg}
    />
  );
}
function ImageDemoPdf() {
  return <img width={200} src={require('../../../static/img/pdf.svg')} />;
}
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const UserProfileTabs = ({ orderDetais,  userData, setPage, setPerPage }) => {
  const [document, setDocument] = useState([]);

  useEffect(() => {
    let docarray = [];
    if (!isEmpty(userData) && userData) {
      userData?.details?.trade_license_upload &&
        docarray.push({ value: userData?.details?.trade_license_upload, key: 'Trade Licence' });
      userData?.details?.vat_registration_upload &&
        docarray.push({ value: userData?.details?.vat_registration_upload, key: 'VAT Registration' });
      userData?.details?.bank_ref_letter_upload &&
        docarray.push({ value: userData?.details?.bank_ref_letter_upload, key: 'Bank Refrence Letter' });
      userData?.details?.supporting_document &&
        docarray.push({ value: userData?.details?.supporting_document, key: 'Supporting Document' });
      userData?.details?.emirates_id && docarray.push({ value: userData?.details?.emirates_id, key: 'Emirates Id' });
      userData?.details?.emirate_id && docarray.push({ value: userData?.details?.emirate_id, key: 'Emirate Id' });
      userData?.details?.vat_certification_upload &&
        docarray.push({ value: userData?.details?.vat_certification_upload, key: 'Vat Certification' });
    }
    setDocument(docarray);
  }, [userData]);
  return (
    <Card>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab={userData?.role === 'BUYER' ? 'Buyer Orders' : 'Seller Sales'} key="1">
          <MySales orders={orderDetais} setPage={setPage} setPerPage={setPerPage}/>
        </TabPane>
        <TabPane tab={userData?.role === 'BUYER' ? 'Buyer Documents' : 'Seller  Documents'} key="2">
          <div>
            {document && document.length > 0 ? (
              <Row>
                {document?.map(item => {
                  let fileurl = item?.value;
                  if(typeof fileurl === 'object') {
                    fileurl = fileurl[0]
                  }
                  const extension = fileurl?.split('.')?.pop();
                  return (
                    <Col xl={6} md={6} xs={6} key={item.key}>
                      <div>
                        {extension === 'pdf' ? (
                          <div>
                            <a href={item?.value || '#'} target="_blank" download>
                              {ImageDemoPdf()}
                              <h3 style={{ marginLeft: '24px' }}>{item.key}</h3>
                            </a>
                          </div>
                        ) : (
                          <div>
                            {ImageDemo(item.value)}
                            <h3>{item.key}</h3>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <p style={{ fontSize: 18, fontStyle: 'italic' }}>No any documents avaliable yet !</p>
              </div>
            )}
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default UserProfileTabs;
