import actions from './actions';
import axios from 'axios';
import initialState from '../../demoData/products.json';
import { message } from 'antd';
export const UPDATE_BUSINESS_MODEL = 'UPDATE_BUSINESS_MODEL';

const {
  GET_BULKUPLOADED_LISTING_BEGIN,
  GET_BULKUPLOADFILES,
  GET_BULKUPLOADED_LISTING,
  getListingsBegin,
  getListingsSuccess,
  getListingsPaginationSuccess,
  emptyListingsPaginationSuccess,
  getListingsErr,
  getListingBegin,
  getListingSuccess,
  getListingErr,
  sortingListingBegin,
  sortingListingSuccess,
  sortingListingErr,
  filterListingBegin,
  filterListingSuccess,
  filterListingErr,
  getAllBrandsBegin,
  getAllBrandsSuccess,
  getAllBrandsErr,
  singleProductBegin,
  singleProductSuccess,
  singleProductErr,
  getAllCategoriesBegin,
  getAllCategoriesSuccess,
  getAllCategoriesErr,
  getSubCategoriesBegin,
  getSubCategoriesSuccess,
  getSubCategoriesErr,
  getAllCarMakesBegin,
  getAllCarMakesSuccess,
  getAllCarMakesErr,
  getCarModelsBegin,
  getCarModelsSuccess,
  getCarModelsErr,
  createListingBegin,
  createListingSuccess,
  createListingErr,
  updateListingBegin,
  updateListingSuccess,
  updateListingErr,
  resetListingFlags,
  getMyListingsSuccess,
  getSingleListingsSuccess,
  getReviewSuccess,
  getCatName
} = actions;

const getListings = () => {
  return async dispatch => {
    try {
      dispatch(getListingsBegin());
      const resp = await axios.get('/api/listings');
      dispatch(getListingsSuccess(resp.data.listings));
    } catch (err) {
      dispatch(getListingsErr(err));
    }
  };
};


const getListingsFromPagination = (page, size, filters, sort) => {
  return async dispatch => {
    try {
      dispatch(getListingsBegin());
      let URL = `/api/listings?page=${page}&perPage=${size}&fromAdmin=true`;
      if (Object.keys(filters).length > 0) {
        Object.keys(filters).map(key => {
          if (filters[key] !== undefined && filters[key] !== null) {
            if (key === 'max_price') URL = `${URL}&${key}=${(filters[key]).toString().replace(/\+/g, '%2B')}`;
            else URL = `${URL}&${key}=${filters[key]}`;
          }
        })
      }
      if (sort) URL = `${URL}&sort=${sort}`;
      const resp = await axios.get(URL);
      dispatch(getListingsPaginationSuccess(resp.data, page));
    } catch (err) {
      dispatch(getListingsErr(err));
    }
  };
};
const emptyListingsFromPagination = () => {
  return async dispatch => {
    dispatch(emptyListingsPaginationSuccess());
  };
};

const getCategoryName = id => {
  return async dispatch => {
    try {
      dispatch(getListingsBegin());
      const resp = await axios.get('/api/categories/all');
      dispatch(getCatName(resp.data));
    } catch (err) {
      dispatch(getListingsErr(err));
    }
  };
};

const GetMyListings = id => {
  return async dispatch => {
    axios
      .get(`/api/listings?userId=${id}`)
      .then(res => {
        dispatch(getMyListingsSuccess(res?.data?.listings));
      })
      .catch(err => {
        dispatch(getListingsErr(err));
      });
  };
};

const getSingleListings = sku => {
  return async dispatch => {
    axios
      .get(`/api/listings/get-single-listing?sku=${sku}`)
      .then(res => {
        const { listings } = res.data;
        dispatch(getSingleListingsSuccess(listings));
      })
      .catch(err => {
        dispatch(getListingsErr(err));
      });
  };
};

const getbulkListingFiles = (user_id = '') => {
  return async dispatch => {
    try {
      const resp = await axios.get(`/api/products/get-bulkuploaded-files?user_id=${user_id}`);

      dispatch({ type: GET_BULKUPLOADFILES, data: resp.data });
    } catch (err) {
      dispatch({ type: GET_BULKUPLOADFILES, data: [] });
    }
  };
};

const addAssigner = data => {
  return async dispatch => {
    try {
      await axios.patch(`/api/products/add-assigner`, data);
    } catch (err) {
      dispatch(getOrderErr(err));
    }
  };
};

const getbulkListing = id => {
  return async dispatch => {
    try {
      // dispatch(getListingBegin());
      dispatch({ type: GET_BULKUPLOADED_LISTING_BEGIN });
      const resp = await axios.get(`/api/products/get-bulkuploaded-listings/${id}`);
      const result = await axios.get(`/api/products/get-bulkuploaded-files/${id}`);
      const { _id, user: seller, createdAt, file_name, type } = result.data || {};
      dispatch({ type: 'SAVE_BULK_FILE', data: { _id, seller, createdAt, file_name, type } });
      dispatch({ type: GET_BULKUPLOADED_LISTING, data: resp.data });
    } catch (err) {
      dispatch({ type: GET_BULKUPLOADED_LISTING, data: [] });
    }
  };
};

const approveBulkList = (payload, setLoading, onClose, fileId, history, clearAll) => {
  return async dispatch => {
    try {
      dispatch(createListingBegin());
      const resp = await axios.put(`${process.env.REACT_APP_ASSETS_URL}/api/products/approve-bulkuploaded-list`, payload);

      dispatch(createListingSuccess(resp.data));
      setLoading(false);
      message.success(`Listing approved successfully.`);
      onClose();
      dispatch(getbulkListing(fileId));
      history.push({
        pathname: `/admin/listings/view-bulk-detail/${fileId}`
      });
      clearAll();
    } catch (err) {
      dispatch(createListingErr(err));
      message.error(err.response.data.message);
      setLoading(false);
    }
  };
};

const getListing = (listing_id) => {
  return async dispatch => {
    try {
      dispatch(getListingBegin());
      const resp = await axios.get(`/api/listings/${listing_id}?similar=false`);
      let listing = resp?.data?.listing
      if(!listing?.imageArray.length && listing.type ==='Aftermarket'){
        listing.imageArray = listing?.taImages?.map(ob=>ob['imageURL800'] || ob['imageURL1600']).filter(i=>i) || []
        listing.pic =  listing?.imageArray[0] || null
      }
      dispatch(getListingSuccess(listing));
    } catch (err) {
      dispatch(getListingErr(err));
    }
  };
};

const getReview = id => {
  return async dispatch => {
    try {
      dispatch(getListingBegin());
      const resp = await axios.get(`/api/review/${id}`);
      dispatch(getReviewSuccess(resp?.data));
    } catch (err) {
      dispatch(getListingErr(err));
    }
  };
};

const getBrandList = () => {
  return async dispatch => {
    try {
      dispatch(getAllBrandsBegin());
      const resp = await axios.get('/api/listing-brands?all=true&array=true');
      dispatch(getAllBrandsSuccess(resp.data.listingBrands));
    } catch (err) {
      dispatch(getAllBrandsErr(err));
    }
  };
};

const sorting = sortBy => {
  return async (dispatch, getState) => {
    try {
      dispatch(sortingListingBegin());
      setTimeout(() => {
        const data = getState().listings.filteredData.sort((a, b) => {
          if (sortBy === 'date') return new Date(b[sortBy]) - new Date(a[sortBy]);
          else return b[sortBy] - a[sortBy];
        });
        dispatch(sortingListingSuccess(data));
      }, 100);
    } catch (err) {
      dispatch(sortingListingErr(err));
    }
  };
};

const filterByPriceRange = range => {
  return async dispatch => {
    dispatch(filterListingBegin());
    try {
      const resp = await axios.post('/api/listing/filter-by-price', range);
      dispatch(filterListingSuccess(resp.data));
    } catch (err) {
      dispatch(filterListingErr(err));
    }
  };
};

const filterByCategory = category => {
  return async dispatch => {
    dispatch(filterListingBegin());
    try {
      const resp = await axios.post('/api/listing/filter-by-category', { category });
      dispatch(filterListingSuccess(resp.data));
    } catch (err) {
      dispatch(filterListingErr(err));
    }
  };
};

const filterByBrand = brand => {
  return async (dispatch, getState) => {
    try {
      dispatch(filterListingBegin());
      if (!brand[0].length) dispatch(filterListingSuccess(getState().listings.data));
      else {
        const data = getState().listings.data.filter(part => {
          return brand[0].includes(part.partBrand);
        });
        dispatch(filterListingSuccess(data));
      }
    } catch (err) {
      dispatch(filterListingErr(err));
    }
  };
};

const filterSinglePage = (paramsId, currentState) => {
  return async dispatch => {
    try {
      dispatch(singleProductBegin());
      const data = currentState.filter(product => {
        return product.id === paramsId;
      });
      dispatch(singleProductSuccess(data));
    } catch (err) {
      dispatch(singleProductErr(err));
    }
  };
};

const updateWishList = id => {
  return async dispatch => {
    try {
      dispatch(filterListingBegin());

      initialState.map(product => {
        if (product.id === id) {
          return product.popular ? (product.popular = false) : (product.popular = true);
        }
        return dispatch(filterListingSuccess(initialState));
      });
    } catch (err) {
      dispatch(filterListingErr(err));
    }
  };
};

const getAllCategories = () => {
  return async dispatch => {
    try {
      dispatch(getAllCategoriesBegin());
      const resp = await axios.get('/api/categories/all');
      dispatch(getAllCategoriesSuccess(resp.data));
    } catch (err) {
      dispatch(getAllCategoriesErr(err));
    }
  };
};

const getSubCategories = categoryId => {
  return async dispatch => {
    try {
      dispatch(getSubCategoriesBegin());
      const resp = await axios.get(`/api/subcategories/${categoryId}`);

      dispatch(getSubCategoriesSuccess(resp.data));
    } catch (err) {
      dispatch(getSubCategoriesErr(err));
    }
  };
};

const getAllCarMakes = () => {
  return async dispatch => {
    try {
      dispatch(getAllCarMakesBegin());
      const resp = await axios.get('/api/car_makes?all=true');
      dispatch(getAllCarMakesSuccess(resp.data?.carMakes));
    } catch (err) {
      dispatch(getAllCarMakesErr(err));
    }
  };
};

const getCarModels = carMakeId => {
  return async dispatch => {
    try {
      dispatch(getCarModelsBegin());
      const resp = await axios.post(`/api/car_models/get-models`, { makes: carMakeId });
      dispatch(getCarModelsSuccess(resp.data));
    } catch (err) {
      dispatch(getCarModelsErr(err));
    }
  };
};

const createListing = (formData, setLoading, history) => {
  return async dispatch => {
    try {
      dispatch(createListingBegin());
      const resp = await axios.post(`/api/listings`, formData);
      dispatch(createListingSuccess(resp.data));
      message.success('Successfully added a new listing !');
      history.push(`/admin/listings`);
      setLoading(false);
      // clearAll();
    } catch (err) {
      dispatch(createListingErr(err));
      message.error(err?.response?.data?.message || "Error while creating a listing");
      setLoading(false);
      console.log(err)
    }
  };
};

const updateListing = (formData, history, setLoading) => {
  return async dispatch => {
    try {
      dispatch(updateListingBegin());
      const resp = await axios.put(`/api/listings/${formData.list_id}`, formData);
      dispatch(updateListingSuccess(resp.data));
      message.success('Successfully updated a listing!');
      setLoading(false);
      history.push(`/admin/listings`);
    } catch (err) {
      dispatch(updateListingErr(err));
      message.error(err?.response?.data?.message || 'Something went wrong!');
      setLoading(false);
    }
  };
};

export const updateBusinessModel = (id, data) => {
  return async dispatch => {
    try {
      await axios.put(`/api/listings/${id}`, data);
      dispatch({
        type: UPDATE_BUSINESS_MODEL,
        payload: id
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const resetFlags = () => {
  return async dispatch => {
    dispatch(resetListingFlags());
  };
};

export {
  getListings,
  getListingsFromPagination,
  emptyListingsFromPagination,
  getBrandList,
  sorting,
  filterSinglePage,
  filterByPriceRange,
  filterByBrand,
  filterByCategory,
  updateWishList,
  getAllCategories,
  getSubCategories,
  getAllCarMakes,
  getCarModels,
  createListing,
  updateListing,
  resetFlags,
  getListing,
  getbulkListingFiles,
  getbulkListing,
  approveBulkList,
  GetMyListings,
  addAssigner,
  getCategoryName,
  getSingleListings,
  getReview
};
