import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button, DatePicker, Input, Checkbox } from 'antd';
import axios from 'axios'
import { SearchOutlined } from '@ant-design/icons';
import { getBuyerList } from '../../../redux/users/actionCreator';
import moment from 'moment';
import Range from './Range';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(timezone);

const SearchBuyer = ({ form, reset, setBuyers, setBuyerCount, coupon, setLoading, page, limit, setPage }) => {
    const dispatch = useDispatch();
    const [emirate, setEmirate] = useState([])
    const [region, setRegion] = useState([])
    const [category, setCategory] = useState([])
    const [joiningDate, setJoiningDate] = useState([])
    const [keyword, setKeyword] = useState('')
    const [filters, setFilters] = useState([])
    const [orderRange, setOrderRange] = useState([0, 200000])
    const [revenueRange, setRevenueRange] = useState([0, 2000000])
    const [totalUsed, setTotalUsed] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [order_date, setORderDateRange] = useState([])
    const [order_value_date, setOrderValueDateRange] = useState([])
    const [buyerType, setBuyerType] = useState([])
    function handleChangeBuyerType(_buyerType, type) {
        setBuyerType(type)
    }

    const { _buyers } = useSelector(state => {
        return {
            _buyers: state.users.buyers?.filter(s => s.status === 'Approved'),
        };
    });
    // useEffect(() => {
    //     _setBuyers(_buyers)
    // }, [_buyers?.length])

    // const _setBuyers = (buyers) => {
    //     setBuyers(buyers)

    // }

    const getFilters = async () => {
        await axios.get('/api/coupon/filters').then(res => {
            setFilters(res.data)
        })
    }

    useEffect(() => {
        getFilters()
    }, [])

    useEffect(() => {
        searchBuyer()
    }, [])

    useEffect(() => {
        let timeout
        if (page !== 1) {
            setPage(1)
            return;
        }
        if (!isFirstRender) {
            timeout = setTimeout(searchBuyer, 400)
        }
        setIsFirstRender(false)
        return () => clearTimeout(timeout)

    }, [orderRange, revenueRange, emirate, region, category, joiningDate, keyword, totalUsed, order_date, buyerType])


    const emirateOptions = useMemo(() => {
        let emirates = filters.find(f => f.type === "emirates")?.value || []
        return emirates.map(s => ({
            value: s.name,
            label: s.name,
            ...s
        }));
    }, [filters]);

    const regionOptions = useMemo(() => {
        let regions = filters.find(f => f.type === "region")?.value || []
        let options = []
        for (let i = 0; i <= emirate.length; i++) {
            let em = regions.filter(r => r.state === emirate[i]?._id)
            if (em.length) {
                let opt = {
                    label: emirate[i].label,
                    options: em.map(s => ({ value: s.name, label: s.name, ...s }))
                }
                options.push(opt)
            }
        }

        return options

    }, [filters, emirate]);

    const categoryOptions = useMemo(() => {
        let categories = [
            'Fleet & Corporate',
            'Chained/Third-party [Independent] Garages',
            'Independent Mom & Pop Garages',
            'Spare Parts & Traders',
            'Gas Station Network',
            'Tyre & Brake Outlets'
        ]
        return categories.map(s => ({
            value: s,
            label: s
        }));
    }, []);

    const handleEmirateChange = (_emirate, emirate) => {
        setEmirate(emirate)
        setRegion([])
    }

    const handleRegionChange = (_region, region) => {
        setRegion(region)
    }

    const handleCategoryChange = (_category, category) => {
        setCategory(category)
    }

    function onDateChange(date, dateString, name) {
        if (name === 'order_date') {
            setORderDateRange(dateString)
        }
        else if (name === 'order_value_date') {
            setOrderValueDateRange(dateString)
        }
        else {
            setJoiningDate(dateString)
        }
    }
    const [isRangeRange, setIsRangeRange] = useState(false)
    const searchBuyer = async () => {
        let URL = `/api/users?role=BUYER&all=true&status=Approved`;

        let em = emirate.map(i => i.value);
        if (em.length) URL = `${URL}&emirate=${em}`;

        let rn = region?.map(i => i.value)
        if (rn.length) URL = `${URL}&region=${rn}`;

        let cat = category?.map(i => encodeURIComponent(i.value))
        console.log(cat);

        if (cat.length) URL = `${URL}&category=${cat}`;
        if (orderRange.length) {
            if (orderRange[0] !== 0 || orderRange[1] !== 200000)
                URL = `${URL}&order_range=${orderRange}`;
        }
        if (revenueRange) {
            if (revenueRange[0] !== 0 || revenueRange[1] !== 2000000)
                URL = `${URL}&revenue_range=${revenueRange}`;
        }
        if (totalUsed) URL = `${URL}&total_used=${totalUsed}`;
        if (coupon) URL = `${URL}&coupon=${coupon}`;
        if (joiningDate) URL = `${URL}&joining_date=${joiningDate}`;
        if (order_date.length) URL = `${URL}&order_date=${order_date}`;
        if (keyword) URL = `${URL}&search=${keyword}`;

        let bType = buyerType?.map(i => i.value)
        setLoading && setLoading(true)


        await axios.get(URL).then(res => {
            setBuyers(res.data?.users);
            setBuyerCount(res.data?.total);
        }).finally(() => {
            setLoading && setLoading(false)
        })
    }


    const resetFilters = () => {
        setJoiningDate([])
        setKeyword('')
        setEmirate([])
        setRegion([])
        setCategory([])
        setOrderRange([0, 200000])
        setRevenueRange([0, 2000000])
        setTotalUsed(false)
        setIsRangeRange(false)
        setORderDateRange([])

    }
    useEffect(() => {
        if (reset) {
            resetFilters()
        }
    }, [reset]);

    const handleSearch = () => {
        if (page !== 1) {
            ;
            return;
        }
        searchBuyer();
    }
    const rangePresets = [
        { 'Last day': [dayjs().add(-1, 'd'), dayjs()] },
        { 'Last 7 days': [dayjs().add(-14, 'd'), dayjs()] },
        { 'Last 90 days': [dayjs().add(-90, 'd'), dayjs()] },
        { 'Last 180 days': [dayjs().add(-180, 'd'), dayjs()] },
    ];
    return (
        <>
            <small style={{ padding: '10px 30px', margin: 0, display: 'block', marginTop: -30 }}>Filter by search, date and coupon usage</small>
            <div style={{ display: 'block', flexWrap: 'wrap', padding: '0 30px', alignItems: 'center' }}>

                <div style={{}}>
                    <Input
                        placeholder="Search by Name, Phone, Email"
                        onChange={(e) => {
                            setKeyword(e.target.value)
                        }}
                        value={keyword}
                        size="small"
                        style={{ height: 35, width: '100%' }}
                    />
                </div>
                <div style={{ marginTop: 25 }}>
                    <RangePicker
                        style={{ height: 35, width: '100%' }} placeholder={["Joining Date Start", "Joining Date End"]} size='small'
                        onChange={onDateChange}
                        value={[joiningDate[1] ? moment(joiningDate[0]) : undefined, joiningDate[1] ? moment(joiningDate[1]) : undefined]}
                    />
                    {/* <DatePicker style={{ height: 35, width: 200 }} placeholder="Joining Date" size='small' value={joiningDate ? moment(joiningDate) : undefined} onChange={onDateChange} /> */}
                </div>
                {coupon && <Checkbox style={{ marginLeft: 25 }} checked={totalUsed} onChange={(e) => setTotalUsed(e.target.checked)}>Total Used</Checkbox>}
            </div>
            <small style={{ padding: '10px 30px', margin: 0, paddingTop: 20, display: 'block' }}>Filter by Emirates, Region or Category of Buyer</small>
            <div style={{ display: 'block', flexWrap: 'wrap', padding: '20px 30px', paddingTop: 0, gap: 20 }}>
                <div style={{ display: 'flex', justifyContent: "space-between", gap: 40, marginBottom: 16 }} >
                    <div style={{ width: '50%' }}>
                        <Select
                            value={emirate}
                            style={{ width: '100%', height: 'inherit', maxHeight: 90, overflowY: "scroll", scrollbarWidth: 'thin' }}
                            onChange={handleEmirateChange}
                            options={emirateOptions}
                            mode='multiple'
                            placeholder="Select a Emirate"
                            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            filterSort={(optionA, optionB) =>
                                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                            }
                            showSearch
                        />
                    </div>
                    <div style={{ width: '50%' }}>

                        <Select
                            value={region}
                            style={{
                                width: '100%', height: 'inherit', maxHeight: 90, overflowY: "scroll", scrollbarWidth: 'thin',
                            }}
                            onChange={handleRegionChange}
                            mode='multiple'
                            options={regionOptions}
                            placeholder="Select a Region"
                            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            filterSort={(optionA, optionB) =>
                                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                            }
                            showSearch
                        />
                    </div>
                </div>
                <div style={{}}>

                    <Select
                        value={category}
                        style={{ width: '100%', height: 'inherit', maxHeight: 90, overflowY: "scroll", scrollbarWidth: 'thin' }}
                        onChange={handleCategoryChange}
                        mode='multiple'
                        options={categoryOptions}
                        placeholder="Select a Category"
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        filterSort={(optionA, optionB) =>
                            optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                        }
                        showSearch
                    />
                </div>
            </div>
            <div style={{ display: 'block', flexWrap: 'wrap', padding: '0 35px' }}>

                <Range range={orderRange}
                    setRange={(value) => (setOrderRange(value), setIsRangeRange(true))} title="No.of Orders " />
                <Range range={revenueRange} setRange={(value) => (setRevenueRange(value), setIsRangeRange(true))} title="Order Value" style={{ marginTop: 20 }} />


                <div style={{ display: 'flex', alignItems: "center", gap: '12px', marginTop: '14px' }}>
                    <span style={{ fontSize: '12px' }}>Date Range</span>
                    <RangePicker
                        style={{ height: 35, width: '80%' }}
                        name='order_date'

                        ranges={{
                            "Last day": [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            "Last 7 days": [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            "Last 30 days": [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            "Last 90 days": [moment().subtract(90, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 180 days': [moment().subtract(180, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        }}
                        placeholder={["From", "To"]} size='small'
                        onChange={(date, dateRanage) => onDateChange(date, dateRanage, 'order_date')}
                        value={[order_date[1] ? moment(order_date[0]) : undefined, order_date[1] ? moment(order_date[1]) : undefined]}
                    />
                </div>
            </div>
            <div style={{ display: 'block', flexWrap: 'wrap', padding: '20px 30px' }}>
                {/* <Button onClick={handleSearch} icon={<SearchOutlined />} style={{ margin: 0 }}>
                    Search Buyer
                </Button> */}
                <Button onClick={resetFilters} style={{ margin: 0, marginLeft: 25 }}>
                    Reset Filters
                </Button>
            </div>
        </>
    );
};

export default React.memo(SearchBuyer);