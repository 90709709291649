import actions from './actions';

const {
  GET_MAKE_BEGIN, GET_MAKE_SUCCESS, GET_MAKE_FAILED,
  ADD_MAKE_BEGIN, ADD_MAKE_SUCCESS, ADD_MAKE_FAILED,
  UPDATE_MAKE_BEGIN, UPDATE_MAKE_SUCCESS, UPDATE_MAKE_FAILED,
  DELETE_MAKE_BEGIN, DELETE_MAKE_SUCCESS, DELETE_MAKE_FAILED
} = actions;

const initState = {
  makes: [],
  total: 0,
  currentPage: 1,
  pageLimit: 10,
  loading: false,
  error: null
};

const MakeReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_MAKE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MAKE_SUCCESS:
      return {
        ...state,
        makes: data.carMakes,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_MAKE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_MAKE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_MAKE_SUCCESS:
      return {
        ...state,
        makes: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_MAKE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_MAKE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MAKE_SUCCESS:
      return {
        ...state,
        makes: data,
        loading: false,
      };
    case UPDATE_MAKE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_MAKE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MAKE_SUCCESS:
      return {
        ...state,
        makes: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_MAKE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default MakeReducer;