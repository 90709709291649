const actions = {
  ALL_CHATROOM_BEGIN: 'STAFF_CHATROOM_BEGIN_AB',
  ALL_CHATROOM_SUCCESS: 'STAFF_CHATROOM_SUCCESS_AB',
  ALL_CHATROOM_ERR: 'STAFF_CHATROOM_ERR_AB',

  CHATROOM_MESSAGE_BEGIN: 'STAFF_MESSAGE_BEGIN_AB',
  CHATROOM_MESSAGE_SUCCESS: 'STAFF_MESSAGE_SUCCESS_AB',
  CHATROOM_MESSAGE_ERR: 'STAFF_MESSAGE_ERR_AB',
  SAVE_SOCKET_USER: 'SAVE_SOCKET_USER_AB',
  saveSocketUser: data => {
    return {
      type: actions.SAVE_SOCKET_USER,
      data,
    };
  },

  chatRoomBegin: () => {
    return {
      type: actions.ALL_CHATROOM_BEGIN
    };
  },

  chatRoomSuccess: data => {
    return {
      type: actions.ALL_CHATROOM_SUCCESS,
      data
    };
  },

  chatRoomErr: err => {
    return {
      type: actions.ALL_CHATROOM_ERR,
      err
    };
  },

  chatRoomMessageBegin: () => {
    return {
      type: actions.CHATROOM_MESSAGE_BEGIN
    };
  },
  chatRoomMessageSuccess: data => {
    return {
      type: actions.CHATROOM_MESSAGE_SUCCESS,
      data
    };
  },

  chatRoomMessageErr: err => {
    return {
      type: actions.CHATROOM_MESSAGE_ERR,
      err
    };
  }
};

export default actions;
