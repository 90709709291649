import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Input, Button } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../styled';
import OrderTable from './OrderTable';
import axios from 'axios';
import { AutoCompleteStyled } from '../../components/autoComplete/style';
import { SearchOutlined } from '@ant-design/icons';

const Orders = () => {
  const [enquiryList, setEnquiryList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const fetchMyAPI = useCallback(async () => {
    let response = await axios.get('api/enquiry');
    setEnquiryList(response?.data?.enquiry);
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [isFiltered]);

  React.useEffect(() => {
    setFilteredData(enquiryList);
  }, [enquiryList]);

  const handleSearch = e => {
    setSearchKeyword(e.target.value);
    setIsFiltered(e.target.value === "" && true)
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      const filter = enquiryList.filter(obj => obj.name.toLowerCase().indexOf(searchKeyword) > -1);
      setFilteredData(filter);
    }
  };

  const handleSearchOnClick = (event) => {
    event.preventDefault(); // Prevent form submission
    const filter = enquiryList.filter(obj => obj.name.toLowerCase().indexOf(searchKeyword) > -1);
    setFilteredData(filter);
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Enquiry List"
          subTitle={
            <>
              <span className="title-counter">{filteredData && filteredData.length} Enquiries </span>
              <AutoCompleteStyled>
                <Input
                  suffix={<Button type="link" onClick={(e) => handleSearchOnClick(e)}><SearchOutlined /></Button>}
                  placeholder="Search by Enquiry name"
                  onChange={handleSearch}
                  value={searchKeyword}
                  onKeyDown={handleKeyDown}
                />
              </AutoCompleteStyled>
            </>
          }
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <OrderTable orders={filteredData} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Orders;
