import actions from './actions';

const initialState = {
  homepageSections: [],
  singleHomePageSection: null,
  isLoading: false,
  totalCount: 0,
  error: null,
  setting: {},
  deliveryTimes: []
};

const {
  HOMEPAGE_SECTION_BEGIN,
  GET_HOMEPAGE_SECTION_SUCCESS,
  ADD_HOMEPAGE_SECTION_SUCCESS,
  HOMEPAGE_SECTION_ERR,
  EDIT_HOMEPAGE_SECTION_SUCCESS,
  DELETE_HOMEPAGE_SECTION_SUCCESS,
  GET_SINGLE_HOMEPAGE_SECTION_SUCCESS,
  GET_SETTING_BEGIN,
  GET_SETTING_SUCCESS,
  GET_SETTING_FAILED,
  DELIVERY_TIMES_BEGIN,
  GET_DELIVERY_TIME_SUCCESS,
  DELIVERY_TIMES_ERROR
} = actions;

const settingReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SETTING_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        setting: data
      };
    case GET_SETTING_FAILED:
      return {
        ...state,
        loading: false,
        err: err
      };
    case HOMEPAGE_SECTION_BEGIN:
      return {
        ...state,
        isLoading: true,
        singleHomePageSection: null
      };
    case GET_HOMEPAGE_SECTION_SUCCESS:
      return {
        ...state,
        homepageSections: data?.homePageSettings,
        totalCount: data?.dataCount,
        isLoading: false
      };
    case GET_SINGLE_HOMEPAGE_SECTION_SUCCESS:
      return {
        ...state,
        singleHomePageSection: data?.homePageSetting,
        isLoading: false
      };
    case ADD_HOMEPAGE_SECTION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case EDIT_HOMEPAGE_SECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_HOMEPAGE_SECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case HOMEPAGE_SECTION_ERR:
      return {
        ...state,
        error: err,
        isLoading: false
      };
    case DELIVERY_TIMES_BEGIN: 
      return {
        ...state,
        isLoading: true
      }
    case GET_DELIVERY_TIME_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        deliveryTimes: data?.deliveryTimes,
        totalCount: data?.total,
      }
    case DELIVERY_TIMES_ERROR: 
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
};

export default settingReducer;
