import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const WalletList = lazy(() => import('../../container/pages/wallet'));
const AddWallet = lazy(() => import('../../container/pages/wallet/AddWallet'));
const EditWallet = lazy(() => import('../../container/pages/wallet/EditWallet'));


const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={WalletList} />
      <Route path={`${path}/add`} component={AddWallet} />
      <Route path={`${path}/edit/:id`} component={EditWallet} />
    </Switch>
  );
};

export default PagesRoute;
