import actions from './actions';
import axios from 'axios';
import { socket } from '../../utility/utility';
import { baseUrl } from '../../constants';
const {
  chatRoomBegin,
  chatRoomSuccess,
  chatRoomErr,
  chatRoomMessageBegin,
  chatRoomMessageSuccess,
  chatRoomMessageErr,
  saveSocketUser
} = actions;

const getAllChatRooms = () => {
  return async dispatch => {
    try {
      dispatch(chatRoomBegin());
      const res = await axios.get('/api/staff-chat-rooms/get-all-rooms');
      const rooms = res?.data?.rooms;
      dispatch(chatRoomSuccess(rooms));
    } catch (err) {
      dispatch(chatRoomErr(err));
    }
  };
};

const getStaffChatRooms = (user_id = '') => {
  return async dispatch => {
    if (user_id) {
      try {
        dispatch(chatRoomBegin());
        // const resp = await axios.get(`${baseUrl}/api/staff-chat-rooms/get-user-rooms?userId=${user_id}`);
        let rooms = [].sort(function (a, b) {
          return new Date(b.lastTime) - new Date(a.lastTime);
        });
        dispatch({
          type: 'INITIALIZE',
          rooms,
          unreadMsgCnt: resp.data.unreadMsgCnt
        });
      } catch (err) {
        dispatch(chatRoomErr(err));
      }
    }
  };
};

export const SetOnlineUsers = users => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_ONLINE_USERS',
      onlineUsers: users
    });
    return Promise.resolve();
  };
};

export const UpdateRooms = data => {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_ROOMS',
      data
    });
    return Promise.resolve();
  };
};

export const AddMessage = data => {
  return (dispatch, state) => {
    dispatch({
      type: 'ADD_MESSAGE',
      data
    });
    return Promise.resolve();
  };
};

export const GetConversations = (roomId, userId) => {
  return async (dispatch, getState) => {
    try {
      const resp = await axios.get(`/api/staff-message/conversations?roomId=${roomId}&userId=${userId}`);
      const newReadMsg = resp.data.newReadMsg;
      dispatch({
        type: 'SET_MESSAGES',
        messages: resp.data.conversations,
        newReadMsg
      });
      return true;
    } catch (err) {
      // popUp("There was a problem with server. Please try again.", 'error');
      return err;
    }
  };
};

export const resetActiveRoom = () => dispatch => {
  dispatch({
    type: 'RESET_ACTIVE_ROOM'
  });
};

export const UpdateActiveRoom = data => {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_ACTIVE_ROOM',
      data
    });
    return Promise.resolve();
  };
};

export const AddTypingUser = data => {
  return (dispatch, getState) => {
    dispatch({
      type: 'ADD_TYPING_USER',
      data
    });
    return Promise.resolve();
  };
};

export const RemoveTypingUser = data => {
  return (dispatch, getState) => {
    dispatch({
      type: 'REMOVE_TYPING_USER',
      data
    });
    return Promise.resolve();
  };
};

export const SetActiveRoom = data => {
  const roomId = data;
  return async (dispatch, getState) => {
    dispatch({
      type: 'SET_ACTIVE_ROOM',
      roomId
    });
    return Promise.resolve();
  };
};

export const ReceiveNewMessage = data => {
  return (dispatch, getState) => {
    const { room, message, sender, createdAt } = data;
    if (getState().staffChat.activeRoom && getState().staffChat.activeRoom.roomId === room) {
      axios
        .post('/api/staff-message/set-read', { id: data._id })
        .then(res => {
          let newMessages = getState().staffChat.messages;
          newMessages.push({
            room,
            message,
            sender,
            status: 'read',
            createdAt
          });
          let newRooms = getState().staffChat.rooms;
          const foundIndex = newRooms.findIndex(x => x.roomId === room);
          newRooms[foundIndex].lastMsg = message;
          newRooms[foundIndex].unreadMsgCnt = 0;
          newRooms[foundIndex].lastTime = createdAt;
          dispatch({
            type: 'UPDATE_MESSAGES',
            data: newMessages
          });

          dispatch({
            type: 'UPDATE_ROOMS',
            data: newRooms
          });
          return Promise.resolve();
        })
        .catch(err => {
          // popUp("There was a problem with server. Please try again.", 'error');
        });
    } else {
      let newRooms = getState().staffChat.rooms;
      const foundIndex = newRooms.findIndex(x => x.roomId === room);
      newRooms[foundIndex]['lastMsg'] = message;
      newRooms[foundIndex]['unreadMsgCnt'] = newRooms[foundIndex]['unreadMsgCnt'] + 1;
      newRooms[foundIndex]['lastTime'] = createdAt;
      dispatch({
        type: 'INCREASE_UNREAD_MESSAGE'
      });
      dispatch({
        type: 'UPDATE_ROOMS',
        data: newRooms
      });
      return Promise.resolve();
    }
  };
};

const createChatRoom = (id, admin) => {
  return async dispatch => {
    try {
      dispatch(chatRoomBegin());
      await axios.post('/api/staff-chat-rooms/add', { staff_id: id, admin_id: admin });
      // dispatch(getStaffChatRooms(id));
    } catch (err) {
      dispatch(chatRoomErr(err));
    }
  };
};

const setSocket = socket => async dispatch => {
  try {
    dispatch(saveSocketUser(socket));
  } catch (error) {
    console.log(error);
  }
};

const getChatRoomMessages = id => {
  return async dispatch => {
    try {
      dispatch(chatRoomMessageBegin());
      const res = await axios.get(`/api/staff-chat-rooms/get-room-message/${id}`);
      const roomMessage = res?.data?.messages;

      dispatch(chatRoomMessageSuccess(roomMessage));
    } catch (err) {
      dispatch(chatRoomMessageErr(err));
    }
  };
};
export { getAllChatRooms, getChatRoomMessages, createChatRoom, setSocket, getStaffChatRooms };
