import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';


const Orders = lazy(() => import('../../container/orders'));
const OrderDetails = lazy(() => import('../../container/orders/OrderDetails'));


const OrdersRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>

      <Route path={`${path}/view/:id`} component={OrderDetails} />
      <Route path={`${path}`} component={Orders} />
    </Switch>
  );
};

export default OrdersRoute;
