import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const NewsletterSubsList = lazy(() => import('../../container/pages/newsletter'));


const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={NewsletterSubsList} />
    </Switch>
  );
};

export default PagesRoute;
