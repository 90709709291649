const actions = {
  ALL_CHATROOM_BEGIN: 'STAFF_CHATROOM_BEGIN',
  ALL_CHATROOM_SUCCESS: 'STAFF_CHATROOM_SUCCESS',
  ALL_CHATROOM_ERR: 'STAFF_CHATROOM_ERR',

  CHATROOM_MESSAGE_BEGIN: 'STAFF_MESSAGE_BEGIN',
  CHATROOM_MESSAGE_SUCCESS: 'STAFF_MESSAGE_SUCCESS',
  CHATROOM_MESSAGE_ERR: 'STAFF_MESSAGE_ERR',
  SAVE_SOCKET_USER: 'SAVE_SOCKET_USER',
  saveSocketUser: data => {
    return {
      type: actions.SAVE_SOCKET_USER,
      data,
    };
  },

  chatRoomBegin: () => {
    return {
      type: actions.ALL_CHATROOM_BEGIN
    };
  },

  chatRoomSuccess: data => {
    return {
      type: actions.ALL_CHATROOM_SUCCESS,
      data
    };
  },

  chatRoomErr: err => {
    return {
      type: actions.ALL_CHATROOM_ERR,
      err
    };
  },

  chatRoomMessageBegin: () => {
    return {
      type: actions.CHATROOM_MESSAGE_BEGIN
    };
  },
  chatRoomMessageSuccess: data => {
    return {
      type: actions.CHATROOM_MESSAGE_SUCCESS,
      data
    };
  },

  chatRoomMessageErr: err => {
    return {
      type: actions.CHATROOM_MESSAGE_ERR,
      err
    };
  }
};

export default actions;
