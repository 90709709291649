import Cookies from 'js-cookie';
import actions from './actions';
import axios from 'axios';
import { message } from 'antd';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, registerBegin, registerSuccess, registerErr } = actions;

const login = (loginValues, history, path) => {
  return async dispatch => {
    try {
      // const playerId = await OneSignal.getPlayerId();
      dispatch(loginBegin());
      axios
        .post(`/api/admin_users/login`, {
          method: 'email',
          email: loginValues.username?.trim()?.toLowerCase(),
          password: loginValues.password?.trim(),
        })
        .then(res => {
          let user = { ...res.data.user, token: res.data.token };
          // user = JSON.stringify(user)
          // dispatch(getPermissions(res.data?.user?.role));
          Cookies.set('logedIn', user);
          Cookies.set('access_token', user?.token);
          dispatch(loginSuccess(user));
          history.push(path.from.pathname);
        })
        .catch(err => {
          console.log(err);
          dispatch(loginErr(err.response?.data?.message));
        });
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const getPermissions = (role) => {
  return async dispatch => {
    try {
      axios
        .get(`/api/roles-permission/${role}`)
        .then(res => {
          dispatch({ type: "SET_PERMISSIONS", data: res.data });
        })
        .catch(err => {
          dispatch({ type: "SET_PERMISSIONS", data: [] });
        });
    } catch (err) {
      dispatch({ type: "SET_PERMISSIONS", data: [] });
    }
  };
}

const getRoles = (role) => {
  return async dispatch => {
    axios
      .get(`/api/roles-permission`)
      .then(res => {
        const roles = res.data?.map(r => r.role) ?? []
        dispatch({ type: "SET_ROLES", data: roles });
      })
      .catch(_ => {
      });
  };
}

const setNotification = (noOfUnseen) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "ADD_UNSEEN_NOTIFICATION_COUNT",
      data: noOfUnseen
    })
  }
}
const getNotification = user_id => {
  return async dispatch => {
    try {
      axios
        .get(`/api/admin_users/notification?user_id=${user_id}`)
        .then(res => {
          dispatch({
            type: "GET_NOTIFICATION",
            data: res.data
          })
        })
        .catch(err => {
          console.log(err)
        });
    } catch (err) {
      console.log(err)
    }
  };
};

const getNotificationInital = user_id => {
  return async dispatch => {
    try {
      axios
        .get(`/api/admin_users/get-notification-inital?user_id=${user_id}`)
        .then(res => {
          dispatch({
            type: "GET_NOTIFICATION",
            data: res.data
          })
        })
        .catch(err => {
          console.log(err)
        });
    } catch (err) {
      console.log(err)
    }
  };
};

const register = (values, history, successRoute = "") => {
  return async dispatch => {
    dispatch(registerBegin())
    axios
      .post(`/api/admin_users/register`, values)
      .then(res => {
        dispatch(registerSuccess())
        message.success('Successfully added a new User!')
        history.push(`/admin/${successRoute}`)
      })
      .catch(err => {
        dispatch(registerErr());
        message.error(err.response.data.message || "Error while creating new user");
      });
  };
};

const registerStaff = (values, history) => {
  return async dispatch => {
    try {
      // dispatch(loginBegin());
      axios
        .post(`/api/admin_users/register`, values)
        .then(res => {
          // Cookies.set('logedIn', res.data);
          // return dispatch(loginSuccess(res.data));
          message.success('Successfully added a new User!')
          history.push('/admin/staff')
          return true
        })
        .catch(err => {
          message.error(err.response.data.message)
        });
    } catch (err) {
      // dispatch(loginErr(err));
    }
  };
};


const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      dispatch(logoutSuccess(null));

    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register, setNotification, getNotification, getNotificationInital, getPermissions, registerStaff, getRoles };
