import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Upload, Select, Checkbox, TimePicker, message, Button as AntdButton } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { OrderCard } from './style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import Heading from '../../components/heading/heading';
import { Main, BasicFormWrapper } from '../styled';
import { getbulkListing } from '../../redux/listing/actionCreator';
import moment from 'moment';
import config from '../../config/config';
import List from './overview/List';
import { UserAvatar } from '../../utility/utility';
import FileUploadModal from '../../components/modals/FileUploadModal ';
const { Option } = Select;
const Details = ({ match, location }) => {
  const dispatch = useDispatch();
  const id = match.params.id;
  const bulkFile = useSelector(state => state.listings.bulkFile);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { bulkListing, loading } = useSelector(state => {
    return {
      bulkListing: state.listings.bulkListing,
      loading: state.listings.isListLoading
    };
  });

  useEffect(() => {
    dispatch(getbulkListing(id));

  }, [id]);
  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <PageHeader
        ghost
        title="Bulk Listing Details"
        subTitle={'Total Listing: ' + (bulkListing?.length || 0)}
        // buttons={[
        //   <a href="/admin/buyparts24_rfq_template.xls" target="_blank">
        //     Download XLS Template
        //   </a>,
        // ]}
      />
      <Main>
        <OrderCard>
          <Cards headless={true}>
            <Row justify="space-between">
              <Col>
                <Heading as="h6">File ID</Heading>
                <span>{id?.substring(0, 6)?.toUpperCase()}</span>
              </Col>
              <Col>
                <Heading as="h6">Seller Info</Heading>
                <div style={{display:'flex', flexDirection:'row' }}>
                  {/* <figure> */}
                  <UserAvatar
                    avatar={bulkFile?.seller?.avatar} 
                    alt="user"
                    style={{ width: 40, borderRadius:20, marginRight:10, height:40, objectFit:'cover'}}/>
                  {/* </figure> */}
                  <div>
                  {bulkFile?.seller?.details?.company_name || '...'},<br />
                  <FeatherIcon icon="map-pin" size={14} />
                  {bulkFile?.seller?.details?.emirate}
                  </div>
                </div>
              </Col>
              <Col>
                <Heading as="h6">Date</Heading>
                <span>
                  {bulkFile
                    ? moment(bulkFile.createdAt)
                        .tz('Asia/Dubai')
                        .format('L')
                    : '...'}
                  {bulkFile
                    ? moment(bulkFile.createdAt)
                        .tz('Asia/Dubai')
                        .format('LTS')
                    : '...'}
                </span>
              </Col>
              <Col >
                <Heading as="h6">Seller Excel File</Heading>
                {bulkFile?.file_name ? (
                  <>
                    <div>
                      <a href={`${bulkFile?.file_name}`} download>
                        Download file
                      </a>
                    </div>
                  </>
                ) : (
                  <span>...</span>
                )}
              </Col>
              <Col>
                <Heading as="h6">Reupload Excel File</Heading>
                {bulkFile?.file_name ? (
                  <>
                    <div>
                      <AntdButton size="small" disabled={bulkListing?.length ? false : true} onClick={() => setIsModalOpen(true)} key="1">
                        + Upload File
                    </AntdButton>
                    </div>
                  </>
                ) : (
                  <span>...</span>
                )}
              </Col>
            </Row>
          </Cards>
        </OrderCard>
        <FileUploadModal 
          showmodal={isModalOpen}
          type={bulkFile?.type}
          onClose={handleClose}
          id={id}
          userId={bulkFile?.seller?._id}
          filename={bulkFile?.file_name}
          />
        <List fileId={id} bulkListing={bulkListing} isLoader={loading} />
      </Main>
    </>
  );
};

Details.propTypes = {
  match: propTypes.object
};

export default Details;
