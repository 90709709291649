import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, REGISTER_BEGIN, REGISTER_SUCCESS, REGISTER_ERR } = actions;

const initState = {
  login: Cookies.get('logedIn') || null,
  routePermissions:[],
  roles: [],
  loading: false,
  error: null,
  notification:{
    noOfUnseen:0
  }
  
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case 'SET_PERMISSIONS':{
      return {
        ...state,
        routePermissions: data
    }
  }
    case 'SET_ROLES':{
      return {
        ...state,
        roles: data
    }
  }
    case 'ADD_UNSEEN_NOTIFICATION_COUNT':
      return {
        ...state,
        notification:{
          ...state.notification,
          noOfUnseen:data
        }
      }
    case 'GET_NOTIFICATION':
      return {
        ...state,
        notification:data
      }
    
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case REGISTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REGISTER_ERR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
