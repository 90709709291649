import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/pages/wizards/Wizards'));
const BuyerList = lazy(() => import('../../container/pages/BuyerListDataTable'));
const SellerList = lazy(() => import('../../container/pages/SellerListDataTable'));
const RequestList = lazy(() => import('../../container/pages/RequestListDataTable'));
const EditSeller = lazy(() => import('../../container/pages/EditSellerForm'));
const EditBuyer = lazy(() => import('../../container/pages/EditBuyerForm'));
const BuyerDetails = lazy(() => import('../../container/pages/BuyerDetails'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/sellers`} component={SellerList} />
      <Route path={`${path}/buyers/:id`} component={BuyerDetails} />
      <Route path={`${path}/buyers`} component={BuyerList} />
      <Route exact path={`${path}/view-seller/:id`} component={EditSeller} />
      <Route exact path={`${path}/view-buyer/:id`} component={EditBuyer} />
      <Route path={`${path}/edit-seller/:id`} component={EditSeller} />
      <Route path={`${path}/edit-buyer/:id`} component={EditBuyer} />
      <Route path={`${path}/add`} component={AddUser} />
      <Route path={`${path}/request`} component={RequestList} />
    </Switch>
  );
};

export default PagesRoute;
