import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Row, Col, Form, Input, Button, message, TreeSelect } from 'antd';
import { Main, BasicFormWrapper } from '../styled';
import { Modal } from '../../components/modals/antd-modals';
import { Cards } from '../../components/cards/frame/cards-frame';
const { SHOW_PARENT } = TreeSelect;

const EditModal = ({visible, closeModal, transformedRoutes, role, getRoleAndPermissions}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const handleUpdate = () => {
        form.submit();
    }
    const handleSubmit = ({role, routePermissions}) => {
        setLoading(true);
        axios.put(`/api/roles-permission/${role}`, {routePermissions})
        .then(res => {
            message.success('Role updated successfully');
        })
        .catch(err => {
            message.error('Error updating role');
        }).finally(() => {
            setLoading(false);
            closeModal();
            getRoleAndPermissions();
        })
    }

    useEffect(() => {
        if(role){
            form.setFieldsValue({
                role: role.role,
                routePermissions: role.routePermissions
            })
        }
    },[role]);
  return (
    <Modal
        width={1000}
          type="primary"
          title={'Edit Role & Route Permissions'}
          visible={visible}
          footer={[
                <Button size="default" type="primary" onClick={() => closeModal()} key="cancel-status">
                  Cancel
                </Button>,
                <Button
                disabled={loading}
                  htmlType="submit"
                  size="default"
                  type="primary"
                  onClick={() => handleUpdate()}
                  key="update-status"
                >
                  Update Role & Route Permissions
                </Button>
              ]
          }
          onCancel={() => closeModal()}
        >
          <div className="customized-msg-add-form" style={{ position: 'relative' }}>
          <BasicFormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              name="rolesandpermissions"
              onFinish={handleSubmit}
            >
                <Row justify="center" gutter={24} style={{ marginBottom: '15px' }}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="role"
                      label="Role"
                      rules={[
                        {
                          message: 'Please input role',
                          required: true
                        }
                      ]}
                    >
                      <Input disabled rows={5} placeholder="role" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={14}>
                    <Form.Item
                      name="routePermissions"
                      label="Route Permission"
                      rules={[
                        {
                          message: 'Please route permissions',
                          required: true
                        }
                      ]}
                    >
                      <TreeSelect
                      treeData={transformedRoutes}
                      treeCheckable
                      showCheckedStrategy={SHOW_PARENT}
                      placeholder="Select route permissions"
                      />
                    </Form.Item>
                  </Col>
                </Row>
            </Form>
          </BasicFormWrapper>

        </div>
        </Modal>
  )
}

export default EditModal