import actions from './actions';
import axios from 'axios';

const { performanceBegin, performanceSuccess, performanceErr } = actions;

const performanceGetData = data => {
  return async dispatch => {
    const { year } = data.performance;
    try {
      dispatch(performanceBegin());
      dispatch(performanceSuccess(year));
    } catch (err) {
      dispatch(performanceErr(err));
    }
  };
};

const performanceFilterData = value => {
  return async dispatch => {
    try {
      dispatch(performanceBegin());
      setTimeout(() => {
        dispatch(performanceSuccess(value));
      }, 100);
    } catch (err) {
      dispatch(performanceErr(err));
    }
  };
};
export { performanceFilterData, performanceGetData };
